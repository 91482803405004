import { Paper, Stack } from '@mui/material';
import { pauseClock, setClockTicks } from '@/stores/ClockStore';
import {
  setCurrentPeriodConfig,
  useFixtureStore,
  type TableAction,
} from '@/stores/FixtureStore';
import { ClockDisplay } from '../Clock/Clock';
import { getPeriodFromTime } from './utils';
import { TimeControl } from './TimeControl';
import { TimeSlider } from './TimeSlider';
import {
  useTimelineMaxTime,
  useTimelinePeriods,
  useTimelineValue,
} from './hooks';

export interface TimelinePeriod {
  offset: number;
  endTime: number | null;
  sequence: number;
  startTime: number;
}

export const Timeline = () => {
  const periodsConfig = useFixtureStore((state) => state.periodsConfig);
  const timelinePeriods = useTimelinePeriods();
  const timeLineMaxTime = useTimelineMaxTime(timelinePeriods.at(-1));
  const timeLineValue = useTimelineValue(timelinePeriods);

  const onChange = (value: number | number[], action?: TableAction) => {
    if (Array.isArray(value)) return;

    const period = action
      ? { ...action.period, offset: 0 }
      : getPeriodFromTime(timelinePeriods, value);
    const periodConfig = periodsConfig.find(
      (periodConfig) => periodConfig.seq === period?.sequence,
    );

    if (!period || !periodConfig) return;

    pauseClock();
    setCurrentPeriodConfig(periodConfig);
    setClockTicks(value - period.offset);
  };

  return (
    <Stack id='time-control' px={5} py={1}>
      <Stack direction={'row'} justifyContent={'center'}>
        <Paper elevation={3} sx={{ padding: (theme) => theme.spacing(1, 2) }}>
          <ClockDisplay />
        </Paper>
      </Stack>
      <TimeSlider
        timeLinePeriods={timelinePeriods}
        onChange={onChange}
        value={timeLineValue}
        maxValue={timeLineMaxTime}
      />
      <TimeControl
        timeLinePeriods={timelinePeriods}
        onChange={onChange}
        value={timeLineValue}
        maxValue={timeLineMaxTime}
      />
    </Stack>
  );
};
