import { FC } from 'react';
import { Stack } from '@mui/material';
import { FreeKickAwardedAction } from '@/types/action/action';
import { REASON_LABEL } from '@/constants';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { getActionMetadata } from '@/utils/actions';
import { RadioButtons } from '@/components/Form/RadioButtons';
import { Autocomplete } from '@/components/Form/Autocomplete';
import { ActionPlayerField } from '@/components/Dialogs/Common/ActionPlayerField';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { FlagField } from '@/components/Dialogs/Common/FlagField';
import { OutcomeField } from '@/components/Dialogs/Common/OutcomeField';
import { ParentAction } from '@/components/ParentAction/ParentAction';
import { SimplePitch } from '../Common/SimplePitch';
import { Controls } from '../Common/Controls';
import {
  FREE_KICK_REASON_OPTIONS,
  FREE_KICK_TYPE,
  FREE_KICK_TYPE_LABEL,
  FreeKickType,
} from './constants';

interface FreeKickDialogContentProps {
  action: FreeKickAwardedAction;
}

const setPartialFreeKickMetadata = (
  metadata: Partial<ActionMeta<FreeKickAwardedAction>>,
) => {
  setActionPartialMetadata<FreeKickAwardedAction>(metadata);
};

export const FreeKickDialogContent: FC<FreeKickDialogContentProps> = ({
  action,
}) => {
  const metadata = getActionMetadata(action);

  const setIsPenalty = (value: number) => {
    setActionPartialMetadata<FreeKickAwardedAction>({
      isPenalty: value === FreeKickType.Penalty,
    });
  };

  return (
    <Stack direction='row' gap={2} flex={1} width='100%'>
      <Stack direction='row' flex={6}>
        <SimplePitch action={action} />
      </Stack>

      <Stack flex={3} gap={2}>
        <RadioButtons
          name='freeKickType'
          onChange={setIsPenalty}
          controlLabel={FREE_KICK_TYPE_LABEL}
          fields={FREE_KICK_TYPE}
          value={
            metadata.isPenalty ? FreeKickType.Penalty : FreeKickType.FreeKick
          }
        />
        <OutcomeField action={action} />
        <Autocomplete
          options={FREE_KICK_REASON_OPTIONS}
          value={metadata.reason}
          onChange={(reason) => setPartialFreeKickMetadata({ reason })}
          label={REASON_LABEL}
        />
        <ActionPlayerField action={action} />
        <PeriodClockField action={action} />
        <ParentAction action={action} />
        <FlagField action={action} />
        <Controls action={action} />
      </Stack>
    </Stack>
  );
};
