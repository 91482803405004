import { RadioButtons } from '@/components/Form/RadioButtons';
import { FILTER_NAME } from '@/stores/FiltersStore/constants';
import { ACTION_STATE, type ActionState } from '@/stores/constants';
import type { FilterComponent } from '../constants';

export const STATUS_OPTIONS: Record<Exclude<ActionState, 'none'>, string> = {
  [ACTION_STATE.ERROR]: 'Not Sent',
  [ACTION_STATE.SYNC]: 'Sending',
  [ACTION_STATE.SUCCESS]: 'Sent',
};

export const StatusFilter: FilterComponent<'status'> = ({
  value,
  onChange,
}) => {
  return (
    <RadioButtons
      controlLabel={FILTER_NAME.STATUS}
      name={FILTER_NAME.STATUS}
      value={value}
      onChange={onChange}
      onClear={() => onChange(null)}
      fields={STATUS_OPTIONS}
    />
  );
};
