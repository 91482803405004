import { ACTION_TYPE_ID, ActionContext } from '@contract';

export const DIRECTION_OF_PLAY_CONFIRMATION =
  'Are you sure you want to change direction of play for your team?';

export const toggleTakenContext = {
  [ACTION_TYPE_ID.Pass]: [
    ActionContext.CornerTaken,
    ActionContext.FreeKickTaken,
    ActionContext.GoalKickTaken,
    ActionContext.PenaltyTaken,
    ActionContext.KickOff,
    ActionContext.KickOffAfterGoal,
  ],
  [ACTION_TYPE_ID.Launch]: [
    ActionContext.FreeKickTaken,
    ActionContext.GoalKickTaken,
  ],
  [ACTION_TYPE_ID.Cross]: [
    ActionContext.CornerTaken,
    ActionContext.FreeKickTaken,
  ],
};
