import { Player } from '@/service/lineups';
import { ACTION_TYPE_ID } from '@contract';
import {
  BadTouchAction,
  BallTouchAction,
  ClearanceAction,
  CrossAction,
  GoalkeeperKickFromHandsAction,
  GoalkeeperPunchAction,
  GoalkeeperThrowAction,
  InterceptionAction,
  LaunchAction,
  PassAction,
  ShotAction,
  TackleAction,
  ThrowInTakenAction,
} from '@/types/action/action';

export const MODE = {
  PITCH: 'pitch',
  PASS: 'pass',
  SHOT: 'shot',
  GOAL: 'goal',
  THROW_IN: 'throw in',
  CROSS: 'cross',
  LAUNCH: 'launch',
  GOALKEEPER_THROW: 'goalkeeper throw',
  YELLOW_CARD: 'yellow card',
  RED_CARD: 'red card',
  VAR_UNDERWAY: 'var underway',
  VAR_RESULT: 'var result',
  SUBSTITUTION: 'substitution',
  OFFSIDE: 'offside',
  FREE_KICK: 'free kick',
  PLAY_STOPPED: 'play stopped',
  GOALKEEPER_PENALTY_FACED: 'goalkeeper penalty faced',
  GOALKEEPER_SAVE: 'goalkeeper save',
  ADDITIONAL_TIME: 'additional Time',
  GOALKEEPER_KICK_FROM_HANDS: 'goalkeeper kick from hands',
  ERROR: 'error',
} as const;
export type Mode = ConstType<typeof MODE>;

export const PLAYER_REF_SYMBOL = Symbol('playerRef');

export type PlayerQuickInputMap = {
  [PLAYER_REF_SYMBOL]?: Player;
  [i: string]: PlayerQuickInputMap;
};

export type TargetActionMode =
  | typeof MODE.PASS
  | typeof MODE.CROSS
  | typeof MODE.LAUNCH
  | typeof MODE.GOALKEEPER_THROW
  | typeof MODE.THROW_IN
  | typeof MODE.GOALKEEPER_KICK_FROM_HANDS;

export type TargetAction =
  | PassAction
  | CrossAction
  | LaunchAction
  | GoalkeeperThrowAction
  | ThrowInTakenAction
  | GoalkeeperKickFromHandsAction;

export const ACTION_TYPE_MODE: Partial<Record<ACTION_TYPE_ID, Mode>> = {
  [ACTION_TYPE_ID.Pass]: MODE.PASS,
  [ACTION_TYPE_ID.Shot]: MODE.SHOT,
  [ACTION_TYPE_ID.Goal]: MODE.GOAL,
  [ACTION_TYPE_ID.ThrowInTaken]: MODE.THROW_IN,
  [ACTION_TYPE_ID.Cross]: MODE.CROSS,
  [ACTION_TYPE_ID.Launch]: MODE.LAUNCH,
  [ACTION_TYPE_ID.GoalkeeperThrow]: MODE.GOALKEEPER_THROW,
  [ACTION_TYPE_ID.YellowCard]: MODE.YELLOW_CARD,
  [ACTION_TYPE_ID.RedCard]: MODE.RED_CARD,
  [ACTION_TYPE_ID.VARUnderway]: MODE.VAR_UNDERWAY,
  [ACTION_TYPE_ID.VARResult]: MODE.VAR_RESULT,
  [ACTION_TYPE_ID.Substitution]: MODE.SUBSTITUTION,
  [ACTION_TYPE_ID.Offside]: MODE.OFFSIDE,
  [ACTION_TYPE_ID.FreeKickAwarded]: MODE.FREE_KICK,
  [ACTION_TYPE_ID.PlayStopped]: MODE.PLAY_STOPPED,
  [ACTION_TYPE_ID.GoalkeeperPenaltyFaced]: MODE.GOALKEEPER_PENALTY_FACED,
  [ACTION_TYPE_ID.GoalkeeperSave]: MODE.GOALKEEPER_SAVE,
  [ACTION_TYPE_ID.AdditionalTime]: MODE.ADDITIONAL_TIME,
  [ACTION_TYPE_ID.GoalkeeperKickFromHands]: MODE.GOALKEEPER_KICK_FROM_HANDS,
} as const;

export function isTargetActionMode(mode: Mode): mode is TargetActionMode {
  return (
    mode === MODE.PASS ||
    mode === MODE.CROSS ||
    mode === MODE.LAUNCH ||
    mode === MODE.GOALKEEPER_THROW ||
    mode === MODE.THROW_IN ||
    mode === MODE.GOALKEEPER_KICK_FROM_HANDS
  );
}

export type AssistAction =
  | PassAction
  | CrossAction
  | LaunchAction
  | ShotAction
  | BallTouchAction
  | BadTouchAction
  | ThrowInTakenAction
  | GoalkeeperThrowAction
  | GoalkeeperPunchAction
  | InterceptionAction
  | ClearanceAction
  | TackleAction
  | GoalkeeperKickFromHandsAction;

export const ACTION_OUTCOME_ONLY_SUCCESS: ACTION_TYPE_ID[] = [
  ACTION_TYPE_ID.Shot,
];
export const ACTION_OUTCOME_DEFAULT_SUCCESS: ACTION_TYPE_ID[] = [
  ...ACTION_OUTCOME_ONLY_SUCCESS,
  ACTION_TYPE_ID.Pass,
  ACTION_TYPE_ID.Cross,
  ACTION_TYPE_ID.Launch,
  ACTION_TYPE_ID.BallTouch,
  ACTION_TYPE_ID.BadTouch,
  ACTION_TYPE_ID.ThrowInAwarded,
  ACTION_TYPE_ID.ThrowInTaken,
  ACTION_TYPE_ID.GoalkeeperThrow,
  ACTION_TYPE_ID.GoalkeeperPunch,
  ACTION_TYPE_ID.GoalKickAwarded,
  ACTION_TYPE_ID.FreeKickAwarded,
  ACTION_TYPE_ID.CornerAwarded,
  ACTION_TYPE_ID.Interception,
  ACTION_TYPE_ID.Clearance,
  ACTION_TYPE_ID.Tackle,
  ACTION_TYPE_ID.Aerial,
  ACTION_TYPE_ID.TakeOn,
  ACTION_TYPE_ID.GoalkeeperKickFromHands,
];
export const ACTION_OUTCOME_ONLY_NONE: ACTION_TYPE_ID[] = [
  ACTION_TYPE_ID.BallRecovery,
  ACTION_TYPE_ID.GoalkeeperSave,
  ACTION_TYPE_ID.GoalkeeperCatch,
  ACTION_TYPE_ID.GoalkeeperDrop,
  ACTION_TYPE_ID.GoalkeeperCollection,
  ACTION_TYPE_ID.GoalkeeperPenaltyFaced,
  ACTION_TYPE_ID.Goal,
  ACTION_TYPE_ID.Dispossessed,
  ACTION_TYPE_ID.Offside,
  ACTION_TYPE_ID.OffsideFor,
  ACTION_TYPE_ID.YellowCard,
  ACTION_TYPE_ID.RedCard,
  ACTION_TYPE_ID.Substitution,
  ACTION_TYPE_ID.VARUnderway,
  ACTION_TYPE_ID.VARResult,
  ACTION_TYPE_ID.Error,
  ACTION_TYPE_ID.PlayStopped,
  ACTION_TYPE_ID.PlayResumed,
  ACTION_TYPE_ID.AdditionalTime,
  ACTION_TYPE_ID.StartPeriod,
  ACTION_TYPE_ID.StartMatch,
  ACTION_TYPE_ID.PlayerBeaten,
  ACTION_TYPE_ID.RefereeDropBall,
];
export const ACTION_ASSIST: ACTION_TYPE_ID[] = [
  ACTION_TYPE_ID.Shot,
  ACTION_TYPE_ID.Pass,
  ACTION_TYPE_ID.Cross,
  ACTION_TYPE_ID.Launch,
  ACTION_TYPE_ID.BallTouch,
  ACTION_TYPE_ID.BadTouch,
  ACTION_TYPE_ID.ThrowInTaken,
  ACTION_TYPE_ID.GoalkeeperThrow,
  ACTION_TYPE_ID.GoalkeeperPunch,
  ACTION_TYPE_ID.Interception,
  ACTION_TYPE_ID.Clearance,
  ACTION_TYPE_ID.Tackle,
  ACTION_TYPE_ID.GoalkeeperKickFromHands,
];
export const ACTION_TEAM_NONE: ACTION_TYPE_ID[] = [
  ACTION_TYPE_ID.StartPeriod,
  ACTION_TYPE_ID.EndPeriod,
  ACTION_TYPE_ID.StartMatch,
  ACTION_TYPE_ID.EndMatch,
  ACTION_TYPE_ID.AdditionalTime,
];
