import { FC } from 'react';
import { RadioButtons } from '@/components/Form/RadioButtons';
import { Action } from '@/types/action/action';
import {
  DIRECTION_OF_PLAY_LABEL,
  DIRECTION_OF_PLAY_OPTIONS,
} from '@/components/Periods/constants';
import { ACTION_TYPE_ID } from '@contract';
import { getActionMetadata } from '@/utils/actions';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';

interface DirectionOfPlayRadioGroupProps {
  action: Action;
}

export const DirectionOfPlayRadioGroup: FC<DirectionOfPlayRadioGroupProps> = ({
  action,
}) => {
  if (action.actionTypeId !== ACTION_TYPE_ID.StartPeriod) return;

  const metadata = getActionMetadata(action);

  const onIsHomeTeamLeftChange = (isHomeTeamLeft: number) => {
    setActionPartialMetadata({ isHomeTeamLeft: !!isHomeTeamLeft });
  };

  return (
    <RadioButtons
      name='isHomeTeamLeft'
      onChange={onIsHomeTeamLeftChange}
      value={Number(metadata.isHomeTeamLeft)}
      fields={DIRECTION_OF_PLAY_OPTIONS}
      controlLabel={DIRECTION_OF_PLAY_LABEL}
    />
  );
};
