import type { FixtureDto } from '@contract';

export const TEAMS = [
  {
    name: 'Juventus',
    shortCode: 'JUV',
  },
  {
    name: 'Torino FC',
    shortCode: 'TOR',
  },
  {
    name: 'Paris Saint-Germain',
    shortCode: 'PSG',
  },
  {
    name: 'Olympique Lyonnais',
    shortCode: 'OL',
  },
  {
    name: 'Bayern Munich',
    shortCode: 'FCB',
  },
  {
    name: 'Borussia Dortmund',
    shortCode: 'BVB',
  },
  {
    name: 'Chelsea',
    shortCode: 'CFC',
  },
  {
    name: 'Arsenal',
    shortCode: 'AFC',
  },
  {
    name: 'Atletico Madrid',
    shortCode: 'ATM',
  },
  {
    name: 'Sevilla FC',
    shortCode: 'SFC',
  },
  {
    name: 'Inter Milan',
    shortCode: 'IM',
  },
  {
    name: 'AC Milan',
    shortCode: 'AM',
  },
  {
    name: 'Real Madrid',
    shortCode: 'RM',
  },
  {
    name: 'FC Barcelona',
    shortCode: 'FCB',
  },
  {
    name: 'Liverpool',
    shortCode: 'LFC',
  },
  {
    name: 'Manchester United',
    shortCode: 'MU',
  },
  {
    name: 'Manchester City',
    shortCode: 'MC',
  },
  {
    name: 'Tottenham Hotspur',
    shortCode: 'TH',
  },
  {
    name: 'Leicester City',
    shortCode: 'LC',
  },
  {
    name: 'West Ham United',
    shortCode: 'WHU',
  },
  {
    name: 'Everton',
    shortCode: 'EFC',
  },
  {
    name: 'Southampton',
    shortCode: 'SFC',
  },
  {
    name: 'Newcastle United',
    shortCode: 'NUFC',
  },
  {
    name: 'Wolverhampton Wanderers',
    shortCode: 'WWFC',
  },
  {
    name: 'Leeds United',
    shortCode: 'LUFC',
  },
  {
    name: 'Aston Villa',
    shortCode: 'AVFC',
  },
  {
    name: 'Crystal Palace',
    shortCode: 'CPFC',
  },
  {
    name: 'Brighton & Hove Albion',
    shortCode: 'BHA',
  },
  {
    name: 'Burnley',
    shortCode: 'BFC',
  },
  {
    name: 'Fulham',
    shortCode: 'FFC',
  },
  {
    name: 'Sheffield United',
    shortCode: 'SUFC',
  },
  {
    name: 'West Bromwich Albion',
    shortCode: 'WBA',
  },
  {
    name: 'Huddersfield Town',
    shortCode: 'HTAFC',
  },
  {
    name: 'Brentford',
    shortCode: 'BFC',
  },
  {
    name: 'Norwich City',
    shortCode: 'NCFC',
  },
  {
    name: 'Watford',
    shortCode: 'WFC',
  },
  {
    name: 'Cardiff City',
    shortCode: 'CCFC',
  },
  {
    name: 'Swansea City',
    shortCode: 'SCFC',
  },
  {
    name: 'Bristol City',
    shortCode: 'BCFC',
  },
  {
    name: 'Nottingham Forest',
    shortCode: 'NFFC',
  },
];

export const getRandomTeam = () => {
  const randomIndex = Math.floor(Math.random() * TEAMS.length);
  const randomTeam = TEAMS[randomIndex];
  TEAMS.splice(randomIndex, 1);

  return randomTeam;
};

export const getFixtureName = (teams: FixtureDto['teams']) => {
  return `${teams[0].name} vs ${teams[1].name}`;
};

export const COMPETITION_NAMES = [
  'Series A',
  'Premier League',
  'La Liga',
  'Bundesliga',
  'Ligue 1',
];

export const COMPETITION_CATEGORIES = [
  'Category 1',
  'Category 2',
  'Category 3',
];

export const COUNTRIES = [
  'Italy',
  'England',
  'Poland',
  'Germany',
  'Greece',
  'Great Britain',
  'France',
  'Spain',
  'Portugal',
  'Netherlands',
  'Belgium',
];

export const makeFakeLocation = () => {
  const randomIndex = Math.floor(Math.random() * COUNTRIES.length);
  return {
    city: '',
    country: COUNTRIES[randomIndex],
    continent: 'Europe',
  };
};

export const PLAYERS = [
  {
    personId: '23468568-64d0-4fcc-ab86-7e7f05257abb',
    fullName: 'Drake Callender',
    firstName: 'Drake',
    lastName: 'Callender',
    shirtNumber: '1',
    position: '',
    location: '',
  },
  {
    personId: '3ad82d70-9ef4-446a-abfa-4bbbe378c7bb',
    fullName: 'Serhiy Kryvtsov',
    firstName: 'Serhiy',
    lastName: 'Kryvtsov',
    shirtNumber: '27',
    position: '',
    location: '',
  },
  {
    personId: 'f5965b9d-bda5-4c22-923e-c2078d8159e2',
    fullName: 'Tomás Avilés',
    firstName: 'Tomás',
    lastName: 'Avilés',
    shirtNumber: '6',
    position: '',
    location: '',
  },
  {
    personId: 'c746beb1-cd78-45f2-a664-e1ffa3e6b33e',
    fullName: 'Jordi Alba',
    firstName: 'Jordi',
    lastName: 'Alba',
    shirtNumber: '18',
    position: '',
    location: '',
  },
  {
    personId: '5f547aa3-6bb1-4db6-aa39-cc92378d8e36',
    fullName: 'DeAndre Yedlin',
    firstName: 'DeAndre',
    lastName: 'Yedlin',
    shirtNumber: '2',
    position: '',
    location: '',
  },
  {
    personId: '5ff808d9-a097-4730-8fce-5ab6cdac5bcf',
    fullName: 'Sergio Busquets',
    firstName: 'Sergio',
    lastName: 'Busquets',
    shirtNumber: '5',
    position: '',
    location: '',
  },
  {
    personId: 'c7d262e1-9247-4358-bbf7-320800ead12d',
    fullName: 'Diego Gómez',
    firstName: 'Diego',
    lastName: 'Gómez',
    shirtNumber: '20',
    position: '',
    location: '',
  },
  {
    personId: '03db5fec-c226-43a0-a0fb-a46a15126d7c',
    fullName: 'Julian Gressel',
    firstName: 'Julian',
    lastName: 'Gressel',
    shirtNumber: '24',
    position: '',
    location: '',
  },
  {
    personId: 'dea86073-7fec-4eef-ac04-03f8c0fe0ecf',
    fullName: 'Luis Suárez',
    firstName: 'Luis',
    lastName: 'Suárez',
    shirtNumber: '9',
    position: '',
    location: '',
  },
  {
    personId: '48630af6-749c-408b-b392-3528727bb3b3',
    firstName: 'Robert',
    lastName: 'Taylor',
    shirtNumber: '16',
    position: '',
    location: '',
    fullName: 'Robert Taylor',
  },
  {
    personId: '64ff1a37-4633-4996-9d35-632d04406b39',
    firstName: 'Lionel',
    lastName: 'Messi',
    shirtNumber: '10',
    position: '',
    location: '',
    fullName: 'Lionel Messi',
  },
];
