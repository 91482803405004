import {
  Avatar,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material';
import { FC } from 'react';
import Delete from '@mui/icons-material/Delete';
import { Player } from '@/service/lineups';
import { type LineupPosition as LineupNamedPosition } from '@/stores/LineupStore/constants';
import { useStartingLineupsContext } from '../StartingLineupContext';

type StartingLineupPlayerListItemProps = {
  player: Player;
  lineupPosition: LineupNamedPosition;
};
export const StartingLineupPlayerListItem: FC<
  StartingLineupPlayerListItemProps
> = ({ player, lineupPosition }) => {
  const startingLineupsStore = useStartingLineupsContext();
  const selectedEntry = startingLineupsStore.useStore(
    (state) => state.selectedEntry,
  );
  const isAddedPlayer = startingLineupsStore.useIsAddedPlayer(player);
  const isSelected =
    !!selectedEntry && !!selectedEntry[1] && selectedEntry[1].id === player.id;

  return (
    <Stack direction='row' alignItems='center'>
      <ListItemButton
        selected={isSelected}
        disableRipple
        onClick={() => startingLineupsStore.selectEntry(lineupPosition, player)}
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              color: 'currentColor',
              transition: (theme) =>
                theme.transitions.create('background-color'),
              backgroundColor: (theme) =>
                isSelected
                  ? theme.palette.primary.main
                  : theme.palette.grey[800],
            }}
          >
            {player.shirtNumber}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={player.fullName} />
      </ListItemButton>
      {isAddedPlayer && (
        <IconButton
          onClick={() => startingLineupsStore.removeAddedPlayer(player)}
        >
          <Delete />
        </IconButton>
      )}
    </Stack>
  );
};
