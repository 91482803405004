import { FC } from 'react';
import { Chip, IconButton, Stack } from '@mui/material';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import HighlightOff from '@mui/icons-material/HighlightOff';
import type { FormationAction } from '@/types/action/action';
import type { PlayerPositionDto } from '@contract';
import {
  PITCH_FORMATION_ID,
  PITCH_LINEUP_ID,
  PitchOrientation,
} from '../constants';
import { PitchSVG as HorizontalPitchSVG } from '../PitchSVG';
import { IconsContainer } from '../IconsContainer';
import { FormationContainer } from '../FormationContainer';
import { EditPitchGrid } from './EditPitchGrid';

interface EditHorizontalFormationProps {
  action: FormationAction;
  onSave: VoidFunction;
  onCancel: VoidFunction;
  onChange: (changedPitchPlayers: PlayerPositionDto[]) => void;
  hasChanged: boolean;
  formation: string;
}

export const EditHorizontalFormation: FC<EditHorizontalFormationProps> = ({
  action,
  onSave,
  onCancel,
  onChange,
  hasChanged,
  formation,
}) => {
  return (
    <Stack flex={1} p={1}>
      <Stack position='relative' id={PITCH_LINEUP_ID} marginTop='auto'>
        <HorizontalPitchSVG />
        <EditPitchGrid
          setPitchPlayers={onChange}
          pitchPlayers={action.metadata.formation.pitchPlayers}
          pitchOrientation={PitchOrientation.Vertical}
        />
        <>
          <IconsContainer>
            <Stack direction='row' alignItems='center'>
              <IconButton
                disabled={!hasChanged}
                color='success'
                onClick={onSave}
              >
                <CheckCircleOutline />
              </IconButton>
              <IconButton color='error' onClick={onCancel}>
                <HighlightOff />
              </IconButton>
            </Stack>
          </IconsContainer>
          <FormationContainer>
            <Chip id={PITCH_FORMATION_ID} label={formation} />
          </FormationContainer>
        </>
      </Stack>
    </Stack>
  );
};
