import { FC, useRef, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Paper,
  Popper,
  Stack,
  Typography,
  type ButtonProps,
} from '@mui/material';
import { CANCEL_BUTTON_LABEL } from '@/constants';
import { useFixtureStore } from '@/stores/FixtureStore';
import { pauseClock } from '@/stores/ClockStore';
import { createAction } from '@/stores/ActionStore/utils';
import type { EndMatchAction, EndPeriodAction } from '@/types/action/action';
import { ACTION_TYPE_ID, METADATA_KEY } from '@contract';
import { postAction } from '@/service/helpers/postAction';
import { END_MATCH_LABEL } from './constants';

export const EndMatch: FC<ButtonProps> = (props) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const toggleConfirmationOpen = () =>
    setIsConfirmationOpen((current) => !current);
  const closeConfirmation = () => setIsConfirmationOpen(false);
  const $popperAnchor = useRef(null);
  const currentPeriodConfig = useFixtureStore(
    (state) => state.currentPeriodConfig,
  );
  const currentPeriod = useFixtureStore((state) => state.currentPeriod);

  if (currentPeriodConfig.seq === 0) return;

  const onEndMatch = async () => {
    if (currentPeriodConfig.seq === 0) return;

    if (currentPeriod && currentPeriod.endTime === null) {
      const endPeriodAction = createAction<EndPeriodAction>(
        ACTION_TYPE_ID.EndPeriod,
        METADATA_KEY.endPeriod,
        {},
      );

      if (endPeriodAction) {
        await postAction(endPeriodAction);
      }
    }

    const endMatchAction = createAction<EndMatchAction>(
      ACTION_TYPE_ID.EndMatch,
      METADATA_KEY.endMatch,
      {},
    );

    if (!endMatchAction) return;

    postAction(endMatchAction);
    pauseClock();
    closeConfirmation();
  };

  return (
    <>
      <Button
        aria-label={END_MATCH_LABEL}
        ref={$popperAnchor}
        onClick={toggleConfirmationOpen}
        variant='text'
        color='inherit'
        size='large'
        {...props}
      >
        {END_MATCH_LABEL}
      </Button>
      <Popper
        anchorEl={$popperAnchor.current}
        open={isConfirmationOpen}
        placement='bottom-end'
      >
        <ClickAwayListener onClickAway={closeConfirmation}>
          <Paper elevation={6}>
            <Stack p={2} gap={3}>
              <Typography variant='body1'>{END_MATCH_LABEL}</Typography>
              <Stack direction='row' gap={1}>
                <Button
                  variant='text'
                  color='inherit'
                  onClick={closeConfirmation}
                >
                  {CANCEL_BUTTON_LABEL}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={onEndMatch}
                >
                  {END_MATCH_LABEL}
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
