import { createTheme } from '@mui/material';
import { blueGrey } from '@mui/material/colors';

export const IMG_COLOR = {
  ORANGE: '#FF4D00',
  ERROR: '#f82222',
} as const;

export const appTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: IMG_COLOR.ORANGE,
    },
    secondary: {
      main: blueGrey[500],
    },
    error: {
      main: IMG_COLOR.ERROR,
    },
  },
});
