import {
  Dialog as MuiDialog,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
} from '@mui/material';
import { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';

interface DialogProps {
  isOpen: boolean;
  title: string | ReactNode;
  fullWidth?: MuiDialogProps['fullWidth'];
  maxWidth?: MuiDialogProps['maxWidth'];
  sx?: MuiDialogProps['sx'];
  onRightClick?: MouseEventHandler<HTMLDivElement>;
  onClose?: VoidFunction;
}

export const Dialog: FC<PropsWithChildren<DialogProps>> = ({
  isOpen,
  onClose,
  title,
  children,
  fullWidth = false,
  maxWidth = false,
  onRightClick,
  sx,
}) => {
  return (
    <MuiDialog
      onContextMenu={onRightClick}
      open={isOpen}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle sx={{ p: 2 }}>{title}</DialogTitle>

      <DialogContent
        sx={{
          display: 'flex',
          p: 2,
          pt: 0,
          ...sx,
        }}
      >
        {children}
      </DialogContent>
    </MuiDialog>
  );
};
