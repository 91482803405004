import { Stack } from '@mui/material';
import { FC } from 'react';
import { ActionPlayerField } from '@/components/Dialogs/Common/ActionPlayerField';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { Autocomplete } from '@/components/Form/Autocomplete';
import { PLAYER_CARDED_LABEL, REASON_LABEL } from '@/constants';
import { YELLOW_CARD_OPTIONS } from '@/service/cards';
import { PLAYERS_LIST_VARIANT } from '@/stores/LineupStore/utils';
import { type YellowCardAction } from '@/types/action/action';
import { getActionMetadata } from '@/utils/actions';
import { sortExceptFirst } from '@/utils/array';
import { CardReason } from '@contract';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { ParentAction } from '@/components/ParentAction/ParentAction';
import { FlagField } from '../Common/FlagField';
import { Controls } from '../Common/Controls';

interface YellowCardDialogContentProps {
  action: YellowCardAction;
}

const onReasonChange = (reason: CardReason) => {
  setActionPartialMetadata<YellowCardAction>({
    reason,
  });
};

export const YellowCardDialogContent: FC<YellowCardDialogContentProps> = ({
  action,
}) => {
  const metadata = getActionMetadata(action);

  return (
    <Stack gap={2}>
      <ActionPlayerField
        action={action}
        playerAutoCompleteProps={{
          label: PLAYER_CARDED_LABEL,
          playersListVariant: PLAYERS_LIST_VARIANT.IN_GAME,
        }}
      />
      <PeriodClockField action={action} />
      <ParentAction action={action} />
      <Autocomplete
        options={sortExceptFirst(YELLOW_CARD_OPTIONS, (a, b) =>
          a.label.localeCompare(b.label),
        )}
        value={metadata.reason}
        onChange={onReasonChange}
        label={REASON_LABEL}
      />
      <FlagField action={action} />
      <Controls action={action} />
    </Stack>
  );
};
