import { ActionWarning } from '@contract';

export enum WarningType {
  Flag = 'flag',
  PassSamePlayer = 'passSamePlayer',
  UnknownPlayer = 'unknownPlayer',
}

const FLAG_TO_QA: ActionWarning = {
  type: WarningType.Flag,
  message: 'Flagged',
};
const PASS_SAME_PLAYER: ActionWarning = {
  type: WarningType.PassSamePlayer,
  message: 'Player cannot pass to himself',
};
const UNKNOWN_PLAYER: ActionWarning = {
  type: WarningType.UnknownPlayer,
  message: 'Missing player',
};

export const ACTION_WARNING = {
  FLAG_TO_QA,
  PASS_SAME_PLAYER,
  UNKNOWN_PLAYER,
} as const;
