import { GOAL_GRID_ZONES } from './constants';

function extractGrid(grid: string, toKeep: string, toRemove: string[] = []) {
  const lines = grid.trim().replaceAll(`'`, '').split('\n');

  return lines
    .map((line) => {
      const zones = line.trim().split(/\s+/);
      const zonesAfterRemoval = zones.filter(
        (zone) => !toRemove.some((el) => zone.includes(el)),
      );

      return zonesAfterRemoval.map((zone) =>
        zone.includes(toKeep) ? zone : 'main',
      );
    })
    .filter((zone) => zone.length)
    .map((zone) => `'${zone.join(' ')}'`)
    .join('\n');
}

export function getGoalGridAreas() {
  const gridFarAreas = extractGrid(GOAL_GRID_ZONES, 'FAR_');
  const gridOffAreas = extractGrid(GOAL_GRID_ZONES, 'OFF_', ['FAR_']);
  const gridWoodAreas = extractGrid(GOAL_GRID_ZONES, 'WOOD_', ['FAR_', 'OFF_']);
  const gridGoalAreas = extractGrid(GOAL_GRID_ZONES, 'GOAL_', [
    'FAR_',
    'OFF_',
    'WOOD_',
  ]);

  return { gridFarAreas, gridGoalAreas, gridOffAreas, gridWoodAreas };
}
