import { Stack, styled } from '@mui/material';
import {
  FC,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { OutPortal } from 'react-reverse-portal';
import Lock from '@mui/icons-material/Lock';
import PitchHorizontalSVG from '@/assets/football-pitch-with-goals.svg?react';
import {
  cancelPitch,
  cancelTargetActionMode,
  setDotCoords,
  setDotTargetCoords,
  setPitchInnerSize,
  setPitchOuterSize,
  useActionStore,
} from '@/stores/ActionStore/ActionStore';
import { commitAction } from '@/stores/ActionStore/commitActions';
import { isTargetActionMode, MODE } from '@/stores/ActionStore/constants';
import { setStreamContainer, useUIStore } from '@/stores/UIStore';
import { ACTION_TYPE_ID } from '@contract';
import { CoordsTarget } from './CoordsTarget';
import { PassCoordsTarget } from './PassCoordsTarget';
import { logKeyDown } from './interactions';
import './styles.css';
import { usePitchElements } from './utils';

const PitchSVG = styled(PitchHorizontalSVG)`
  flex: 1;
`;

const PITCH_MAIN_ID = 'pitch-main';

export const Pitch: FC = () => {
  const $pitchContainer = useRef<HTMLDivElement | null>(null);
  const $streamPortalNode = useUIStore((state) => state.$streamPortalNode);
  const $streamContainer = useUIStore((state) => state.$streamContainer);
  const mode = useActionStore((state) => state.mode);
  const pitchBlockedActionType = useUIStore(
    (state) => state.pitchBlockedActionType,
  );

  /**
   * This component is responsible for controlling stored pitch sizes.
   * This is due to programatic coords setting when creating actions
   * using main pitch view. For example locking pitch for corner action.
   * Stored values should not be controlled by other component
   * and this component must not be used more than once on the page.
   */
  const { innerClientRect, outerClientRect } =
    usePitchElements($pitchContainer);
  useEffect(() => {
    setPitchInnerSize(innerClientRect);
  }, [innerClientRect]);
  useEffect(() => {
    setPitchOuterSize(outerClientRect);
  }, [outerClientRect]);

  useEffect(() => {
    window.addEventListener('keydown', logKeyDown, false);

    return () => {
      window.removeEventListener('keydown', logKeyDown, false);
    };
  }, []);

  useEffect(() => {
    setStreamContainer($pitchContainer.current);
    return () => {
      setStreamContainer(null);
    };
  }, [$pitchContainer]);

  const onMouseMove = useCallback(function onMouseMove(e: ReactMouseEvent) {
    setDotTargetCoords(e);
  }, []);

  const mouseMoveListener = isTargetActionMode(mode) ? onMouseMove : undefined;

  function onMouseLeave() {
    if (isTargetActionMode(mode)) {
      setDotTargetCoords(null);
    }
  }

  function onRightClick(e: ReactMouseEvent) {
    e.preventDefault();

    if (isTargetActionMode(mode)) {
      cancelTargetActionMode();
      return;
    }
    if (mode === MODE.PITCH) {
      cancelPitch();
      return;
    }
  }

  function onPitchClick(e: ReactMouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (!outerClientRect) return;

    if (mode === MODE.PITCH && pitchBlockedActionType === null) {
      setDotCoords(e);
      return;
    }

    if (mode === MODE.PASS) {
      return commitAction(ACTION_TYPE_ID.Pass);
    }
    if (mode === MODE.CROSS) {
      return commitAction(ACTION_TYPE_ID.Cross);
    }
    if (mode === MODE.LAUNCH) {
      return commitAction(ACTION_TYPE_ID.Launch);
    }
    if (mode === MODE.GOALKEEPER_THROW) {
      return commitAction(ACTION_TYPE_ID.GoalkeeperThrow);
    }
    if (mode === MODE.THROW_IN) {
      return commitAction(ACTION_TYPE_ID.ThrowInTaken);
    }
    if (mode === MODE.GOALKEEPER_KICK_FROM_HANDS) {
      return commitAction(ACTION_TYPE_ID.GoalkeeperKickFromHands);
    }
  }

  return (
    <Stack
      onMouseLeave={onMouseLeave}
      onContextMenu={onRightClick}
      onMouseMove={mouseMoveListener}
      sx={{
        position: 'relative',
        flexDirection: 'row',
        pointerEvents: 'all',
        backgroundColor: 'var(--pitchColor)',
      }}
    >
      <Stack
        id={PITCH_MAIN_ID}
        ref={$pitchContainer}
        sx={{ width: '100%', position: 'relative' }}
      >
        <PitchSVG onClick={onPitchClick} />
        {$streamContainer === $pitchContainer.current &&
          !!$streamPortalNode && <OutPortal node={$streamPortalNode} />}
        <CoordsTarget>
          {pitchBlockedActionType !== null && <Lock sx={{ fontSize: '1em' }} />}
        </CoordsTarget>
        <PassCoordsTarget />
      </Stack>
    </Stack>
  );
};
