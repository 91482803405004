import { Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { EventsTable } from '@/components/EventHistory/EventsTable/EventsTable';
import { useFixtureStore, TableAction } from '@/stores/FixtureStore';
import { FiltersDrawer } from '@/components/ActionFilters/FiltersDrawer';
import { ActionDialog } from '@/components/Dialogs/ActionDialog';
import {
  applyFilters,
  useFiltersStore,
} from '@/stores/FiltersStore/FiltersStore';
import { ResendActionsButton } from '@/components/EventHistory/ResendActionsButton';
import { ExportActions } from '@/components/ExportActions/ExportActions';
import { FiltersDrawerVariant } from '@/components/ActionFilters/constants';
import { Score } from '@/components/EventHistory/Score';
import { SelectedFilterChips } from '@/components/ActionFilters/SelectedFilterChips';
import { OpenFiltersButton } from '@/components/ActionFilters/OpenFiltersButton';

export const EventHistoryView = () => {
  const [actionsState, setActionsState] = useState<TableAction[]>([]);
  const actions = useFixtureStore((state) => state.actions);
  const { filterValues, tableActions } = useFiltersStore();

  useEffect(() => {
    applyFilters();
  }, [filterValues, actions]);

  useEffect(() => {
    setActionsState(tableActions);
  }, [tableActions]);

  return (
    <Stack
      sx={{ gap: 2, pt: 2, pb: 0, px: 1, overflow: 'hidden', height: '100%' }}
    >
      <Stack direction='row' alignItems='center'>
        <Score />
        <Typography variant='h5' marginLeft='auto'>
          Events table ({actionsState.length})
        </Typography>
      </Stack>
      <Stack direction='row' gap={1}>
        <SelectedFilterChips />

        <Stack direction='row' gap={1} marginLeft='auto'>
          <ExportActions />
          <OpenFiltersButton />
          <ResendActionsButton />
        </Stack>
      </Stack>
      <EventsTable actions={actionsState} />
      <ActionDialog />
      <FiltersDrawer variant={FiltersDrawerVariant.COLLECTOR} />
    </Stack>
  );
};
