import { FC, type PropsWithChildren } from 'react';
import { useActionStore } from '@/stores/ActionStore/ActionStore';
import { CoordsDot } from './CoordsDot';

const COORDS_DOT_TEST_ID = 'pitch-dot';

export const CoordsTarget: FC<PropsWithChildren> = ({ children }) => {
  const playerNumber = useActionStore((state) => state.playerNumber);
  const selectedPlayer = useActionStore((state) => state.player);
  const coords = useActionStore((state) => state.dotCoords);

  if (!coords) return;

  return (
    <CoordsDot
      data-testid={COORDS_DOT_TEST_ID}
      coords={coords}
      playerNumber={playerNumber || selectedPlayer?.shirtNumber || ''}
    >
      {children}
    </CoordsDot>
  );
};
