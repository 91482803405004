import { FC, useEffect } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { Pitch } from '@/components/Pitch/Pitch';
import { HotkeyManager } from '@/components/HotkeyManager/HotkeyManager';
import { ActionsTable } from '@/components/ActionsTable/ActionsTable';
import { Formation } from '@/components/Formation/Formation';
import { SelectedPlayersLabels } from '@/components/SelectedPlayerLabel/SelectedPlayerLabel';
import { ActionDialog } from '@/components/Dialogs/ActionDialog';
import { ActionButtons } from '@/components/ActionButtons/ActionButtons';
import { Clock } from '@/components/Clock/Clock';
import { Periods } from '@/components/Periods/Periods';
import { EndMatch } from '@/components/Periods/EndMatch';
import { LoadingOverlay } from '@/components/LoadingOverlay/LoadingOverlay';
import { ROUTES_FIXTURE_VIEW, ROUTE_ID } from '@/router/constants';
import { useGetLineups } from '@/service/lineups';

export const PitchView: FC = () => {
  const navigate = useNavigate();
  const matches = useMatches();
  const { data } = useGetLineups();
  const collectorView = matches.find(
    (match) =>
      match.id === ROUTE_ID.HOME_COLLECTOR_PITCH ||
      match.id === ROUTE_ID.AWAY_COLLECTOR_PITCH,
  );

  useEffect(() => {
    if (!data || data.pitchPlayers.length) return;
    navigate(
      `${collectorView?.pathname}${ROUTES_FIXTURE_VIEW.STARTING_LINEUP.PATH}`,
    );
  }, [collectorView?.pathname, data, navigate]);

  if (!data || !data.pitchPlayers.length)
    return (
      <LoadingOverlay>
        <Typography>Awaiting starting lineups</Typography>
      </LoadingOverlay>
    );

  return (
    <HotkeyManager hotkeysVariant='collector'>
      <Stack height='100%' overflow='hidden'>
        {/* Main content */}
        <Stack direction='row' flex='1 0 0' overflow='hidden' gap={1}>
          {/* Left side */}
          <Stack sx={{ flex: '1 1 60%', overflowY: 'auto' }} paddingTop={1}>
            <Box display='flex' alignItems='center' gap={0.5} marginBottom={2}>
              <Periods />
              <Clock />
              <SelectedPlayersLabels />
              <EndMatch sx={{ marginLeft: 'auto' }} />
            </Box>

            <Pitch />

            <Stack flex='1' minHeight={200} overflow='auto'>
              <ActionsTable />
            </Stack>
          </Stack>

          {/* Right side */}
          <Stack flex='1 1 40%' overflow='hidden'>
            <ActionButtons />
            <Formation />
          </Stack>
        </Stack>
      </Stack>
      <ActionDialog />
    </HotkeyManager>
  );
};
