import { Box, css, styled } from '@mui/material';
import type { FC } from 'react';

const ZoneBox = styled(Box)(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      border: 1px solid yellow;
    }
  `,
);

interface ZoneProps {
  zone: string;
}

export const Zone: FC<ZoneProps> = ({ zone }) => {
  const [zoneType, zoneId] = zone.split('_');

  return (
    <ZoneBox
      style={{ gridArea: zone }}
      data-zone-id={zoneId}
      data-zone-type={zoneType}
    />
  );
};
