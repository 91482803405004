import {
  Checkbox,
  CheckboxProps,
  Chip,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useEffect } from 'react';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import SyncAlt from '@mui/icons-material/SyncAlt';
import { ASSIST_LABEL } from '@/constants';
import { getLatestActionVersion } from '@/stores/utils';
import { isAssistActionType, isPositiveOutcome } from '@/utils/actions';
import { PlayerName } from '@/components/PlayerName/PlayerName';
import { Action } from '@/types/action/action';
import type { PlayerDto } from '@contract';
import { ActionChip } from '@/components/ActionChip/ActionChip';

const ASSIST_CHECKBOX_NAME = 'is-assisted-by';

const DisabledTooltip: FC = () => {
  return (
    <Tooltip
      sx={{ justifySelf: 'flex-end' }}
      title={
        <>
          <Typography variant='body2'>Parent action is required.</Typography>
          <Typography variant='body2'>
            Parent action needs successful outcome to become assist.
          </Typography>
        </>
      }
    >
      <InfoOutlined />
    </Tooltip>
  );
};

const SamePlayerTooltip: FC = () => {
  return (
    <Tooltip
      sx={{ justifySelf: 'flex-end' }}
      title={
        <Typography variant='body2'>
          The same player made previous action.
        </Typography>
      }
    >
      <InfoOutlined />
    </Tooltip>
  );
};

type AssistFieldProps = {
  action: Action;
  player: PlayerDto | null;
  assistPlayer: PlayerDto | null;
  onChange: (assistPlayer: PlayerDto | null) => void;
};

export const AssistField: FC<AssistFieldProps> = ({
  action,
  player,
  assistPlayer,
  onChange,
}) => {
  const parentAction = action.parentActionId
    ? getLatestActionVersion(action.parentActionId)
    : null;
  const isParentActionAssistType =
    !!parentAction && isAssistActionType(parentAction);
  const isParentActionPositive =
    !!parentAction && isPositiveOutcome(parentAction);
  const parentActionPlayer = parentAction && parentAction.player;

  const isPlayerMismatch = assistPlayer?.id !== parentActionPlayer?.id;

  const handleChange: CheckboxProps['onChange'] = (e) => {
    const isChecked = e.target.checked;
    const value = isChecked ? parentActionPlayer : null;

    onChange(value);
  };

  const isDisabled = (() => {
    if (assistPlayer) return false;
    if (
      isParentActionAssistType &&
      isParentActionPositive &&
      parentActionPlayer &&
      player &&
      parentActionPlayer.id !== player.id
    ) {
      return false;
    }

    return true;
  })();

  const error = (() => {
    if (!assistPlayer) return false;
    if (!parentAction) {
      return 'Parent action required';
    }
    if (!isParentActionPositive) {
      return 'Previous action is LOST';
    }
    if (!isParentActionAssistType) {
      return 'Previous action cannot be an assist';
    }

    if (parentActionPlayer && assistPlayer.id !== parentActionPlayer.id) {
      return 'Assist player does not match previous action player.';
    }
    return false;
  })();

  const InfoTooltip = () => {
    if (!isDisabled) return;
    if (parentActionPlayer && player && parentActionPlayer.id === player.id) {
      return <SamePlayerTooltip />;
    }
    return <DisabledTooltip />;
  };

  const syncAssistPlayer = () => {
    onChange(parentActionPlayer);
  };

  useEffect(() => {
    if (player && assistPlayer && player.id === assistPlayer.id) {
      onChange(null);
    }
  }, [player, assistPlayer, onChange]);

  useEffect(() => {
    if (!parentAction) {
      onChange(null);
    }
  }, [parentAction, onChange]);

  return (
    <Stack>
      {!!error && (
        <Typography variant='body2' color='error'>
          {error}
        </Typography>
      )}
      <Stack direction='row' alignItems='center'>
        <FormControlLabel
          label={ASSIST_LABEL}
          disabled={isDisabled}
          control={
            <Checkbox
              name={ASSIST_CHECKBOX_NAME}
              aria-label={ASSIST_LABEL}
              checked={!!assistPlayer}
              onChange={handleChange}
            />
          }
        />
        {assistPlayer && <Chip label={<PlayerName player={assistPlayer} />} />}
        {assistPlayer && isPlayerMismatch && (
          <IconButton onClick={syncAssistPlayer}>
            <SyncAlt />
          </IconButton>
        )}
        <InfoTooltip />
      </Stack>
      {parentAction && parentActionPlayer && (
        <Stack flex={'1 1 0'} direction='row'>
          <ActionChip action={parentAction} />
        </Stack>
      )}
    </Stack>
  );
};
