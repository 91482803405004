import { VAROutcome } from '@contract';

export const VAR_RESULT: Record<VAROutcome, string> = {
  [VAROutcome.GoalAllowed]: 'Goal Allowed',
  [VAROutcome.GoalDisallowed]: 'Goal Disallowed',
  [VAROutcome.NoPenalty]: 'No Penalty',
  [VAROutcome.NoRedCard]: 'No Red Card',
  [VAROutcome.PenaltyAwarded]: 'Penalty Awarded',
  [VAROutcome.RedCard]: 'Red Card',
};

export const VAR_RESULT_OPTIONS = Object.keys(VAR_RESULT).map((reason) => {
  const key = Number(reason) as VAROutcome;
  return {
    value: key,
    label: VAR_RESULT[key],
  };
});
