/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { ShotAction } from '@/types/action/action';
import { AttemptType, BodyPart, Extras, PatternOfPlay } from '@contract';
import { RadioButtons } from '@/components/Form/RadioButtons';
import {
  ATTEMPT_BY_LABEL,
  ATTEMPT_TYPE_LABEL,
  BODY_PARTS_LABEL,
  EXTRAS_LABEL,
  PATTERN_OF_PLAY_LABEL,
} from '@/constants';
import { CheckboxGroup } from '@/components/Form/CheckboxGroup';
import { DIRECTION_OF_PLAY } from '@/components/Periods/constants';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { OffTargetDestinationGroup } from '@/components/Dialogs/ShotDialogContent/OffTargetDestinationGroup';
import { ParentAction } from '@/components/ParentAction/ParentAction';
import { useFixtureStore } from '@/stores/FixtureStore';
import { ShotPitch } from '../Common/ShotPitch';
import {
  ATTEMPT_TYPE,
  BODY_PART,
  getAttemptExtrasOptions,
  PATTERN_OF_PLAY,
  ShotGoalBodyPart,
} from '../constants';
import { AssistField } from '../Common/AssistField';
import { ActionPlayerField } from '../Common/ActionPlayerField';
import { PeriodClockField } from '../Common/PeriodClockField';
import { Goal } from '../Common/Goal/Goal';
import { Controls } from '../Common/Controls';

interface ShotDialogContentProps {
  action: ShotAction;
}

const onAttemptTypeChange = (attemptType: AttemptType) => {
  setActionPartialMetadata<ShotAction>({ attemptType, zoneNumber: null });
};

const onBodyPartChange = (bodyPart: ShotGoalBodyPart) => {
  setActionPartialMetadata<ShotAction>({ bodyPart });
};

const onExtrasChange = (extras: Extras[]) => {
  setActionPartialMetadata<ShotAction>({ extras });
};

const onAssistPlayerChange = (
  assistPlayer: ActionMeta<ShotAction>['assistPlayer'],
) => {
  setActionPartialMetadata<ShotAction>({ assistPlayer });
};

export const ShotDialogContent: FC<ShotDialogContentProps> = ({ action }) => {
  const directionOfPlay = useFixtureStore((state) => state.directionOfPlay);
  const [previousAttemptType, setPreviousAttemptType] = useState<AttemptType>(
    action.metadata.shot.attemptType || AttemptType.TemptAttempt,
  );
  const patternOfPlay = action.metadata.shot.patternOfPlay;
  const extrasFields = useMemo(
    () => getAttemptExtrasOptions(patternOfPlay),
    [patternOfPlay],
  );

  useEffect(() => {
    const { attemptType } = action.metadata.shot;
    if (attemptType === previousAttemptType) return;
    setPreviousAttemptType(attemptType);

    switch (attemptType) {
      case AttemptType.OffTarget:
        setActionPartialMetadata<ShotAction>({
          blockersPositions: [
            directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
              ? { x: 15, y: 50 }
              : { x: 85, y: 50 },
          ],
          patternOfPlay: PatternOfPlay.RegularPlay,
          bodyPart: BodyPart.RightFoot,
        });
        break;
      case AttemptType.OnTarget:
        setActionPartialMetadata<ShotAction>({
          blockersPositions: [
            directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
              ? { x: 15, y: 50 }
              : { x: 85, y: 50 },
          ],
          patternOfPlay: PatternOfPlay.RegularPlay,
          bodyPart: BodyPart.RightFoot,
        });
        break;
      case AttemptType.Blocked:
        setActionPartialMetadata<ShotAction>({
          zoneNumber: null,
          blockersPositions: [
            directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
              ? { x: 15, y: 50 }
              : { x: 85, y: 50 },
          ],
          patternOfPlay: PatternOfPlay.RegularPlay,
          bodyPart: BodyPart.RightFoot,
        });
        break;
      case AttemptType.TemptAttempt:
        setActionPartialMetadata<ShotAction>({
          zoneNumber: null,
          patternOfPlay: null,
          blockersPositions: null,
          bodyPart: null,
          extras: null,
        });
        break;
    }
  }, [action.metadata.shot.attemptType]);

  const onPatternOfPlayChange = (patternOfPlay: PatternOfPlay) => {
    const extras = action.metadata.shot.extras || [];

    setActionPartialMetadata<ShotAction>({ patternOfPlay });

    if (
      patternOfPlay === PatternOfPlay.Penalty &&
      !extras.includes(Extras.BigChance)
    ) {
      setActionPartialMetadata<ShotAction>({
        extras: [...extras, Extras.BigChance],
      });
    }
  };

  return (
    <Stack direction='row' flex={1} gap={2} width='100%'>
      <Stack flex={3} gap={2}>
        <RadioButtons
          name='attemptType'
          controlLabel={ATTEMPT_TYPE_LABEL}
          fields={ATTEMPT_TYPE}
          value={action.metadata.shot.attemptType}
          onChange={onAttemptTypeChange}
        />
        <Goal
          zoneNumber={String(action.metadata.shot.zoneNumber)}
          offTargetDisabled={[
            AttemptType.TemptAttempt,
            AttemptType.OnTarget,
            AttemptType.Blocked,
          ].includes(action.metadata.shot.attemptType)}
          onTargetDisabled={[
            AttemptType.TemptAttempt,
            AttemptType.OffTarget,
            AttemptType.Blocked,
          ].includes(action.metadata.shot.attemptType)}
          setPartialMetadata={setActionPartialMetadata}
        />
        <ShotPitch
          attemptType={action.metadata.shot.attemptType}
          position={action.metadata.shot.position}
          blockersPositions={
            action.metadata.shot.attemptType === AttemptType.TemptAttempt
              ? null
              : action.metadata.shot.blockersPositions
          }
          setPartialMetadata={setActionPartialMetadata}
        />
      </Stack>
      <Stack flex={2} gap={2}>
        <ActionPlayerField
          action={action}
          playerAutoCompleteProps={{ label: ATTEMPT_BY_LABEL }}
        />
        <PeriodClockField action={action} />
        <ParentAction action={action} />
        <AssistField
          action={action}
          onChange={onAssistPlayerChange}
          player={action.player}
          assistPlayer={action.metadata.shot.assistPlayer}
        />
        <RadioButtons
          name='patternOfPlay'
          onChange={onPatternOfPlayChange}
          controlLabel={PATTERN_OF_PLAY_LABEL}
          fields={PATTERN_OF_PLAY}
          value={action.metadata.shot.patternOfPlay}
          disabled={
            action.metadata.shot.attemptType === AttemptType.TemptAttempt
          }
        />
        <RadioButtons
          name='bodyPart'
          onChange={onBodyPartChange}
          controlLabel={BODY_PARTS_LABEL}
          fields={BODY_PART}
          value={action.metadata.shot.bodyPart as ShotGoalBodyPart}
          disabled={
            action.metadata.shot.attemptType === AttemptType.TemptAttempt
          }
        />
        <OffTargetDestinationGroup action={action} />
        <CheckboxGroup
          controlLabel={EXTRAS_LABEL}
          fields={extrasFields}
          onChange={onExtrasChange}
          values={action.metadata.shot.extras || []}
          disabled={
            action.metadata.shot.attemptType === AttemptType.TemptAttempt
          }
        />
        <Controls action={action} />
      </Stack>
    </Stack>
  );
};
