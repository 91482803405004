import { FC } from 'react';
import { Stack } from '@mui/material';
import { TableAction } from '@/stores/FixtureStore';
import {
  getPeriodCell,
  getPlayerCell,
  getStateCell,
  getWarningCell,
} from '@/components/ActionsTable/TableCells';
import { QAHistoryTableColumn } from '@/components/QAHistoryTable/constants';
import { TableExtrasCell } from '../TableExtrasCell/TableExtrasCell';
import { OutcomeIcon } from '../ActionOutcome/OutcomeIcon';

type ActionsTableCellProps = {
  column: QAHistoryTableColumn;
  action: TableAction;
};

function getCellValue(
  columnId: QAHistoryTableColumn['id'],
  action: TableAction,
) {
  switch (columnId) {
    case 'state':
      return getStateCell(action);
    case 'warnings':
      return getWarningCell(action[columnId]);
    case 'isSuccessful':
      return <OutcomeIcon outcome={action.isSuccessful} />;
    case 'player':
      return getPlayerCell(action[columnId]);
    case 'extras':
      return <TableExtrasCell extras={action[columnId]} />;
    case 'period':
      return getPeriodCell(action);
    default:
      return action[columnId];
  }
}

export const QAHistoryTableCell: FC<ActionsTableCellProps> = ({
  column,
  action,
}) => {
  return (
    <Stack
      key={column.id}
      width={`${column.width}%`}
      justifyContent='center'
      flexWrap={'wrap'}
      lineHeight={1.43}
      py={1}
      direction='row'
      fontSize='0.875rem'
      textAlign='center'
      alignItems='center'
    >
      {getCellValue(column.id, action)}
    </Stack>
  );
};
