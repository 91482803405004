import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { ROUTE_URL } from '@/router/constants';
import { HOME_BUTTON_LABEL } from '@/constants';

interface RoleErrorProps {
  error: string;
}

export const RoleError: FC<RoleErrorProps> = ({ error }) => {
  return (
    <Stack flex='1 0 auto' alignItems='center'>
      <Typography variant='h2'>Roles missing</Typography>
      <Typography variant='h5'>{error}</Typography>
      <Button
        style={{
          marginTop: 12,
        }}
        component={RouterLink}
        to={ROUTE_URL.HOME}
        startIcon={<HomeIcon />}
        variant='outlined'
      >
        {HOME_BUTTON_LABEL}
      </Button>
    </Stack>
  );
};
