import { useMemo } from 'react';
import { ACTION_WARNING } from '@/components/ActionWarnings/constants';
import {
  ACTION_ASSIST,
  ACTION_OUTCOME_ONLY_NONE,
  ACTION_OUTCOME_ONLY_SUCCESS,
  AssistAction,
} from '@/stores/ActionStore/constants';
import { TableAction } from '@/stores/FixtureStore';
import { getLatestActionVersion } from '@/stores/utils';
import { Action } from '@/types/action/action';
import { ACTION_TYPE_ID, ActionWarning } from '@contract';
import { UNKNOWN_PLAYER_ID } from '@/stores/LineupStore/constants';

const { FLAG_TO_QA, PASS_SAME_PLAYER, UNKNOWN_PLAYER } = ACTION_WARNING;

export function isFlagToQAWarning(warning: ActionWarning) {
  return warning.type === FLAG_TO_QA.type;
}

export function hasFlagToQa(action: Action | TableAction) {
  if (!action.warnings) return false;
  return action.warnings.some(isFlagToQAWarning);
}

export function getNoFlagWarnings(action: Action | TableAction) {
  if (!action.warnings) return null;
  return action.warnings.filter((warning) => !isFlagToQAWarning(warning));
}

export function isPassSamePlayerWarning(warning: ActionWarning) {
  return warning.type === PASS_SAME_PLAYER.type;
}

export function isPassSamePlayerAction(action: Action) {
  if (!action.player) return false;

  const parentAction = action.parentActionId
    ? getLatestActionVersion(action.parentActionId)
    : null;

  if (!parentAction) {
    return false;
  }

  if (
    parentAction.isSuccessful !== true ||
    parentAction.isRemoved ||
    !parentAction.player
  ) {
    return false;
  }

  if (
    ![
      ACTION_TYPE_ID.Pass,
      ACTION_TYPE_ID.Cross,
      ACTION_TYPE_ID.Launch,
      ACTION_TYPE_ID.GoalkeeperThrow,
      ACTION_TYPE_ID.GoalkeeperKickFromHands,
    ].includes(parentAction.actionTypeId)
  ) {
    return false;
  }

  return action.player.id === parentAction.player.id;
}

export function isUnknownPlayerAction(action: Action) {
  if (!action.player) return false;

  return action.player.id === UNKNOWN_PLAYER_ID;
}

export function isUnknownPlayerWarning(warning: ActionWarning) {
  return warning.type === UNKNOWN_PLAYER.type;
}

export function getActionMetadata<T extends Action>(action: T) {
  return action.metadata[action.actionTypeMetadata] as ActionMeta<T>;
}

export function hasActionValues<T extends Action>(
  action: T,
  fields: Array<keyof T>,
) {
  return fields.every((field) => action[field] !== null);
}

export function hasMetadataValues<Metadata extends ActionMeta<Action>>(
  metadata: Metadata,
  fields: Array<keyof Metadata>,
) {
  return fields.every((field) => metadata[field] !== null);
}

export function isPositiveOutcome(action: Action) {
  return !!action.isSuccessful;
}

export function isAssistActionType(action: Action): action is AssistAction {
  return ACTION_ASSIST.includes(action.actionTypeId);
}

export function hasActionChanged(initialAction: Action, action: Action) {
  return JSON.stringify(initialAction) !== JSON.stringify(action);
}

export function isActionOutcomeEditable(actionTypeId: Action['actionTypeId']) {
  if (
    ACTION_OUTCOME_ONLY_NONE.includes(actionTypeId) ||
    ACTION_OUTCOME_ONLY_SUCCESS.includes(actionTypeId)
  ) {
    return false;
  }
  return true;
}

export function useIsActionOutcomeEditable(
  actionTypeId: Action['actionTypeId'],
) {
  return useMemo(() => isActionOutcomeEditable(actionTypeId), [actionTypeId]);
}
