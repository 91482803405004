import { FC } from 'react';
import { Stack } from '@mui/material';
import {
  ActionWarning,
  GoalkeeperSaveAttemptType,
  GoalkeeperSaveExtras,
  GoalkeeperSaveType,
} from '@contract';
import { GoalkeeperSaveAction } from '@/types/action/action';
import { getActionMetadata } from '@/utils/actions';
import { RadioButtons } from '@/components/Form/RadioButtons';
import {
  ATTEMPT_TYPE_LABEL,
  BODY_PARTS_LABEL,
  EXTRAS_LABEL,
  STANCE_LABEL,
} from '@/constants';
import {
  setAction,
  setActionPartialMetadata,
} from '@/stores/ActionStore/ActionStore';
import {
  GOALKEEPER_SAVE_ATTEMPT_TYPE,
  GOALKEEPER_SAVE_BODY_PART,
  GOALKEEPER_SAVE_STANCE,
  GoalkeeperSaveBodyPart,
  goalkeeperSaveExtras,
} from '@/components/Dialogs/GoalkeeperSaveDialogContent/constants';
import { CheckboxGroup } from '@/components/Form/CheckboxGroup';
import { ActionPlayerField } from '@/components/Dialogs/Common/ActionPlayerField';
import { FlagField } from '@/components/Dialogs/Common/FlagField';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { toggleWarning } from '@/components/ActionWarnings/helpers';
import { ACTION_WARNING } from '@/components/ActionWarnings/constants';
import { ParentAction } from '@/components/ParentAction/ParentAction';
import { Controls } from '../Common/Controls';
import { SimplePitch } from '../Common/SimplePitch';

interface GoalkeeperSaveDialogContentProps {
  action: GoalkeeperSaveAction;
}

const onBodyPartChange = (bodyPart: GoalkeeperSaveBodyPart) => {
  setActionPartialMetadata<GoalkeeperSaveAction>({ bodyPart });
};

const onStanceChange = (type: GoalkeeperSaveType) => {
  setActionPartialMetadata<GoalkeeperSaveAction>({ type });
};

const onExtrasChange = (extras: GoalkeeperSaveExtras[]) => {
  setActionPartialMetadata<GoalkeeperSaveAction>({ extras });
};

export const GoalkeeperSaveDialogContent: FC<
  GoalkeeperSaveDialogContentProps
> = ({ action }) => {
  const metadata = getActionMetadata(action);

  const onAttemptTypeChange = (attemptType: GoalkeeperSaveAttemptType) => {
    let modifiedWarnings: ActionWarning[] = [];
    let modifiedMetadata = { ...metadata };

    if (attemptType === GoalkeeperSaveAttemptType.TemptSave) {
      modifiedWarnings = toggleWarning(null, ACTION_WARNING.FLAG_TO_QA);
      modifiedMetadata = {
        type: null,
        bodyPart: null,
        extras: null,
        position: metadata.position,
        attemptType: GoalkeeperSaveAttemptType.TemptSave,
      };
    }
    if (attemptType === GoalkeeperSaveAttemptType.Save) {
      modifiedMetadata.attemptType = GoalkeeperSaveAttemptType.Save;
    }

    const updatedAction = {
      ...action,
      warnings: modifiedWarnings,
      metadata: {
        goalkeeperSave: modifiedMetadata,
      },
    };

    setAction(updatedAction);
  };

  return (
    <Stack direction='row' width='100%' gap={2}>
      <Stack direction='row' flex={6}>
        <SimplePitch action={action} />
      </Stack>

      <Stack flex={3} gap={2}>
        <RadioButtons
          name={ATTEMPT_TYPE_LABEL}
          onChange={onAttemptTypeChange}
          value={metadata.attemptType}
          controlLabel={ATTEMPT_TYPE_LABEL}
          fields={GOALKEEPER_SAVE_ATTEMPT_TYPE}
        />
        <RadioButtons
          name={BODY_PARTS_LABEL}
          onChange={onBodyPartChange}
          value={metadata.bodyPart as GoalkeeperSaveBodyPart}
          controlLabel={BODY_PARTS_LABEL}
          fields={GOALKEEPER_SAVE_BODY_PART}
          disabled={
            metadata.attemptType === GoalkeeperSaveAttemptType.TemptSave
          }
        />
        <RadioButtons
          name={STANCE_LABEL}
          onChange={onStanceChange}
          value={metadata.type}
          controlLabel={STANCE_LABEL}
          fields={GOALKEEPER_SAVE_STANCE}
          disabled={
            metadata.attemptType === GoalkeeperSaveAttemptType.TemptSave
          }
        />
        <CheckboxGroup
          controlLabel={EXTRAS_LABEL}
          fields={goalkeeperSaveExtras}
          onChange={onExtrasChange}
          values={metadata.extras || []}
          disabled={
            metadata.attemptType === GoalkeeperSaveAttemptType.TemptSave
          }
        />
        <ActionPlayerField
          action={action}
          playerAutoCompleteProps={{
            disabled:
              metadata.attemptType === GoalkeeperSaveAttemptType.TemptSave,
          }}
        />
        <PeriodClockField
          action={action}
          disabled={
            metadata.attemptType === GoalkeeperSaveAttemptType.TemptSave
          }
        />
        <ParentAction action={action} />
        <FlagField
          action={action}
          disabled={
            metadata.attemptType === GoalkeeperSaveAttemptType.TemptSave
          }
        />
        <Controls action={action} />
      </Stack>
    </Stack>
  );
};
