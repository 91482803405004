import { Stack, colors } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { SignOutButton } from '../SignOutButton/SignOutButton';

export const NavBar: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack
      direction='row'
      sx={{
        backgroundColor: colors.grey[900],
        height: '50px',
        alignItems: 'center',
      }}
    >
      {children}
      <SignOutButton size='small' />
    </Stack>
  );
};
