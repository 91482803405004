import {
  ACTION_TYPE_ID,
  CardReason,
  ErrorReason,
  FreeKickAwardedReason,
  GoalkeeperPenaltyFacedOutcome,
  GoalType,
  METADATA_KEY,
  RedCardType,
  BodyPart,
  PatternOfPlay,
  AttemptType,
  Stance,
  GoalkeeperSaveAttemptType,
  TeamDto,
} from '@contract';
import type {
  Action,
  AdditionalTimeAction,
  AerialAction,
  BadTouchAction,
  BallRecoveryAction,
  BallTouchAction,
  ClearanceAction,
  CornerAwardedAction,
  CrossAction,
  DispossessedAction,
  EndMatchAction,
  EndPeriodAction,
  ErrorAction,
  FormationAction,
  FreeKickAwardedAction,
  GoalAction,
  GoalkeeperCatchAction,
  GoalkeeperCollectionAction,
  GoalkeeperDropAction,
  GoalkeeperKickFromHandsAction,
  GoalkeeperPenaltyFacedAction,
  GoalkeeperPunchAction,
  GoalkeeperSaveAction,
  GoalkeeperThrowAction,
  GoalKickAwardedAction,
  InterceptionAction,
  LaunchAction,
  OffsideAction,
  OffsideForAction,
  PassAction,
  PlayerBeatenAction,
  PlayerLeftAction,
  PlayerReturnedAction,
  PlayResumedAction,
  PlayStoppedAction,
  PostMatchCheckCompleteAction,
  RedCardAction,
  RefereeDropBallAction,
  ShotAction,
  StartMatchAction,
  StartPeriodAction,
  SubstitutionAction,
  TackleAction,
  TakeOnAction,
  ThrowInAwardedAction,
  ThrowInTakenAction,
  VARResultAction,
  VARUnderwayAction,
  YellowCardAction,
} from '@/types/action/action';

import { createAction } from '@/stores/ActionStore/utils';
import { KICK_OFF_COORDS } from '@/components/Pitch/constants';
import { useFixtureStore } from '@/stores/FixtureStore';
import { getActionDefaultPlayer } from '@/stores/LineupStore/utils';

function createActionWithDefaultPlayer<T extends Action>(
  actionTypeId: T['actionTypeId'],
  metadataKey: T['actionTypeMetadata'],
  metadata?: ActionMeta<T>,
) {
  const action = createAction<T>(actionTypeId, metadataKey, metadata);

  if (!action) return null;

  return {
    ...action,
    player: getActionDefaultPlayer(action),
  };
}

export function createDefaultAction(
  actionTypeId: Action['actionTypeId'],
  teamId?: TeamDto['id'],
) {
  const isHomeTeamLeft =
    useFixtureStore.getState().currentPeriod.isHomeTeamLeft;
  const gameState = useFixtureStore.getState().gameState;
  const homeTeam = useFixtureStore.getState().homeTeam;
  const isLeftToRight = isHomeTeamLeft !== (homeTeam?.id === teamId);
  const xOffset = isLeftToRight ? 10 : -10;

  switch (actionTypeId) {
    case ACTION_TYPE_ID.StartMatch:
      return createActionWithDefaultPlayer<StartMatchAction>(
        actionTypeId,
        METADATA_KEY.startMatch,
      );
    case ACTION_TYPE_ID.EndMatch:
      return createActionWithDefaultPlayer<EndMatchAction>(
        actionTypeId,
        METADATA_KEY.endMatch,
      );
    case ACTION_TYPE_ID.StartPeriod:
      return createActionWithDefaultPlayer<StartPeriodAction>(
        actionTypeId,
        METADATA_KEY.startPeriod,
        { isHomeTeamLeft: !isHomeTeamLeft },
      );
    case ACTION_TYPE_ID.EndPeriod:
      return createActionWithDefaultPlayer<EndPeriodAction>(
        actionTypeId,
        METADATA_KEY.endPeriod,
      );
    case ACTION_TYPE_ID.RefereeDropBall:
      return createActionWithDefaultPlayer<RefereeDropBallAction>(
        actionTypeId,
        METADATA_KEY.refereeDropBall,
      );
    case ACTION_TYPE_ID.GoalkeeperCollection:
      return createActionWithDefaultPlayer<GoalkeeperCollectionAction>(
        actionTypeId,
        METADATA_KEY.goalkeeperCollection,
        { position: KICK_OFF_COORDS },
      );
    case ACTION_TYPE_ID.GoalkeeperCatch:
      return createActionWithDefaultPlayer<GoalkeeperCatchAction>(
        actionTypeId,
        METADATA_KEY.goalkeeperCatch,
        { position: KICK_OFF_COORDS },
      );
    case ACTION_TYPE_ID.GoalkeeperPunch:
      return createActionWithDefaultPlayer<GoalkeeperPunchAction>(
        actionTypeId,
        METADATA_KEY.goalkeeperPunch,
        {
          isAssist: false,
          position: KICK_OFF_COORDS,
        },
      );
    case ACTION_TYPE_ID.GoalkeeperDrop:
      return createActionWithDefaultPlayer<GoalkeeperDropAction>(
        actionTypeId,
        METADATA_KEY.goalkeeperDrop,
        { position: KICK_OFF_COORDS },
      );
    case ACTION_TYPE_ID.BallTouch:
      return createActionWithDefaultPlayer<BallTouchAction>(
        actionTypeId,
        METADATA_KEY.ballTouch,
        {
          isAssist: false,
          position: KICK_OFF_COORDS,
        },
      );
    case ACTION_TYPE_ID.Substitution:
      return createActionWithDefaultPlayer<SubstitutionAction>(
        actionTypeId,
        METADATA_KEY.substitution,
        { targetPlayer: null },
      );
    case ACTION_TYPE_ID.VARUnderway:
      return createActionWithDefaultPlayer<VARUnderwayAction>(
        actionTypeId,
        METADATA_KEY.varUnderway,
        { reason: null },
      );
    case ACTION_TYPE_ID.PlayStopped:
      return createActionWithDefaultPlayer<PlayStoppedAction>(
        actionTypeId,
        METADATA_KEY.playStopped,
        { reason: null, isFromOppositeTeam: null },
      );
    case ACTION_TYPE_ID.AdditionalTime:
      return createActionWithDefaultPlayer<AdditionalTimeAction>(
        actionTypeId,
        METADATA_KEY.additionalTime,
        { extraTime: 0 },
      );
    case ACTION_TYPE_ID.Formation:
      return createActionWithDefaultPlayer<FormationAction>(
        actionTypeId,
        METADATA_KEY.formation,
        {
          pitchPlayers:
            (teamId &&
              gameState.lineups[teamId] &&
              gameState.lineups[teamId].pitchPlayers) ||
            [],
        },
      );
    case ACTION_TYPE_ID.PlayerLeft:
      return createActionWithDefaultPlayer<PlayerLeftAction>(
        actionTypeId,
        METADATA_KEY.playerLeft,
      );
    case ACTION_TYPE_ID.PlayerReturned:
      return createActionWithDefaultPlayer<PlayerReturnedAction>(
        actionTypeId,
        METADATA_KEY.playerReturned,
      );
    case ACTION_TYPE_ID.VARResult:
      return createActionWithDefaultPlayer<VARResultAction>(
        actionTypeId,
        METADATA_KEY.varResult,
        { outcome: null },
      );
    case ACTION_TYPE_ID.PlayResumed:
      return createActionWithDefaultPlayer<PlayResumedAction>(
        actionTypeId,
        METADATA_KEY.playResumed,
      );
    case ACTION_TYPE_ID.RedCard:
      return createActionWithDefaultPlayer<RedCardAction>(
        actionTypeId,
        METADATA_KEY.redCard,
        { outcome: RedCardType.SecondYellow, reason: CardReason.Foul },
      );
    case ACTION_TYPE_ID.YellowCard:
      return createActionWithDefaultPlayer<YellowCardAction>(
        actionTypeId,
        METADATA_KEY.yellowCard,
        { reason: CardReason.Foul },
      );
    case ACTION_TYPE_ID.GoalKickAwarded:
      return createActionWithDefaultPlayer<GoalKickAwardedAction>(
        actionTypeId,
        METADATA_KEY.goalKickAwarded,
        { position: isLeftToRight ? { x: 11, y: 50 } : { x: 89, y: 50 } },
      );
    case ACTION_TYPE_ID.Offside:
      return createActionWithDefaultPlayer<OffsideAction>(
        actionTypeId,
        METADATA_KEY.offside,
        { position: KICK_OFF_COORDS },
      );
    case ACTION_TYPE_ID.PostMatchCheckComplete:
      return createActionWithDefaultPlayer<PostMatchCheckCompleteAction>(
        actionTypeId,
        METADATA_KEY.postMatchCheckComplete,
      );
    case ACTION_TYPE_ID.ThrowInTaken:
      return createActionWithDefaultPlayer<ThrowInTakenAction>(
        actionTypeId,
        METADATA_KEY.throwInTaken,
        {
          position: KICK_OFF_COORDS,
          targetPosition: {
            x: KICK_OFF_COORDS.x + xOffset,
            y: KICK_OFF_COORDS.y,
          },
          direction: null,
          isAssist: false,
        },
      );
    case ACTION_TYPE_ID.FreeKickAwarded:
      return createActionWithDefaultPlayer<FreeKickAwardedAction>(
        actionTypeId,
        METADATA_KEY.freeKickAwarded,
        {
          position: KICK_OFF_COORDS,
          isPenalty: false,
          reason: FreeKickAwardedReason.Foul,
        },
      );
    case ACTION_TYPE_ID.ThrowInAwarded:
      return createActionWithDefaultPlayer<ThrowInAwardedAction>(
        actionTypeId,
        METADATA_KEY.throwInAwarded,
        { position: KICK_OFF_COORDS },
      );
    case ACTION_TYPE_ID.CornerAwarded:
      return createActionWithDefaultPlayer<CornerAwardedAction>(
        actionTypeId,
        METADATA_KEY.cornerAwarded,
        { position: isLeftToRight ? { x: 0, y: 100 } : { x: 100, y: 100 } },
      );
    case ACTION_TYPE_ID.Clearance:
      return createActionWithDefaultPlayer<ClearanceAction>(
        actionTypeId,
        METADATA_KEY.clearance,
        {
          position: KICK_OFF_COORDS,
          bodyPart: null,
          isAssist: false,
        },
      );
    case ACTION_TYPE_ID.Cross:
      return createActionWithDefaultPlayer<CrossAction>(
        actionTypeId,
        METADATA_KEY.cross,
        {
          actionContext: null,
          position: KICK_OFF_COORDS,
          direction: null,
          distance: null,
          isAssist: false,
          targetPosition: {
            x: KICK_OFF_COORDS.x + xOffset,
            y: KICK_OFF_COORDS.y,
          },
          bodyPart: null,
        },
      );
    case ACTION_TYPE_ID.Pass:
      return createActionWithDefaultPlayer<PassAction>(
        actionTypeId,
        METADATA_KEY.pass,
        {
          actionContext: null,
          position: KICK_OFF_COORDS,
          direction: null,
          distance: null,
          isAssist: false,
          targetPosition: {
            x: KICK_OFF_COORDS.x + xOffset,
            y: KICK_OFF_COORDS.y,
          },
          bodyPart: null,
        },
      );
    case ACTION_TYPE_ID.Tackle:
      return createActionWithDefaultPlayer<TackleAction>(
        actionTypeId,
        METADATA_KEY.tackle,
        { position: KICK_OFF_COORDS, isAssist: false },
      );
    case ACTION_TYPE_ID.Interception:
      return createActionWithDefaultPlayer<InterceptionAction>(
        actionTypeId,
        METADATA_KEY.interception,
        {
          position: KICK_OFF_COORDS,
          isAssist: false,
          bodyPart: null,
        },
      );
    case ACTION_TYPE_ID.Error:
      return createActionWithDefaultPlayer<ErrorAction>(
        actionTypeId,
        METADATA_KEY.error,
        { reason: ErrorReason.LeadingToShot },
      );
    case ACTION_TYPE_ID.Aerial:
      return createActionWithDefaultPlayer<AerialAction>(
        actionTypeId,
        METADATA_KEY.aerial,
        { position: KICK_OFF_COORDS },
      );
    case ACTION_TYPE_ID.BallRecovery:
      return createActionWithDefaultPlayer<BallRecoveryAction>(
        actionTypeId,
        METADATA_KEY.ballRecovery,
        { position: KICK_OFF_COORDS },
      );
    case ACTION_TYPE_ID.Dispossessed:
      return createActionWithDefaultPlayer<DispossessedAction>(
        actionTypeId,
        METADATA_KEY.dispossessed,
        { position: KICK_OFF_COORDS },
      );
    case ACTION_TYPE_ID.Launch:
      return createActionWithDefaultPlayer<LaunchAction>(
        actionTypeId,
        METADATA_KEY.launch,
        {
          actionContext: null,
          position: KICK_OFF_COORDS,
          direction: null,
          distance: null,
          isAssist: false,
          targetPosition: {
            x: KICK_OFF_COORDS.x + xOffset,
            y: KICK_OFF_COORDS.y,
          },
          bodyPart: null,
        },
      );
    case ACTION_TYPE_ID.GoalkeeperThrow:
      return createActionWithDefaultPlayer<GoalkeeperThrowAction>(
        actionTypeId,
        METADATA_KEY.goalkeeperThrow,
        {
          actionContext: null,
          position: KICK_OFF_COORDS,
          direction: null,
          distance: null,
          isAssist: false,
          targetPosition: {
            x: KICK_OFF_COORDS.x + xOffset,
            y: KICK_OFF_COORDS.y,
          },
          bodyPart: null,
        },
      );
    case ACTION_TYPE_ID.BadTouch:
      return createActionWithDefaultPlayer<BadTouchAction>(
        actionTypeId,
        METADATA_KEY.badTouch,
        { position: KICK_OFF_COORDS, isAssist: false },
      );
    case ACTION_TYPE_ID.TakeOn:
      return createActionWithDefaultPlayer<TakeOnAction>(
        actionTypeId,
        METADATA_KEY.takeOn,
        { position: KICK_OFF_COORDS },
      );
    case ACTION_TYPE_ID.PlayerBeaten:
      return createActionWithDefaultPlayer<PlayerBeatenAction>(
        actionTypeId,
        METADATA_KEY.playerBeaten,
        { position: KICK_OFF_COORDS },
      );
    case ACTION_TYPE_ID.GoalkeeperKickFromHands:
      return createActionWithDefaultPlayer<GoalkeeperKickFromHandsAction>(
        actionTypeId,
        METADATA_KEY.goalkeeperKickFromHands,
        {
          actionContext: null,
          position: KICK_OFF_COORDS,
          direction: null,
          distance: null,
          isAssist: false,
          targetPosition: {
            x: KICK_OFF_COORDS.x + xOffset,
            y: KICK_OFF_COORDS.y,
          },
          bodyPart: null,
        },
      );
    case ACTION_TYPE_ID.GoalkeeperPenaltyFaced:
      return createActionWithDefaultPlayer<GoalkeeperPenaltyFacedAction>(
        actionTypeId,
        METADATA_KEY.goalkeeperPenaltyFaced,
        {
          position: KICK_OFF_COORDS,
          stance: Stance.DivedLeft,
          outcome: GoalkeeperPenaltyFacedOutcome.Scored,
        },
      );
    case ACTION_TYPE_ID.GoalkeeperSave:
      return createActionWithDefaultPlayer<GoalkeeperSaveAction>(
        actionTypeId,
        METADATA_KEY.goalkeeperSave,
        {
          attemptType: GoalkeeperSaveAttemptType.TemptSave,
          bodyPart: null,
          type: null,
          extras: null,
          position: KICK_OFF_COORDS,
        },
      );
    case ACTION_TYPE_ID.OffsideFor:
      return createActionWithDefaultPlayer<OffsideForAction>(
        actionTypeId,
        METADATA_KEY.offsideFor,
        { position: KICK_OFF_COORDS },
      );
    case ACTION_TYPE_ID.Shot:
      return createActionWithDefaultPlayer<ShotAction>(
        actionTypeId,
        METADATA_KEY.shot,
        {
          assistPlayer: null,
          zoneNumber: null,
          blockersPositions: null,
          bodyPart: null,
          extras: null,
          patternOfPlay: null,
          attemptType: AttemptType.TemptAttempt,
          position: isLeftToRight ? { x: 15, y: 50 } : { x: 85, y: 50 },
          isAssist: false,
          shotOffTargetType: null,
        },
      );
    case ACTION_TYPE_ID.Goal:
      return createActionWithDefaultPlayer<GoalAction>(
        actionTypeId,
        METADATA_KEY.goal,
        {
          assistPlayer: null,
          bodyPart: BodyPart.RightFoot,
          extras: null,
          patternOfPlay: PatternOfPlay.RegularPlay,
          position: KICK_OFF_COORDS,
          zoneNumber: 91,
          blockersPositions: [
            isLeftToRight ? { x: 15, y: 50 } : { x: 85, y: 50 },
          ],
          goalType: GoalType.Goal,
        },
      );
    default:
      return null;
  }
}
