import { RadioButtons } from '@/components/Form/RadioButtons';
import { FILTER_NAME } from '@/stores/FiltersStore/constants';
import type { FilterComponent } from '../constants';

export const WARNINGS_OPTIONS = {
  0: 'Without warnings',
  1: 'With warnings',
} as const;

export const WarningsFilter: FilterComponent<'warnings'> = ({
  value,
  onChange,
}) => {
  return (
    <RadioButtons
      controlLabel={FILTER_NAME.WARNINGS}
      name={FILTER_NAME.WARNINGS}
      value={value === null ? null : Number(value)}
      onChange={(value) => {
        onChange(!!value);
      }}
      onClear={() => onChange(null)}
      fields={WARNINGS_OPTIONS}
    />
  );
};
