import { FC } from 'react';
import { Chip, ChipProps } from '@mui/material';
import Public from '@mui/icons-material/Public';
import { currentEnvironment } from '@/utils/environment';

type EnvironmentLabelProps = ChipProps;

export const EnvironmentLabel: FC<EnvironmentLabelProps> = (props) => {
  return (
    <Chip
      icon={<Public />}
      label={currentEnvironment}
      size='small'
      color='info'
      variant='outlined'
      {...props}
    />
  );
};
