import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setAction, useActionStore } from '@/stores/ActionStore/ActionStore';
import { ACTION_TYPE_ID, METADATA_KEY } from '@contract';
import { createAction } from '@/stores/ActionStore/utils';
import type { FormationAction } from '@/types/action/action';
import { setLineup, useLineupStore } from '@/stores/LineupStore/LineupStore';
import { postAction } from '@/service/helpers/postAction';
import { PitchOrientation } from './constants';
import { DisplayHorizontalFormation } from './DisplayFormation/DisplayHorizontalFormation';
import { DisplayVerticalFormation } from './DisplayFormation/DisplayVerticalFormation';
import { EditFormation } from './EditFormation/EditFormation';

type FormationProps = {
  pitchOrientation?: PitchOrientation;
};

export const Formation: FC<FormationProps> = ({
  pitchOrientation = PitchOrientation.Horizontal,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const action = useActionStore((state) => state.action);
  const pitchPlayers = useLineupStore((state) => state.pitchPlayers);
  const isHorizontal = pitchOrientation === PitchOrientation.Horizontal;

  const onEdit = () => {
    const action = createAction<FormationAction>(
      ACTION_TYPE_ID.Formation,
      METADATA_KEY.formation,
      { pitchPlayers },
    );

    if (!action) {
      return;
    }

    setAction(action);
    setIsEditing(true);
  };

  const onSave = () => {
    if (!action) return;

    setLineup(pitchPlayers);
    postAction(action);
    setIsEditing(false);

    if (!isHorizontal) {
      navigate('..', { relative: 'route' });
    }
  };

  const onCancel = () => {
    setIsEditing(false);
  };

  if (isEditing && action && action.actionTypeId === ACTION_TYPE_ID.Formation) {
    return (
      <EditFormation
        action={action}
        onCancel={onCancel}
        onSave={onSave}
        isHorizontal={isHorizontal}
      />
    );
  }

  if (isHorizontal) {
    return <DisplayHorizontalFormation onEdit={onEdit} />;
  }

  return <DisplayVerticalFormation onEdit={onEdit} />;
};
