export const GOAL_GRID_ZONES = `
  'FAR_142 FAR_142 FAR_142 FAR_142 FAR_142  FAR_142  FAR_142  FAR_142  FAR_142  FAR_142  FAR_142  FAR_142  FAR_142 FAR_142 FAR_142 FAR_142'
  'FAR_141 OFF_1   OFF_2   OFF_3   OFF_4    OFF_5    OFF_6    OFF_7    OFF_8    OFF_9    OFF_10   OFF_11   OFF_12  OFF_13  OFF_14  FAR_143'
  'FAR_141 OFF_15  OFF_16  OFF_17  OFF_18   OFF_19   OFF_20   OFF_21   OFF_22   OFF_23   OFF_24   OFF_25   OFF_26  OFF_27  OFF_28  FAR_143'
  'FAR_141 OFF_29  OFF_30  OFF_31  OFF_32   OFF_33   OFF_34   OFF_35   OFF_36   OFF_37   OFF_38   OFF_39   OFF_40  OFF_41  OFF_42  FAR_143'
  'FAR_141 OFF_43  OFF_44  OFF_45  WOOD_46  WOOD_47  WOOD_48  WOOD_49  WOOD_50  WOOD_51  WOOD_52  WOOD_53  OFF_54  OFF_55  OFF_56  FAR_143'
  'FAR_141 OFF_57  OFF_58  OFF_59  WOOD_60  GOAL_61  GOAL_62  GOAL_63  GOAL_64  GOAL_65  GOAL_66  WOOD_67  OFF_68  OFF_69  OFF_70  FAR_143'
  'FAR_141 OFF_71  OFF_72  OFF_73  WOOD_74  GOAL_75  GOAL_76  GOAL_77  GOAL_78  GOAL_79  GOAL_80  WOOD_81  OFF_82  OFF_83  OFF_84  FAR_143'
  'FAR_141 OFF_85  OFF_86  OFF_87  WOOD_88  GOAL_89  GOAL_90  GOAL_91  GOAL_92  GOAL_93  GOAL_94  WOOD_95  OFF_96  OFF_97  OFF_98  FAR_143'
  'FAR_141 OFF_99  OFF_100 OFF_101 WOOD_102 GOAL_103 GOAL_104 GOAL_105 GOAL_106 GOAL_107 GOAL_108 WOOD_109 OFF_110 OFF_111 OFF_112 FAR_143'
  'FAR_141 OFF_113 OFF_114 OFF_115 WOOD_116 GOAL_117 GOAL_118 GOAL_119 GOAL_120 GOAL_121 GOAL_122 WOOD_123 OFF_124 OFF_125 OFF_126 FAR_143'
  'FAR_141 OFF_127 OFF_128 OFF_129 WOOD_130 GOAL_131 GOAL_132 GOAL_133 GOAL_134 GOAL_135 GOAL_136 WOOD_137 OFF_138 OFF_139 OFF_140 FAR_143'
`;

export const ZONE_TYPES = {
  FAR: 'FAR',
  GOAL: 'GOAL',
  OFF: 'OFF',
  WOOD: 'WOOD',
};

export const GOAL_COLORS = {
  GOAL: 'green',
  OFF: 'green',
  WOOD: 'gray',
  FAR: 'purple',
  DISABLED: 'black',
};
