import hotkeys, { KeyHandler, type HotkeysEvent } from 'hotkeys-js';
import { Key } from 'ts-key-enum';

const numericKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'] as const;
type NumericKey = (typeof numericKeys)[number];
const modifierKeys = [Key.Control, Key.Shift, Key.Alt, Key.Meta];
type ModifierKey = (typeof modifierKeys)[number];

type NumericKeyInputCallback = (key: NumericKey) => unknown;

function isNumericKey(key: string): key is NumericKey {
  return numericKeys.includes(key as NumericKey);
}
/**
 * This function is used for quicker response because of hotkeys-js combo feature.
 *
 * It has to be used with the '*' wildcard `hotkeys-js` key.
 *
 * It covers the case where user can quickly press several keys
 * and any one of them was not yet released.
 *
 * Example:
 *
 * User quickly presses '1' and '2'. '2' is pressed before '1' is released.
 * Only '1' gets registered as hotkeys-js treats '2' press as '1+2' combo.
 */
export function onNumericKeyInput(cb: NumericKeyInputCallback): KeyHandler {
  return function (e) {
    if (isNumericKey(e.key)) {
      cb(e.key);
      return;
    }
    return;
  };
}

/**
 * Hotkeys-js uses modifier keys to perform combo shortcuts.
 * This closure allows detecting single modifier key pressed without
 * disabling combo shortcuts.
 * It needs to be registered on both keydown and keyup presses.
 * When desired modifier key is released and no other keys were used
 * in combo, the callback will be fired.
 * Whenever modifier key is used together with any other key
 * the callback will not be fired.
 */
export function onSingleModifierKey(modifierKey: ModifierKey, cb: KeyHandler) {
  let isCombo = false;
  let isModifier = false;
  return function (e: KeyboardEvent, he: HotkeysEvent) {
    const isKeyup = e.type === 'keyup';
    if (!isKeyup) {
      isModifier = e.key === modifierKey;
      const allKeys = hotkeys.getPressedKeyCodes();
      isCombo = allKeys.length > 1;
    }
    if (isKeyup && isModifier && !isCombo) {
      isModifier = false;
      isCombo = false;
      return cb(e, he);
    }
  };
}
