import { TABLE_CLASS_NAMES } from './constants';

export const findRowInPath = (path: HTMLElement[]) =>
  path.find(
    (element) =>
      element.classList && element.classList.contains(TABLE_CLASS_NAMES.ROW),
  ) as HTMLDivElement | undefined;

export const getRowActionId = (rowEl: HTMLDivElement | undefined) => {
  return rowEl && rowEl.dataset.actionId;
};
