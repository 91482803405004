import { ACTION_TYPE_ID } from '@contract';
import { getCurrentAccessToken } from '@/stores/UserStore';
import { getTeamPossessionPhaseId, storeAction } from '@/stores/FixtureStore';
import { ACTION_STATE } from '@/stores/constants';
import { Action } from '@/types/action/action';
import { formatTime } from '@/utils/formatTime';
import {
  setRecentAddedAction,
  setRecentUpdatedAction,
} from '@/stores/UnsyncedActionsStore';
import { isNewAction } from '@/stores/utils';
import { getActionWarnings } from '@/components/ActionWarnings/helpers';
import { SERVICE_ENDPOINT } from '../constants/endpoint';
import { postData } from '../postData';

function getActionUrl(actionTypeId: ACTION_TYPE_ID) {
  switch (actionTypeId) {
    case ACTION_TYPE_ID.Pass:
      return SERVICE_ENDPOINT.PassAction.post;
    case ACTION_TYPE_ID.Cross:
      return SERVICE_ENDPOINT.CrossAction.post;
    case ACTION_TYPE_ID.Launch:
      return SERVICE_ENDPOINT.LaunchAction.post;
    case ACTION_TYPE_ID.Shot:
      return SERVICE_ENDPOINT.ShotAction.post;
    case ACTION_TYPE_ID.Goal:
      return SERVICE_ENDPOINT.GoalAction.post;
    case ACTION_TYPE_ID.Error:
      return SERVICE_ENDPOINT.Error.post;
    case ACTION_TYPE_ID.PlayerLeft:
      return SERVICE_ENDPOINT.PlayerLeftAction.post;
    case ACTION_TYPE_ID.PlayerReturned:
      return SERVICE_ENDPOINT.PlayerReturnedAction.post;
    case ACTION_TYPE_ID.YellowCard:
      return SERVICE_ENDPOINT.CardAction.Yellow.post;
    case ACTION_TYPE_ID.RedCard:
      return SERVICE_ENDPOINT.CardAction.Red.post;
    case ACTION_TYPE_ID.Clearance:
      return SERVICE_ENDPOINT.ClearanceAction.post;
    case ACTION_TYPE_ID.GoalkeeperCollection:
      return SERVICE_ENDPOINT.GoalkeeperAction.Collection.post;
    case ACTION_TYPE_ID.GoalkeeperCatch:
      return SERVICE_ENDPOINT.GoalkeeperAction.Catch.post;
    case ACTION_TYPE_ID.GoalkeeperDrop:
      return SERVICE_ENDPOINT.GoalkeeperAction.Drop.post;
    case ACTION_TYPE_ID.GoalkeeperPunch:
      return SERVICE_ENDPOINT.GoalkeeperAction.Punch.post;
    case ACTION_TYPE_ID.Interception:
      return SERVICE_ENDPOINT.InterceptionAction.post;
    case ACTION_TYPE_ID.Offside:
      return SERVICE_ENDPOINT.OffsideAction.post;
    case ACTION_TYPE_ID.Aerial:
      return SERVICE_ENDPOINT.AerialAction.post;
    case ACTION_TYPE_ID.Dispossessed:
      return SERVICE_ENDPOINT.DispossessedAction.post;
    case ACTION_TYPE_ID.BallTouch:
      return SERVICE_ENDPOINT.BallTouchAction.post;
    case ACTION_TYPE_ID.GoalkeeperSave:
      return SERVICE_ENDPOINT.GoalkeeperAction.Save.post;
    case ACTION_TYPE_ID.BallRecovery:
      return SERVICE_ENDPOINT.BallRecoveryAction.post;
    case ACTION_TYPE_ID.GoalkeeperPenaltyFaced:
      return SERVICE_ENDPOINT.GoalkeeperPenaltyFacedAction.post;
    case ACTION_TYPE_ID.OffsideFor:
      return SERVICE_ENDPOINT.OffsideForAction.post;
    case ACTION_TYPE_ID.ThrowInAwarded:
      return SERVICE_ENDPOINT.ThrowInAction.Awarded.post;
    case ACTION_TYPE_ID.ThrowInTaken:
      return SERVICE_ENDPOINT.ThrowInAction.Taken.post;
    case ACTION_TYPE_ID.CornerAwarded:
      return SERVICE_ENDPOINT.CornerAction.Awarded.post;
    case ACTION_TYPE_ID.GoalKickAwarded:
      return SERVICE_ENDPOINT.GoalKickAction.Awarded.post;
    case ACTION_TYPE_ID.FreeKickAwarded:
      return SERVICE_ENDPOINT.FreeKickAction.Awarded.post;
    case ACTION_TYPE_ID.VARUnderway:
      return SERVICE_ENDPOINT.VARAction.Underway.post;
    case ACTION_TYPE_ID.VARResult:
      return SERVICE_ENDPOINT.VARAction.Result.post;
    case ACTION_TYPE_ID.Substitution:
      return SERVICE_ENDPOINT.SubstitutionAction.post;
    case ACTION_TYPE_ID.PlayResumed:
      return SERVICE_ENDPOINT.PlayAction.Resumed.post;
    case ACTION_TYPE_ID.Tackle:
      return SERVICE_ENDPOINT.TackleAction.post;
    case ACTION_TYPE_ID.BadTouch:
      return SERVICE_ENDPOINT.BadTouchAction.post;
    case ACTION_TYPE_ID.GoalkeeperThrow:
      return SERVICE_ENDPOINT.GoalkeeperAction.Throw.post;
    case ACTION_TYPE_ID.PlayStopped:
      return SERVICE_ENDPOINT.PlayAction.Stopped.post;
    case ACTION_TYPE_ID.StartPeriod:
      return SERVICE_ENDPOINT.ClockAction.periodStart;
    case ACTION_TYPE_ID.EndPeriod:
      return SERVICE_ENDPOINT.ClockAction.periodEnd;
    case ACTION_TYPE_ID.StartMatch:
      return SERVICE_ENDPOINT.ClockAction.fixtureStart;
    case ACTION_TYPE_ID.EndMatch:
      return SERVICE_ENDPOINT.ClockAction.fixtureEnd;
    case ACTION_TYPE_ID.AdditionalTime:
      return SERVICE_ENDPOINT.AdditionalTimeAction.post;
    case ACTION_TYPE_ID.PlayerBeaten:
      return SERVICE_ENDPOINT.PlayerBeatenAction.post;
    case ACTION_TYPE_ID.TakeOn:
      return SERVICE_ENDPOINT.TakeOnAction.post;
    case ACTION_TYPE_ID.RefereeDropBall:
      return SERVICE_ENDPOINT.RefereeDropBall.post;
    case ACTION_TYPE_ID.GoalkeeperKickFromHands:
      return SERVICE_ENDPOINT.GoalkeeperAction.KickFromHands.post;
    case ACTION_TYPE_ID.Lineups:
      return SERVICE_ENDPOINT.LineupsAction.post;
    case ACTION_TYPE_ID.Formation:
      return SERVICE_ENDPOINT.FormationAction.post;
    case ACTION_TYPE_ID.PostMatchCheckComplete:
      return SERVICE_ENDPOINT.PostMatchCheckCompleteAction.post;
    default:
      return;
  }
}

export async function postAction(actionData: Action) {
  const url = getActionUrl(actionData.actionTypeId);
  const token = getCurrentAccessToken();
  const teamPossessionPhaseId = getTeamPossessionPhaseId(actionData);

  if (!token || !url) return;
  const data: Action = {
    ...structuredClone(actionData),
    clockTime: formatTime(actionData.clockTime),
    messageId: null,
    createdAt: actionData.createdAt || Date.now(),
    updatedAt: Date.now(),
    state: ACTION_STATE.SYNC,
    teamPossessionPhaseId,
  };
  if (isNewAction(data)) {
    setRecentAddedAction(data.actionId);
  } else {
    setRecentUpdatedAction(data.actionId);
  }

  data.warnings = getActionWarnings(data);

  storeAction(data);

  return postData<Action, string>({
    url,
    data,
    token,
  }).catch(() => {
    storeAction({ ...data, state: ACTION_STATE.ERROR });
  });
}
