import { FC } from 'react';
import { MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import Flag from '@mui/icons-material/Flag';
import { setContextAction } from '@/stores/ActionStore/ActionStore';
import { hasFlagToQa } from '@/utils/actions';
import type { PropsWithAction } from '../ActionContextMenuContent';
import { toggleFlagToChecker } from '../actions';

export const TOGGLE_FLAG_LABEL = 'Toggle flag';

export const ToggleFlag: FC<PropsWithAction> = ({ action }) => {
  return (
    <MenuItem
      aria-label={TOGGLE_FLAG_LABEL}
      onClick={() => {
        toggleFlagToChecker(action);
        setContextAction(null);
      }}
    >
      <ListItemIcon>
        <Flag />
      </ListItemIcon>
      <ListItemText disableTypography>
        {hasFlagToQa(action) ? 'Unflag' : 'Flag'}
      </ListItemText>
    </MenuItem>
  );
};
