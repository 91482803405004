import { Chip, ChipProps } from '@mui/material';
import { FC } from 'react';
import Keyboard from '@mui/icons-material/Keyboard';
import { useActionStore } from '@/stores/ActionStore/ActionStore';

export const PitchModeLabel: FC<ChipProps> = (props) => {
  const mode = useActionStore((state) => state.mode);
  return (
    <Chip
      variant='outlined'
      color='info'
      size='small'
      icon={<Keyboard />}
      label={mode.toUpperCase()}
      {...props}
    />
  );
};
