import {
  createContext,
  useContext,
  useMemo,
  type FC,
  type PropsWithChildren,
} from 'react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  LINEUP_NAMED_POSITION,
  PITCH_PLAYERS_MAX_LEN,
  type LineupPositionEntry,
  type LineupStore,
  type StartingLineupStore,
} from '@/stores/LineupStore/constants';
import {
  calculateFormation,
  decomposeLineup,
  getSwappedPositionLineup,
  playerPositionsToMap,
} from '@/stores/LineupStore/utils';
import { Position } from '@contract';
import { isDebugEnabled, isTraceStoreEnabled } from '@/utils/debug';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';
import type { Player } from '@/service/lineups';

function getStoreDefault(
  partial: Partial<StartingLineupStore>,
): StartingLineupStore {
  return {
    teamId: undefined,
    teamName: undefined,
    pitchPlayersMap: new Map(),
    addedPlayers: [],
    offPlayers: [],
    squadPlayers: [],
    benchPlayers: [],
    pitchPlayers: [],
    lineup: [],
    selectedEntry: null,
    pitchPlayersLength: 0,
    formation: '',
    isGKSelected: false,
    ...partial,
  };
}

class LineupsStore {
  useStore;

  constructor() {
    const { teamId, teamName } = useLineupStore.getState();
    this.useStore = create<StartingLineupStore>()(
      devtools(() => ({ ...getStoreDefault({ teamId, teamName }) }), {
        enabled: isDebugEnabled(),
        trace: isTraceStoreEnabled,
        name: 'StartingLineupStore',
      }),
    );

    this.useStore.subscribe((state, prevState) => {
      if (state.lineup === prevState.lineup) return;
      const { benchPlayers, squadPlayers, pitchPlayers } = decomposeLineup(
        state.lineup,
      );

      return this.useStore.setState({
        benchPlayers,
        squadPlayers,
        pitchPlayers,
        selectedEntry: null,
      });
    });

    this.useStore.subscribe((newState, prevState) => {
      if (prevState.pitchPlayers === newState.pitchPlayers) return;
      const { pitchPlayers } = newState;

      return this.useStore.setState({
        pitchPlayersMap: playerPositionsToMap(pitchPlayers),
        pitchPlayersLength: pitchPlayers.length,
        formation: calculateFormation(pitchPlayers),
        isGKSelected: pitchPlayers.some(
          ({ position }) => position === Position.Gk,
        ),
      });
    });

    useLineupStore.subscribe((lineupState) => {
      this.useStore.setState((state) => {
        if (
          state.teamName === lineupState.teamName ||
          state.teamId === lineupState.teamId
        )
          return state;

        return {
          teamName: lineupState.teamName,
          teamId: lineupState.teamId,
        };
      });
    });
  }

  setLineup(lineup: LineupStore['lineup']) {
    return this.useStore.setState({ lineup }, false, 'setLineup');
  }

  setAddedPlayers(addedPlayers: Player[]) {
    return this.useStore.setState({ addedPlayers }, false, 'setAddedPlayers');
  }

  removeAddedPlayer(player: Player) {
    return this.useStore.setState(
      (state) => {
        const addedPlayers = state.addedPlayers.filter(
          (p) => p.id !== player.id,
        );
        const lineup = state.lineup.filter(
          ({ player: p }) => p.id !== player.id,
        );
        return { addedPlayers, lineup };
      },
      false,
      'removeAddedPlayer',
    );
  }

  addAddedPlayer(player: Player) {
    return this.useStore.setState(
      (state) => {
        const addedPlayers = [...state.addedPlayers, player];
        const lineup: StartingLineupStore['lineup'] = [
          ...state.lineup,
          { player, position: LINEUP_NAMED_POSITION.SQUAD },
        ];

        return { addedPlayers, lineup };
      },
      false,
      'addAddedPlayer',
    );
  }

  useIsAddedPlayer(player: Player) {
    const addedPlayers = this.useStore((state) => state.addedPlayers);
    return addedPlayers.some((p) => p.id === player.id);
  }

  selectEntry(
    position: LineupPositionEntry[0],
    player: LineupPositionEntry[1],
  ) {
    this.useStore.setState((state) => {
      const oldEntry = state.selectedEntry;
      const newEntry: LineupPositionEntry = [position, player];
      const isTheSamePosition = oldEntry && oldEntry[0] === position;
      const isTheSamePlayer =
        oldEntry &&
        (oldEntry[1] === player ||
          (oldEntry[1] && player && oldEntry[1].id === player.id));

      if (isTheSamePlayer && isTheSamePosition) {
        return { selectedEntry: null };
      }
      if (!oldEntry) {
        return { selectedEntry: newEntry };
      }

      const [destinationPosition, destinationPlayer] = newEntry;
      const [targetPosition, targetPlayer] = oldEntry;
      if (!destinationPlayer && !targetPlayer) {
        return { selectedEntry: newEntry };
      }
      if (destinationPosition === targetPosition) {
        return { selectedEntry: newEntry };
      }
      if (targetPlayer === destinationPlayer) {
        return { selectedEntry: newEntry };
      }

      if (state.pitchPlayersLength >= PITCH_PLAYERS_MAX_LEN) {
        if (
          destinationPosition in Position &&
          !(targetPosition in Position) &&
          targetPlayer &&
          !destinationPlayer
        ) {
          return { selectedEntry: newEntry };
        }
        if (
          targetPosition in Position &&
          !(destinationPosition in Position) &&
          !targetPlayer &&
          destinationPlayer
        ) {
          return { selectedEntry: newEntry };
        }
      }

      const lineup = getSwappedPositionLineup(state.lineup, oldEntry, newEntry);
      return { lineup, selectedEntry: null };
    });
  }
}

const StartingLineupsContext = createContext<LineupsStore>(new LineupsStore());

export const useStartingLineupsContext = () =>
  useContext(StartingLineupsContext);

export const StartingLineupContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const lineupsStore = useMemo(() => new LineupsStore(), []);

  return (
    <StartingLineupsContext.Provider value={lineupsStore}>
      {children}
    </StartingLineupsContext.Provider>
  );
};
