import { FC } from 'react';
import { Chip, ChipProps } from '@mui/material';
import Build from '@mui/icons-material/Build';
import { version } from '@root/package.json';

type VersionLabel = ChipProps;

export const VersionLabel: FC<VersionLabel> = (props) => {
  return (
    <Chip
      icon={<Build />}
      label={version}
      size='small'
      color='info'
      variant='outlined'
      {...props}
    />
  );
};
