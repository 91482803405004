import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { FC } from 'react';
import { Player } from '@/service/lineups';
import { PlayerCurrentCard } from '@/components/PlayerCard/PlayerCard';
import { PlayerSubstitutions } from '@/components/PlayerSubstitutions/PlayerSubstitutions';

type LiveLineupPlayerListItemProps = {
  player: Player;
};

export const LiveLineupPlayerListItem: FC<LiveLineupPlayerListItemProps> = ({
  player,
}) => {
  return (
    <ListItem sx={{ gap: 2 }}>
      <ListItemAvatar sx={{ position: 'relative', minWidth: 'unset' }}>
        <Avatar sx={{ color: 'currentColor' }}>{player.shirtNumber}</Avatar>
        <PlayerCurrentCard player={player} />
      </ListItemAvatar>
      <ListItemText primary={player.fullName} />
      <PlayerSubstitutions playerId={player.id} />
    </ListItem>
  );
};
