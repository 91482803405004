import { styled, Box, BoxProps } from '@mui/material';
import { CSSProperties, FC } from 'react';

const _Dot = styled(Box)`
  --size: 18px;
  --color: var(--targetsColor);
  width: var(--size);
  aspect-ratio: 1;
  background-color: var(--color);
  border-radius: 50%;
`;

type DotProps = Omit<BoxProps, 'color' | 'size'> & {
  color?: string;
  size?: string;
};

export const Dot: FC<DotProps> = (allProps) => {
  const { color, size, ...props } = allProps;
  return (
    <_Dot
      style={{ '--color': color, '--size': size } as CSSProperties}
      {...props}
    />
  );
};
