import { FC } from 'react';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import type { Action, PassAction } from '@/types/action/action';
import { ACTION_TYPE_ID, ActionContext } from '@contract';
import { RadioButtons } from '@/components/Form/RadioButtons';
import {
  ACTION_CONTEXT_LABEL,
  CORNER_TAKEN_LABEL,
  FREE_KICK_TAKEN_LABEL,
  GOAL_KICK_TAKEN_LABEL,
  KICK_OFF_AFTER_GOAL_LABEL,
  KICK_OFF_START_PERIOD_LABEL,
  NONE_LABEL,
  PENALTY_TAKEN_LABEL,
} from '@/constants';
import { NULL_STRING } from './constants';

const ACTION_CONTEXT_FIELDS = {
  [ActionContext.CornerTaken]: CORNER_TAKEN_LABEL,
  [ActionContext.FreeKickTaken]: FREE_KICK_TAKEN_LABEL,
  [ActionContext.GoalKickTaken]: GOAL_KICK_TAKEN_LABEL,
  [ActionContext.KickOff]: KICK_OFF_START_PERIOD_LABEL,
  [ActionContext.KickOffAfterGoal]: KICK_OFF_AFTER_GOAL_LABEL,
  [ActionContext.PenaltyTaken]: PENALTY_TAKEN_LABEL,
  [NULL_STRING]: NONE_LABEL,
};

type ActionContextFieldProps = {
  action: Action;
};

export const ActionContextField: FC<ActionContextFieldProps> = ({ action }) => {
  if (action.actionTypeId !== ACTION_TYPE_ID.Pass) return;

  const onChange = (actionContext: ActionContext) => {
    if (!action) return;
    setActionPartialMetadata<PassAction>({
      actionContext: isNaN(actionContext) ? null : actionContext,
    });
  };

  return (
    <RadioButtons
      name='actionContext'
      onChange={onChange}
      controlLabel={ACTION_CONTEXT_LABEL}
      fields={ACTION_CONTEXT_FIELDS}
      value={action.metadata.pass.actionContext}
      sx={{ maxWidth: '60ch' }}
    />
  );
};
