import { Box, type BoxProps } from '@mui/material';
import { FC } from 'react';
import type { PlayerDto } from '@contract';
import { useFixtureStore, type FixtureStore } from '@/stores/FixtureStore';
import { CardIcon } from '../CardIcon/CardIcon';

type PlayerCardProps = BoxProps & {
  state: FixtureStore['gameState'];
  player: PlayerDto;
  variant?: 'absolute' | 'inline';
};
const ABSOLUTE_SX: BoxProps['sx'] = {
  position: 'absolute',
  right: '-15%',
  top: '-15%',
};

export const PlayerCard: FC<PlayerCardProps> = ({
  player,
  state,
  variant = 'absolute',
  sx,
  ...boxProps
}) => {
  const playerCard = state.cards[player.id] ?? null;
  if (playerCard === null) return;

  const _sx: BoxProps['sx'] = variant === 'absolute' ? ABSOLUTE_SX : {};
  return (
    <Box
      sx={{
        width: '0.8em',
        ..._sx,
        ...sx,
      }}
      {...boxProps}
    >
      <CardIcon variant={playerCard} />
    </Box>
  );
};

type PlayerCurrentCardProps = Omit<PlayerCardProps, 'state'>;

export const PlayerCurrentCard: FC<PlayerCurrentCardProps> = ({
  player,
  ...props
}) => {
  const gameState = useFixtureStore((state) => state.gameState);
  return <PlayerCard state={gameState} player={player} {...props} />;
};
