import { Stack } from '@mui/material';
import { FC } from 'react';
import { TableAction, useFixtureStore } from '@/stores/FixtureStore';
import { useClockStore } from '@/stores/ClockStore';
import { COLUMNS, TABLE_CLASS_NAMES } from './constants';
import { QAHistoryTableCell } from './QAHistoryTableCell';

interface ActionsHistoryTableRowProps {
  action: TableAction;
  isSelected: boolean;
}

export const QAHistoryTableRow: FC<ActionsHistoryTableRowProps> = ({
  action,
  isSelected,
}) => {
  const clockTimeTicks = useClockStore((state) => state.clockTimeTicks);
  const currentPeriod = useFixtureStore((state) => state.currentPeriod);

  if (
    action.period.sequence > currentPeriod.sequence ||
    (action.period.sequence === currentPeriod.sequence &&
      clockTimeTicks < action.clockTimeTicks)
  )
    return;

  return (
    <Stack
      className={`${TABLE_CLASS_NAMES.ROW}`}
      direction='row'
      data-action-state={action.state}
      data-action-id={action.actionId}
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: (theme) => theme.palette.action.hover,
        },
        backgroundColor: (theme) =>
          isSelected ? theme.palette.action.selected : 'unset',
      }}
    >
      {COLUMNS.map((column) => (
        <QAHistoryTableCell key={column.id} action={action} column={column} />
      ))}
    </Stack>
  );
};
