import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';

type MinMaxInputProps = TextFieldProps & {
  onNumberChange: (value: number) => void;
  min: number;
  max: number;
};

export const MinMaxInput: FC<MinMaxInputProps> = (allProps) => {
  const { onNumberChange, min, max, ...props } = allProps;

  const handleChange = (fieldValue: number) => {
    if (fieldValue < min || fieldValue > max) return;

    onNumberChange(fieldValue);
  };

  return (
    <TextField
      type='number'
      InputProps={{
        inputProps: { min, max },
      }}
      onChange={(e) => handleChange(Number(e.target.value))}
      {...props}
    />
  );
};
