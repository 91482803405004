import { v4 as uuid } from 'uuid';
import { type DataCollectionDto, FixtureDto, UserRole } from '@contract';
import type { FixturesCollectionResponse } from '@/service/dataCollection';
import { TEAM_SIDE } from '@/components/FixturesCollectionsTable/constants';
import { SS_KEY } from '@/utils/window';
import {
  COMPETITION_CATEGORIES,
  COMPETITION_NAMES,
  makeFakeLocation,
  getFixtureName as makeFixtureName,
  getRandomTeam,
} from './fakeData';

export const makeFakeCollectionTeams = (): FixtureDto['teams'] => {
  const homeTeam = {
    id: uuid(),
    side: TEAM_SIDE.HOME,
    ...getRandomTeam(),
  };

  const awayTeam = {
    id: uuid(),
    side: TEAM_SIDE.AWAY,
    ...getRandomTeam(),
  };

  return [homeTeam, awayTeam];
};

/**
 *
 * @param isHomeTeamAssigned
 * When assigned sets the value to one specified, otherwise it's null
 * @returns CollectionDto
 */
export function makeFakeCollectionInfo(data: {
  dataCollectionId: string;
  roles: UserRole[];
  teams?: FixtureDto['teams'];
}): DataCollectionDto {
  const { dataCollectionId, roles, teams } = data;
  const location = makeFakeLocation();
  const _teams = teams ?? makeFakeCollectionTeams();
  const competitionName =
    COMPETITION_NAMES[Math.floor(Math.random() * COMPETITION_NAMES.length)];
  const competitionCategory =
    COMPETITION_CATEGORIES[
      Math.floor(Math.random() * COMPETITION_CATEGORIES.length)
    ];

  return {
    id: dataCollectionId,
    status: 0,
    myAcceptation: 'Pending',
    chatLink: '',
    isHomeTeamAssigned: null,
    roles,
    fixture: {
      id: uuid(),
      name: makeFixtureName(_teams),
      status: 0,
      startDateUtc: new Date('2023-12-21T21:00:00'),
      sportName: 'Soccer',
      competitionName,
      competitionCategory,
      competitionLocation: location,
      venueName: 'Fake venue',
      venueLocation: location,
      teams: _teams,
    },
    periodDetails: [
      {
        seq: 1,
        label: '1st half',
        initialClock: 0,
        disableEndingPeriodLimit: 2700,
      },
      {
        seq: 2,
        label: '2nd half',
        initialClock: 2700,
        disableEndingPeriodLimit: 5400,
      },
    ],
  };
}

export function makeFakeFixturesCollectionResponse(): FixturesCollectionResponse {
  const sessionData = sessionStorage.getItem(SS_KEY.MOCK_FIXTURES_COLLECTION);
  if (sessionData) {
    return JSON.parse(sessionData);
  }

  const upcomingTeams = makeFakeCollectionTeams();
  const data = {
    upcomingCollections: [
      makeFakeCollectionInfo({
        dataCollectionId: uuid(),
        roles: [
          UserRole.HomeScorer,
          UserRole.AwayScorer,
          UserRole.Qa,
          UserRole.Supervisor,
        ],
        teams: upcomingTeams,
      }),
    ],
    previousCollections: [
      makeFakeCollectionInfo({
        dataCollectionId: uuid(),
        roles: [UserRole.HomeScorer],
      }),
      makeFakeCollectionInfo({
        dataCollectionId: uuid(),
        roles: [UserRole.AwayScorer],
      }),
      makeFakeCollectionInfo({
        dataCollectionId: uuid(),
        roles: [UserRole.Qa],
      }),
      makeFakeCollectionInfo({
        dataCollectionId: uuid(),
        roles: [UserRole.Supervisor],
      }),
    ],
  };

  sessionStorage.setItem(SS_KEY.MOCK_FIXTURES_COLLECTION, JSON.stringify(data));
  return data;
}
