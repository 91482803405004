import { useFixtureStore } from '@/stores/FixtureStore';
import { DIRECTION_OF_PLAY } from './constants';

export const getIsHomeTeamLeft = (directionOfPlay: DIRECTION_OF_PLAY) => {
  const { isHomeTeamCollector } = useFixtureStore.getState();

  if (isHomeTeamCollector === null) return true;
  if (isHomeTeamCollector) {
    return directionOfPlay === DIRECTION_OF_PLAY.LEFT_TO_RIGHT;
  }
  return directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT;
};
