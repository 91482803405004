import { Stack, TableContainer } from '@mui/material';
import { FC, useCallback, useRef, type MouseEvent } from 'react';
import { ViewportList } from 'react-viewport-list';
import { type TableAction } from '@/stores/FixtureStore';
import { findRowInPath, getRowActionId } from '../ActionsTable/utils';
import { COLUMNS, TABLE_CLASS_NAMES } from '../ActionsTable/constants';
import { ActionsTableHeader } from '../ActionsTable/ActionsTableHeader';
import { ActionTableCell } from '../ActionsTable/ActionsTableCell';

type ActionPickerProps = {
  onChange: (actionId: string | null) => void;
  tableActions: TableAction[];
  selected?: string;
};

export const ActionPicker: FC<ActionPickerProps> = ({
  onChange,
  tableActions,
  selected,
}) => {
  const $viewportRef = useRef(null);
  const $list = useRef(null);

  const initialIndex = !selected
    ? undefined
    : tableActions.findIndex((action) => action.actionId === selected);

  const onRowClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      const path = e.nativeEvent.composedPath() as HTMLElement[];

      const actionId = getRowActionId(findRowInPath(path));
      if (!actionId) return;
      if (actionId === selected) return onChange(null);
      return onChange(actionId);
    },
    [onChange, selected],
  );

  return (
    <TableContainer
      id='action-picker'
      ref={$viewportRef}
      sx={{ maxHeight: 450, maxWidth: '98vw', width: 1200, minWidth: 600 }}
    >
      <Stack>
        <ActionsTableHeader />
        <Stack onClick={onRowClick}>
          <ViewportList
            viewportRef={$viewportRef}
            ref={$list}
            initialIndex={initialIndex}
            items={tableActions}
          >
            {(action) => (
              <Stack
                key={action.actionId}
                direction='row'
                data-action-id={action.actionId}
                className={TABLE_CLASS_NAMES.ROW}
                sx={{
                  cursor: 'pointer',
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.action.hover,
                  },
                  backgroundColor: (theme) => {
                    if (!selected) return;
                    if (selected === action.actionId)
                      return theme.palette.action.selected;
                  },
                }}
              >
                {COLUMNS.map((column) => (
                  <ActionTableCell
                    key={column.id}
                    action={action}
                    column={column}
                  />
                ))}
              </Stack>
            )}
          </ViewportList>
        </Stack>
      </Stack>
    </TableContainer>
  );
};
