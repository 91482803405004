import { FC } from 'react';
import { Button, Chip, Stack } from '@mui/material';
import type { FormationAction } from '@/types/action/action';
import VerticalPitchSVG from '@/assets/football-pitch-vertical.svg?react';
import type { PlayerPositionDto } from '@contract';
import {
  PITCH_FORMATION_ID,
  PITCH_LINEUP_ID,
  PitchOrientation,
} from '../constants';
import { FormationContainer } from '../FormationContainer';
import { EditPitchGrid } from './EditPitchGrid';

interface EditVerticalFormationProps {
  action: FormationAction;
  pitchOrientation?: PitchOrientation;
  onSave: VoidFunction;
  onCancel: VoidFunction;
  onChange: (changedPitchPlayers: PlayerPositionDto[]) => void;
  hasChanged: boolean;
  formation: string;
}

export const EditVerticalFormation: FC<EditVerticalFormationProps> = ({
  action,
  onSave,
  onCancel,
  onChange,
  hasChanged,
  formation,
}) => {
  return (
    <Stack flex={1} p={1} gap={1}>
      <Stack position='relative' id={PITCH_LINEUP_ID} marginTop='auto'>
        <VerticalPitchSVG />
        <EditPitchGrid
          setPitchPlayers={onChange}
          pitchPlayers={action.metadata.formation.pitchPlayers}
          pitchOrientation={PitchOrientation.Vertical}
        />
        <FormationContainer>
          <Chip id={PITCH_FORMATION_ID} label={formation} />
        </FormationContainer>
      </Stack>
      <Stack direction='row' justifyContent='end' gap={1}>
        <Button variant='outlined' color='secondary' onClick={onCancel}>
          Cancel
        </Button>
        <Button variant='outlined' disabled={!hasChanged} onClick={onSave}>
          Commit
        </Button>
      </Stack>
    </Stack>
  );
};
