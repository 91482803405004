import { FC } from 'react';
import { FormLabel, Stack } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { SubstitutionAction } from '@/types/action/action';
import {
  setAction,
  setActionPartialMetadata,
} from '@/stores/ActionStore/ActionStore';
import { PlayerAutocomplete } from '@/components/Form/PlayerAutocomplete';
import { Player } from '@/service/lineups';
import { PLAYERS_LIST_VARIANT } from '@/stores/LineupStore/utils';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { ParentAction } from '@/components/ParentAction/ParentAction';
import { isNewAction } from '@/stores/utils';
import { Controls } from '../Common/Controls';
import { PLAYER_OFF_LABEL, PLAYER_ON_LABEL } from './constants';

interface SubstitutionDialogContentProps {
  action: SubstitutionAction;
}

const subbedOnPlayerChange = (player: Player | null) => {
  setActionPartialMetadata<SubstitutionAction>({
    targetPlayer: player,
  });
};

export const SubstitutionDialogContent: FC<SubstitutionDialogContentProps> = ({
  action,
}) => {
  /**
   * Subtracting clock time to get players list
   * from state before that action.
   * When editing substitution the corresponding game state
   * would have a snapshot at this action clockTimeTicks.
   * To properly edit substitution, we need to use previous state instead.
   */
  const clockTimeTicks = isNewAction(action)
    ? action.clockTimeTicks
    : action.clockTimeTicks - 1;
  const subbedOffPlayerChange = (player: Player | null) => {
    setAction({
      ...action,
      player,
    });
  };

  return (
    <Stack p={1} gap={2}>
      <Stack direction='row'>
        <PlayArrowIcon sx={{ rotate: '180deg' }} color='error' />
        <FormLabel>{PLAYER_OFF_LABEL}</FormLabel>
      </Stack>
      <PlayerAutocomplete
        label={PLAYER_OFF_LABEL}
        player={action.player}
        periodNumber={action.period.sequence}
        clockTimeTicks={clockTimeTicks}
        teamId={action.team?.id}
        onChange={subbedOffPlayerChange}
      />
      <Stack direction='row'>
        <PlayArrowIcon color='success' />
        <FormLabel>{PLAYER_ON_LABEL}</FormLabel>
      </Stack>
      <PlayerAutocomplete
        label={PLAYER_ON_LABEL}
        player={action.metadata.substitution.targetPlayer}
        periodNumber={action.period.sequence}
        clockTimeTicks={clockTimeTicks}
        teamId={action.team?.id}
        playersListVariant={PLAYERS_LIST_VARIANT.BENCH}
        onChange={subbedOnPlayerChange}
      />
      <PeriodClockField action={action} />
      <ParentAction action={action} />
      <Controls action={action} />
    </Stack>
  );
};
