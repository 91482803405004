import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  SxProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DIRECTION } from './constants';

interface RadioButtonsProps<T> {
  name: string;
  onChange: (arg: T) => void;
  value: T | null;
  controlLabel: string;
  fields?: Record<string, string>;
  disabled?: boolean;
  onClear?: () => void;
  sx?: SxProps;
  direction?: DIRECTION;
}

export function RadioButtons<T extends number | string = string | number>({
  name,
  onChange,
  controlLabel,
  fields,
  disabled = false,
  value,
  onClear,
  sx,
  direction = DIRECTION.ROW,
}: RadioButtonsProps<T>) {
  if (!fields) return;

  return (
    <FormControl>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <FormLabel id={controlLabel} sx={{ textTransform: 'capitalize' }}>
          {controlLabel}
        </FormLabel>
        {onClear && (
          <IconButton onClick={onClear}>
            <CloseIcon />
          </IconButton>
        )}
      </Stack>

      <RadioGroup
        row={direction === DIRECTION.ROW}
        value={value === null ? null : String(value)}
        aria-labelledby={controlLabel}
        onChange={(e) => {
          const valueAsString = e.target.value;
          const valueAsNumber = Number(valueAsString);

          if (Number.isNaN(valueAsNumber)) {
            onChange(valueAsString as T);
          } else {
            onChange(valueAsNumber as T);
          }
        }}
        sx={sx}
      >
        {Object.keys(fields).map((value) => (
          <FormControlLabel
            key={value}
            value={value}
            disabled={disabled}
            control={<Radio />}
            label={fields[value]}
            name={name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
