import { UserRole } from '@contract';

export const ROUTE_ID = {
  SIGN_IN: 'signIn',
  HOME: 'home',
  FIXTURE_COLLECTION: 'fixtureCollection',
  HOME_COLLECTOR: 'homeCollector',
  HOME_COLLECTOR_PITCH: 'homeCollectorView',
  HOME_COLLECTOR_HISTORY: 'homeCollectorHistory',
  HOME_COLLECTOR_LINEUP_INIT: 'homeCollectorLineupInit',
  HOME_COLLECTOR_LIVE_LINEUP: 'homeCollectorLiveLineup',
  AWAY_COLLECTOR: 'awayCollector',
  AWAY_COLLECTOR_PITCH: 'awayCollectorView',
  AWAY_COLLECTOR_HISTORY: 'awayCollectorHistory',
  AWAY_COLLECTOR_LINEUP_INIT: 'awayCollectorLineupInit',
  AWAY_COLLECTOR_LIVE_LINEUP: 'awayCollectorLiveLineup',
  QA: 'qa',
  QA_PITCH: 'qaView',
  QA_HISTORY: 'qaHistory',
  QA_LINEUP_INIT: 'qaLineupInit',
  QA_LIVE_LINEUP: 'qaLiveLineup',
} as const;

export type RouteId = ConstType<typeof ROUTE_ID>;

export const ROUTE_PARAM = {
  COLLECTION_ID: 'collectionId',
} as const;

const PATH_BASE = {
  AUTH: 'auth',
  OKTA: 'okta',
  HOME_COLLECTOR: 'collector/home',
  AWAY_COLLECTOR: 'collector/away',
  QA: 'qa',
} as const;

export const ROUTES_FIXTURE_VIEW = {
  STARTING_LINEUP: {
    PATH: 'starting-lineup',
    NAME: 'Starting lineup',
  },
  PITCH: {
    PATH: '',
    NAME: 'Pitch',
  },
  HISTORY: {
    PATH: 'history',
    NAME: 'Event history',
  },
  QA: {
    PATH: '',
    NAME: 'Quality assurance',
  },
  LIVE_LINEUP: {
    PATH: 'live-lineup',
    NAME: 'Live lineup',
  },
};

export const ROUTE_PATH = {
  AUTH: PATH_BASE.AUTH,
  OKTA: PATH_BASE.OKTA,
  HOME_COLLECTOR: {
    DEFAULT: PATH_BASE.HOME_COLLECTOR,
    HISTORY: `${PATH_BASE.HOME_COLLECTOR}/${ROUTES_FIXTURE_VIEW.HISTORY.PATH}`,
    LINEUP_INIT: `${PATH_BASE.HOME_COLLECTOR}/${ROUTES_FIXTURE_VIEW.STARTING_LINEUP.PATH}`,
    LIVE_LINEUP: `${PATH_BASE.HOME_COLLECTOR}/${ROUTES_FIXTURE_VIEW.LIVE_LINEUP.PATH}`,
  },
  AWAY_COLLECTOR: {
    DEFAULT: PATH_BASE.AWAY_COLLECTOR,
    HISTORY: `${PATH_BASE.AWAY_COLLECTOR}/${ROUTES_FIXTURE_VIEW.HISTORY.PATH}`,
    LINEUP_INIT: `${PATH_BASE.AWAY_COLLECTOR}/${ROUTES_FIXTURE_VIEW.STARTING_LINEUP.PATH}`,
    LIVE_LINEUP: `${PATH_BASE.AWAY_COLLECTOR}/${ROUTES_FIXTURE_VIEW.LIVE_LINEUP.PATH}`,
  },
  QA: {
    DEFAULT: PATH_BASE.QA,
  },
};

export const ROUTE_URL = {
  HOME: '/',
  SIGN_IN: `/${ROUTE_PATH.AUTH}`,
  OKTA_LOGIN_CALLBACK: `/${ROUTE_PATH.AUTH}/${ROUTE_PATH.OKTA}`,
  VIEW: {
    HOME_COLLECTOR: {
      DEFAULT: `/:${ROUTE_PARAM.COLLECTION_ID}/${ROUTE_PATH.HOME_COLLECTOR.DEFAULT}`,
      HISTORY: `/:${ROUTE_PARAM.COLLECTION_ID}/${ROUTE_PATH.HOME_COLLECTOR.HISTORY}`,
      LINEUP_INIT: `/:${ROUTE_PARAM.COLLECTION_ID}/${ROUTE_PATH.HOME_COLLECTOR.LINEUP_INIT}`,
      LIVE_LINEUP: `/:${ROUTE_PARAM.COLLECTION_ID}/${ROUTE_PATH.HOME_COLLECTOR.LIVE_LINEUP}`,
    },
    AWAY_COLLECTOR: {
      DEFAULT: `/:${ROUTE_PARAM.COLLECTION_ID}/${ROUTE_PATH.AWAY_COLLECTOR.DEFAULT}`,
      HISTORY: `/:${ROUTE_PARAM.COLLECTION_ID}/${ROUTE_PATH.AWAY_COLLECTOR.HISTORY}`,
      LINEUP_INIT: `/:${ROUTE_PARAM.COLLECTION_ID}/${ROUTE_PATH.AWAY_COLLECTOR.LINEUP_INIT}`,
      LIVE_LINEUP: `/:${ROUTE_PARAM.COLLECTION_ID}/${ROUTE_PATH.AWAY_COLLECTOR.LIVE_LINEUP}`,
    },
    QA: {
      DEFAULT: `/:${ROUTE_PARAM.COLLECTION_ID}/${ROUTE_PATH.QA.DEFAULT}`,
    },
  },
};

export const ROLE_VIEW = {
  [UserRole.Qa]: ROUTE_PATH.QA.DEFAULT,
  [UserRole.Supervisor]: ROUTE_PATH.QA.DEFAULT,
  [UserRole.HomeScorer]: ROUTE_PATH.HOME_COLLECTOR.DEFAULT,
  [UserRole.AwayScorer]: ROUTE_PATH.AWAY_COLLECTOR.DEFAULT,
};
