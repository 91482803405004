import { FC } from 'react';
import { Stack, Typography, type StackProps } from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { Player } from '@/service/lineups';
import { useGetPlayerSubstitutions } from '@/stores/FixtureStore';
import { SubstitutionColors } from '@/components/QALineups/constants';
import { SubstitutionTime } from './SubstitutionTime';

type SubstitutionProps = StackProps & {
  playerId: Player['id'];
};

export const PlayerSubstitutions: FC<SubstitutionProps> = ({
  playerId,
  ...stackProps
}) => {
  const substitutions = useGetPlayerSubstitutions(playerId);

  if (!substitutions) return;

  return (
    <Stack gap={1} {...stackProps}>
      {substitutions.map((substitution) => (
        <Stack
          gap={0.5}
          key={`${playerId}-${substitution.periodSeq}-${substitution.clockTimeTicks}`}
          direction='row'
        >
          <CompareArrowsIcon color={SubstitutionColors[substitution.type]} />
          <SubstitutionTime
            clockTimeTicks={substitution.clockTimeTicks}
            periodSeq={substitution.periodSeq}
          />
          <Typography>
            ({substitution.substitutionPlayer.shirtNumber})
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
