import { Stack } from '@mui/material';
import { FC, useCallback, useRef } from 'react';
import { ClickableDots, type DotProps } from '@/components/Pitch/ClickableDots';
import { getActionMetadata } from '@/utils/actions';
import PitchHorizontalSVG from '@/assets/football-pitch-with-goals.svg?react';
import { TargetAction } from '@/stores/ActionStore/constants';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { PITCH_POSITION } from '../constants';

type DestinationPitchProps = {
  action: TargetAction;
};

export const DestinationPitch: FC<DestinationPitchProps> = ({ action }) => {
  const metadata = getActionMetadata(action);
  const $pitchContainer = useRef<HTMLDivElement>(null);

  const setCoords = useCallback((dot: DotProps) => {
    if (!dot.positionId) return;

    if (dot.positionId === PITCH_POSITION.PASS_FROM) {
      setActionPartialMetadata<TargetAction>({ position: dot.coords });
    }

    if (dot.positionId === PITCH_POSITION.PASS_TO) {
      setActionPartialMetadata<TargetAction>({ targetPosition: dot.coords });
    }
  }, []);

  return (
    <Stack
      sx={{
        position: 'relative',
        flex: 1,
        width: 'clamp(500px, 100%, 1000px)',
      }}
      ref={$pitchContainer}
    >
      <PitchHorizontalSVG />
      <ClickableDots
        dots={[
          {
            positionId: PITCH_POSITION.PASS_FROM,
            coords: metadata.position,
          },
          {
            positionId: PITCH_POSITION.PASS_TO,
            coords: metadata.targetPosition,
          },
        ]}
        setCoords={setCoords}
        shouldDisplayLine
        pitchContainerRef={$pitchContainer}
      />
    </Stack>
  );
};
