import { FC, useMemo, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { TeamDto } from '@contract';
import { Dialog } from '@/components/Dialog/Dialog';
import { CANCEL_BUTTON_LABEL, CREATE_BUTTON_LABEL } from '@/constants';
import { ActionTypeAutocomplete } from '@/components/Form/ActionTypeAutocomplete';
import { TeamGroup } from '@/components/Form/TeamGroup';
import {
  setActionViewAction,
  setDialogAction,
} from '@/stores/ActionStore/ActionStore';
import { ADD_ACTION_DIALOG_TITLE } from '@/components/AddAction/constants';
import { isQaActionType, isTeamActionType } from '@/components/AddAction/utils';
import { ACTION_TYPES, ActionTypeOption } from '@/types/action/actionType';
import { createDefaultAction } from '@/stores/ActionStore/createActions';

interface AddActionDialogContentProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const AddActionDialog: FC<AddActionDialogContentProps> = ({
  isOpen,
  onClose,
}) => {
  const [team, setTeam] = useState<TeamDto | null>(null);
  const [actionType, setActionType] = useState<ActionTypeOption | null>(null);
  const isRadioGroupDisabled = !actionType || !isTeamActionType(actionType);
  const isCreateDisabled =
    !actionType || (isTeamActionType(actionType) && !team);
  const sortedOptions = useMemo(() => {
    return ACTION_TYPES.filter(isQaActionType).sort((a, b) =>
      a.label.localeCompare(b.label),
    );
  }, []);

  const onCreate = () => {
    if (!actionType) return;

    const action = createDefaultAction(actionType.value, team?.id);

    if (!action) return;

    setActionViewAction(null);
    setDialogAction({
      ...action,
      team: team ? { id: team.id, name: team.name } : null,
    });

    _onClose();
  };

  const _onClose = () => {
    setTeam(null);
    setActionType(null);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      onClose={_onClose}
      maxWidth='sm'
      isOpen={isOpen}
      title={ADD_ACTION_DIALOG_TITLE}
    >
      <Stack width='100%' gap={2}>
        <ActionTypeAutocomplete
          options={sortedOptions}
          onChange={(actionType: ActionTypeOption | null) => {
            setActionType(actionType);
            setTeam(null);
          }}
        />
        <TeamGroup
          value={team?.id || null}
          disabled={isRadioGroupDisabled}
          onChange={(team: TeamDto | null) => setTeam(team)}
        />
        <Stack gap={2} flexDirection='row' justifyContent='end'>
          <Button variant='outlined' color='inherit' onClick={_onClose}>
            {CANCEL_BUTTON_LABEL}
          </Button>
          <Button
            disabled={isCreateDisabled}
            onClick={onCreate}
            variant='contained'
          >
            {CREATE_BUTTON_LABEL}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
