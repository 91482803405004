import type { FC } from 'react';
import { Box, Stack, Typography, type BoxProps } from '@mui/material';
import type { Player } from '@/service/lineups';
import { PlayerSubstitutions } from '@/components/PlayerSubstitutions/PlayerSubstitutions';
import { PlayerCurrentCard } from '@/components/PlayerCard/PlayerCard';
import { LineupType } from './constants';

export type LineupPlayersProps = BoxProps & {
  players: Player[];
  type: LineupType;
};

type LineupPlayer = {
  player: Player;
};

const HomePlayer: FC<LineupPlayer> = ({ player }) => {
  return (
    <Stack gap={2} direction='row' alignItems={'center'}>
      <Typography minWidth={'2ch'}>{player.shirtNumber}</Typography>
      <Typography overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
        {player.fullName}
      </Typography>
      <PlayerCurrentCard variant='inline' player={player}></PlayerCurrentCard>
      <PlayerSubstitutions playerId={player.id} marginLeft='auto' />
    </Stack>
  );
};

const AwayPlayer: FC<LineupPlayer> = ({ player }) => {
  return (
    <Stack gap={2} direction='row-reverse' alignItems={'center'}>
      <Typography minWidth='2ch' textAlign='end'>
        {player.shirtNumber}
      </Typography>
      <Typography
        overflow='hidden'
        textOverflow='ellipsis'
        whiteSpace='nowrap'
        textAlign='end'
      >
        {player.fullName}
      </Typography>
      <PlayerCurrentCard variant='inline' player={player}></PlayerCurrentCard>

      <PlayerSubstitutions
        playerId={player.id}
        direction='row-reverse'
        marginRight='auto'
      />
    </Stack>
  );
};

export const LineupPlayers: FC<LineupPlayersProps> = ({
  players,
  type,
  ...boxProps
}) => {
  return (
    <Box width='100%' {...boxProps}>
      {players.map((player) =>
        type === LineupType.Home ? (
          <HomePlayer key={player.id} player={player} />
        ) : (
          <AwayPlayer key={player.id} player={player} />
        ),
      )}
    </Box>
  );
};
