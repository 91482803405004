import { Box, Typography, TypographyProps, type BoxProps } from '@mui/material';
import { FC, type ComponentProps, type ReactElement } from 'react';
import { darkGlass } from '@/utils/style/darkGlass';
import type { PlayerCard, PlayerCurrentCard } from '../PlayerCard/PlayerCard';

const activeStyle = {
  backgroundColor: 'hsla(0deg 66% 30% / 100%)',
  backdropFilter: 'none',
  transform: 'scale(1.1)',
};
const activePartialStyle = {
  backgroundColor: 'hsla(0deg 75% 15% / 75%)',
};

type CardComponent =
  | { cardComponent?: ReactElement<ComponentProps<typeof PlayerCard>> }
  | { cardComponent?: ReactElement<ComponentProps<typeof PlayerCurrentCard>> };
type LineupPlayerShirtProps = BoxProps & CardComponent;

export const LineupPlayerShirt: FC<LineupPlayerShirtProps> = ({
  sx,
  children,
  cardComponent,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        width: '2.5em',
        aspectRatio: '1',
        borderRadius: '50%',
        boxShadow: (theme) => theme.shadows[6],
        ...darkGlass,
        alignItems: 'center',
        justifyContent: 'center',
        '.active &': {
          ...activeStyle,
        },
        '.active-partial &': {
          ...activePartialStyle,
        },
        ...sx,
      }}
      {...props}
    >
      <Typography fontSize={'1em'} fontWeight={'inherit'}>
        {children}
      </Typography>
      {cardComponent}
    </Box>
  );
};

export const LineupPlayerLabel: FC<TypographyProps> = ({ sx, ...props }) => {
  return (
    <Typography
      sx={{
        position: 'absolute',
        top: '100%',
        maxWidth: '100%',
        marginTop: (theme) => theme.spacing(0.5),
        fontSize: '0.7em',
        fontWeight: 500,
        textAlign: 'center',
        lineHeight: 1.1,
        padding: (theme) => theme.spacing(0.5, 1),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        boxShadow: (theme) => theme.shadows[3],
        ...darkGlass,
        borderRadius: '999em',
        '.active &': {
          ...activeStyle,
        },
        '.active-partial &': {
          ...activePartialStyle,
        },
        ...sx,
      }}
      {...props}
    />
  );
};
