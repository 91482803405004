import { Paper, Stack } from '@mui/material';
import { COLUMNS } from './constants';

export const QAHistoryTableHeader = () => {
  return (
    <Paper
      elevation={2}
      sx={{
        top: 0,
        position: 'sticky',
        zIndex: 1,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Stack
        direction='row'
        sx={{
          textAlign: 'center',
        }}
      >
        {COLUMNS.map((column) => (
          <Stack
            key={column.id}
            width={`${column.width}%`}
            direction='row'
            alignItems='center'
            sx={{
              fontSize: '0.8rem',
              fontWeight: 700,
              lineHeight: 2,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {column.name}
          </Stack>
        ))}
      </Stack>
    </Paper>
  );
};
