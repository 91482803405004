import { FC } from 'react';
import { TeamDto } from '@contract';
import { RadioButtons } from '@/components/Form/RadioButtons';
import { useFixtureStore } from '@/stores/FixtureStore';
import { TEAM_LABEL } from '@/components/AddAction/constants';

interface TeamGroupProps {
  value: TeamDto['id'] | null;
  onChange: (team: TeamDto | null) => void;
  disabled?: boolean;
}

export const TeamGroup: FC<TeamGroupProps> = ({
  value,
  onChange,
  disabled = false,
}) => {
  const homeTeam = useFixtureStore((state) => state.homeTeam);
  const awayTeam = useFixtureStore((state) => state.awayTeam);

  if (!homeTeam || !awayTeam) return;

  const onTeamChange = (teamId: TeamDto['id']) => {
    const selectedTeam = [homeTeam, awayTeam].find(
      (team) => team.id === teamId,
    );

    if (!selectedTeam) return;

    onChange(selectedTeam);
  };

  return (
    <RadioButtons
      name='team'
      onChange={onTeamChange}
      value={value}
      controlLabel={TEAM_LABEL}
      fields={{ [homeTeam.id]: homeTeam.name, [awayTeam.id]: awayTeam.name }}
      disabled={disabled}
    />
  );
};
