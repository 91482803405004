import { FC, type MouseEvent, MutableRefObject, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { ViewportList, ViewportListRef } from 'react-viewport-list';
import {
  setAction,
  setActionViewAction,
  useActionStore,
} from '@/stores/ActionStore/ActionStore';
import { type TableAction } from '@/stores/FixtureStore';
import { getLatestActionVersion } from '@/stores/utils';
import { useActionsTable } from '@/components/ActionsTable/useActionsTable';
import { ACTION_TABLE_VARIANT } from '@/components/ActionContextMenu/ActionContextMenu';
import { ConfirmationDialog } from '@/components/Dialogs/Confirmation/ConfirmationDialog';
import { hasActionChanged } from '@/utils/actions';
import type { Action } from '@/types/action/action';
import { NO_ACTIONS_ADDED_LABEL } from '@/constants';
import { submitActionDialog } from '../Dialogs/utils';
import { QAHistoryTableRow } from './QAHistoryTableRow';
import { SAVE_ACTION_CONFIRMATION_TITLE } from './constants';

interface ActionsTableBodyProps {
  viewportRef: MutableRefObject<HTMLDivElement | null>;
  listRef: MutableRefObject<ViewportListRef | null>;
  actions: TableAction[];
}

export const QAHistoryTableBody: FC<ActionsTableBodyProps> = ({
  viewportRef,
  listRef,
  actions,
}) => {
  const actionViewAction = useActionStore((state) => state.actionViewAction);
  const action = useActionStore((state) => state.action);
  const contextAction = useActionStore((state) => state.contextAction);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { onContextMenuClick, contextMenu, getRowId } = useActionsTable(
    ACTION_TABLE_VARIANT.QA_VIEW,
  );
  const [clickedAction, setClickedAction] = useState<Action | null>(null);

  const selectedActionId = (function () {
    if (contextAction) {
      return contextAction.actionId;
    }
    if (
      action &&
      actionViewAction &&
      action.actionId === actionViewAction.actionId
    ) {
      return action.actionId;
    }
    return null;
  })();

  const onQaTableRowClick = (e: MouseEvent<HTMLDivElement>) => {
    const clickedRowActionId = getRowId(e);

    if (!clickedRowActionId) {
      return;
    }

    const clickedRowAction = getLatestActionVersion(clickedRowActionId);
    const nextClickedAction =
      clickedRowAction?.actionId !== clickedAction?.actionId
        ? clickedRowAction
        : null;
    setClickedAction(nextClickedAction);

    if (
      action &&
      actionViewAction &&
      clickedRowAction &&
      hasActionChanged(actionViewAction, action)
    ) {
      return setIsConfirmationOpen(true);
    }

    setAction(nextClickedAction);
    setActionViewAction(nextClickedAction);
  };

  const closeConfirmation = () => {
    if (!actionViewAction || !action) return;

    setAction(clickedAction);
    setActionViewAction(clickedAction);
    setIsConfirmationOpen(false);
  };

  const onConfirm = () => {
    if (!actionViewAction || !action) return;

    submitActionDialog(action);
    closeConfirmation();
  };

  if (!actions.length) {
    return (
      <Typography variant='body2' sx={{ textAlign: 'center', padding: '8px' }}>
        {NO_ACTIONS_ADDED_LABEL}
      </Typography>
    );
  }

  return (
    <Stack onClick={onQaTableRowClick} onContextMenu={onContextMenuClick}>
      <ViewportList viewportRef={viewportRef} ref={listRef} items={actions}>
        {(action) => (
          <QAHistoryTableRow
            key={action.actionId}
            action={action}
            isSelected={selectedActionId === action.actionId}
          />
        )}
      </ViewportList>
      {contextMenu}
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        title={SAVE_ACTION_CONFIRMATION_TITLE}
        onCancel={closeConfirmation}
        onConfirm={onConfirm}
      />
    </Stack>
  );
};
