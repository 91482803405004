import { v4 as uuid } from 'uuid';
import type { Player } from '@/service/lineups';
import { SubstitutionType } from '@contract';

export enum LineupType {
  Home = 'home',
  Away = 'away',
}

export const SubstitutionColors = {
  [SubstitutionType.SubOut]: 'error',
  [SubstitutionType.SubIn]: 'success',
} as const;

export const mockPlayer: Player = {
  firstName: 'Brian',
  fullName: 'Brian Smith',
  id: uuid(),
  lastName: 'Smith',
  shirtNumber: '88',
};
