import { Divider, Paper, Stack, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { StartingLineupPlayersList } from '@/components/LineupEditor/StartingLineup/StartingLineupPlayersList';
import { SubmitButton } from '@/components/LineupEditor/SubmitButton';
import { SquadInfo } from '@/components/LineupEditor/SquadInfo';
import { DropZone } from '@/components/LineupEditor/DropZone';
import { LINEUP_NAMED_POSITION } from '@/stores/LineupStore/constants';
import { AddPlayer } from '@/components/LineupEditor/AddPlayer';
import { LoadingOverlay } from '@/components/LoadingOverlay/LoadingOverlay';
import {
  StartingLineupContextProvider,
  useStartingLineupsContext,
} from '@/components/LineupEditor/StartingLineupContext';
import { useGetLineups } from '@/service/lineups';
import { composeStartingLineup } from '@/stores/LineupStore/utils';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';
import { PitchGrid } from '@/components/LineupEditor/PitchGrid';
import { ResponsivePitch } from '@/components/Pitch/ResponsivePitch';
import { StartingPlayersType } from '@/components/LineupEditor/constants';

export const ELEMENT_ID = {
  PITCH: 'lineup-editor-pitch',
  SQUAD_PLAYERS: 'lineup-editor-squad-players',
  BENCH_PLAYERS: 'lineup-editor-bench-players',
  RETIRED_PLAYERS: 'lineup-editor-retired-players',
  OFF_PLAYERS: 'lineup-editor-off-players',
};

const _LineupEditorView: FC = () => {
  const startingLineupStore = useStartingLineupsContext();
  const { data: dataLineups, isLoading: isFetchingLineups } = useGetLineups();
  const teamId = useLineupStore((state) => state.teamId);
  const isLoading = isFetchingLineups || !teamId;

  useEffect(() => {
    if (!dataLineups) return;

    startingLineupStore.setLineup(composeStartingLineup(dataLineups));
    startingLineupStore.setAddedPlayers(dataLineups.addedPlayers);
  }, [dataLineups, startingLineupStore]);

  if (isLoading) {
    return (
      <Stack flex='1'>
        <LoadingOverlay>
          {!teamId && (
            <Typography variant='caption'>Waiting for team id...</Typography>
          )}
          {!isFetchingLineups && (
            <Typography variant='caption'>
              Waiting for lineups response...
            </Typography>
          )}
        </LoadingOverlay>
      </Stack>
    );
  }

  return (
    <Stack flex={'1'} gap={1} padding={1}>
      <Stack
        direction='row'
        gap={1}
        flex={'1 1 0'}
        overflow='hidden'
        justifyContent='center'
      >
        <Stack
          id={ELEMENT_ID.PITCH}
          flex='1'
          maxWidth='840px'
          position='relative'
        >
          <ResponsivePitch>
            <PitchGrid />
          </ResponsivePitch>
        </Stack>

        <Paper
          component={Stack}
          id={ELEMENT_ID.SQUAD_PLAYERS}
          gap={1}
          elevation={2}
          p={2}
          overflow={'hidden'}
          minWidth='50ch'
        >
          <Stack
            direction='row'
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
          >
            <Typography
              fontSize={'1.2em'}
              textAlign={'center'}
              variant='overline'
            >
              Players
            </Typography>
            <AddPlayer color='primary' />
          </Stack>
          <Divider flexItem />

          <Stack overflow={'hidden'}>
            <DropZone lineupPosition={LINEUP_NAMED_POSITION.SQUAD} />
            <Stack flex='1' overflow={'auto'}>
              <StartingLineupPlayersList
                lineupPosition={LINEUP_NAMED_POSITION.SQUAD}
                playersType={StartingPlayersType.Squad}
              />
            </Stack>
          </Stack>
          <Stack marginTop='auto' gap={1}>
            <Divider flexItem />
            <SquadInfo />
            <Divider flexItem />
            <SubmitButton sx={{ alignSelf: 'flex-end' }} />
          </Stack>
        </Paper>

        <Paper
          id={ELEMENT_ID.BENCH_PLAYERS}
          component={Stack}
          gap={1}
          elevation={2}
          p={2}
          overflow={'hidden'}
          minWidth='40ch'
        >
          <Typography
            fontSize={'1.2em'}
            textAlign={'center'}
            variant='overline'
          >
            Bench
          </Typography>
          <Divider flexItem />

          <DropZone lineupPosition={LINEUP_NAMED_POSITION.BENCH} />
          <Stack flex='1' overflow={'auto'}>
            <StartingLineupPlayersList
              lineupPosition={LINEUP_NAMED_POSITION.BENCH}
              playersType={StartingPlayersType.Bench}
            />
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
};

export const LineupEditorView = () => (
  <StartingLineupContextProvider>
    <_LineupEditorView />
  </StartingLineupContextProvider>
);
