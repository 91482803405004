import { FC } from 'react';
import { Box, type BoxProps } from '@mui/material';
import { CardType } from '@contract';

type CardIndicatorProps = BoxProps & {
  variant: CardType;
};

const CARD_TEST_ID = {
  [CardType.Yellow]: 'card-yellow',
  [CardType.Red]: 'card-red',
  [CardType.SecondYellow]: 'card-second-yellow',
} as const;

const CARD_TYPE_BG = {
  [CardType.Yellow]: 'yellow',
  [CardType.Red]: 'red',
  [CardType.SecondYellow]:
    'linear-gradient(125deg, yellow 0 47%, red 53% 100%)',
} as const;

export const CardIcon: FC<CardIndicatorProps> = (props) => {
  const { variant, sx, ...boxProps } = props;
  return (
    <Box
      data-testid={CARD_TEST_ID[variant]}
      sx={{
        aspectRatio: '6 / 9', // :D
        background: CARD_TYPE_BG[variant],
        outline: '1px solid black',
        ...sx,
      }}
      {...boxProps}
    />
  );
};
