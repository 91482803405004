import { Box, Button, Stack, Typography } from '@mui/material';
import type { FC } from 'react';

interface ButtonsGroupProps {
  title: string;
  buttons: {
    onClick: () => void;
    label: string;
  }[];
}

export const ButtonsGroup: FC<ButtonsGroupProps> = ({ title, buttons }) => {
  return (
    <Stack m={0.5} fontSize='1rem'>
      <Typography variant='overline' textAlign='center' fontSize='0.85em'>
        {title}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridAutoRows: 'max-content',
          gap: 0.5,
        }}
      >
        {buttons.map(({ label, onClick }) => (
          <Button
            key={label}
            variant='outlined'
            onClick={(e) => {
              e.currentTarget.blur();
              onClick();
            }}
            sx={{
              fontSize: '1em',
            }}
          >
            {label}
          </Button>
        ))}
      </Box>
    </Stack>
  );
};
