import { useMemo } from 'react';
import type { GameStateLineupsDto } from '@contract';
import { useClockStore } from './ClockStore';
import { useFixtureStore } from './FixtureStore';
import {
  findClosest,
  makePeriodStatesTimestamps,
  selectActionVersion,
} from './utils';
import { useBackupActions } from './UnsyncedActionsStore';
import { EMPTY_LINEUP } from './LineupStore/constants';
import { useFiltersStore } from './FiltersStore/FiltersStore';
import { useLineupStore } from './LineupStore/LineupStore';
import {
  subscribeActions,
  subscribeState,
  useSocketStore,
} from './SocketStore/SocketStore';

export function useLatestFilteredTableAction() {
  const tableActions = useFiltersStore((state) => state.tableActions);
  return tableActions.at(0);
}

export function useLatestFilteredActionId() {
  const latestAction = useLatestFilteredTableAction();
  return latestAction?.actionId;
}
export function useLatestTableAction() {
  const tableActions = useFixtureStore((state) => state.tableActions);
  return tableActions.at(0);
}

export function useLatestActionId() {
  const latestAction = useLatestTableAction();
  return latestAction?.actionId;
}

export function useLatestActionIdAt(period: number, clockTimeTicks: number) {
  const actionTimestampMap = useFiltersStore(
    (state) => state.actionTimestampMap,
  );
  const periodTimestamps = actionTimestampMap[period];
  if (!periodTimestamps) return;

  const closestTime = findClosest(clockTimeTicks, periodTimestamps.timestamps);
  if (closestTime === undefined || closestTime > clockTimeTicks) return;

  const actionId = periodTimestamps.timestampAction[closestTime];
  return actionId;
}

export function useCurrentlyLatestAction() {
  const currentPeriod = useFixtureStore((state) => state.currentPeriod);
  const currentClockTime = useClockStore((state) => state.clockTimeTicks);
  const latestFilteredActionId = useLatestActionIdAt(
    currentPeriod.sequence,
    currentClockTime,
  );
  return useLatestActionVersion(latestFilteredActionId);
}

export function useLatestActionVersion(actionId: string | undefined) {
  const backups = useBackupActions();
  const backup = actionId ? backups[actionId] : null;
  const actions = useFixtureStore((state) => state.actions);
  const actionHistory = actionId ? actions.get(actionId) : undefined;
  return selectActionVersion(backup, actionHistory);
}
/**
 * A hook for components with independent game state control
 * such as edit dialogs. Changing period or time there should use
 * corresponding game state.
 */

export function useGameStateAt(periodNumber: number, clockTimeTicks: number) {
  const collectionState = useFixtureStore((state) => state.collectionState);
  /**
   * Fallback. It's a get so the hook does not cause reflows.
   */
  const currentGameState = useFixtureStore.getState().gameState;
  if (!collectionState) return currentGameState;
  const period = collectionState.periods.find(
    (period) => period.sequence === periodNumber,
  );
  if (!period) return currentGameState;
  const timestamps = makePeriodStatesTimestamps(period);
  const timestamp = findClosest(clockTimeTicks, timestamps);
  if (timestamp === undefined) return currentGameState;
  return period.gameStates[timestamp];
}
/**
 * A hook which returns current lineup for given team.
 * Current lineup is a lineup in current game state.
 * This is prepared for QA view, where LineupStore is not used.
 * For collector view to display current lineup use LineupStore.
 */

export function useTeamCurrentLineups(teamId: string): GameStateLineupsDto {
  const gameState = useFixtureStore((state) => state.gameState);
  return gameState.lineups[teamId] || EMPTY_LINEUP;
}

/**
 * A hook which returns lineup for given team
 * at given time in given period.
 * Provided for edit dialogs where lineups and state
 * is independent from game clock.
 */
export function useTeamLineupsAt(
  periodNumber: number,
  clockTimeTicks: number,
  teamId: string,
): GameStateLineupsDto {
  const gameState = useGameStateAt(periodNumber, clockTimeTicks);
  return gameState.lineups[teamId] ?? EMPTY_LINEUP;
}

export function useCurrentPeriodLabel() {
  const currentPeriod = useFixtureStore((state) => state.currentPeriodConfig);

  return currentPeriod.label;
}

export function usePeriodLabel(sequence: number) {
  const periodsConfig = useFixtureStore((state) => state.periodsConfig);
  const period = periodsConfig.find((period) => period.seq === sequence);

  return period ? period.label : '';
}

export function useRetrySubsribeActions() {
  const dataCollectionId = useFixtureStore((state) => state.dataCollectionId);
  const teamId = useLineupStore((state) => state.teamId);
  const actionsSubscribed = useSocketStore((state) => state.actionsSubscribed);
  const actionsError = useSocketStore((state) => state.actionsError);
  return useMemo(() => {
    if (!actionsError || actionsSubscribed || !dataCollectionId) {
      return undefined;
    }

    return subscribeActions.bind(null, { dataCollectionId, teamId });
  }, [actionsError, actionsSubscribed, dataCollectionId, teamId]);
}

export function useRetrySubsribeState() {
  const dataCollectionId = useFixtureStore((state) => state.dataCollectionId);
  const stateSubscribed = useSocketStore((state) => state.stateSubscribed);
  const stateError = useSocketStore((state) => state.stateError);
  return useMemo(() => {
    if (!stateError || stateSubscribed || !dataCollectionId) {
      return undefined;
    }

    return subscribeState.bind(null, { dataCollectionId });
  }, [dataCollectionId, stateError, stateSubscribed]);
}
