import type { TableAction } from '@/stores/FixtureStore';
import type { TimelinePeriod } from './TimeLine';

export const getPeriodFromTime = (periods: TimelinePeriod[], time: number) => {
  return periods.length === 1
    ? periods[0]
    : periods.find((p) => {
        if (p.startTime > time) {
          return false;
        }

        if (p.endTime !== null && p.endTime >= time) {
          return true;
        }

        if (p.endTime === null) {
          return true;
        }

        return false;
      });
};

export const getPreviousAction = (
  timeWithOffset: number,
  tableActions: TableAction[],
  period: TimelinePeriod,
) => {
  if (!period) return null;

  const time = timeWithOffset - period.offset;
  const previousAction = tableActions.find((action) => {
    if (
      period.sequence === action.period.sequence &&
      time > action.clockTimeTicks
    ) {
      return true;
    }

    if (period.sequence > action.period.sequence) {
      return true;
    }

    return false;
  });

  return previousAction || null;
};

export const getNextAction = (
  timeWithOffset: number,
  tableActions: TableAction[],
  period: TimelinePeriod,
) => {
  if (!period) return null;

  const time = timeWithOffset - period.offset;
  const nextAction = [...tableActions].reverse().find((action) => {
    if (
      period.sequence === action.period.sequence &&
      time < action.clockTimeTicks
    ) {
      return true;
    }

    if (period.sequence < action.period.sequence) {
      return true;
    }

    return false;
  });

  return nextAction || null;
};
