import { useRef, type FC } from 'react';
import { ViewportListRef } from 'react-viewport-list';
import { Stack, TableContainer } from '@mui/material';
import { QAHistoryTableHeader } from '@/components/QAHistoryTable/QAHistoryTableHeader';
import type { TableAction } from '@/stores/FixtureStore';
import { QAHistoryTableBody } from './QAHistoryTableBody';

interface QAHistoryTableProps {
  actions: TableAction[];
}

export const QAHistoryTable: FC<QAHistoryTableProps> = ({ actions }) => {
  const viewportRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<ViewportListRef | null>(null);

  return (
    <TableContainer id='actions-table' ref={viewportRef}>
      <Stack sx={{ minWidth: 900 }}>
        <QAHistoryTableHeader />
        <QAHistoryTableBody
          actions={actions}
          viewportRef={viewportRef}
          listRef={listRef}
        />
      </Stack>
    </TableContainer>
  );
};
