import FlagIcon from '@mui/icons-material/Flag';
import { ActionWarning as ActionWarningType } from '@contract';
import { hasFlagToQa } from '@/utils/actions';
import { TableAction } from '@/stores/FixtureStore';
import type { Player } from '@/service/lineups';
import { PlayerName } from '../PlayerName/PlayerName';
import { ActionWarning } from '../ActionWarnings/ActionWarning';
import { PeriodNumber } from '../PeriodNumber/PeriodNumber';
import { TABLE_ACTION_STATE_ICON } from './constants';

export const getStateCell = (action: TableAction) => {
  return TABLE_ACTION_STATE_ICON[action.state];
};

export const getPlayerCell = (player?: Player) => {
  if (!player) return;
  return <PlayerName player={player} />;
};

export const getWarningCell = (warnings: ActionWarningType[] | null) => {
  if (warnings && warnings.length) {
    return <ActionWarning warnings={warnings} />;
  }
  return <span data-testid='warnings-none' />;
};

export const getFlagCell = (action: TableAction) => {
  if (hasFlagToQa(action)) return <FlagIcon data-testid='flag' color='error' />;
  return <span data-testid='flags-none' />;
};

export const getPeriodCell = (action: TableAction) => {
  return <PeriodNumber action={action} />;
};
