import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { TableAction } from '@/stores/FixtureStore';
import { useIsDebugEnabled } from '@/utils/debug';
import {
  useRecentAddedActionId,
  useRecentUpdatedActionId,
} from '@/stores/UnsyncedActionsStore';
import { COLUMNS, TABLE_CLASS_NAMES } from './constants';
import { ActionTableCell } from './ActionsTableCell';

interface ActionsTableRowProps {
  action: TableAction;
}

export const ActionsTableRow: FC<ActionsTableRowProps> = ({ action }) => {
  const [isDebugEnabled] = useIsDebugEnabled();
  const recentAdded = useRecentAddedActionId();
  const recentUpdated = useRecentUpdatedActionId();

  const isRecentAdded = recentAdded === action.actionId;
  const isRecentUpdated = recentUpdated === action.actionId;

  let trackingString = '';
  if (isRecentAdded) trackingString += 'A';
  if (isRecentUpdated) trackingString += 'U';

  return (
    <Stack
      className={`${TABLE_CLASS_NAMES.ROW}`}
      direction='row'
      data-action-state={action.state}
      data-action-id={action.actionId}
      sx={{
        position: 'relative',
        backgroundColor: () => (action.isRemoved ? '#262626' : ''),
        color: () => (action.isRemoved ? '#979797' : ''),
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: (theme) => theme.palette.action.hover,
        },
      }}
    >
      {COLUMNS.map((column) => (
        <ActionTableCell key={column.id} action={action} column={column} />
      ))}
      {isDebugEnabled && (
        <Stack sx={{ position: 'absolute', right: 0, top: 0, bottom: 0 }}>
          <Typography variant='caption'>{trackingString}</Typography>
        </Stack>
      )}
    </Stack>
  );
};
