import type { Player } from '@/service/lineups';
import {
  Position,
  type GameStateLineupsDto,
  type PlayerDto,
  type PlayerPositionDto,
} from '@contract';

export const PITCH_PLAYERS_MAX_LEN = 11;

export const LINEUP_NAMED_POSITION = {
  BENCH: 'bench',
  OFF: 'off',
  SQUAD: 'squad',
  RETIRED: 'retired',
} as const;
export type LineupNamedPosition = ConstType<typeof LINEUP_NAMED_POSITION>;
export type LineupPosition = Position | LineupNamedPosition;

export type PlayerPosition = {
  player: PlayerDto;
  position: LineupPosition;
};

export type PitchPositionMap = Map<Position, Player | null>;

export type LineupPositionEntry = [LineupPosition, Player | null];

/**
 * Used only inside Context for StartingLineup page.
 */
export type StartingLineupStore = {
  teamId?: string;
  teamName?: string;
  /**
   * New players added to Collection
   */
  addedPlayers: Player[];
  /**
   * Players not in the game.
   */
  offPlayers: Player[];
  squadPlayers: Player[];
  /**
   * Players on pitch
   */
  pitchPlayers: PlayerPositionDto[];
  /**
   * A map of all pitch positions and player assignments.
   * Required to render lineup editor grid.
   */
  pitchPlayersMap: PitchPositionMap;
  /**
   * Substitute players.
   */
  benchPlayers: Player[];
  /**
   * Whole lineup list. Used to make management easier.
   * Specific players arrays are produced based on changes to
   * this list.
   */
  lineup: PlayerPosition[];
  /**
   * Used for lineup editor. Clicking on player or position
   * sets selected entry. When that happens, previous entry is
   * analyzed and corresponding switch is made.
   */
  selectedEntry: LineupPositionEntry | null;
  pitchPlayersLength: number;
  formation: string;
  isGKSelected: boolean;
};

/**
 * This store is global but its used for collector view.
 * It holds current lineup info, based on current period and time.
 * It is also responsible for building hotkey shirt mapping
 * for player quick input during the game.
 */
export type LineupStore = {
  teamId?: string;
  teamName?: string;
  /**
   * Players on pitch
   */
  pitchPlayers: PlayerPositionDto[];
  /**
   * A map of all pitch positions and player assignments.
   * Required to render lineup editor grid.
   */
  // pitchPlayersMap: PitchPositionMap;
  /**
   * Substitute players.
   */
  benchPlayers: Player[];
  /**
   * Players in the game but currently off the pitch.
   */
  offPlayers: Player[];

  retiredPlayers: Player[];
  /**
   * Whole lineup list. Used to make management easier.
   * Specific players arrays are produced based on changes to
   * this list.
   */
  lineup: PlayerPosition[];
};

export const EMPTY_LINEUP: GameStateLineupsDto = {
  benchPlayers: [],
  offPlayers: [],
  pitchPlayers: [],
  retiredPlayers: [],
};

export const POSITION_NAME = {
  [Position.Gk]: 'GK',
  [Position.Lb]: 'LB',
  [Position.Lcb]: 'LCB',
  [Position.Cb]: 'CB',
  [Position.Rcb]: 'RCB',
  [Position.Rb]: 'RB',
  [Position.Lwb]: 'LWB',
  [Position.Ldm]: 'LDM',
  [Position.Cdm]: 'CDM',
  [Position.Rdm]: 'RDM',
  [Position.Rwb]: 'RWB',
  [Position.Lm]: 'LM',
  [Position.Lcm]: 'LCM',
  [Position.Cm]: 'CM',
  [Position.Rcm]: 'RCM',
  [Position.Rm]: 'RM',
  [Position.Lw]: 'LW',
  [Position.Lam]: 'LAM',
  [Position.Cam]: 'CAM',
  [Position.Ram]: 'RAM',
  [Position.Rw]: 'RW',
  [Position.Ss]: 'SS',
  [Position.Lcf]: 'LCF',
  [Position.St]: 'ST',
  [Position.Rcf]: 'RCF',
} as const;

export const FORMATION_MAP: Array<Position[]> = [
  [Position.Lb, Position.Lcb, Position.Cb, Position.Rcb, Position.Rb],
  [Position.Lwb, Position.Ldm, Position.Cdm, Position.Rdm, Position.Rwb],
  [Position.Lm, Position.Lcm, Position.Cm, Position.Rcm, Position.Rm],
  [Position.Lw, Position.Lam, Position.Cam, Position.Ram, Position.Rw],
  [Position.Ss],
  [Position.Lcf, Position.St, Position.Rcf],
];

export const UNKNOWN_TEAM_LABEL = 'Team unknown';
export const UNKNOWN_PLAYER_LABEL = 'Unknown';
export const UNKNOWN_PLAYER_ID = '00000000-0000-0000-0000-000000000000';

export const UNKNOWN_PLAYER: PlayerDto = {
  id: UNKNOWN_PLAYER_ID,
  firstName: '',
  lastName: UNKNOWN_PLAYER_LABEL,
  fullName: '',
  shirtNumber: '0',
};
