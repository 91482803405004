import { List, type ListProps } from '@mui/material';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';
import { LivePlayersType } from '../constants';
import { LiveLineupPlayerListItem } from './LiveLineupPlayerListItem';

type LiveLineupPlayersListProps = ListProps & {
  playersType: LivePlayersType;
};

export function LiveLineupPlayersList({
  playersType,
  ...listProps
}: LiveLineupPlayersListProps) {
  const playersList = useLineupStore((state) => state[playersType]);

  return (
    <List {...listProps}>
      {playersList.map((player) => (
        <LiveLineupPlayerListItem key={player.id} player={player} />
      ))}
    </List>
  );
}
