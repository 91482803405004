import { Button, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import {
  closeDrawer,
  setFilterValues,
  useFiltersStore,
} from '@/stores/FiltersStore/FiltersStore';
import { useSimpleReducer } from '@/utils/useSimpleReducer';
import {
  DEFAULT_FILTER_VALUE,
  type FilterValue,
} from '@/stores/FiltersStore/constants';
import { CANCEL_BUTTON_LABEL, SAVE_BUTTON_LABEL } from '@/constants';
import { FILTERS_LABEL, FiltersDrawerVariant } from './constants';
import { QAForm } from './Forms/QAForm';
import { CollectorForm } from './Forms/CollectorForm';

interface FiltersDrawerContentProps {
  variant: FiltersDrawerVariant;
}

export const FiltersDrawerContent: FC<FiltersDrawerContentProps> = ({
  variant,
}) => {
  const filterValues = useFiltersStore((state) => state.filterValues);

  const [selectedFiltersValues, setSelectedFiltersValues] =
    useSimpleReducer<FilterValue>(filterValues);

  const handleSubmit = () => {
    setFilterValues(selectedFiltersValues);
    closeDrawer();
  };

  const clearFilters = () => {
    setSelectedFiltersValues(DEFAULT_FILTER_VALUE);
  };

  const resetFilters = () => {
    setSelectedFiltersValues(filterValues);
  };

  return (
    <Stack gap={2}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h6'>{FILTERS_LABEL}</Typography>
        <Stack direction='row' gap={2}>
          <Button onClick={resetFilters} variant='outlined' color='primary'>
            Restore
          </Button>
          <Button onClick={clearFilters} variant='outlined' color='primary'>
            Clear all
          </Button>
        </Stack>
      </Stack>

      {variant === FiltersDrawerVariant.QA && (
        <QAForm
          filterValue={selectedFiltersValues}
          onChange={setSelectedFiltersValues}
        />
      )}

      {variant === FiltersDrawerVariant.COLLECTOR && (
        <CollectorForm
          filterValue={selectedFiltersValues}
          onChange={setSelectedFiltersValues}
        />
      )}

      <Stack direction='row' justifyContent='flex-end' gap={2}>
        <Button onClick={closeDrawer} variant='outlined' color='secondary'>
          {CANCEL_BUTTON_LABEL}
        </Button>
        <Button onClick={handleSubmit} variant='contained'>
          {SAVE_BUTTON_LABEL}
        </Button>
      </Stack>
    </Stack>
  );
};
