import {
  ACTION_TYPE_ID,
  AttemptType,
  BodyPart,
  ErrorReason,
  GameStateLineupsDto,
  GoalType,
  METADATA_KEY,
  Position,
} from '@contract';
import { postAction } from '@/service/helpers/postAction';
import {
  type Action,
  AerialAction,
  BadTouchAction,
  BallRecoveryAction,
  BallTouchAction,
  ClearanceAction,
  CornerAwardedAction,
  CrossAction,
  DispossessedAction,
  ErrorAction,
  GoalkeeperCatchAction,
  GoalkeeperCollectionAction,
  GoalkeeperDropAction,
  GoalkeeperKickFromHandsAction,
  GoalkeeperPunchAction,
  GoalkeeperThrowAction,
  GoalKickAwardedAction,
  InterceptionAction,
  LaunchAction,
  OffsideAction,
  OffsideForAction,
  PassAction,
  PlayerBeatenAction,
  PlayerLeftAction,
  PlayerReturnedAction,
  PlayResumedAction,
  type RefereeDropBallAction,
  type ShotAction,
  TackleAction,
  TakeOnAction,
  ThrowInAwardedAction,
  ThrowInTakenAction,
} from '@/types/action/action';
import {
  getActionMetadata,
  hasActionChanged,
  isActionOutcomeEditable,
} from '@/utils/actions';
import {
  composeLineup,
  decomposeLineup,
  findPlayerById,
  getSwappedPositionLineup,
} from '@/stores/LineupStore/utils';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';
import { CoordsCalc } from '@/utils/coordsCalc';
import { isActionFieldsFulfilled } from '@/utils/isActionFieldsFulfilled';
import { isNewAction } from '@/stores/utils';
import { DIRECTION_OF_PLAY } from '@/components/Periods/constants';
import { PENALTY_COORDS } from '@/components/Dialogs/FreeKickDialogContent/constants';
import { DEFAULT_GAME_STATE, PRE_GAME_PERIOD } from '@/stores/constants';
import {
  getGameStateAt,
  setCollectionState,
  useFixtureStore,
} from '../FixtureStore';
import { getRecentAddedAction } from '../UnsyncedActionsStore';
import { createAction, createActionBase, modifyActionMetadata } from './utils';
import { MODE } from './constants';
import {
  ACTIONS_DEFAULT_STATE,
  setCoords,
  setPlayer,
  setPlayerNumber,
  setRecentlyAwardedAction,
  updatePreviousActionIsAssist,
  useActionStore,
  type ActionStore,
} from './ActionStore';

type CommitBaseAction<T extends Action> = {
  requiredStateFields: Array<keyof ActionStore>;
  actionTypeId: T['actionTypeId'];
  metadataKey: T['actionTypeMetadata'];
  metadata?: ActionMeta<T>;
  createMetadata?(state: ActionStore): ActionMeta<T>;
};

const AT = ACTION_TYPE_ID;
const ACTION_COMMIT_FN: Partial<Record<ACTION_TYPE_ID, VoidFunction>> =
  Object.freeze({
    [AT.Aerial]: commitAerial,
    [AT.BadTouch]: commitBadTouch,
    [AT.BallRecovery]: commitBallRecovery,
    [AT.BallTouch]: commitBallTouch,
    [AT.Error]: commitError,
    [AT.YellowCard]: commitYellowCard,
    [AT.RedCard]: commitRedCard,
    [AT.Clearance]: commitClearance,
    [AT.CornerAwarded]: commitCornerAwarded,
    [AT.Cross]: commitPass,
    [AT.Dispossessed]: commitDispossessed,
    [AT.GoalkeeperCatch]: commitGoalkeeperCatch,
    [AT.GoalkeeperCollection]: commitGoalkeeperCollection,
    [AT.GoalkeeperDrop]: commitGoalkeeperDrop,
    [AT.GoalkeeperPenaltyFaced]: commitGoalkeeperPenaltyFaced,
    [AT.GoalkeeperPunch]: commitGoalkeeperPunch,
    [AT.GoalkeeperSave]: commitGoalkeeperSave,
    [AT.GoalkeeperThrow]: commitPass,
    [AT.GoalKickAwarded]: commitGoalKickAwarded,
    [AT.Interception]: commitInterception,
    [AT.Launch]: commitPass,
    [AT.Offside]: commitOffside,
    [AT.OffsideFor]: commitOffsideFor,
    [AT.Pass]: commitPass,
    [AT.PlayerLeft]: commitPlayerLeft,
    [AT.PlayerReturned]: commitPlayerReturned,
    [AT.PlayResumed]: commitPlayResumed,
    [AT.Tackle]: commitTackle,
    [AT.ThrowInAwarded]: commitThrowInAwarded,
    [AT.ThrowInTaken]: commitThrowInTaken,
    [AT.Shot]: commitShot,
    [AT.Goal]: commitGoal,
    [AT.Substitution]: commitSubstitution,
    [AT.FreeKickAwarded]: commitFreeKickAwarded,
    [AT.PlayerBeaten]: commitPlayerBeaten,
    [AT.TakeOn]: commitTakeOn,
    [AT.RefereeDropBall]: commitRefereeDropBall,
    [AT.GoalkeeperKickFromHands]: commitPass,
  });

export function commitAction(actionTypeId: ACTION_TYPE_ID) {
  const commit = ACTION_COMMIT_FN[actionTypeId];
  if (commit) {
    commit();
  }
}

function commitBasicAction<T extends Action>({
  actionTypeId,
  metadata,
  createMetadata,
  metadataKey,
  requiredStateFields,
}: CommitBaseAction<T>) {
  return useActionStore.setState((state) => {
    if (requiredStateFields.some((field) => !state[field])) return state;

    const createdAction = createAction<T>(
      actionTypeId,
      metadataKey,
      metadata ||
        (createMetadata && createMetadata(state)) ||
        ({} as ActionMeta<T>),
    );
    if (!createdAction) return state;

    postAction(createdAction);

    return ACTIONS_DEFAULT_STATE;
  });
}

export function commitYellowCard() {
  return useActionStore.setState((state) => {
    if (!state.action) return state;

    const { action } = state;

    if (!action || action.actionTypeId !== ACTION_TYPE_ID.YellowCard)
      return state;

    postAction(action);

    return ACTIONS_DEFAULT_STATE;
  });
}

export function commitRedCard() {
  return useActionStore.setState((state) => {
    const { action } = state;

    if (
      !action ||
      action.actionTypeId !== ACTION_TYPE_ID.RedCard ||
      !action.player
    )
      return state;

    postAction(action);

    return ACTIONS_DEFAULT_STATE;
  });
}

function commitPlayerLeft() {
  return commitBasicAction<PlayerLeftAction>({
    requiredStateFields: ['player'],
    actionTypeId: ACTION_TYPE_ID.PlayerLeft,
    metadataKey: METADATA_KEY.playerLeft,
    metadata: {},
  });
}

function commitPlayerReturned() {
  return commitBasicAction<PlayerReturnedAction>({
    requiredStateFields: ['player'],
    actionTypeId: ACTION_TYPE_ID.PlayerReturned,
    metadataKey: METADATA_KEY.playerReturned,
    metadata: {},
  });
}

function commitError() {
  return commitBasicAction<ErrorAction>({
    requiredStateFields: ['player'],
    actionTypeId: ACTION_TYPE_ID.Error,
    metadataKey: METADATA_KEY.error,
    metadata: {
      reason: ErrorReason.LeadingToShot,
    },
  });
}

function commitClearance() {
  return commitBasicAction<ClearanceAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.Clearance,
    metadataKey: METADATA_KEY.clearance,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
      bodyPart: null,
      isAssist: false,
    }),
  });
}

function commitGoalkeeperCatch() {
  return commitBasicAction<GoalkeeperCatchAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.GoalkeeperCatch,
    metadataKey: METADATA_KEY.goalkeeperCatch,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
    }),
  });
}

function commitGoalkeeperCollection() {
  return commitBasicAction<GoalkeeperCollectionAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.GoalkeeperCollection,
    metadataKey: METADATA_KEY.goalkeeperCollection,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
    }),
  });
}

function commitGoalkeeperDrop() {
  return commitBasicAction<GoalkeeperDropAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.GoalkeeperDrop,
    metadataKey: METADATA_KEY.goalkeeperDrop,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
    }),
  });
}

function commitGoalkeeperPunch() {
  return commitBasicAction<GoalkeeperPunchAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.GoalkeeperPunch,
    metadataKey: METADATA_KEY.goalkeeperPunch,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
      isAssist: false,
    }),
  });
}

function commitInterception() {
  return commitBasicAction<InterceptionAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.Interception,
    metadataKey: METADATA_KEY.interception,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
      bodyPart: null,
      isAssist: false,
    }),
  });
}

function commitOffside() {
  return commitBasicAction<OffsideAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.Offside,
    metadataKey: METADATA_KEY.offside,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
    }),
  });
}

function commitAerial() {
  return commitBasicAction<AerialAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.Aerial,
    metadataKey: METADATA_KEY.aerial,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
    }),
  });
}

function commitDispossessed() {
  return commitBasicAction<DispossessedAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.Dispossessed,
    metadataKey: METADATA_KEY.dispossessed,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
    }),
  });
}

function commitBallTouch() {
  return commitBasicAction<BallTouchAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.BallTouch,
    metadataKey: METADATA_KEY.ballTouch,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
      isAssist: false,
    }),
  });
}

export function commitGoalkeeperSave() {
  const { pitchPlayers } = useLineupStore.getState();

  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const playerPosition = pitchPlayers.find(
      ({ position }) => position === Position.Gk,
    );
    const goalkeeperPlayer = findPlayerById(
      playerPosition ? playerPosition.player.id : '',
    );

    if (!state.action) return state;

    const { action } = state;

    if (!isActionFieldsFulfilled(action)) return state;

    const initialAction = state.dialogAction || state.actionViewAction;

    if (
      initialAction &&
      !isNewAction(action) &&
      !hasActionChanged(initialAction, action)
    ) {
      return state;
    }

    postAction({ ...action, player: goalkeeperPlayer });

    return {
      ...ACTIONS_DEFAULT_STATE,
      mode: MODE.PITCH,
    };
  });
}

function commitBallRecovery() {
  return commitBasicAction<BallRecoveryAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.BallRecovery,
    metadataKey: METADATA_KEY.ballRecovery,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
    }),
  });
}

export function commitGoalkeeperPenaltyFaced() {
  return useActionStore.setState((state) => {
    if (!state.action) return state;

    postAction(state.action);

    return ACTIONS_DEFAULT_STATE;
  });
}

function commitOffsideFor() {
  return commitBasicAction<OffsideForAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.OffsideFor,
    metadataKey: METADATA_KEY.offsideFor,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
    }),
  });
}

export function toggleActionOutcome() {
  return useActionStore.setState(
    (state) => {
      if (state.action || state.dialogAction || state.contextAction) {
        return state;
      }
      const action = getRecentAddedAction();
      if (!action) {
        return state;
      }
      if (!isActionOutcomeEditable(action.actionTypeId)) {
        return state;
      }

      const updatedAction = {
        ...action,
        isSuccessful: !action.isSuccessful,
      };

      postAction(updatedAction);
      setRecentlyAwardedAction(null);

      return ACTIONS_DEFAULT_STATE;
    },
    false,
    'toggleActionOutcome',
  );
}

export function tryToggleHeaded() {
  return useActionStore.setState(
    (state) => {
      if (state.action || state.dialogAction || state.contextAction) {
        return state;
      }
      const action = getRecentAddedAction();
      if (!action) return state;
      const actionType = action.actionTypeId;
      if (
        actionType !== ACTION_TYPE_ID.Pass &&
        actionType !== ACTION_TYPE_ID.Clearance &&
        actionType !== ACTION_TYPE_ID.Interception
      ) {
        return state;
      }
      const bodyPart = getActionMetadata(action).bodyPart;
      const newBodyPart = bodyPart === null ? BodyPart.Head : null;
      const newMeta = {
        bodyPart: newBodyPart,
      };
      const actionUpdate = modifyActionMetadata(action, newMeta);
      postAction(actionUpdate);
      return ACTIONS_DEFAULT_STATE;
    },
    false,
    'tryToggleHeaded',
  );
}

export function commitShot() {
  return useActionStore.setState((state) => {
    if (!state.action) return state;

    const { action } = state;
    if (
      !isActionFieldsFulfilled(action) ||
      action.actionTypeId !== ACTION_TYPE_ID.Shot
    ) {
      return state;
    }

    const initialAction = state.dialogAction || state.actionViewAction;

    if (
      initialAction &&
      !isNewAction(action) &&
      !hasActionChanged(initialAction, action)
    ) {
      return state;
    }

    postAction(action);
    updatePreviousActionIsAssist(action);
    if (!action.messageId) {
      setRecentlyAwardedAction(null);
    }

    return {
      ...ACTIONS_DEFAULT_STATE,
      mode: MODE.PITCH,
    };
  });
}

export function commitGoal() {
  return useActionStore.setState((state) => {
    if (!state.action) return state;

    const { action: goalAction } = state;

    if (goalAction.actionTypeId !== ACTION_TYPE_ID.Goal) return state;

    const isNewGoal = isNewAction(goalAction);
    const isGoal = goalAction.metadata.goal.goalType === GoalType.Goal;

    if (isNewGoal && isGoal) {
      const defaultShotAction = createActionBase<ShotAction>(
        ACTION_TYPE_ID.Shot,
        METADATA_KEY.shot,
      );
      if (!defaultShotAction) return state;
      const goalMeta = getActionMetadata(goalAction);

      const shotAction: ShotAction = {
        ...defaultShotAction,
        parentActionId: goalAction.parentActionId,
        clockTime: goalAction.clockTime,
        clockTimeTicks: goalAction.clockTimeTicks,
        player: goalAction.player,
        createdAt: goalAction.createdAt - 1,
        metadata: {
          shot: {
            attemptType: AttemptType.OnTarget,
            assistPlayer: goalMeta.assistPlayer,
            zoneNumber: goalMeta.zoneNumber,
            blockersPositions: goalMeta.blockersPositions,
            bodyPart: goalMeta.bodyPart,
            extras: goalMeta.extras,
            patternOfPlay: goalMeta.patternOfPlay,
            position: goalMeta.position,
            isAssist: false,
            shotOffTargetType: null,
          },
        },
      };
      goalAction.parentActionId = shotAction.actionId;
      postAction(shotAction);
      updatePreviousActionIsAssist(shotAction);
    }

    setCoords(goalAction.metadata.goal.position);
    postAction(goalAction);
    if (isNewGoal) {
      setRecentlyAwardedAction(null);
    }

    return {
      ...ACTIONS_DEFAULT_STATE,
      mode: MODE.PITCH,
    };
  });
}

export function commitSubstitution() {
  const { dataCollectionId, collectionState } = useFixtureStore.getState();
  return useActionStore.setState((state) => {
    const { action } = state;

    if (!action || action.actionTypeId !== ACTION_TYPE_ID.Substitution)
      return state;

    if (
      !collectionState ||
      !dataCollectionId ||
      !action.team ||
      !action.player ||
      !action.metadata.substitution.targetPlayer
    ) {
      return state;
    }

    const teamId = action.team.id;
    const periodNumber = action.period.sequence;
    const clockTimeTicks = action.clockTimeTicks;
    const playerId = action.player.id;
    const targetPlayerId = action.metadata.substitution.targetPlayer.id;

    const newCollectionState = structuredClone(collectionState);

    const periodIdx = newCollectionState.periods.findIndex(
      (period) => period.sequence === periodNumber,
    );

    const currentGameStateLineup = composeLineup(
      getGameStateAt(periodNumber, clockTimeTicks).lineups[teamId],
    );

    const playerOff = currentGameStateLineup.find(
      ({ player }) => player.id === playerId,
    );

    const playerOn = currentGameStateLineup.find(
      ({ player }) => player.id === targetPlayerId,
    );

    if (!playerOff || !playerOn) return state;

    const swappedLineup = getSwappedPositionLineup(
      currentGameStateLineup,
      [playerOn.position, playerOn.player],
      [playerOff.position, playerOff.player],
    );

    const decomposedSwappedLineup = decomposeLineup(
      swappedLineup,
    ) as GameStateLineupsDto;

    const updatedPeriod = {
      ...(newCollectionState.periods[periodIdx] || PRE_GAME_PERIOD),
      gameStates: {
        ...newCollectionState.periods[periodIdx]?.gameStates,
        [clockTimeTicks]: {
          ...(newCollectionState.periods[periodIdx]?.gameStates[
            clockTimeTicks
          ] || DEFAULT_GAME_STATE),
          lineups: {
            ...newCollectionState.periods[periodIdx]?.gameStates[clockTimeTicks]
              ?.lineups,
            [teamId]: decomposedSwappedLineup,
          },
        },
      },
    };

    newCollectionState.periods =
      periodIdx >= 0
        ? newCollectionState.periods.map((period, idx) =>
            idx === periodIdx ? updatedPeriod : period,
          )
        : [...newCollectionState.periods, updatedPeriod];

    setCollectionState(newCollectionState);
    postAction(action);
    setPlayerNumber(null);
    setPlayer(null);

    return {
      ...ACTIONS_DEFAULT_STATE,
      mode: MODE.PITCH,
    };
  });
}

export function commitFreeKickAwarded() {
  const { directionOfPlay } = useFixtureStore.getState();

  return useActionStore.setState((state) => {
    const { action } = state;

    if (!action || action.actionTypeId !== ACTION_TYPE_ID.FreeKickAwarded)
      return state;

    const metadata = getActionMetadata(action);

    if (action.isSuccessful) {
      if (metadata.isPenalty) {
        setCoords(
          directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
            ? PENALTY_COORDS.LEFT
            : PENALTY_COORDS.RIGHT,
        );
      }

      setRecentlyAwardedAction(action);
    }

    postAction(action);

    return {
      ...ACTIONS_DEFAULT_STATE,
      mode: MODE.PITCH,
    };
  });
}

function commitPass() {
  return useActionStore.setState((state) => {
    if (!state.playerNumber || !state.coords || !state.targetCoords) {
      return state;
    }
    if (!state.player) {
      return state;
    }

    const action = state.action;
    const actionId = action && action.actionId;

    if (!action || !actionId) {
      return state;
    }

    if (
      action.actionTypeMetadata !== METADATA_KEY.pass &&
      action.actionTypeMetadata !== METADATA_KEY.cross &&
      action.actionTypeMetadata !== METADATA_KEY.launch &&
      action.actionTypeMetadata !== METADATA_KEY.goalkeeperThrow &&
      action.actionTypeMetadata !== METADATA_KEY.goalkeeperKickFromHands
    ) {
      return state;
    }

    const updatedAction:
      | PassAction
      | CrossAction
      | LaunchAction
      | GoalkeeperThrowAction
      | GoalkeeperKickFromHandsAction = modifyActionMetadata(action, {
      targetPosition: {
        x: state.targetCoords.x,
        y: state.targetCoords.y,
      },
      bodyPart: null,
    });

    postAction(updatedAction);
    setRecentlyAwardedAction(null);

    return {
      ...ACTIONS_DEFAULT_STATE,
      coords: state.targetCoords,
      dotCoords: state.dotTargetCoords,
      targetCoords: null,
      dotTargetCoords: null,
      playerNumber: null,
      player: null,
      mode: MODE.PITCH,
    };
  });
}

function commitThrowInAwarded() {
  return useActionStore.setState((state) => {
    if (!state.coords) return state;

    const action = createAction<ThrowInAwardedAction>(
      ACTION_TYPE_ID.ThrowInAwarded,
      METADATA_KEY.throwInAwarded,
      {
        position: state.coords,
      },
    );

    if (!action) return state;

    postAction(action);
    setRecentlyAwardedAction(action);
    return ACTIONS_DEFAULT_STATE;
  });
}

function commitThrowInTaken() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords || !state.targetCoords) return state;

    const action = createAction<ThrowInTakenAction>(
      ACTION_TYPE_ID.ThrowInTaken,
      METADATA_KEY.throwInTaken,
      {
        position: state.coords,
        targetPosition: state.targetCoords,
        direction: null,
        isAssist: false,
      },
    );

    if (!action) return state;

    postAction(action);
    setRecentlyAwardedAction(null);

    return {
      ...ACTIONS_DEFAULT_STATE,
      coords: state.targetCoords,
      dotCoords: state.dotTargetCoords,
      targetCoords: null,
      dotTargetCoords: null,
      playerNumber: null,
      player: null,
      mode: MODE.PITCH,
    };
  });
}

function commitCornerAwarded() {
  useActionStore.setState((state) => {
    if (!state.coords) return state;

    const action = createAction<CornerAwardedAction>(
      ACTION_TYPE_ID.CornerAwarded,
      METADATA_KEY.cornerAwarded,
      {
        position: state.coords,
      },
    );

    if (!action) return state;

    postAction(action);
    setCoords(CoordsCalc.assumeCornerCoords(state.coords));
    setRecentlyAwardedAction(action);

    return ACTIONS_DEFAULT_STATE;
  });
}

function commitGoalKickAwarded() {
  return useActionStore.setState((state) => {
    if (!state.coords) return state;

    const action = createAction<GoalKickAwardedAction>(
      ACTION_TYPE_ID.GoalKickAwarded,
      METADATA_KEY.goalKickAwarded,
      {
        position: state.coords,
      },
    );

    if (!action) return state;

    postAction(action);
    setCoords(CoordsCalc.assumeGoalKickCoords(state.coords));
    setRecentlyAwardedAction(action);
    return ACTIONS_DEFAULT_STATE;
  });
}

function commitPlayResumed() {
  return commitBasicAction<PlayResumedAction>({
    requiredStateFields: [],
    actionTypeId: ACTION_TYPE_ID.PlayResumed,
    metadataKey: METADATA_KEY.playResumed,
    metadata: {},
  });
}

function commitTackle() {
  return commitBasicAction<TackleAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.Tackle,
    metadataKey: METADATA_KEY.tackle,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
      isAssist: false,
    }),
  });
}

function commitBadTouch() {
  return commitBasicAction<BadTouchAction>({
    requiredStateFields: ['player', 'coords'],
    actionTypeId: ACTION_TYPE_ID.BadTouch,
    metadataKey: METADATA_KEY.badTouch,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
      isAssist: false,
    }),
  });
}

function commitPlayerBeaten() {
  return commitBasicAction<PlayerBeatenAction>({
    requiredStateFields: ['player', 'coords', 'playerNumber'],
    actionTypeId: ACTION_TYPE_ID.PlayerBeaten,
    metadataKey: METADATA_KEY.playerBeaten,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
    }),
  });
}

function commitTakeOn() {
  return commitBasicAction<TakeOnAction>({
    requiredStateFields: ['player', 'coords', 'playerNumber'],
    actionTypeId: ACTION_TYPE_ID.TakeOn,
    metadataKey: METADATA_KEY.takeOn,
    createMetadata: (state: ActionStore) => ({
      position: state.coords!,
    }),
  });
}

function commitRefereeDropBall() {
  return commitBasicAction<RefereeDropBallAction>({
    requiredStateFields: [],
    actionTypeId: ACTION_TYPE_ID.RefereeDropBall,
    metadataKey: METADATA_KEY.refereeDropBall,
    metadata: {},
  });
}
