import { TableCell, TableHead, TableRow } from '@mui/material';
import { FIXTURES_COLLECTIONS_COLUMNS } from './constants';

export const FixturesCollectionsTableHeader = () => {
  return (
    <TableHead
      sx={{
        background: 'rgba(255, 255, 255, 0.05)',
        borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      }}
    >
      <TableRow>
        {FIXTURES_COLLECTIONS_COLUMNS.map((column) => (
          <TableCell key={column.id} align='left'>
            {column.name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
