import { FC, useState } from 'react';
import {
  MenuItem,
  ListItemText,
  Stack,
  Typography,
  Button,
  ListItemIcon,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { postAction } from '@/service/helpers/postAction';
import {
  setContextAction,
  setRecentlyAwardedAction,
} from '@/stores/ActionStore/ActionStore';
import { Dialog } from '@/components/Dialog/Dialog';
import { CANCEL_BUTTON_LABEL, DELETE_LABEL } from '@/constants';
import { AWARDED_ACTIONS } from '@/stores/constants';
import type { PropsWithAction } from '../ActionContextMenuContent';

export const DELETE_DIALOG_TITLE = 'Delete action';

export const Delete: FC<PropsWithAction> = ({ action }) => {
  const [isRemoveActionDialogOpen, setIsRemoveActionDialogOpen] =
    useState(false);

  const handleRemove = async () => {
    postAction({ ...action, isRemoved: true });

    if (AWARDED_ACTIONS.includes(action.actionTypeId)) {
      setRecentlyAwardedAction(null);
    }

    setIsRemoveActionDialogOpen(false);
    setContextAction(null);
  };

  return (
    <>
      <MenuItem
        onClick={() => {
          setIsRemoveActionDialogOpen(true);
        }}
      >
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText aria-label={DELETE_LABEL} disableTypography>
          {DELETE_LABEL}
        </ListItemText>
      </MenuItem>

      <Dialog isOpen={isRemoveActionDialogOpen} title={DELETE_DIALOG_TITLE}>
        <Stack sx={{ gap: '20px' }}>
          <Typography>Are you sure you want to remove this action?</Typography>
          <Stack direction='row' justifyContent='flex-end' gap={1}>
            <Button
              aria-label={CANCEL_BUTTON_LABEL}
              onClick={() => setIsRemoveActionDialogOpen(false)}
            >
              {CANCEL_BUTTON_LABEL}
            </Button>
            <Button aria-label={DELETE_LABEL} onClick={handleRemove}>
              {DELETE_LABEL}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
