import { MenuList, MenuItem, ListItemText, SxProps } from '@mui/material';
import { FC } from 'react';
import { Theme } from '@emotion/react';
import { Player } from '@/service/lineups';
import { makeTablePlayerName } from '@/stores/utils';
import { type Action } from '@/types/action/action';
import { UNKNOWN_PLAYER } from '@/stores/LineupStore/constants';
import { useFixtureStore } from '@/stores/FixtureStore';
import { composeLineup } from '@/stores/LineupStore/utils';
import { sortPlayersByShirtNumber } from '@/utils/sortPlayersByShirtNumber';

const NO_PLAYERS_LABEL = 'No players available';

type PlayersListProps = {
  action: Action;
  onClick: (player: Player) => void;
};

export const PlayersList: FC<PlayersListProps> = ({ action, onClick }) => {
  const gameState = useFixtureStore((state) => state.gameState);
  const lineup = action.team
    ? composeLineup(gameState.lineups[action.team.id])
    : [];

  const menuStyle = (): SxProps<Theme> => ({ py: 0 });

  if (!lineup.length)
    return (
      <MenuList variant='menu' sx={menuStyle()}>
        <MenuItem>
          <ListItemText>{NO_PLAYERS_LABEL}</ListItemText>
        </MenuItem>
      </MenuList>
    );

  const playerList = lineup.map(({ player }) => {
    const playerName = makeTablePlayerName(player);
    const isPlayer = action.player && action.player.id === player.id;

    return {
      name: playerName,
      value: player,
      disabled: !!isPlayer,
    };
  });

  const unknownPlayer = {
    name: UNKNOWN_PLAYER.lastName,
    value: UNKNOWN_PLAYER,
    disabled: !!action.player && action.player.id === UNKNOWN_PLAYER.id,
  };

  return (
    <MenuList variant='menu' sx={menuStyle()}>
      {[unknownPlayer, ...playerList]
        .sort(({ value: a }, { value: b }) => sortPlayersByShirtNumber(a, b))
        .map(({ name, value, disabled }) => (
          <MenuItem
            key={name}
            onClick={() => onClick(value)}
            disabled={disabled}
          >
            <ListItemText disableTypography>{name}</ListItemText>
          </MenuItem>
        ))}
    </MenuList>
  );
};
