import { FC } from 'react';
import { Chip } from '@mui/material';
import { type Player } from '@/service/lineups';
import { makeTablePlayerName } from '@/stores/utils';
import {
  UNKNOWN_PLAYER_ID,
  UNKNOWN_PLAYER_LABEL,
} from '@/stores/LineupStore/constants';

type PlayerNameByPlayerProps = {
  player: Player | null;
};

const UnknownPlayerChip = () => (
  <Chip
    data-testid='unknown-player'
    size='small'
    label={UNKNOWN_PLAYER_LABEL}
  />
);

export const PlayerName: FC<PlayerNameByPlayerProps> = ({ player }) => {
  if (!player) return;

  if (player.id === UNKNOWN_PLAYER_ID) return <UnknownPlayerChip />;

  return makeTablePlayerName(player);
};
