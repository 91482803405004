import { FC, useRef, type MutableRefObject } from 'react';
import { Box } from '@mui/material';
import type { Coords } from '@/types';
import { CoordsCalc } from '@/utils/coordsCalc';
import { getActionMetadata } from '@/utils/actions';
import PitchHorizontalSVG from '@/assets/football-pitch-with-goals.svg?react';
import { PITCH_POSITION } from '@/components/Dialogs/constants';
import { CoordsLine } from '@/components/Pitch/CoordsLine';
import { useFixtureStore } from '@/stores/FixtureStore';
import {
  COORDS_DOT_CONTAINER_TEST_ID,
  COORDS_TARGET_DOT_CONTAINER_TEST_ID,
  TeamColor,
} from '@/components/Pitch/constants';
import { useCurrentlyLatestAction } from '@/stores/hooks';
import type { Action } from '@/types/action/action';
import { PitchOrientation } from '../Formation/constants';
import { CoordsDot } from './CoordsDot';
import { usePitchElements } from './utils';
import { ResponsivePitch } from './ResponsivePitch';

function getTeamColor(
  action: Action,
  homeTeamId?: string,
  awayTeamId?: string,
) {
  if (!action.team) return TeamColor.None;
  if (action.team?.id === homeTeamId) return TeamColor.Home;
  if (action.team?.id === awayTeamId) return TeamColor.Away;
  return TeamColor.None;
}

type PitchPreviewDataProps = {
  pitchContainerRef: MutableRefObject<HTMLDivElement | null>;
};

const PitchPreviewData: FC<PitchPreviewDataProps> = ({ pitchContainerRef }) => {
  const action = useCurrentlyLatestAction();
  const { innerClientRect, outerClientRect } =
    usePitchElements(pitchContainerRef);
  const homeTeam = useFixtureStore((state) => state.homeTeam);
  const awayTeam = useFixtureStore((state) => state.awayTeam);

  if (!action) return;

  const metadata = getActionMetadata(action);
  const dotColor = getTeamColor(action, homeTeam?.id, awayTeam?.id);

  const getOuterCoords = (innerCoords: Coords) => {
    if (!outerClientRect || !innerClientRect) return innerCoords;
    return CoordsCalc.scaleUp(innerCoords, innerClientRect, outerClientRect);
  };

  return (
    <>
      {'position' in metadata && (
        <CoordsDot
          data-testid={COORDS_DOT_CONTAINER_TEST_ID}
          player={action.player}
          coords={getOuterCoords(metadata.position)}
          positionId={PITCH_POSITION.ACTION_POSITION}
          color={dotColor}
        />
      )}

      {'targetPosition' in metadata && (
        <CoordsDot
          data-testid={COORDS_TARGET_DOT_CONTAINER_TEST_ID}
          coords={getOuterCoords(metadata.targetPosition)}
          positionId={PITCH_POSITION.TARGET_POSITION}
          color={dotColor}
        />
      )}
      {'position' in metadata && 'targetPosition' in metadata && (
        <CoordsLine
          coordsStart={getOuterCoords(metadata.position)}
          coordsEnd={getOuterCoords(metadata.targetPosition)}
          color={dotColor}
          asArrow
        />
      )}
    </>
  );
};

export const PitchPreview: FC = () => {
  const $pitchContainer = useRef<HTMLDivElement | null>(null);

  return (
    <Box
      id='pitch-preview'
      ref={$pitchContainer}
      sx={{
        position: 'relative',
        flex: 1,
      }}
    >
      <ResponsivePitch
        variant='goals'
        orientation={PitchOrientation.Horizontal}
        PitchSvg={PitchHorizontalSVG}
        sx={{ margin: 'unset' }}
      >
        <PitchPreviewData pitchContainerRef={$pitchContainer} />
      </ResponsivePitch>
    </Box>
  );
};
