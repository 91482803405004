import { Box, colors } from '@mui/material';
import { CSSProperties, FC, PropsWithChildren } from 'react';

interface LabelProps {
  backgroundColor?: string;
  sx?: CSSProperties;
}

export const Label: FC<PropsWithChildren<LabelProps>> = ({
  backgroundColor,
  children,
  sx,
}) => {
  return (
    <Box
      sx={{
        borderRadius: '999em',
        background: backgroundColor || colors.grey[300],
        display: 'flex',
        alignItems: 'center',
        color: (theme) =>
          theme.palette.getContrastText(backgroundColor || colors.grey[300]),
        p: 1,
        width: 'fit-content',
        maxWidth: '100%',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
