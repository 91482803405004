import {
  colors,
  Paper,
  Stack,
  Typography,
  type StackProps,
} from '@mui/material';
import { FC } from 'react';
import { useFixtureStore } from '@/stores/FixtureStore';

export const Score: FC<StackProps> = ({ sx, ...stackProps }) => {
  const gameState = useFixtureStore((state) => state.gameState);
  const homeTeam = useFixtureStore((state) => state.homeTeam);
  const awayTeam = useFixtureStore((state) => state.awayTeam);

  if (!gameState || !homeTeam || !awayTeam) return <></>;
  const homeTeamScore = gameState.score[homeTeam.id] || 0;
  const awayTeamScore = gameState.score[awayTeam.id] || 0;

  return (
    <Paper
      component={Stack}
      elevation={0}
      square={false}
      gap={2}
      direction='row'
      sx={{
        backgroundColor: colors.grey[900],
        width: 'fit-content',
        p: '10px',
        ...sx,
      }}
      {...stackProps}
    >
      <Stack gap={8} direction='row'>
        <Typography variant='h5'>{homeTeam.name}</Typography>
        <Typography variant='h6'>
          {homeTeamScore} : {awayTeamScore}
        </Typography>
        <Typography variant='h5'>{awayTeam.name}</Typography>
      </Stack>
    </Paper>
  );
};
