import { Action } from '@/types/action/action';
import {
  ACTION_TYPE_ID,
  AttemptType,
  GoalkeeperSaveAttemptType,
  PlayStoppedReason,
} from '@contract';
import { hasActionValues, hasMetadataValues } from '@/utils/actions';
import {
  MAX_EXTRA_TIME_VALUE,
  MIN_EXTRA_TIME_VALUE,
} from '@/components/Dialogs/AdditionalTimeDialogContent/constants';

export function isActionFieldsFulfilled(action: Action) {
  switch (action.actionTypeId) {
    case ACTION_TYPE_ID.GoalkeeperSave:
      return (
        action.metadata.goalkeeperSave.attemptType ===
          GoalkeeperSaveAttemptType.TemptSave ||
        (hasActionValues(action, ['player', 'clockTime']) &&
          hasMetadataValues(action.metadata.goalkeeperSave, [
            'bodyPart',
            'type',
          ]))
      );
    case ACTION_TYPE_ID.GoalkeeperPenaltyFaced:
      return (
        hasActionValues(action, ['player', 'clockTime']) &&
        hasMetadataValues(action.metadata.goalkeeperPenaltyFaced, [
          'outcome',
          'stance',
        ])
      );
    case ACTION_TYPE_ID.Shot:
      return (
        (hasActionValues(action, ['player', 'clockTime']) &&
          hasMetadataValues(action.metadata.shot, [
            'bodyPart',
            'patternOfPlay',
          ])) ||
        (action.metadata.shot.attemptType === AttemptType.TemptAttempt &&
          hasActionValues(action, ['player', 'clockTime']))
      );
    case ACTION_TYPE_ID.Goal:
      return (
        hasActionValues(action, ['player', 'clockTime']) &&
        hasMetadataValues(action.metadata.goal, ['bodyPart', 'patternOfPlay'])
      );
    case ACTION_TYPE_ID.VARResult:
      return (
        hasActionValues(action, ['clockTime']) &&
        hasMetadataValues(action.metadata.varResult, ['outcome'])
      );
    case ACTION_TYPE_ID.VARUnderway:
      return (
        hasActionValues(action, ['clockTime']) &&
        hasMetadataValues(action.metadata.varUnderway, ['reason'])
      );
    case ACTION_TYPE_ID.Substitution:
      return (
        hasActionValues(action, ['player', 'clockTime']) &&
        hasMetadataValues(action.metadata.substitution, ['targetPlayer'])
      );
    case ACTION_TYPE_ID.YellowCard:
      return (
        hasActionValues(action, ['player', 'clockTime']) &&
        hasMetadataValues(action.metadata.yellowCard, ['reason'])
      );
    case ACTION_TYPE_ID.RedCard:
      return (
        hasActionValues(action, ['player', 'clockTime']) &&
        hasMetadataValues(action.metadata.redCard, ['reason', 'outcome'])
      );
    case ACTION_TYPE_ID.PlayStopped:
      return (
        (hasMetadataValues(action.metadata.playStopped, ['reason']) &&
          action.metadata.playStopped.reason !== PlayStoppedReason.Injury) ||
        action.metadata.playStopped.isFromOppositeTeam ||
        (action.metadata.playStopped.reason === PlayStoppedReason.Injury &&
          hasActionValues(action, ['player', 'clockTime']))
      );
    case ACTION_TYPE_ID.AdditionalTime:
      return (
        hasActionValues(action, ['clockTime']) &&
        action.metadata.additionalTime.extraTime >= MIN_EXTRA_TIME_VALUE &&
        action.metadata.additionalTime.extraTime <= MAX_EXTRA_TIME_VALUE
      );
    case ACTION_TYPE_ID.Formation:
      return (
        hasActionValues(action, ['clockTime']) &&
        !!action.metadata.formation.pitchPlayers.length
      );
    default:
      return hasActionValues(action, ['clockTime']);
  }
}
