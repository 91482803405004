import { FreeKickAwardedReason } from '@contract';

export enum FreeKickType {
  FreeKick = 0,
  Penalty = 1,
}

export const PENALTY_COORDS = {
  LEFT: {
    x: 10.5,
    y: 50,
  },
  RIGHT: {
    x: 89.5,
    y: 50,
  },
};

export const FREE_KICK_TYPE_LABEL = 'Free Kick Type';

export const FREE_KICK_TYPE: Record<FreeKickType, string> = {
  [FreeKickType.FreeKick]: 'Free Kick',
  [FreeKickType.Penalty]: 'Penalty',
};

const FREE_KICK_REASON: Record<FreeKickAwardedReason, string> = {
  [FreeKickAwardedReason.Aerial]: 'Aerial',
  [FreeKickAwardedReason.BackPass]: 'Back pass',
  [FreeKickAwardedReason.DangerousPlay]: 'Dangerous play',
  [FreeKickAwardedReason.Dissent]: 'Dissent',
  [FreeKickAwardedReason.Foul]: 'Foul',
  [FreeKickAwardedReason.Handball]: 'Handball',
  [FreeKickAwardedReason.Push]: 'Push',
  [FreeKickAwardedReason.Simulation]: 'Simulation',
  [FreeKickAwardedReason.SixSecondRule]: '6 second rule',
};

export const FREE_KICK_REASON_OPTIONS = Object.keys(FREE_KICK_REASON).map(
  (reason) => {
    const key = Number(reason) as FreeKickAwardedReason;
    return {
      value: key,
      label: FREE_KICK_REASON[key],
    };
  },
);
