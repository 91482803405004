import {
  PlayerPositionDto,
  Position,
  GameStateLineupsDto,
  type LineupsDto,
  type PlayerDto,
  ACTION_TYPE_ID,
} from '@contract';
import { sortPlayersByShirtNumber } from '@/utils/sortPlayersByShirtNumber';
import type { Player } from '@/service/lineups';
import { DEFAULT_GAME_STATE } from '@/stores/constants';
import { Action } from '@/types/action/action';
import { useFixtureStore } from '@/stores/FixtureStore';
import { ACTION_TYPE_DEFAULT_PLAYER } from '@/components/Dialogs/constants';
import { useLineupStore } from './LineupStore';
import {
  UNKNOWN_PLAYER,
  type StartingLineupStore,
  FORMATION_MAP,
  LINEUP_NAMED_POSITION,
  type LineupPosition,
  type LineupPositionEntry,
  type LineupStore,
  type PitchPositionMap,
  type PlayerPosition,
} from './constants';

export function getDefaultPitchPlayersMap() {
  return Object.values(Position).reduce<PitchPositionMap>((acc, position) => {
    if (typeof position === 'string') return acc;
    acc.set(position, null);
    return acc;
  }, new Map());
}

export function calculateFormation(pitchPlayers: PlayerPositionDto[]) {
  const formation = FORMATION_MAP.reduce<number[]>((acc, line) => {
    const lineValue = line.reduce<number>((lineAcc, linePosition) => {
      const exists = pitchPlayers.some(
        ({ position }) => position === linePosition,
      );
      if (exists) {
        lineAcc++;
      }
      return lineAcc;
    }, 0);

    if (lineValue) {
      acc.push(lineValue);
    }
    return acc;
  }, []);
  return formation.join('-');
}

export function findPlayerById(id: string) {
  if (!id) {
    return null;
  }
  const lineup = useLineupStore.getState().lineup;
  const playerPosition = lineup.find(({ player }) => player.id === id);
  if (!playerPosition) return null;

  return playerPosition.player;
}

export const PLAYERS_LIST_VARIANT = {
  IN_GAME: 'in-game',
  PITCH: 'pitch-players',
  BENCH: 'bench-players',
  OFF: 'off-players',
} as const;
export type PlayersListVariant = ConstType<typeof PLAYERS_LIST_VARIANT>;

export function getPlayersList(
  lineups: GameStateLineupsDto,
  listVariant?: PlayersListVariant,
): Player[] {
  let result: Player[];
  switch (listVariant) {
    case PLAYERS_LIST_VARIANT.IN_GAME:
      result = [
        ...lineups.pitchPlayers.map(({ player }) => player),
        ...lineups.benchPlayers,
        ...lineups.retiredPlayers,
      ];
      break;
    case PLAYERS_LIST_VARIANT.BENCH:
      result = [...lineups.benchPlayers];
      break;
    case PLAYERS_LIST_VARIANT.OFF:
      result = [...lineups.offPlayers];
      break;
    case PLAYERS_LIST_VARIANT.PITCH:
    default:
      result = lineups.pitchPlayers.map(({ player }) => player);
      break;
  }
  result.unshift(UNKNOWN_PLAYER);
  return result.sort(sortPlayersByShirtNumber);
}

export function playerPositionsToMap(
  playerPositions: PlayerPositionDto[],
): PitchPositionMap {
  const map = getDefaultPitchPlayersMap();
  return playerPositions.reduce<PitchPositionMap>((acc, entry) => {
    acc.set(entry.position, entry.player);
    return acc;
  }, map);
}

function isPlayerPositionDto(
  playerPosition: PlayerPosition,
): playerPosition is PlayerPositionDto {
  return isPitchPosition(playerPosition.position);
}

function isPitchPosition(position: LineupPosition): position is Position {
  return typeof position === 'number' && position in Position;
}

export function getSwappedPositionLineup(
  lineup: LineupStore['lineup'],
  a: LineupPositionEntry,
  b: LineupPositionEntry,
) {
  const newLineup = [...lineup];
  const [positionA, playerA] = a;
  const [positionB, playerB] = b;

  if (playerA) {
    const newPlayerPosition: PlayerPosition = {
      position: positionB,
      player: playerA,
    };
    const lineupPlayerIdx = newLineup.findIndex(
      ({ player }) => player.id === playerA.id,
    );
    newLineup.splice(lineupPlayerIdx, 1, newPlayerPosition);
  }
  if (playerB) {
    const swappedPlayerPosition: PlayerPosition = {
      position: positionA,
      player: playerB,
    };
    const lineupPlayerIdx = newLineup.findIndex(
      ({ player }) => player.id === playerB.id,
    );
    newLineup.splice(lineupPlayerIdx, 1, swappedPlayerPosition);
  }
  return newLineup;
}

export function decomposeLineup(lineup: LineupStore['lineup']) {
  const {
    benchPlayers,
    offPlayers,
    squadPlayers,
    pitchPlayers,
    retiredPlayers,
  } = lineup.reduce(
    (acc, pp) => {
      if (pp.position === LINEUP_NAMED_POSITION.BENCH) {
        acc.benchPlayers.push(pp.player);
      }
      if (pp.position === LINEUP_NAMED_POSITION.OFF) {
        acc.offPlayers.push(pp.player);
      }
      if (pp.position === LINEUP_NAMED_POSITION.SQUAD) {
        acc.squadPlayers.push(pp.player);
      }
      if (pp.position === LINEUP_NAMED_POSITION.RETIRED) {
        acc.retiredPlayers.push(pp.player);
      }
      if (isPlayerPositionDto(pp)) {
        acc.pitchPlayers.push(pp);
      }
      return acc;
    },
    {
      benchPlayers: [] as LineupStore['benchPlayers'],
      offPlayers: [] as LineupStore['offPlayers'],
      squadPlayers: [] as StartingLineupStore['squadPlayers'],
      pitchPlayers: [] as LineupStore['pitchPlayers'],
      retiredPlayers: [] as LineupStore['retiredPlayers'],
    },
  );

  return {
    benchPlayers: benchPlayers.sort(sortPlayersByShirtNumber),
    offPlayers: offPlayers.sort(sortPlayersByShirtNumber),
    squadPlayers: squadPlayers.sort(sortPlayersByShirtNumber),
    retiredPlayers: retiredPlayers.sort(sortPlayersByShirtNumber),
    pitchPlayers,
  };
}

export function composeStartingLineup(data: LineupsDto) {
  const { benchPlayers, pitchPlayers, squadPlayers } = data;
  const inGameLineup: LineupStore['lineup'] = [];

  benchPlayers.forEach((player) =>
    inGameLineup.push({ player, position: LINEUP_NAMED_POSITION.BENCH }),
  );
  squadPlayers.forEach((player) =>
    inGameLineup.push({ player, position: LINEUP_NAMED_POSITION.SQUAD }),
  );
  pitchPlayers.forEach((pp) => inGameLineup.push(pp));

  return inGameLineup;
}

export function composeLineup(data: GameStateLineupsDto) {
  const {
    benchPlayers = [],
    pitchPlayers = [],
    offPlayers = [],
    retiredPlayers = [],
  } = data || DEFAULT_GAME_STATE.lineups;
  const inGameLineup: LineupStore['lineup'] = [];

  pitchPlayers.forEach((pp) => inGameLineup.push(pp));
  benchPlayers.forEach((player) =>
    inGameLineup.push({ player, position: LINEUP_NAMED_POSITION.BENCH }),
  );
  offPlayers.forEach((player) =>
    inGameLineup.push({ player, position: LINEUP_NAMED_POSITION.OFF }),
  );
  retiredPlayers.forEach((player) =>
    inGameLineup.push({ player, position: LINEUP_NAMED_POSITION.RETIRED }),
  );

  return inGameLineup;
}

export function mapPlayerPositionToPlayer(
  playerPosition: PlayerPositionDto,
): PlayerDto {
  return playerPosition.player;
}

export function getActionDefaultPlayer(action: Action) {
  const gameState = useFixtureStore.getState().gameState;

  if (action.actionTypeId === ACTION_TYPE_ID.GoalkeeperSave && action.team) {
    return (
      gameState.lineups[action.team.id].pitchPlayers.find(
        ({ position }) => position === Position.Gk,
      )?.player || ACTION_TYPE_DEFAULT_PLAYER[action.actionTypeId]
    );
  }

  return ACTION_TYPE_DEFAULT_PLAYER[action.actionTypeId];
}
