import { FC, useState } from 'react';
import {
  Autocomplete,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { setCurrentPeriodConfig, useFixtureStore } from '@/stores/FixtureStore';
import { CANCEL_BUTTON_LABEL } from '@/constants';
import { ACTION_TYPE_ID, type PeriodDetailsDto, METADATA_KEY } from '@contract';
import { createAction } from '@/stores/ActionStore/utils';
import type {
  EndPeriodAction,
  StartMatchAction,
  StartPeriodAction,
} from '@/types/action/action';
import { postAction } from '@/service/helpers/postAction';
import { setCoords } from '@/stores/ActionStore/ActionStore';
import { pauseClock, setClockTicks, startClock } from '@/stores/ClockStore';
import { RadioButtons } from '../Form/RadioButtons';
import { DIRECTION } from '../Form/constants';
import {
  DIRECTION_OF_PLAY,
  DIRECTION_OF_PLAY_LABEL,
  DIRECTION_OF_PLAY_OPTIONS,
  END_PERIOD_LABEL,
  KICK_OFF_LABEL,
  MATCH_DETAILS_LABEL,
  SELECT_PERIOD_LABEL,
} from './constants';
import { getIsHomeTeamLeft } from './helpers';

type EditPeriodProps = {
  onClose: VoidFunction;
};

export const EditPeriod: FC<EditPeriodProps> = ({ onClose }) => {
  const periodsConfig = useFixtureStore((state) => state.periodsConfig);
  const currentPeriodConfig = useFixtureStore(
    (state) => state.currentPeriodConfig,
  );
  const currentPeriod = useFixtureStore((state) => state.currentPeriod);
  const isPeriodInProgress =
    currentPeriod.endTime === null && currentPeriod.sequence !== 0;

  const directionOfPlay = useFixtureStore((state) => state.directionOfPlay);

  const [selectedPeriod, setSelectedPeriod] = useState<PeriodDetailsDto>(() => {
    if (isPeriodInProgress && currentPeriod.sequence !== 0) {
      return currentPeriodConfig;
    }

    return (
      periodsConfig.find(
        (period) => period.seq === currentPeriodConfig.seq + 1,
      ) || currentPeriodConfig
    );
  });
  const [selectedDirectionOfPlay, setSelectedDirectionOfPlay] = useState(() => {
    if (currentPeriod.startTime && currentPeriod.endTime) {
      return directionOfPlay === DIRECTION_OF_PLAY.LEFT_TO_RIGHT
        ? DIRECTION_OF_PLAY.RIGHT_TO_LEFT
        : DIRECTION_OF_PLAY.LEFT_TO_RIGHT;
    }
    return directionOfPlay;
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onKickOff = async () => {
    if (currentPeriodConfig.seq === 0) {
      const startMatchAction = createAction<StartMatchAction>(
        ACTION_TYPE_ID.StartMatch,
        METADATA_KEY.startMatch,
        {},
      );
      if (!startMatchAction) return;
      setIsLoading(true);
      await postAction(startMatchAction);
    }

    setCurrentPeriodConfig(selectedPeriod);
    setClockTicks(selectedPeriod.initialClock || 0);

    const startPeriodAction = createAction<StartPeriodAction>(
      ACTION_TYPE_ID.StartPeriod,
      METADATA_KEY.startPeriod,
      {
        isHomeTeamLeft: getIsHomeTeamLeft(selectedDirectionOfPlay),
      },
    );
    if (!startPeriodAction) return;

    postAction({
      ...startPeriodAction,
      period: {
        sequence: selectedPeriod.seq,
        label: selectedPeriod.label,
      },
    });
    setIsLoading(false);
    setCoords({ x: 50, y: 50 });

    onClose();

    startClock();
  };

  const onEndPeriod = () => {
    if (!selectedPeriod) return;

    const endPeriodAction = createAction<EndPeriodAction>(
      ACTION_TYPE_ID.EndPeriod,
      METADATA_KEY.endPeriod,
      {},
    );

    if (!endPeriodAction) return;

    postAction({
      ...endPeriodAction,
      period: {
        sequence: selectedPeriod.seq,
        label: selectedPeriod.label,
      },
    });
    pauseClock();
    onClose();
  };

  return (
    <Stack direction='row' alignItems='top' p={2} gap={3}>
      <Stack gap={2} justifyContent='space-between' sx={{ minWidth: '200px' }}>
        <Typography variant='body1'>{MATCH_DETAILS_LABEL}</Typography>
        <Autocomplete
          autoHighlight
          disabled={isPeriodInProgress}
          disableClearable={true}
          value={selectedPeriod || undefined}
          options={periodsConfig}
          isOptionEqualToValue={(option, value) => option.seq === value.seq}
          getOptionDisabled={(option) => option.seq === 0}
          renderInput={(params) => (
            <TextField {...params} label={SELECT_PERIOD_LABEL} />
          )}
          onChange={(_, period) => setSelectedPeriod(period)}
        />
      </Stack>

      <RadioButtons
        disabled={isPeriodInProgress}
        name='directionOfPlay'
        onChange={setSelectedDirectionOfPlay}
        value={selectedDirectionOfPlay}
        controlLabel={DIRECTION_OF_PLAY_LABEL}
        fields={DIRECTION_OF_PLAY_OPTIONS}
        direction={DIRECTION.COLUMN}
      />
      <Stack alignItems='end' direction='row' gap={1}>
        <Button variant='text' color='inherit' onClick={onClose}>
          {CANCEL_BUTTON_LABEL}
        </Button>
        {isPeriodInProgress ? (
          <Button variant='contained' color='primary' onClick={onEndPeriod}>
            {END_PERIOD_LABEL}
          </Button>
        ) : (
          <Button
            variant='contained'
            color='primary'
            onClick={onKickOff}
            disabled={!selectedPeriod || isLoading}
          >
            {KICK_OFF_LABEL}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
