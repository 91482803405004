import { ROUTES_FIXTURE_VIEW, ROUTE_ID } from './constants';

export type TabRouteDetails = {
  ID: string;
  PATH: string;
  NAME: string;
};

export enum FIXTURE_VIEW_ROUTES {
  HOME_COLLECTOR = 'HOME_COLLECTOR',
  AWAY_COLLECTOR = 'AWAY_COLLECTOR',
  QA = 'QA',
}

export const IS_HOME_TEAM_VIEW = {
  [FIXTURE_VIEW_ROUTES.HOME_COLLECTOR]: true,
  [FIXTURE_VIEW_ROUTES.AWAY_COLLECTOR]: false,
  [FIXTURE_VIEW_ROUTES.QA]: null,
} as const;

export const ROUTES: Record<FIXTURE_VIEW_ROUTES, TabRouteDetails[]> = {
  [FIXTURE_VIEW_ROUTES.HOME_COLLECTOR]: [
    {
      ...ROUTES_FIXTURE_VIEW.STARTING_LINEUP,
      ID: ROUTE_ID.HOME_COLLECTOR_LINEUP_INIT,
    },
    { ...ROUTES_FIXTURE_VIEW.PITCH, ID: ROUTE_ID.HOME_COLLECTOR_PITCH },
    { ...ROUTES_FIXTURE_VIEW.HISTORY, ID: ROUTE_ID.HOME_COLLECTOR_HISTORY },
    {
      ...ROUTES_FIXTURE_VIEW.LIVE_LINEUP,
      ID: ROUTE_ID.HOME_COLLECTOR_LIVE_LINEUP,
    },
  ],
  [FIXTURE_VIEW_ROUTES.AWAY_COLLECTOR]: [
    {
      ...ROUTES_FIXTURE_VIEW.STARTING_LINEUP,
      ID: ROUTE_ID.AWAY_COLLECTOR_LINEUP_INIT,
    },
    { ...ROUTES_FIXTURE_VIEW.PITCH, ID: ROUTE_ID.AWAY_COLLECTOR_PITCH },
    { ...ROUTES_FIXTURE_VIEW.HISTORY, ID: ROUTE_ID.AWAY_COLLECTOR_HISTORY },
    {
      ...ROUTES_FIXTURE_VIEW.LIVE_LINEUP,
      ID: ROUTE_ID.AWAY_COLLECTOR_LIVE_LINEUP,
    },
  ],
  [FIXTURE_VIEW_ROUTES.QA]: [{ ...ROUTES_FIXTURE_VIEW.QA, ID: ROUTE_ID.QA }],
};
