import { useSessionStorage } from 'usehooks-ts';
import { isDevMode } from './environment';
import { SS_KEY, registerWindowUtil } from './window';
import { noop } from './noop';

export function isDebugEnabled() {
  return window.sessionStorage.getItem(SS_KEY.DEBUG) === 'true';
}

if (window.sessionStorage.getItem(SS_KEY.DEBUG) === null && isDevMode) {
  window.sessionStorage.setItem(SS_KEY.DEBUG, 'true');
}

export function useIsDebugEnabled() {
  return useSessionStorage<boolean | undefined>(
    SS_KEY.DEBUG,
    isDevMode ? true : undefined,
  );
}

let registered = false;
if (!registered) {
  registerWindowUtil('setDebug', (enable) =>
    window.sessionStorage.setItem(SS_KEY.DEBUG, enable.toString()),
  );
  isDevMode &&
    console.log('`window._ed.setDebug(boolean)` - enable/disable debug mode');
  registered = true;
}
export const isTraceStoreEnabled =
  window.sessionStorage.getItem(SS_KEY.TRACE_STORE) === 'true';

export const konsole = new Proxy(console, {
  get(target, prop) {
    if (!isDebugEnabled()) {
      return noop;
    }
    return Reflect.get(target, prop);
  },
});
