import { MenuList, MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import { FC } from 'react';
import type { Action } from '@/types/action/action';
import { OutcomeIcon } from '@/components/ActionOutcome/OutcomeIcon';
import { DEFAULT_OUTCOME_OPTIONS } from './constants';

type OutcomeListProps = {
  action: Action;
  onClick: (outcome: boolean | null) => void;
};

export const OutcomeList: FC<OutcomeListProps> = ({ action, onClick }) => {
  const outcomeList = DEFAULT_OUTCOME_OPTIONS.map(({ value, label }) => {
    return {
      name: label,
      value,
      disabled: value === action.isSuccessful,
    };
  });

  return (
    <MenuList variant='menu' sx={{ py: 0 }}>
      {outcomeList.map(({ name, value, disabled }) => (
        <MenuItem key={name} onClick={() => onClick(value)} disabled={disabled}>
          <ListItemIcon>
            <OutcomeIcon outcome={value} />
          </ListItemIcon>
          <ListItemText disableTypography>{name}</ListItemText>
        </MenuItem>
      ))}
    </MenuList>
  );
};
