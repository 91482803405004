import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

export const NotFound: FC = () => {
  return (
    <Stack flex='1 0 auto' alignItems='center'>
      <Typography variant='h1'>404</Typography>
      <Typography variant='h5'>We were looking everywhere</Typography>
    </Stack>
  );
};
