export enum LivePlayersType {
  Bench = 'benchPlayers',
  Off = 'offPlayers',
  Retired = 'retiredPlayers',
}

export enum StartingPlayersType {
  Bench = 'benchPlayers',
  Squad = 'squadPlayers',
}
