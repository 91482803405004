import { FC, type ReactNode } from 'react';
import { useFixtureStore } from '@/stores/FixtureStore';

type TeamNameProps = {
  id: string;
  fallbackValue?: ReactNode;
};

export const TeamName: FC<TeamNameProps> = ({ id, fallbackValue }) => {
  const homeTeam = useFixtureStore((state) => state.homeTeam);
  const awayTeam = useFixtureStore((state) => state.awayTeam);

  if (homeTeam && homeTeam.id === id) {
    return homeTeam.name;
  }
  if (awayTeam && awayTeam.id === id) {
    return awayTeam.name;
  }
  return fallbackValue || '';
};
