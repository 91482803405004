import { styled, Box, css } from '@mui/material';
import { PitchItem } from './PitchItem';
import { useStartingLineupsContext } from './StartingLineupContext';

export const bottomUpGrid = `
  '. LCF ST RCF .'
  '. . SS . .'
  'LW LAM CAM RAM RW'
  'LM LCM CM RCM RM'
  'LWB LDM CDM RDM RWB'
  'LB LCB CB RCB RB'
  '. . GK . .'
`;

const PlayersGrid = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    inset: 0;
    display: grid;
    padding: ${theme.spacing(0.5)};
    gap: ${theme.spacing(0.5)};
    grid-template-areas: ${bottomUpGrid};
  `,
);
export const PitchGrid = () => {
  const startingLineupStore = useStartingLineupsContext();
  const pitchPlayersMap = startingLineupStore.useStore(
    (state) => state.pitchPlayersMap,
  );

  const pitchEntries = [...pitchPlayersMap.entries()];
  return (
    <PlayersGrid>
      {pitchEntries.map(([position, player]) => (
        <PitchItem key={position} position={position} player={player} />
      ))}
    </PlayersGrid>
  );
};
