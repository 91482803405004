import { ROUTE_PATH } from './constants';
import { FIXTURE_VIEW_ROUTES } from './fixtureViewRoutes';

export const getRoleView = () => {
  const path = window.location.pathname;
  if (path.includes(ROUTE_PATH.HOME_COLLECTOR.DEFAULT)) {
    return FIXTURE_VIEW_ROUTES.HOME_COLLECTOR;
  }
  if (path.includes(ROUTE_PATH.AWAY_COLLECTOR.DEFAULT)) {
    return FIXTURE_VIEW_ROUTES.AWAY_COLLECTOR;
  }
  if (path.includes(ROUTE_PATH.QA.DEFAULT)) {
    return FIXTURE_VIEW_ROUTES.QA;
  }
  return null;
};
