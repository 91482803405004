import { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import { useNavigate } from 'react-router-dom';
import { ROUTE_URL } from '@/router/constants';

const okta = new OktaAuth({
  issuer: import.meta.env.VITE_OKTA_ISSUER,
  clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}${ROUTE_URL.OKTA_LOGIN_CALLBACK}`,
  headers: {
    'Accept-Language': 'en-US',
  },
});

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const restoreLocation: RestoreOriginalUriFunction = useCallback(
    async (_: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
        replace: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    return () => {
      okta.options.restoreOriginalUri = undefined;
    };
  }, []);

  return (
    <Security oktaAuth={okta} restoreOriginalUri={restoreLocation}>
      {children}
    </Security>
  );
};
