import { Stack } from '@mui/material';
import { leftColumnButtons, rightColumnButtons } from './constants';
import { ButtonsGroup } from './ButtonsGroup';

export const ActionButtons = () => {
  return (
    <Stack id='action-buttons' overflow='auto' direction='row'>
      {[leftColumnButtons, rightColumnButtons].map((column, index) => (
        <Stack key={'column_' + index} flex={1}>
          {column.map((buttonsGroup) => (
            <ButtonsGroup
              key={buttonsGroup.title}
              title={buttonsGroup.title}
              buttons={buttonsGroup.buttons}
            />
          ))}
        </Stack>
      ))}
    </Stack>
  );
};
