import { ACTION_TYPE_ID } from '@contract';
import { Action } from '@/types/action/action';

export const ACTION_TYPE: Record<ACTION_TYPE_ID, string> = {
  [ACTION_TYPE_ID.Pass]: 'Pass',
  [ACTION_TYPE_ID.Shot]: 'Shot',
  [ACTION_TYPE_ID.Goal]: 'Goal',

  [ACTION_TYPE_ID.Lineups]: 'Lineups',

  [ACTION_TYPE_ID.StartPeriod]: 'Start period',
  [ACTION_TYPE_ID.EndPeriod]: 'End period',

  [ACTION_TYPE_ID.EndMatch]: 'End match',
  [ACTION_TYPE_ID.StartMatch]: 'Start match',

  [ACTION_TYPE_ID.Aerial]: 'Aerial',
  [ACTION_TYPE_ID.BallRecovery]: 'Ball recovery',
  [ACTION_TYPE_ID.BallTouch]: 'Ball touch',
  [ACTION_TYPE_ID.Dispossessed]: 'Dispossessed',
  [ACTION_TYPE_ID.GoalkeeperPenaltyFaced]: 'Goalkeeper penalty faced',
  [ACTION_TYPE_ID.GoalkeeperSave]: 'Goalkeeper save',
  [ACTION_TYPE_ID.OffsideFor]: 'Offside for',
  [ACTION_TYPE_ID.Offside]: 'Offside Against',
  [ACTION_TYPE_ID.Clearance]: 'Clearance',
  [ACTION_TYPE_ID.Cross]: 'Cross',
  [ACTION_TYPE_ID.Launch]: 'Launch',
  [ACTION_TYPE_ID.CornerAwarded]: 'Corner awarded',
  [ACTION_TYPE_ID.FreeKickAwarded]: 'Free kick',
  [ACTION_TYPE_ID.ThrowInAwarded]: 'Throw in awarded',
  [ACTION_TYPE_ID.ThrowInTaken]: 'Throw in taken',
  [ACTION_TYPE_ID.GoalKickAwarded]: 'Goal kick awarded',
  [ACTION_TYPE_ID.YellowCard]: 'Yellow Card',
  [ACTION_TYPE_ID.RedCard]: 'Red Card',
  [ACTION_TYPE_ID.Tackle]: 'Tackle',
  [ACTION_TYPE_ID.Interception]: 'Interception',
  [ACTION_TYPE_ID.GoalkeeperCollection]: 'Goalkeeper collection',
  [ACTION_TYPE_ID.GoalkeeperPunch]: 'Goalkeeper punch',
  [ACTION_TYPE_ID.GoalkeeperCatch]: 'Goalkeeper catch',
  [ACTION_TYPE_ID.GoalkeeperDrop]: 'Goalkeeper drop',
  [ACTION_TYPE_ID.VARUnderway]: 'VAR underway',
  [ACTION_TYPE_ID.VARResult]: 'VAR result',
  [ACTION_TYPE_ID.Error]: 'Error',
  [ACTION_TYPE_ID.PlayerReturned]: 'Player returned',
  [ACTION_TYPE_ID.PlayerLeft]: 'Player left',
  [ACTION_TYPE_ID.Substitution]: 'Substitution',
  [ACTION_TYPE_ID.AdditionalTime]: 'Additional time',
  [ACTION_TYPE_ID.PostMatchCheckComplete]: 'Post Match Check Complete',
  [ACTION_TYPE_ID.PlayResumed]: 'Play resumed',
  [ACTION_TYPE_ID.PlayStopped]: 'Play stopped',
  [ACTION_TYPE_ID.BadTouch]: 'Bad Touch',
  [ACTION_TYPE_ID.GoalkeeperThrow]: 'Goalkeeper throw',
  [ACTION_TYPE_ID.PlayerBeaten]: 'Player beaten',
  [ACTION_TYPE_ID.TakeOn]: 'Take on',
  [ACTION_TYPE_ID.GoalkeeperKickFromHands]: 'GK kick - hands',
  [ACTION_TYPE_ID.RefereeDropBall]: 'Referee drop ball',
  [ACTION_TYPE_ID.Formation]: 'Formation',
};

export type ActionType = typeof ACTION_TYPE;

export type ActionTypeOption = (typeof ACTION_TYPES)[number];

export const ACTION_TYPES = Object.keys(ACTION_TYPE).map((k) => {
  const key = Number(k) as Action['actionTypeId'];
  return {
    value: key,
    label: ACTION_TYPE[key],
  };
});
