import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Stack } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { ROUTE_URL } from '@/router/constants';
import { SignInForm } from '@/components/SignInForm/SignInForm';
import viennaReyes from '@/assets/vienna-reyes.png';
import imgLogo from '@/assets/img-logo.png';

export const SignInView = () => {
  const { authState } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      return;
    }
    navigate(ROUTE_URL.HOME);
  }, [authState, navigate]);

  return (
    <Stack direction='row' height='100vh'>
      <Stack
        alignItems='center'
        justifyContent='center'
        flex='1 0 0'
        sx={{
          overflow: 'hidden',
          backgroundImage: `url(${viennaReyes})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{
            background: `rgba(0, 0, 0, 0.42)`,
            width: `100%`,
            height: `100%`,
          }}
        >
          <img src={imgLogo} alt='IMG Arena' width='25%' />
        </Stack>
      </Stack>

      <Stack flex='1 0 0'>
        <Paper elevation={1} sx={{ padding: 5, m: 'auto', maxWidth: '470px' }}>
          <SignInForm />
        </Paper>
      </Stack>
    </Stack>
  );
};
