import { FC, useMemo, useState } from 'react';
import {
  Autocomplete,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Check from '@mui/icons-material/Check';
import { setClockTicks, useClockStore } from '@/stores/ClockStore';
import { MinMaxInput } from '@/components/Form/MinMaxInput';
import { setCurrentPeriodConfig, useFixtureStore } from '@/stores/FixtureStore';
import type { PeriodDetailsDto } from '@contract';
import { SELECT_PERIOD_LABEL } from '../Periods/constants';
import {
  MAX_MINUTES_VALUE,
  MAX_SECONDS_VALUE,
  MIN_MINUTES_VALUE,
  MIN_SECONDS_VALUE,
} from './constants';

type EditClockProps = {
  autoFocus?: boolean;
  afterSubmit?: (newTime: number, periodSeq: PeriodDetailsDto) => unknown;
};

export const EditClock: FC<EditClockProps> = ({
  autoFocus = true,
  afterSubmit,
}) => {
  const [m, s] = useClockStore.getState().clockTime.split(':').map(Number);
  const [minutes, setMinutes] = useState(m);
  const [seconds, setSeconds] = useState(s);
  const [selectedPeriod, setSelectedPeriod] = useState(
    useFixtureStore.getState().currentPeriodConfig,
  );
  const periodsConfig = useFixtureStore((state) => state.periodsConfig);
  const collectionState = useFixtureStore((state) => state.collectionState);
  const startedPeriods = useMemo(
    () =>
      periodsConfig.reduce<PeriodDetailsDto[]>((acc, config) => {
        if (
          collectionState &&
          collectionState.periods.some(
            (period) => period.sequence === config.seq,
          )
        ) {
          acc.push(config);
        }
        return acc;
      }, []),
    [periodsConfig, collectionState],
  );

  const onSave = () => {
    const newTicks = minutes * 60 + seconds;
    setClockTicks(newTicks);
    setCurrentPeriodConfig(selectedPeriod);
    afterSubmit &&
      typeof afterSubmit === 'function' &&
      afterSubmit(newTicks, selectedPeriod);
  };

  return (
    <Stack direction='row' alignItems={'center'}>
      <Autocomplete
        options={startedPeriods}
        value={selectedPeriod}
        isOptionEqualToValue={(option, value) => option.seq === value.seq}
        renderInput={(params) => (
          <TextField {...params} label={SELECT_PERIOD_LABEL} />
        )}
        onChange={(_, periodConfig) => setSelectedPeriod(periodConfig)}
        autoHighlight
        disableClearable
        sx={{ width: '16ch' }}
      />
      <MinMaxInput
        autoFocus={autoFocus}
        sx={{ width: '8ch' }}
        size='small'
        name='minutes'
        value={minutes}
        onNumberChange={(minutes) => setMinutes(minutes)}
        min={MIN_MINUTES_VALUE}
        max={MAX_MINUTES_VALUE}
      />
      <Typography fontSize='1.5em'>:</Typography>
      <MinMaxInput
        sx={{ width: '8ch' }}
        size='small'
        name='seconds'
        value={seconds}
        onNumberChange={(seconds) => setSeconds(seconds)}
        min={MIN_SECONDS_VALUE}
        max={MAX_SECONDS_VALUE}
      />
      <Button onClick={onSave}>
        <Check />
      </Button>
    </Stack>
  );
};
