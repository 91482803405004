import Warning from '@mui/icons-material/Warning';
import Storage from '@mui/icons-material/Storage';
import { TableAction } from '@/stores/FixtureStore';

export const SAVE_ACTION_CONFIRMATION_TITLE =
  'Would you like to save data before opening new action?';

type ColumnId = keyof TableAction;
export type QAHistoryTableColumn = {
  id: ColumnId | 'team';
  name: JSX.Element | string;
  width: number;
};

export const COLUMNS: QAHistoryTableColumn[] = [
  { id: 'period', name: 'Period', width: 6 },
  { id: 'time', name: 'Time', width: 9 },
  { id: 'type', name: 'Event', width: 10 },
  { id: 'player', name: 'Player', width: 7 },
  { id: 'team', name: 'Team', width: 12 },
  { id: 'extras', name: 'Extras', width: 15 },
  { id: 'isSuccessful', name: 'Outcome', width: 22 },
  { id: 'warnings', name: <Warning fontSize='small' />, width: 9 },
  { id: 'state', name: <Storage fontSize='small' />, width: 10 },
];

export const TABLE_CLASS_NAMES = {
  ROW: 'action-row',
};

(function () {
  const totalWidth = COLUMNS.reduce((acc, col) => {
    return acc + col.width;
  }, 0);
  if (totalWidth !== 100) {
    console.error(
      `QAHistoryTable columns width is ${totalWidth}% - should be a 100%`,
    );
  }
})();
