import { FC } from 'react';
import Timer from '@mui/icons-material/Timer';
import { NestedMenuItem } from '../NestedMenuItem';
import { EditActionTimePanel } from '../MenuPanels/EditActionTimePanel';
import type { PropsWithAction } from '../ActionContextMenuContent';

export const EditTime: FC<PropsWithAction> = ({ action }) => {
  return (
    <NestedMenuItem menuItemLabel='Edit time' icon={<Timer />}>
      <EditActionTimePanel action={action} />
    </NestedMenuItem>
  );
};
