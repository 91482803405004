import { Stack } from '@mui/material';
import { FC } from 'react';
import { Autocomplete } from '@/components/Form/Autocomplete';
import { VAROutcome } from '@contract';
import { VARResultAction } from '@/types/action/action';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { REASON_LABEL } from '@/constants';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { ParentAction } from '@/components/ParentAction/ParentAction';
import { Controls } from '../Common/Controls';
import { VAR_RESULT_OPTIONS } from './constants';

interface VARResultDialogContentProps {
  action: VARResultAction;
}

const onReasonChange = (outcome: VAROutcome) => {
  setActionPartialMetadata<VARResultAction>({ outcome });
};

export const VARResultDialogContent: FC<VARResultDialogContentProps> = ({
  action,
}) => {
  return (
    <Stack gap={2}>
      <Stack gap={6} direction='row'>
        <Stack gap={2}>
          <Autocomplete
            options={VAR_RESULT_OPTIONS}
            value={action.metadata.varResult.outcome || null}
            onChange={onReasonChange}
            label={REASON_LABEL}
          />
          <PeriodClockField action={action} />
          <ParentAction action={action} />
          <Controls action={action} />
        </Stack>
      </Stack>
    </Stack>
  );
};
