import { Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { TableAction } from '@/stores/FixtureStore';

interface PeriodNumberProps {
  action: TableAction;
}

export const PERIOD_TEST_ID = 'period';

export const PeriodNumber: FC<PeriodNumberProps> = ({ action }) => {
  if (action.period === null) return;

  const {
    period: { label, sequence },
  } = action;

  return (
    <Tooltip placement='right' title={label}>
      <Typography data-testid={PERIOD_TEST_ID}>{sequence}</Typography>
    </Tooltip>
  );
};
