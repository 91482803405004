import { FC } from 'react';
import { Stack } from '@mui/material';
import { FROM_PLAYER_LABEL } from '@/constants';
import { TargetAction } from '@/stores/ActionStore/constants';
import { ParentAction } from '@/components/ParentAction/ParentAction';
import { DestinationPitch } from '../Common/DestinationPitch';
import { PeriodClockField } from '../Common/PeriodClockField';
import { OutcomeField } from '../Common/OutcomeField';
import { FlagField } from '../Common/FlagField';
import { ActionPlayerField } from '../Common/ActionPlayerField';
import { IsAssistActionField } from '../Common/IsAssistActionField';
import { IsHeadedField } from '../Common/IsHeadedField';
import { ActionContextField } from '../Common/ActionContextField';
import { Controls } from '../Common/Controls';

interface PassDialogContentProps {
  action: TargetAction;
}

export const PassDialogContent: FC<PassDialogContentProps> = ({ action }) => {
  if (!action) return;

  return (
    <Stack direction='row' gap={2} flex={1} width='100%'>
      <Stack direction='row' flex={6}>
        <DestinationPitch action={action} />
      </Stack>

      <Stack flex={3} gap={2}>
        <PeriodClockField action={action} />
        <ParentAction action={action} />
        <OutcomeField action={action} />
        <ActionPlayerField
          action={action}
          playerAutoCompleteProps={{ label: FROM_PLAYER_LABEL }}
        />
        <ActionContextField action={action} />
        <Stack direction='row' flexWrap='wrap'>
          <FlagField action={action} />
          <IsAssistActionField action={action} />
          <IsHeadedField action={action} />
        </Stack>
        <Controls action={action} />
      </Stack>
    </Stack>
  );
};
