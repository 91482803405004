import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { isDebugEnabled, isTraceStoreEnabled } from '@/utils/debug';
import { makePlayerQuickInputMap } from '../ActionStore/playerInput';
import { setPlayerInputMap } from '../ActionStore/ActionStore';
import { decomposeLineup } from './utils';
import { LineupStore } from './constants';

function getStoreDefault(): LineupStore {
  return {
    teamId: undefined,
    teamName: undefined,
    benchPlayers: [],
    offPlayers: [],
    pitchPlayers: [],
    retiredPlayers: [],
    lineup: [],
  };
}

export const useLineupStore = create<LineupStore>()(
  devtools(() => ({ ...getStoreDefault() }), {
    enabled: isDebugEnabled(),
    trace: isTraceStoreEnabled,
    name: 'LineupStore',
  }),
);

export function reset() {
  useLineupStore.setState({ ...getStoreDefault() }, false, 'reset');
}
export function resetPlayers() {
  useLineupStore.setState({
    benchPlayers: [],
    offPlayers: [],
    pitchPlayers: [],
    retiredPlayers: [],
    lineup: [],
  });
}

export function setTeamId(teamId: LineupStore['teamId']) {
  useLineupStore.setState({ teamId }, false, 'setTeamId');
}

export function setLineup(lineup: LineupStore['lineup']) {
  return useLineupStore.setState({ lineup }, false, 'setLineup');
}

export const onLineupChange = useLineupStore.subscribe((state, prevState) => {
  if (state.lineup === prevState.lineup) return;

  const { benchPlayers, offPlayers, pitchPlayers, retiredPlayers } =
    decomposeLineup(state.lineup);

  return useLineupStore.setState({
    benchPlayers,
    offPlayers,
    pitchPlayers,
    retiredPlayers,
  });
});

export const onPitchPlayersChange = useLineupStore.subscribe(
  (newState, prevState) => {
    if (prevState.pitchPlayers === newState.pitchPlayers) return;
    const { pitchPlayers } = newState;
    setPlayerInputMap(
      pitchPlayers.length
        ? makePlayerQuickInputMap(pitchPlayers.map(({ player }) => player))
        : null,
    );
  },
);
