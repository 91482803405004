import { FC, useMemo } from 'react';
import { Stack } from '@mui/material';
import { RadioButtons } from '@/components/Form/RadioButtons';
import {
  ATTEMPT_BY_LABEL,
  BODY_PARTS_LABEL,
  EXTRAS_LABEL,
  GOAL_TYPE_LABEL,
  PATTERN_OF_PLAY_LABEL,
} from '@/constants';
import { CheckboxGroup } from '@/components/Form/CheckboxGroup';
import { GoalAction } from '@/types/action/action';
import {
  ACTION_TYPE_ID,
  AttemptType,
  BodyPart,
  Extras,
  PatternOfPlay,
  GoalType,
} from '@contract';
import { getLatestActionVersion } from '@/stores/utils';
import {
  GOAL_TYPE,
  getGoalExtras,
} from '@/components/Dialogs/GoalDialogContent/constants';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { ParentAction } from '@/components/ParentAction/ParentAction';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { Goal } from '../Common/Goal/Goal';
import { ShotPitch } from '../Common/ShotPitch';
import { BODY_PART, PATTERN_OF_PLAY } from '../constants';
import { AssistField } from '../Common/AssistField';
import { ActionPlayerField } from '../Common/ActionPlayerField';
import { Controls } from '../Common/Controls';

interface GoalDialogContentProps {
  action: GoalAction;
}

const onGoalTypeChange = (goalType: GoalType) => {
  setActionPartialMetadata<GoalAction>({ goalType, extras: null });
};

const onBodyPartChange = (bodyPart: BodyPart) => {
  setActionPartialMetadata<GoalAction>({ bodyPart });
};

const onExtrasChange = (extras: Extras[]) => {
  setActionPartialMetadata<GoalAction>({ extras });
};

const onAssistPlayerChange = (
  assistPlayer: ActionMeta<GoalAction>['assistPlayer'],
) => {
  setActionPartialMetadata<GoalAction>({ assistPlayer });
};

export const GoalDialogContent: FC<GoalDialogContentProps> = ({ action }) => {
  const parentAction = action.parentActionId
    ? getLatestActionVersion(action.parentActionId)
    : null;

  const shotAction =
    parentAction && parentAction.actionTypeId === ACTION_TYPE_ID.Shot
      ? parentAction
      : null;
  const isOwnGoal = action.metadata.goal.goalType === GoalType.OwnGoal;
  const isGoal = action.metadata.goal.goalType === GoalType.Goal;
  const patternOfPlay = action.metadata.goal.patternOfPlay;
  const extrasFields = useMemo(
    () => getGoalExtras(patternOfPlay),
    [patternOfPlay],
  );

  const onPatternOfPlayChange = (patternOfPlay: PatternOfPlay) => {
    const extras = action.metadata.goal.extras || [];

    setActionPartialMetadata<GoalAction>({ patternOfPlay });

    if (
      patternOfPlay === PatternOfPlay.Penalty &&
      !extras.includes(Extras.BigChance)
    ) {
      setActionPartialMetadata<GoalAction>({
        extras: [...extras, Extras.BigChance],
      });
    }
  };

  return (
    <Stack direction='row' gap={2} flex={1} width='100%'>
      <Stack flex={3} gap={2}>
        <RadioButtons
          name='goalType'
          controlLabel={GOAL_TYPE_LABEL}
          fields={GOAL_TYPE}
          value={action.metadata.goal.goalType}
          onChange={onGoalTypeChange}
        />
        <Goal
          zoneNumber={String(action.metadata.goal.zoneNumber)}
          setPartialMetadata={setActionPartialMetadata}
          offTargetDisabled
          goalColor={
            action.metadata.goal.goalType === GoalType.Goal ? 'blue' : 'red'
          }
        />
        <ShotPitch
          position={action.metadata.goal.position}
          blockersPositions={
            isGoal ? action.metadata.goal.blockersPositions : null
          }
          attemptType={isGoal ? AttemptType.OnTarget : AttemptType.TemptAttempt}
          setPartialMetadata={setActionPartialMetadata}
        />
      </Stack>
      <Stack flex={2} gap={2}>
        <ActionPlayerField
          playerAutoCompleteProps={{ label: ATTEMPT_BY_LABEL }}
          action={action}
        />
        <PeriodClockField action={action} />
        <ParentAction action={action} />
        <AssistField
          action={shotAction || action}
          onChange={onAssistPlayerChange}
          player={action.player}
          assistPlayer={isOwnGoal ? null : action.metadata.goal.assistPlayer}
        />
        <RadioButtons
          name='patternOfPlay'
          onChange={onPatternOfPlayChange}
          controlLabel={PATTERN_OF_PLAY_LABEL}
          fields={PATTERN_OF_PLAY}
          value={action.metadata.goal.patternOfPlay}
        />
        <RadioButtons
          name='bodyPart'
          onChange={onBodyPartChange}
          controlLabel={BODY_PARTS_LABEL}
          fields={BODY_PART}
          value={action.metadata.goal.bodyPart}
        />
        <CheckboxGroup
          controlLabel={EXTRAS_LABEL}
          fields={extrasFields}
          onChange={onExtrasChange}
          values={action.metadata.goal.extras || []}
          disabled={isOwnGoal}
        />
        <Controls action={action} />
      </Stack>
    </Stack>
  );
};
