import { Action } from '@/types/action/action';
import {
  ACTION_TYPE_ID,
  Extras,
  METADATA_KEY,
  type PeriodDto,
} from '@contract';
import {
  FILTER_NAME,
  FilterName,
  FilterValue,
  type PlayerType,
  type TeamFilter,
  CommonOptions,
} from '@/stores/FiltersStore/constants';
import { type ActionState } from '@/stores/constants';
import {
  OUTCOME_TYPE,
  type OutcomeType,
} from '@/components/ActionOutcome/constants';
import { getActionMetadata, getNoFlagWarnings, hasFlagToQa } from './actions';

const makePlayersFilter = (players: PlayerType) => {
  const { option, playersSelected } = players;
  if (option === CommonOptions.All) return null;
  if (option === CommonOptions.None) {
    return (action: Action) => !action.player;
  }
  if (playersSelected.length === 0) return null;

  return (action: Action) =>
    playersSelected.some((player) => action.player?.id === player.id);
};

const makeActionTypeFilter = (events: ACTION_TYPE_ID[]) => {
  if (events.length === 0) return null;
  return (action: Action) =>
    events.some((event) => action.actionTypeId === event);
};

const makeOutcomeFilter = (outcome: OutcomeType | null) => {
  if (outcome === null) return null;
  if (outcome === OUTCOME_TYPE.NONE) {
    return (action: Action) => action.isSuccessful === null;
  }
  if (outcome === OUTCOME_TYPE.SUCCESSFUL) {
    return (action: Action) => action.isSuccessful === true;
  }
  return (action: Action) => action.isSuccessful === false;
};

const makeFlagFilter = (withFlag: boolean | null) => {
  if (withFlag === null) return null;
  return (action: Action) =>
    withFlag ? hasFlagToQa(action) : !hasFlagToQa(action);
};

const makeWarningFilter = (withWarnings: boolean | null) => {
  if (withWarnings === null) return null;

  return (action: Action) => {
    const noFlagWarnings = getNoFlagWarnings(action);
    const hasNoFlagWarnings = !!noFlagWarnings && noFlagWarnings.length > 0;

    if (withWarnings) return hasNoFlagWarnings;

    return !hasNoFlagWarnings;
  };
};

const makeExtrasFilter = (extras: Extras[]) => {
  if (extras.length === 0) return null;

  return (action: Action) => {
    if (
      !action.metadata ||
      !action.actionTypeMetadata ||
      action.actionTypeMetadata === METADATA_KEY.goalkeeperSave
    )
      return false;
    const metadata = getActionMetadata(action);
    const extrasMetadata = 'extras' in metadata && metadata.extras;

    if (!extrasMetadata) return false;

    return extras.some((extra) => {
      return extrasMetadata.includes(extra);
    });
  };
};

const makePeriodFilter = (period: PeriodDto[]) => {
  if (period.length === 0) return null;
  return (action: Action) =>
    period.some((p) => action.period?.sequence === p.sequence);
};

const makeTeamFilter = (team: TeamFilter | null) => {
  if (team === null) return null;
  if (team === CommonOptions.None) return (action: Action) => !action.team;

  return (action: Action) => action.team?.id === team;
};

const makeStatusFilter = (status: ActionState | null) => {
  if (status === null) return null;
  return (action: Action) => action.state === status;
};

type ActionFilterFn = (action: Action) => boolean;

function getFilterFn(
  filterName: FilterName,
  filterValues: FilterValue,
): ActionFilterFn | null {
  switch (filterName) {
    case FILTER_NAME.PLAYERS:
      return makePlayersFilter(filterValues[filterName]);
    case FILTER_NAME.EVENTS:
      return makeActionTypeFilter(filterValues[filterName]);
    case FILTER_NAME.EXTRAS:
      return makeExtrasFilter(filterValues[filterName]);
    case FILTER_NAME.OUTCOME:
      return makeOutcomeFilter(filterValues[filterName]);
    case FILTER_NAME.WARNINGS:
      return makeWarningFilter(filterValues[filterName]);
    case FILTER_NAME.FLAGS:
      return makeFlagFilter(filterValues[filterName]);
    case FILTER_NAME.PERIOD:
      return makePeriodFilter(filterValues[filterName]);
    case FILTER_NAME.STATUS:
      return makeStatusFilter(filterValues[filterName]);
    case FILTER_NAME.TEAM:
      return makeTeamFilter(filterValues[filterName]);
    default:
      return null;
  }
}

export function makeFilters(filterValues: FilterValue) {
  const filters = Object.keys(filterValues).reduce<ActionFilterFn[]>(
    (acc, key) => {
      const filterName = key as keyof typeof filterValues;
      const filterFn = getFilterFn(filterName, filterValues);
      if (filterFn !== null) acc.push(filterFn);
      return acc;
    },
    [],
  );
  return filters;
}
