import { FC, useMemo } from 'react';
import { Stack } from '@mui/material';
import { ActionPlayerField } from '@/components/Dialogs/Common/ActionPlayerField';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { Autocomplete } from '@/components/Form/Autocomplete';
import { RadioButtons } from '@/components/Form/RadioButtons';
import { CARD_LABEL, PLAYER_CARDED_LABEL, REASON_LABEL } from '@/constants';
import {
  CARD_OPTIONS,
  RED_CARD_OPTIONS,
  YELLOW_CARD_OPTIONS,
} from '@/service/cards';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { PLAYERS_LIST_VARIANT } from '@/stores/LineupStore/utils';
import { type RedCardAction } from '@/types/action/action';
import { sortExceptFirst } from '@/utils/array';
import { CardReason, RedCardType } from '@contract';
import { getActionMetadata } from '@/utils/actions';
import { ParentAction } from '@/components/ParentAction/ParentAction';
import { FlagField } from '../Common/FlagField';
import { Controls } from '../Common/Controls';

interface RedCardDialogContentProps {
  action: RedCardAction;
}

const onCardChange = (outcome: RedCardType) => {
  setActionPartialMetadata<RedCardAction>({
    outcome,
  });
  setActionPartialMetadata<RedCardAction>({
    reason:
      outcome === RedCardType.SecondYellow
        ? CardReason.Foul
        : CardReason.OtherReason,
  });
};

const onReasonChange = (reason: CardReason) => {
  setActionPartialMetadata<RedCardAction>({
    reason,
  });
};

export const RedCardDialogContent: FC<RedCardDialogContentProps> = ({
  action,
}) => {
  const metadata = getActionMetadata(action);

  const CARD_REASON_OPTIONS = useMemo(() => {
    return metadata.outcome === RedCardType.SecondYellow
      ? YELLOW_CARD_OPTIONS
      : RED_CARD_OPTIONS;
  }, [metadata.outcome]);

  return (
    <Stack gap={2}>
      <RadioButtons
        name={CARD_LABEL}
        onChange={onCardChange}
        value={metadata.outcome}
        controlLabel={CARD_LABEL}
        fields={CARD_OPTIONS}
      />
      <ActionPlayerField
        action={action}
        playerAutoCompleteProps={{
          label: PLAYER_CARDED_LABEL,
          playersListVariant: PLAYERS_LIST_VARIANT.IN_GAME,
        }}
      />
      <PeriodClockField action={action} />
      <ParentAction action={action} />
      <Autocomplete
        options={sortExceptFirst(CARD_REASON_OPTIONS, (a, b) =>
          a.label.localeCompare(b.label),
        )}
        value={metadata.reason}
        onChange={onReasonChange}
        label={REASON_LABEL}
      />
      <FlagField action={action} />
      <Controls action={action} />
    </Stack>
  );
};
