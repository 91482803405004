/* eslint-disable @typescript-eslint/no-explicit-any */
/* c8 ignore start */
export const debounce = <Fn extends (...args: any[]) => any>(
  fn: Fn,
  waitFor: number,
) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  function debouncedFn(...args: Parameters<Fn>) {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => fn(...args), waitFor);
  }
  return debouncedFn;
};
/* c8 ignore stop */
