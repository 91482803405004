import { GoalkeeperPenaltyFacedOutcome, Stance } from '@contract';

export const GOALKEEPER_PENALTY_FACED_OUTCOME = {
  [GoalkeeperPenaltyFacedOutcome.Scored]: 'Scored',
  [GoalkeeperPenaltyFacedOutcome.Saved]: 'Saved',
  [GoalkeeperPenaltyFacedOutcome.Missed]: 'Missed',
  [GoalkeeperPenaltyFacedOutcome.Passed]: 'Passed',
};

export const GOALKEEPER_PENALTY_FACED_STANCE = {
  [Stance.DivedLeft]: 'Dived Left',
  [Stance.DivedRight]: 'Dived Right',
  [Stance.Standing]: 'Standing',
};
