import { Box, css, styled, type BoxProps } from '@mui/material';
import { CSSProperties, FC } from 'react';
import type { Coords } from '@/types';
import { Dot } from '@/components/Dot/Dot';
import { Player } from '@/service/lineups';
import { makeTablePlayerName } from '@/stores/utils';
import { POSITION_ATTRIBUTE } from './constants';

type CoordsDotProps = BoxProps & {
  coords: Coords;
  playerNumber?: string | null;
  color?: string;
  positionId?: string;
  player?: Player | null;
};

const DotContainer = styled(Box)`
  --left: 0;
  --top: 0;
  position: absolute;
  top: var(--top);
  left: var(--left);
  translate: -50% -50%;
  pointer-events: none;
  z-index: 1;
  overflow: visible;
`;

const DotOverline = styled('span')`
  position: absolute;
  bottom: 101%;
  left: 50%;
  translate: -50% 0;
  min-width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  background-color: var(--pitchColor, black);
  display: flex;
  align-items: center;
`;

const PlayerNumber = styled('span')(
  ({ theme }) => css`
    padding-inline: ${theme.spacing(0.5)};
  `,
);

export const CoordsDot: FC<CoordsDotProps> = (props) => {
  const {
    coords,
    playerNumber = null,
    player = null,
    color,
    positionId,
    ...boxProps
  } = props;
  const overline = playerNumber ? playerNumber : makeTablePlayerName(player);

  return (
    <DotContainer
      {...boxProps}
      style={
        {
          '--left': coords.x + '%',
          '--top': coords.y + '%',
          '--targetsColor': color,
        } as CSSProperties
      }
    >
      <Dot {...{ [POSITION_ATTRIBUTE]: positionId }} />
      <DotOverline>
        {overline && <PlayerNumber>{overline}</PlayerNumber>}
        {boxProps.children}
      </DotOverline>
    </DotContainer>
  );
};
