import { ShotOffTargetType } from '@contract';
import { NULL_STRING, NullString } from '@/components/Dialogs/Common/constants';
import { NONE_LABEL } from '@/constants';

export const OFF_TARGET_DESTINATION: Record<
  ShotOffTargetType | NullString,
  string
> = {
  [ShotOffTargetType.PastGoalLine]: 'Past goal line',
  [ShotOffTargetType.NotPastGoalLine]: 'Not past goal line',
  [ShotOffTargetType.OutSideline]: 'Out sideline',
  [NULL_STRING]: NONE_LABEL,
};
