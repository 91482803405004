import { IconButton, Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { AttemptType } from '@contract';
import { DEFENDERS_VALUES, PITCH_PLAYERS_VALUES } from '../Dialogs/constants';
import { Dot } from '../Dot/Dot';
interface PitchControlsProps {
  defendersCount: number;
  onDefendersCountChange: (count: number) => void;
  attemptType: AttemptType;
}

export const TEST_ID = {
  DEFENDERS_CONTROLS: 'defenders-controls',
  DEFENDERS_DECREASE: 'defenders-decrease',
  DEFENDERS_INCREASE: 'defenders-increase',
} as const;

const Item: FC<PropsWithChildren<{ color?: string; label: string }>> = ({
  color,
  label,
  children,
}) => {
  return (
    <>
      <Dot sx={{ width: '20px', height: '20px' }} color={color}></Dot>
      <Typography variant='subtitle1'>{label}</Typography>
      {children}
    </>
  );
};

export const PitchControls = ({
  defendersCount,
  attemptType,
  onDefendersCountChange,
}: PitchControlsProps) => {
  return (
    <Stack
      direction='row'
      gap={1.5}
      id='pitch-controls'
      sx={{
        alignItems: 'center',
        height: '40px',
        width: '100%',
        p: 1,
      }}
    >
      <Item
        color={PITCH_PLAYERS_VALUES.SHOT.COLOR}
        label={PITCH_PLAYERS_VALUES.SHOT.LABEL}
      />
      {attemptType !== AttemptType.TemptAttempt && (
        <>
          <Item
            color={PITCH_PLAYERS_VALUES.GOALKEEPER.COLOR}
            label={
              attemptType === AttemptType.Blocked
                ? PITCH_PLAYERS_VALUES.DEFENDER.LABEL
                : PITCH_PLAYERS_VALUES.GOALKEEPER.LABEL
            }
          />
          <Item
            color={PITCH_PLAYERS_VALUES.DEFENDER.COLOR}
            label={PITCH_PLAYERS_VALUES.DEFENDER.LABEL}
          >
            <Stack
              data-testid={TEST_ID.DEFENDERS_CONTROLS}
              direction='row'
              gap={1}
              sx={{
                alignItems: 'center',
                p: 1,
              }}
            >
              <IconButton
                data-testid={TEST_ID.DEFENDERS_DECREASE}
                onClick={() => onDefendersCountChange(defendersCount - 1)}
                disabled={defendersCount === DEFENDERS_VALUES.MIN_COUNT}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
              <Typography variant='subtitle1'>{defendersCount}</Typography>
              <IconButton
                data-testid={TEST_ID.DEFENDERS_INCREASE}
                onClick={() => onDefendersCountChange(defendersCount + 1)}
                disabled={defendersCount === DEFENDERS_VALUES.MAX_COUNT}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Stack>
          </Item>
        </>
      )}
    </Stack>
  );
};
