export const CLOCK_BUTTON_ID = 'clock';

export const CLOCK_CONTROL_ID = {
  DECREASE: 'clock-decrease',
  INCREASE: 'clock-increase',
  EDIT: 'clock-edit',
  PAUSE: 'clock-pause',
  START: 'clock-start',
};

export const MIN_MINUTES_VALUE = 0;
export const MAX_MINUTES_VALUE = 999;
export const MIN_SECONDS_VALUE = 0;
export const MAX_SECONDS_VALUE = 59;
