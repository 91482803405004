import { MouseEvent } from 'react';
import { useActionStore } from '@/stores/ActionStore/ActionStore';
import { Dialog } from '@/components/Dialog/Dialog';
import { ACTION_TYPE } from '@/types/action/actionType';
import { getActionMetadata } from '@/utils/actions';
import { onDialogClose } from '@/components/Dialogs/utils';
import { ActionDialogContent } from './ActionDialogContent';

export const ActionDialog = () => {
  const dialogAction = useActionStore((state) => state.dialogAction);
  const action = useActionStore((state) => state.action);

  const onRightClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onDialogClose();
  };

  if (!dialogAction) return;
  if (!action) return;

  if (action.actionId !== dialogAction.actionId) return;

  const actionName =
    ACTION_TYPE[action.actionTypeId] || action.actionTypeMetadata;
  const metadata = getActionMetadata(action);
  const isBigDialog =
    ('position' in metadata && !!metadata.position) ||
    ('pitchPlayers' in metadata && !!metadata.pitchPlayers);

  return (
    <Dialog
      isOpen
      fullWidth={isBigDialog}
      maxWidth='xl'
      title={actionName}
      onRightClick={onRightClick}
    >
      <ActionDialogContent action={action} />
    </Dialog>
  );
};
