export const KICK_OFF_LABEL = 'KICK OFF';
export const END_PERIOD_LABEL = 'END PERIOD';
export const MATCH_DETAILS_LABEL = 'MATCH DETAILS';
export const END_MATCH_LABEL = 'END MATCH';
export const SELECT_PERIOD_LABEL = 'Select Period';
export const DIRECTION_OF_PLAY_LABEL = 'Direction Of Play';
export const TOGGLE_GAME_DETAILS_LABEL = 'Game details';

export enum DIRECTION_OF_PLAY {
  RIGHT_TO_LEFT = 0,
  LEFT_TO_RIGHT = 1,
}

export const DIRECTION_OF_PLAY_OPTIONS = {
  [DIRECTION_OF_PLAY.LEFT_TO_RIGHT]: 'Left to Right',
  [DIRECTION_OF_PLAY.RIGHT_TO_LEFT]: 'Right to Left',
};

export const directionOfPlayOptions = Object.keys(
  DIRECTION_OF_PLAY_OPTIONS,
).map((k) => {
  const key = Number(k) as DIRECTION_OF_PLAY;
  return {
    label: DIRECTION_OF_PLAY_OPTIONS[key],
    value: key,
  };
});
