import { FC, useRef, useState } from 'react';
import ArrowRight from '@mui/icons-material/East';
import ArrowLeft from '@mui/icons-material/West';
import { Button, ClickAwayListener, Paper, Popper } from '@mui/material';
import { useFixtureStore } from '@/stores/FixtureStore';
import { DataCollectionPhase } from '@contract';
import { FIXTURE_PHASE_LABEL } from '@/stores/constants';
import { EditPeriod } from './EditPeriod';
import { DIRECTION_OF_PLAY, TOGGLE_GAME_DETAILS_LABEL } from './constants';

export const Periods: FC = () => {
  const gameState = useFixtureStore((state) => state.gameState);
  const directionOfPlay = useFixtureStore((state) => state.directionOfPlay);
  const [isMatchDetailOpen, setIsMatchDetailsOpen] = useState(false);
  const toggleMatchDetailsOpen = () =>
    setIsMatchDetailsOpen((current) => !current);
  const closeMatchDetails = () => setIsMatchDetailsOpen(false);
  const $popperAnchor = useRef(null);

  const isKickOffDisabled = gameState
    ? gameState.phase === DataCollectionPhase.PostGame
    : false;

  const gameStateLabel =
    FIXTURE_PHASE_LABEL[
      gameState ? gameState.phase : DataCollectionPhase.Unknown
    ];

  const DirectionArrow =
    directionOfPlay === DIRECTION_OF_PLAY.LEFT_TO_RIGHT
      ? ArrowRight
      : ArrowLeft;

  return (
    <>
      <Button
        aria-label={TOGGLE_GAME_DETAILS_LABEL}
        ref={$popperAnchor}
        onClick={toggleMatchDetailsOpen}
        variant='text'
        color='inherit'
        size='large'
        disabled={isKickOffDisabled}
        endIcon={<DirectionArrow />}
      >
        {gameStateLabel}
      </Button>

      <Popper anchorEl={$popperAnchor.current} open={isMatchDetailOpen}>
        <ClickAwayListener onClickAway={closeMatchDetails}>
          <Paper elevation={6}>
            <EditPeriod onClose={closeMatchDetails} />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
