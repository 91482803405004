import { Button, Paper, Stack, Typography } from '@mui/material';
import { FC, type PropsWithChildren } from 'react';

type ErrorRetryProps = PropsWithChildren & {
  message: string;
  onRetry?: VoidFunction;
  retryText?: string;
};

export const Error: FC<ErrorRetryProps> = ({
  message,
  onRetry,
  retryText = 'Retry',
}) => {
  return (
    <Paper component={Stack} elevation={5} p={3} textAlign='center' gap={2}>
      <Typography variant='h3'>Error</Typography>
      <Typography>{message}</Typography>
      {onRetry && (
        <Button color='primary' variant='contained' onClick={onRetry}>
          {retryText}
        </Button>
      )}
    </Paper>
  );
};
