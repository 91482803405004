import Timer from '@mui/icons-material/Timer';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import TimerOff from '@mui/icons-material/TimerOff';
import { useClockStore } from '@/stores/ClockStore';
import { CLOCK_BUTTON_ID } from '@/components/Clock/constants';
import { useCurrentPeriodLabel } from '@/stores/hooks';
import { ClockTime } from './ClockTime';
import { ClockControls } from './ClockControls';

export const CURRENT_CLOCK_TEST_ID = 'current-clock-time';

export const Clock: FC = () => {
  const isRunning = useClockStore((state) => state.isRunning);
  const [controlsVisible, setControlsVisible] = useState(true);
  const toggleControls = () => setControlsVisible((current) => !current);

  return (
    <>
      <Button
        id={CLOCK_BUTTON_ID}
        variant='outlined'
        color='inherit'
        startIcon={isRunning ? <Timer /> : <TimerOff />}
        sx={{ fontSize: '1.3rem' }}
        onClick={toggleControls}
        data-testid={CURRENT_CLOCK_TEST_ID}
      >
        <ClockTime />
      </Button>
      {controlsVisible && <ClockControls />}
    </>
  );
};

export const ClockDisplay: FC = () => {
  const currentPeriodLabel = useCurrentPeriodLabel();

  return (
    <Stack direction={'row'} gap={1}>
      <Typography>{currentPeriodLabel}</Typography>
      <Divider orientation='vertical' flexItem />
      <Typography data-testid={CURRENT_CLOCK_TEST_ID}>
        <ClockTime />
      </Typography>
    </Stack>
  );
};
