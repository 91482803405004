import { FC } from 'react';
import { styled } from '@mui/material';
import { FixtureAction } from '@contract';

type ActionWarningTooltipProps = {
  warnings: NonNullable<FixtureAction['warnings']>;
};
const WarningList = styled('ul')`
  padding-inline-start: 1.5em;
`;
export const ActionWarningTooltip: FC<ActionWarningTooltipProps> = ({
  warnings,
}) => {
  return (
    <WarningList>
      {warnings.map((warning) => (
        <li key={warning.type}>{warning.message}</li>
      ))}
    </WarningList>
  );
};
