import { type FC } from 'react';
import { useFixtureStore } from '@/stores/FixtureStore';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';
import { bottomUpGrid } from '@/components/LineupEditor/PitchGrid';
import { PlayersGrid } from '../PlayersGrid';
import { PITCH_GRID_ID, PitchOrientation, gridAreas } from '../constants';
import { PitchItem } from './PitchItem';

type PitchGridProps = {
  pitchOrientation?: PitchOrientation;
};

export const PitchGrid: FC<PitchGridProps> = ({
  pitchOrientation = PitchOrientation.Horizontal,
}) => {
  const pitchPlayers = useLineupStore((state) => state.pitchPlayers);
  const directionOfPlay = useFixtureStore((state) => state.directionOfPlay);
  const pitchPlayersMap = pitchPlayers.reduce((acc, player) => {
    acc.set(player.position, player.player);
    return acc;
  }, new Map());
  const pitchPlayersEntries = [...pitchPlayersMap.entries()];
  const isPitchHorizontal = pitchOrientation === PitchOrientation.Horizontal;

  return (
    <PlayersGrid
      id={PITCH_GRID_ID}
      style={{
        gridTemplateAreas: isPitchHorizontal
          ? gridAreas[directionOfPlay]
          : bottomUpGrid,
        gridTemplateColumns: `repeat(${isPitchHorizontal ? 7 : 5}, 1fr)`,
        gridTemplateRows: `repeat(${isPitchHorizontal ? 5 : 7}, 1fr)`,
      }}
    >
      {pitchPlayersEntries.map(([position, player]) => (
        <PitchItem key={position} position={position} player={player} />
      ))}
    </PlayersGrid>
  );
};
