import { DIRECTION_OF_PLAY } from '../Periods/constants';

export const rightToLeftGrid = `
  '. . RW RM RWB RB .'
  'RCF . RAM RCM RDM RCB .'
  'ST SS CAM CM CDM CB GK'
  'LCF . LAM LCM LDM LCB .'
  '. . LW LM LWB LB .'`;

export const leftToRightGrid = `
  '. LB LWB LM LW . .'
  '. LCB LDM LCM LAM . LCF'
  'GK CB CDM CM CAM SS ST'
  '. RCB RDM RCM RAM . RCF'
  '. RB RWB RM RW . .'
`;

export const gridAreas = {
  [DIRECTION_OF_PLAY.RIGHT_TO_LEFT]: rightToLeftGrid,
  [DIRECTION_OF_PLAY.LEFT_TO_RIGHT]: leftToRightGrid,
};

export const PITCH_LINEUP_ID = 'pitch-lineup';
export const PITCH_FORMATION_ID = 'pitch-formation';
export const PITCH_GRID_ID = 'pitch-grid';

export enum PitchOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}
