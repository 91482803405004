import { colors } from '@mui/material';
import { CollectionStatus, FixtureStatus, UserRole } from '@contract';

export const NO_FIXTURES_LABEL = 'No fixtures collection added yet.';

export const TEAM_SIDE = {
  HOME: 'Home',
  AWAY: 'Away',
} as const;

export type TeamSide = ConstType<typeof TEAM_SIDE>;

export const FIXTURES_COLLECTIONS_TYPE = {
  UPCOMING: 'upcoming',
  PREVIOUS: 'previous',
} as const;

export type FixturesCollectionsType = ConstType<
  typeof FIXTURES_COLLECTIONS_TYPE
>;

export const FixturesCollectionsTableTitle = {
  [FIXTURES_COLLECTIONS_TYPE.PREVIOUS]: 'My previous fixtures collections',
  [FIXTURES_COLLECTIONS_TYPE.UPCOMING]: 'My upcoming fixtures collections',
} as const;

export type Fixture = {
  id: string;
  date: string;
  time: string;
  homeTeam: string;
  awayTeam: string;
  roles: string;
  sport: string;
  competition: string;
  dataStatus: CollectionStatus;
  fixtureStatus: FixtureStatus;
};

type ColumnId = keyof Fixture;

type FixturesCollectionsTableColumn = {
  id: ColumnId;
  name: JSX.Element;
};

export const FixtureCollectionStatusLabel = {
  [FixtureStatus.Abandoned]: 'Abandoned',
  [FixtureStatus.Cancelled]: 'Cancelled',
  [FixtureStatus.Completed]: 'Completed',
  [FixtureStatus.Live]: 'Live',
  [FixtureStatus.Postponed]: 'Postponed',
  [FixtureStatus.Scheduled]: 'Scheduled',
  [FixtureStatus.Suspended]: 'Suspended',
  [FixtureStatus.Unverified]: 'Unverified',
} as const;

export const FixtureCollectionDataStatusLabel = {
  [CollectionStatus.CollectingLive]: 'In progress',
  [CollectionStatus.CollectingPostMatch]: 'Post match',
  [CollectionStatus.CollectionCompleted]: 'Completed',
  [CollectionStatus.CollectionDropped]: 'Dropped',
  [CollectionStatus.CollectionScheduled]: 'Scheduled',
  [CollectionStatus.CollectionSuspended]: 'Suspended',
  [CollectionStatus.PostMatchChecked]: 'Post match checked',
} as const;

export const UserRoleLabel = {
  [UserRole.Qa]: 'QA',
  [UserRole.HomeScorer]: 'Home Scorer',
  [UserRole.AwayScorer]: 'Away Scorer',
  [UserRole.Supervisor]: 'Supervisor',
} as const;

export const FixtureCollectionStatusColor = {
  [FixtureStatus.Abandoned]: colors.blueGrey[300],
  [FixtureStatus.Cancelled]: colors.blueGrey[300],
  [FixtureStatus.Completed]: colors.green[300],
  [FixtureStatus.Live]: colors.red[300],
  [FixtureStatus.Postponed]: colors.amber[300],
  [FixtureStatus.Scheduled]: colors.amber[300],
  [FixtureStatus.Suspended]: colors.blueGrey[300],
  [FixtureStatus.Unverified]: colors.amber[300],
} as const;

export const FixtureCollectionDataStatusColor = {
  [CollectionStatus.CollectingLive]: colors.red[300],
  [CollectionStatus.CollectingPostMatch]: colors.amber[300],
  [CollectionStatus.CollectionCompleted]: colors.green[300],
  [CollectionStatus.CollectionDropped]: colors.blueGrey[300],
  [CollectionStatus.CollectionScheduled]: colors.amber[300],
  [CollectionStatus.CollectionSuspended]: colors.blueGrey[300],
  [CollectionStatus.PostMatchChecked]: colors.green[300],
} as const;

export const FIXTURES_COLLECTIONS_COLUMNS: FixturesCollectionsTableColumn[] = [
  { id: 'date', name: <span>Date</span> },
  { id: 'time', name: <span>Time</span> },
  { id: 'homeTeam', name: <span>Home Team</span> },
  { id: 'awayTeam', name: <span>Away Team</span> },
  { id: 'roles', name: <span>Roles</span> },
  { id: 'sport', name: <span>Sport</span> },
  { id: 'competition', name: <span>Competition</span> },
  { id: 'dataStatus', name: <span>Data Status</span> },
  { id: 'fixtureStatus', name: <span>Fixture Status</span> },
] as const;
