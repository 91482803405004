export function downloadJSON(content: object, name: string) {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(content, null, 2),
  )}`;
  const link = document.createElement('a');

  link.href = jsonString;
  link.download = `${name}.json`;
  link.click();
}
