import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import DirectionsRun from '@mui/icons-material/DirectionsRun';
import Bolt from '@mui/icons-material/Bolt';
import TimerOutlined from '@mui/icons-material/TimerOutlined';
import Delete from '@mui/icons-material/Delete';
import { Action } from '@/types/action/action';
import { ACTION_TYPE } from '@/types/action/actionType';
import { PlayerName } from '@/components/PlayerName/PlayerName';
import { OutcomeIcon } from '../ActionOutcome/OutcomeIcon';

type ActionChipProps = {
  action: Action;
};
const ChipPart: FC<{ icon?: ReactNode; content?: ReactNode }> = ({
  icon,
  content,
}) => {
  return (
    <Stack direction='row' alignItems={'center'} gap={0.5} mr={0.5}>
      {icon}
      {content && (
        <Typography component='span' lineHeight={1} variant='body2'>
          {content}
        </Typography>
      )}
    </Stack>
  );
};
export const ActionChip: FC<ActionChipProps> = ({ action }) => {
  let actionTime = action.clockTime;
  if (action.period) {
    actionTime = `${action.period.sequence} - ${action.clockTime}`;
  }

  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: '999em',
      }}
    >
      <Box display={'flex'} gap={1} p={1}>
        <ChipPart icon={<OutcomeIcon outcome={action.isSuccessful} />} />
        <Divider orientation='vertical' flexItem />
        <ChipPart icon={<TimerOutlined />} content={actionTime} />
        <Divider orientation='vertical' flexItem />
        <ChipPart icon={<Bolt />} content={ACTION_TYPE[action.actionTypeId]} />
        {action.player && (
          <>
            <Divider orientation='vertical' flexItem />
            <ChipPart
              icon={<DirectionsRun />}
              content={<PlayerName player={action.player} />}
            />
          </>
        )}
        {action.isRemoved && (
          <>
            <Divider orientation='vertical' flexItem />
            <ChipPart icon={<Delete />} />
          </>
        )}
      </Box>
    </Paper>
  );
};
