import { FC } from 'react';
import { Typography, type TypographyProps } from '@mui/material';
import { useFixtureStore } from '@/stores/FixtureStore';

type SubstitutionTimeProps = TypographyProps & {
  clockTimeTicks: number;
  periodSeq: number;
};

export const SubstitutionTime: FC<SubstitutionTimeProps> = ({
  clockTimeTicks,
  periodSeq,
  ...typographyProps
}) => {
  const periodsConfig = useFixtureStore((state) => state.periodsConfig);
  const periodConfig = periodsConfig.find((cfg) => cfg.seq === periodSeq);
  const maxTime = periodConfig
    ? periodConfig.disableEndingPeriodLimit
    : undefined;

  let time = '';
  if (typeof maxTime === 'number' && clockTimeTicks >= maxTime) {
    const diff = clockTimeTicks - maxTime;
    time = `${Math.floor(maxTime / 60)}+${Math.floor(diff / 60) + 1}`;
  } else {
    time = `${Math.floor(clockTimeTicks / 60) + 1}`;
  }
  time += "'";
  return <Typography {...typographyProps}>{time}</Typography>;
};
