import { Stack, Theme } from '@mui/material';
import { FC, useCallback } from 'react';
import type { Player } from '@/service/lineups';
import type { Position } from '@contract';
import { POSITION_NAME } from '@/stores/LineupStore/constants';
import { LineupPlayerLabel, LineupPlayerShirt } from '../Formation/Player';
import { useStartingLineupsContext } from './StartingLineupContext';

type PitchItemProps = {
  position: Position;
  player: Player | null;
};

function getTransition(theme: Theme) {
  return theme.transitions.create(['transform', 'background-color'], {
    duration: theme.transitions.duration.shortest,
    easing: theme.transitions.easing.easeOut,
  });
}
export const PitchItem: FC<PitchItemProps> = ({ position, player }) => {
  const startingLineupsStore = useStartingLineupsContext();
  const selectedEntry = startingLineupsStore.useStore(
    (state) => state.selectedEntry,
  );

  const isSelected = !!selectedEntry && selectedEntry[0] === position;
  const positionName = POSITION_NAME[position];

  const onClick = useCallback(() => {
    startingLineupsStore.selectEntry(position, player);
  }, [position, player, startingLineupsStore]);

  const getClassName = () => {
    if (isSelected) {
      return 'active';
    }
    if (player) {
      return 'active-partial';
    }
  };

  return (
    <Stack
      style={{ gridArea: positionName }}
      sx={{
        color: 'white',
        position: 'relative',
        justifySelf: 'stretch',
        alignSelf: 'center',
        alignItems: 'center',
        fontSize: '1.1rem',
      }}
      className={getClassName()}
    >
      <LineupPlayerShirt
        sx={{
          width: '3em',
          fontSize: '1.2em',
          fontWeight: '900',
          cursor: 'pointer',
          transition: getTransition,
        }}
        onClick={onClick}
      >
        {player ? player.shirtNumber : positionName}
      </LineupPlayerShirt>
      {player && (
        <LineupPlayerLabel sx={{ transition: getTransition }}>
          {player.fullName}
        </LineupPlayerLabel>
      )}
      {player && (
        <LineupPlayerLabel
          sx={{
            bottom: '100%',
            top: 'unset',
            marginTop: 'unset',
            transition: getTransition,
          }}
        >
          {positionName}
        </LineupPlayerLabel>
      )}
    </Stack>
  );
};
