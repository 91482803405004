import { FC } from 'react';
import {
  CrossAction,
  GoalkeeperThrowAction,
  LaunchAction,
  PassAction,
} from '@/types/action/action';
import { ACTION_TYPE_ID } from '@contract';
import { ToggleAssist } from './MenuItems/ToggleAssist';
import { ToggleHeaded } from './MenuItems/ToggleHeaded';
import { ToggleActionContext } from './MenuItems/ToggleActionContext';

interface PassContextMenuProps {
  action: PassAction | CrossAction | LaunchAction | GoalkeeperThrowAction;
}

export const PassContextMenu: FC<PassContextMenuProps> = ({ action }) => {
  const isPassAction = action.actionTypeId === ACTION_TYPE_ID.Pass;
  const isLaunchAction = action.actionTypeId === ACTION_TYPE_ID.Launch;
  const isCrossAction = action.actionTypeId === ACTION_TYPE_ID.Cross;

  return (
    <>
      {(isPassAction || isLaunchAction || isCrossAction) && <ToggleActionContext action={action} />}
      {isPassAction && <ToggleHeaded action={action} />}
      <ToggleAssist action={action} />
    </>
  );
};
