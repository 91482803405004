import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';

type CheckboxValues<T> = {
  value: T;
  label: string;
  isDisabled?: boolean;
};

interface CheckboxGroupProps<T> {
  controlLabel: string;
  fields: CheckboxValues<T>[];
  disabled?: boolean;
  onChange: (args: T[]) => void;
  values: T[];
}

export function CheckboxGroup<T>({
  controlLabel,
  fields,
  disabled,
  values,
  onChange,
}: CheckboxGroupProps<T>) {
  return (
    <FormControl>
      <FormLabel>{controlLabel}</FormLabel>
      <FormGroup row>
        {fields.map(({ value, label, isDisabled }) => (
          <FormControlLabel
            key={label}
            control={
              <Checkbox
                checked={values.includes(value)}
                onChange={(_, checked) => {
                  if (checked) {
                    onChange([...values, value]);
                  } else {
                    const newValues = values.filter((val) => val !== value);

                    onChange(newValues);
                  }
                }}
                value={value}
              />
            }
            label={label}
            disabled={disabled || isDisabled}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
