/* eslint-disable @typescript-eslint/no-explicit-any */
import { HubConnectionState } from '@microsoft/signalr';
import type { Action } from '@/types/action/action';
/* Path aliases in worker bundle causes build issues :/ */
import {
  SocketMessageType,
  SocketMethodNames,
  type DataCollectionStateDto,
} from '../../types/contract';

// Messages from worker to host
/**
 * This enum contains action/message names dispatched by WebWorker.
 * Those messages are handled by main thread (worker host).
 * Most of them directly pass the corresponding data coming from WebSocket.
 */
export const SCORING_WORKER_ACTION = {
  WORKER_READY: 'workerReady',
  ACTIONS_SUBSCRIBED: 'actionsSubscribed',
  STATE_SUBSCRIBED: 'stateSubscribed',
  ACTIONS_SUBSCRIBE_ERROR: 'actionsSubscribeError',
  STATE_SUBSCRIBE_ERROR: 'stateSubscribeError',
  WS_CONNECTION: 'wsConnection',
  ACTIONS_RECEIVED: SocketMessageType.actionsHistoryReceived,
  ACTION_ADDED: SocketMessageType.actionAdded,
  STATE_RECEIVED: SocketMessageType.stateReceived,
} as const;

export type ScoringWorkerAction = ConstType<typeof SCORING_WORKER_ACTION>;
export type WorkerReadyMsg = {
  action: typeof SCORING_WORKER_ACTION.WORKER_READY;
  payload: { isWorkerReady: boolean };
};
export type ActionsSubscribedMsg = {
  action: typeof SCORING_WORKER_ACTION.ACTIONS_SUBSCRIBED;
  payload: { actionsSubscribed: boolean | string };
};
export type StateSubscribedMsg = {
  action: typeof SCORING_WORKER_ACTION.STATE_SUBSCRIBED;
  payload: { stateSubscribed: boolean };
};
export type ActionsSubscribeErrorMsg = {
  action: typeof SCORING_WORKER_ACTION.ACTIONS_SUBSCRIBE_ERROR;
  payload: { actionsError: boolean };
};
export type StateSubscribeErrorMsg = {
  action: typeof SCORING_WORKER_ACTION.STATE_SUBSCRIBE_ERROR;
  payload: { stateError: boolean };
};
export type WebSocketConnectionMsg = {
  action: typeof SCORING_WORKER_ACTION.WS_CONNECTION;
  payload: { wsConnection: HubConnectionState };
};
export type ActionsReceivedMsg = {
  action: typeof SCORING_WORKER_ACTION.ACTIONS_RECEIVED;
  payload: { actions: Map<string, Action[]> | null };
};
export type ActionAddedMsg = {
  action: typeof SCORING_WORKER_ACTION.ACTION_ADDED;
  payload: { action: Action };
};
export type StateReceivedMsg = {
  action: typeof SCORING_WORKER_ACTION.STATE_RECEIVED;
  payload: DataCollectionStateDto;
};

export type ScoringWorkerMsg =
  | WorkerReadyMsg
  | ActionsSubscribedMsg
  | StateSubscribedMsg
  | ActionsSubscribeErrorMsg
  | StateSubscribeErrorMsg
  | ActionsReceivedMsg
  | ActionAddedMsg
  | WebSocketConnectionMsg
  | StateReceivedMsg;

export type ScoringWorkerMsgEvent = MessageEvent<ScoringWorkerMsg>;
// END: Messages from worker to host
// Messages from host to worker
/**
 * This enum contains action/message names dispatched by host.
 * Those messages are received by WebWorker and most of them
 * directly invoke a WebSocket action/message.
 */
export const SCORING_WORKER_HOST_ACTION = {
  TOKEN: 'token',
  /**
   * Subscription methods
   */
  ACTIONS_SUBSCRIBE: SocketMethodNames.subscribeActions,
  ACTIONS_UNSUBSCRIBE: SocketMethodNames.unsubscribeActions,
  STATE_SUBSCRIBE: SocketMethodNames.subscribeState,
  STATE_UNSUBSCRIBE: SocketMethodNames.unsubscribeState,
} as const;

interface SubscribePayload {
  dataCollectionId: string;
  teamId?: string;
  oldDataCollectionId?: string;
  oldTeamId?: string;
}

export type TokenMsg = {
  action: typeof SCORING_WORKER_HOST_ACTION.TOKEN;
  payload: string;
};

export type SubscribeMsg = {
  action:
    | typeof SCORING_WORKER_HOST_ACTION.ACTIONS_SUBSCRIBE
    | typeof SCORING_WORKER_HOST_ACTION.ACTIONS_UNSUBSCRIBE
    | typeof SCORING_WORKER_HOST_ACTION.STATE_SUBSCRIBE
    | typeof SCORING_WORKER_HOST_ACTION.STATE_UNSUBSCRIBE;
  payload: SubscribePayload;
};

export type ScoringWorkerHostMsg = TokenMsg | SubscribeMsg;

export type ScoringWorkerHostEvent = MessageEvent<ScoringWorkerHostMsg>;
// END: Messages from host to worker

// interface for main thread instance
export interface ScoringAPIWorker
  extends Omit<
    Worker,
    'postMessage' | 'addEventListener' | 'removeEventListener'
  > {
  addEventListener(
    type: 'message',
    listener: (this: ScoringAPIWorker, ev: ScoringWorkerMsgEvent) => any,
    options?: boolean | AddEventListenerOptions,
  ): void;
  removeEventListener(
    type: 'message',
    listener: (this: ScoringAPIWorker, ev: ScoringWorkerMsgEvent) => any,
    options?: boolean | EventListenerOptions,
  ): void;

  postMessage(message: ScoringWorkerHostMsg, transfer: Transferable[]): void;
  postMessage(
    message: ScoringWorkerHostMsg,
    options?: StructuredSerializeOptions,
  ): void;
}
