import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { HubConnectionState } from '@microsoft/signalr';
import ScoringWorkerUrl from '@/workers/scoring/worker?worker&url';
import {
  SCORING_WORKER_HOST_ACTION,
  ScoringAPIWorker,
  type SubscribeMsg,
} from '@/workers/scoring/types';
import { isDebugEnabled, isTraceStoreEnabled } from '@/utils/debug';
import { onWorkerMessage } from './handlers';

export type SocketStore = {
  isOnline: boolean;
  isWorkerReady: boolean;
  wsConnection: HubConnectionState;
  worker: ScoringAPIWorker;
  /**
   * string - subscribed to teamId
   * true - subscribed to both teams
   * false - unsubscribed
   */
  actionsSubscribed: boolean | string;
  actionsReceived: boolean;
  stateReceived: boolean;
  stateSubscribed: boolean;
  actionsError: boolean;
  stateError: boolean;
};

const DEFAULT_STATE: SocketStore = {
  isOnline: true,
  wsConnection: HubConnectionState.Disconnected,
  isWorkerReady: false,
  actionsSubscribed: false,
  actionsReceived: false,
  stateSubscribed: false,
  stateReceived: false,
  actionsError: false,
  stateError: false,
  worker: new Worker(getWorkerUrl(), { type: 'module' }),
};

export const useSocketStore = create<SocketStore>()(
  devtools(() => ({ ...DEFAULT_STATE }), {
    enabled: isDebugEnabled(),
    trace: isTraceStoreEnabled,
    name: 'SocketStore',
  }),
);

function setOnline() {
  return useSocketStore.setState({ isOnline: true });
}

function setOffline() {
  return useSocketStore.setState({ isOnline: false });
}

export function setWSConnection(wsConnection: HubConnectionState) {
  if (useSocketStore.getState().wsConnection === wsConnection) return;
  return useSocketStore.setState({ wsConnection }, false, 'setWSConnection');
}

export function setIsWorkerReady(isWorkerReady: boolean) {
  if (useSocketStore.getState().isWorkerReady === isWorkerReady) return;
  return useSocketStore.setState({ isWorkerReady }, false, 'isWorkerReady');
}
export function setActionsSubscribed(
  actionsSubscribed: SocketStore['actionsSubscribed'],
) {
  return useSocketStore.setState({ actionsSubscribed });
}
export function setActionsReceived(
  actionsReceived: SocketStore['actionsReceived'],
) {
  return useSocketStore.setState({ actionsReceived });
}
export function setStateSubscribed(stateSubscribed: boolean) {
  return useSocketStore.setState({ stateSubscribed });
}
export function setStateReceived(stateReceived: SocketStore['stateReceived']) {
  return useSocketStore.setState({ stateReceived });
}

export function setActionsError(actionsError: SocketStore['actionsError']) {
  return useSocketStore.setState({ actionsError });
}

export function setStateError(stateError: SocketStore['stateError']) {
  return useSocketStore.setState({ stateError });
}

function getWorkerUrl() {
  const [workerFilePath, workerQueryString] = ScoringWorkerUrl.split('?');
  const workerSearchParams = new URLSearchParams(workerQueryString || '');

  return workerFilePath + '?' + workerSearchParams.toString();
}

export function addWorkerEventListener() {
  const worker = useSocketStore.getState().worker;

  worker.addEventListener('message', onWorkerMessage);
}

export function sendToken(token?: string) {
  if (!token) return;

  const worker = useSocketStore.getState().worker;

  worker.postMessage({
    action: SCORING_WORKER_HOST_ACTION.TOKEN,
    payload: token,
  });
}

export function subscribeActions(payload: SubscribeMsg['payload']) {
  const { wsConnection, worker } = useSocketStore.getState();

  if (wsConnection !== HubConnectionState.Connected) return;

  setActionsError(false);

  const message: SubscribeMsg = {
    action: SCORING_WORKER_HOST_ACTION.ACTIONS_SUBSCRIBE,
    payload,
  };
  worker.postMessage(message);
}

export function unsubscribeActions(payload: SubscribeMsg['payload']) {
  const { wsConnection, worker } = useSocketStore.getState();

  if (wsConnection !== HubConnectionState.Connected) return;

  const message: SubscribeMsg = {
    action: SCORING_WORKER_HOST_ACTION.ACTIONS_UNSUBSCRIBE,
    payload,
  };
  worker.postMessage(message);
}

export function subscribeState(payload: SubscribeMsg['payload']) {
  const { wsConnection, worker } = useSocketStore.getState();

  if (wsConnection !== HubConnectionState.Connected) return;

  setStateError(false);

  const message: SubscribeMsg = {
    action: SCORING_WORKER_HOST_ACTION.STATE_SUBSCRIBE,
    payload,
  };
  worker.postMessage(message);
}

export function unsubscribeState(payload: SubscribeMsg['payload']) {
  const { wsConnection, worker } = useSocketStore.getState();

  if (wsConnection !== HubConnectionState.Connected) return;

  const message: SubscribeMsg = {
    action: SCORING_WORKER_HOST_ACTION.STATE_UNSUBSCRIBE,
    payload,
  };
  worker.postMessage(message);
}

window.addEventListener('offline', setOffline);
window.addEventListener('online', setOnline);
