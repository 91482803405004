import { useCallback, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Stack,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { downloadJSON } from '@/utils/export';
import { sortByActionTimes, sortByCreatedAt } from '@/utils/sortByActionTimes';
import { useFixtureStore } from '@/stores/FixtureStore';
import { EXPORT_ALL_LABEL, EXPORT_LABEL } from './constants';

export const ExportActions = () => {
  const collectionId = useFixtureStore((state) => state.dataCollectionId);

  const actions = useFixtureStore((state) => state.actions);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIdOpen] = useState(false);

  const onExportClick = useCallback(() => {
    if (!collectionId) return;
    const exportData = [...actions.values()]
      .map((value) => value[0])
      .filter((value) => !value.isRemoved)
      .sort(sortByActionTimes);

    downloadJSON(exportData, `${collectionId}-${new Date().toJSON()}`);
  }, [actions, collectionId]);

  const onExportAllClick = useCallback(() => {
    if (!collectionId) return;
    const exportData = [...actions.values()].flat().sort(sortByCreatedAt);

    downloadJSON(exportData, `${collectionId}-${new Date().toJSON()}-all`);
    handleClose();
  }, [actions, collectionId]);

  const handleToggle = () => {
    setIdOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setIdOpen(false);
  };

  return (
    <Stack>
      <ButtonGroup ref={anchorRef}>
        <Button onClick={onExportClick} startIcon={<DownloadIcon />}>
          {EXPORT_LABEL}
        </Button>
        <Button size='small' onClick={handleToggle}>
          <ArrowDropDownIcon fontSize='small' />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 999 }}
        open={isOpen}
        anchorEl={anchorRef.current}
        style={{ width: anchorRef.current?.clientWidth }}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList sx={{ padding: 0 }}>
                  <MenuItem
                    sx={{ justifyContent: 'center' }}
                    onClick={onExportAllClick}
                  >
                    {EXPORT_ALL_LABEL}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
};
