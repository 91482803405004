import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { isDebugEnabled, isTraceStoreEnabled } from '@/utils/debug';
import { timeSecondsToString } from '@/utils/timeSecondsToString';
import { debounce } from '@/utils/debounce';
import { switchGameState } from './FixtureStore';

export type ClockStore = {
  clockTimeTicks: number;
  clockTime: string;
  isRunning: boolean;
};

export const DEFAULT_STATE: ClockStore = {
  clockTimeTicks: 0,
  clockTime: '00:00',
  isRunning: false,
};

export const useClockStore = create<ClockStore>()(
  devtools(
    () => ({
      ...DEFAULT_STATE,
    }),
    {
      enabled: isDebugEnabled(),
      trace: isTraceStoreEnabled,
      name: 'ClockStore',
    },
  ),
);

export const debouncedStateSwitch = debounce(switchGameState, 100);

/**
 * Subscribe to clock ticks change.
 */
useClockStore.subscribe((state, prevState) => {
  if (state.clockTimeTicks === prevState.clockTimeTicks) return;
  useClockStore.setState({
    clockTime: timeSecondsToString(state.clockTimeTicks),
  });
  debouncedStateSwitch(state.clockTimeTicks);
});

export function reset() {
  useClockStore.setState(DEFAULT_STATE);
}

export function setClockTicks(clockTicks: number) {
  return useClockStore.setState((state) => {
    if (state.isRunning) {
      _pauseClock();
      _startClock();
    }
    return { clockTimeTicks: Math.max(0, clockTicks) };
  });
}

const SEC = 1000;
let nextTickAt: number | null = null;
let tickTimeout: number | null = null;

function tickClockTimeout() {
  if (nextTickAt === null) return;
  const now = Date.now();
  const drift = now - nextTickAt;
  if (drift > SEC) {
    console.warn('Clock update did not happen within a second.');
  }
  nextTickAt += SEC;
  useClockStore.setState((state) => ({
    clockTimeTicks: state.clockTimeTicks + 1,
  }));
  tickTimeout = window.setTimeout(tickClockTimeout, Math.max(0, SEC - drift));
}

function _startClock() {
  if (tickTimeout) window.clearTimeout(tickTimeout);
  nextTickAt = Date.now() + SEC;
  tickTimeout = window.setTimeout(tickClockTimeout, SEC);
}
export function startClock() {
  _startClock();
  useClockStore.setState({ isRunning: true });
}

function _pauseClock() {
  if (tickTimeout) {
    window.clearTimeout(tickTimeout);
  }
  tickTimeout = null;
  nextTickAt = null;
}
export function pauseClock() {
  _pauseClock();
  useClockStore.setState({ isRunning: false });
}

export function updateClockBy(seconds: number) {
  useClockStore.setState((state) => {
    if (state.isRunning) {
      _pauseClock();
      _startClock();
    }
    return {
      clockTimeTicks: Math.max(0, state.clockTimeTicks + seconds),
    };
  });
}

export function getCurrentClock() {
  const { clockTimeTicks, clockTime } = useClockStore.getState();
  return {
    clockTimeTicks,
    clockTime,
  };
}
