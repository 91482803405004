import { Chip, ChipProps, Tooltip, TooltipProps } from '@mui/material';
import { FC } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

interface FilterChip {
  onDelete: () => void;
  tooltipMessage?: TooltipProps['title'];
  icon?: ChipProps['icon'];
  label: ChipProps['label'];
}

export const FilterChip: FC<FilterChip> = ({
  label,
  onDelete,
  icon,
  tooltipMessage,
}) => {
  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.button === 1) {
      onDelete();
    }
  };

  return (
    <Tooltip arrow placement='top' title={tooltipMessage}>
      <Chip
        onMouseDown={onMouseDown}
        label={label}
        deleteIcon={<CancelIcon />}
        icon={icon}
        onDelete={onDelete}
      />
    </Tooltip>
  );
};
