import { FC } from 'react';
import { useActionStore } from '@/stores/ActionStore/ActionStore';
import { CoordsLine } from './CoordsLine';
import { CoordsDot } from './CoordsDot';

const COORDS_TARGET_DOT_TEST_ID = 'pitch-target-dot';

export const PassCoordsTarget: FC = () => {
  const coords = useActionStore((state) => state.dotCoords);
  const targetCoords = useActionStore((state) => state.dotTargetCoords);
  if (!coords || !targetCoords) return;

  return (
    <>
      <CoordsLine coordsStart={coords} coordsEnd={targetCoords} />
      <CoordsDot
        data-testid={COORDS_TARGET_DOT_TEST_ID}
        coords={targetCoords}
      />
    </>
  );
};
