import { FC } from 'react';
import { PeriodDto } from '@contract';
import { TIME_LABEL } from '@/constants';
import { setAction } from '@/stores/ActionStore/ActionStore';
import type { Action } from '@/types/action/action';
import { timeStringToSeconds } from '@/utils/timeStringToSeconds';
import { PeriodTimeSelect } from '@/components/Form/PeriodTimeSelect';

type ClockTimeFieldProps = {
  action: Action;
  disabled?: boolean;
};

export const PeriodClockField: FC<ClockTimeFieldProps> = ({
  action,
  disabled,
}) => {
  const onPeriodChange = (period: PeriodDto) => {
    setAction({
      ...action,
      period,
    });
  };

  const onClockTimeChange = (clockTime: string) => {
    setAction({
      ...action,
      clockTime,
      clockTimeTicks: timeStringToSeconds(clockTime),
    });
  };

  return (
    <PeriodTimeSelect
      onClockTimeChange={onClockTimeChange}
      period={action.period}
      onPeriodChange={onPeriodChange}
      controlLabel={TIME_LABEL}
      clockTime={action.clockTime}
      disabled={disabled}
    />
  );
};
