/* c8 ignore start */
export const ENVIRONMENT = {
  // vite dev server
  LOCAL: 'development',

  // CI testing
  TEST: 'test',

  // deployment
  DEV: 'dev',
  STAGING: 'staging',
  PROD: 'production',
} as const;

export const DEV_ENV = {
  [ENVIRONMENT.LOCAL]: ENVIRONMENT.LOCAL,
  [ENVIRONMENT.DEV]: ENVIRONMENT.DEV,
} as const;

export type DevEnv = ConstType<typeof DEV_ENV>;
export type Environment = ConstType<typeof ENVIRONMENT>;
/* c8 ignore stop */
