import useSWR from 'swr';
import { v4 as uuidv4 } from 'uuid';
import type { LineupsDto, PlayerDto } from '@contract';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';
import { useFixtureStore } from '@/stores/FixtureStore';
import { useAuthToken } from './hooks/authToken';
import { SERVICE_ENDPOINT } from './constants/endpoint';
import { fetchData } from './fetchData';
import { isUsingMockData } from './helpers/isUsingMockData';

export type Player = {
  id: string;
  firstName: string;
  lastName: string;
  shirtNumber: string;
  fullName: string;
};

export type Formation = {
  positionId: number;
  id: string;
  positionAbbreviation: string;
  positionName: string;
};

async function getFakeStartingLineup() {
  const lineups = (await import('../../db/lineups.json')).default.lineups;
  const fakeSquadPlayers: PlayerDto[] = [
    ...lineups.addedPlayers,
    ...lineups.benchPlayers,
    ...lineups.squadPlayers,
    ...lineups.pitchPlayers.map((pp) => ({ ...pp.player })),
    ...lineups.substitutes,
  ].map((player) => ({ ...player, id: uuidv4() }));

  const fakeResponse: LineupsDto = {
    addedPlayers: [],
    benchPlayers: [],
    pitchPlayers: [],
    squadPlayers: fakeSquadPlayers,
  };
  return fakeResponse;
}

async function getLineupsCallback(url: string, token: string) {
  if (!isUsingMockData()) {
    return fetchData({ url, token });
  }
  return fetchData({ url, token })
    .then(async (response: LineupsDto) => {
      const { addedPlayers, benchPlayers, pitchPlayers, squadPlayers } =
        response;
      const totalPlayers =
        addedPlayers.length +
        benchPlayers.length +
        pitchPlayers.length +
        squadPlayers.length;
      if (totalPlayers !== 0) {
        return response;
      }
      return getFakeStartingLineup();
    })
    .catch(() => getFakeStartingLineup());
}

export function useGetLineups() {
  const token = useAuthToken();
  const teamId = useLineupStore((state) => state.teamId) || '';
  const collectionId = useFixtureStore((state) => state.dataCollectionId) || '';
  let url: string | null = null;

  if (teamId && collectionId) {
    const queryParams = new URLSearchParams({ teamId, collectionId });
    url = `${SERVICE_ENDPOINT.Lineups.get}?${queryParams}`;
  } else {
    url = null;
  }

  const output = useSWR<LineupsDto>(
    url,
    (url: string) => getLineupsCallback(url, token),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  );

  return output;
}
