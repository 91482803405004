import {
  FC,
  MouseEvent,
  useEffect,
  useRef,
  useState,
  type MutableRefObject,
} from 'react';
import { Box } from '@mui/material';
import type { Coords } from '@/types';
import { CoordsCalc } from '@/utils/coordsCalc';
import { POSITION_ATTRIBUTE } from '@/components/Pitch/constants';
import { CoordsDot } from './CoordsDot';
import { usePitchElements } from './utils';
import { CoordsLine } from './CoordsLine';

export type DotProps = {
  positionId: string;
  coords: Coords;
  color?: string;
};

interface ClickableDotsProps {
  dots: DotProps[];
  shouldDisplayLine?: boolean;
  pitchContainerRef?: MutableRefObject<HTMLDivElement | null>;
  setCoords: (coords: DotProps) => void;
}

export const ClickableDots: FC<ClickableDotsProps> = ({
  dots,
  shouldDisplayLine = false,
  pitchContainerRef,
  setCoords,
}) => {
  const [activeDot, setActiveDot] = useState<string | null>(null);
  const $clickableContainer = useRef<HTMLDivElement>(null);
  const { innerClientRect, outerClientRect } = usePitchElements(
    pitchContainerRef || $clickableContainer,
  );

  useEffect(() => {
    if (dots && dots.length === 1) {
      setActiveDot(dots[0].positionId);
      return;
    }

    setActiveDot(null);
  }, [dots]);

  const onDropClick = (e: MouseEvent<HTMLDivElement | SVGSVGElement>) => {
    const positionId = (e.target as HTMLElement).getAttribute(
      POSITION_ATTRIBUTE,
    );

    if (positionId && !activeDot) {
      setActiveDot(positionId);
      return;
    }

    if (!innerClientRect) return;

    const coords = CoordsCalc.calcClickToActionCoords(
      e,
      innerClientRect,
      outerClientRect || innerClientRect,
    );

    if (activeDot) {
      setCoords({ positionId: activeDot, coords });
    }

    if (dots && dots.length !== 1) {
      setActiveDot(null);
    }
  };

  const getOuterCoords = (innerCoords: Coords) => {
    if (!outerClientRect || !innerClientRect) return innerCoords;
    return CoordsCalc.scaleUp(innerCoords, innerClientRect, outerClientRect);
  };

  if (!dots) return;

  return (
    <Box
      id='clickable-dots'
      ref={$clickableContainer}
      onClick={onDropClick}
      sx={{ position: 'absolute', inset: 0 }}
    >
      {dots.map((dot) => (
        <CoordsDot
          key={dot.positionId}
          coords={getOuterCoords(dot.coords)}
          positionId={dot.positionId}
          color={dot.color}
          sx={{
            border: dot.positionId === activeDot ? '2px solid black' : 'unset',
            borderRadius: '50%',
            pointerEvents: activeDot ? 'none' : 'unset',
          }}
        />
      ))}
      {shouldDisplayLine && (
        <CoordsLine
          coordsStart={getOuterCoords(dots[0].coords)}
          coordsEnd={getOuterCoords(dots[1].coords)}
          asArrow
        />
      )}
    </Box>
  );
};
