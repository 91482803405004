import { List, type ListProps } from '@mui/material';
import type { LineupNamedPosition } from '@/stores/LineupStore/constants';
import { useStartingLineupsContext } from '../StartingLineupContext';
import { StartingPlayersType } from '../constants';
import { StartingLineupPlayerListItem } from './StartingLineupPlayerListItem';

type StartingLineupPlayersListProps = ListProps & {
  playersType: StartingPlayersType;
  lineupPosition: LineupNamedPosition;
};

export function StartingLineupPlayersList({
  playersType,
  lineupPosition,
  ...listProps
}: StartingLineupPlayersListProps) {
  const playersList = useStartingLineupsContext().useStore(
    (state) => state[playersType],
  );

  return (
    <List {...listProps}>
      {playersList.map((player) => (
        <StartingLineupPlayerListItem
          key={player.id}
          player={player}
          lineupPosition={lineupPosition}
        />
      ))}
    </List>
  );
}
