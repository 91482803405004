import { Divider, Paper, Stack, Typography } from '@mui/material';
import { LINEUP_NAMED_POSITION } from '@/stores/LineupStore/constants';
import { LiveLineupPlayersList } from '@/components/LineupEditor/LiveLineup/LiveLineupPlayersList';
import { LivePlayersType } from '@/components/LineupEditor/constants';
import { PitchOrientation } from '@/components/Formation/constants';
import { Formation } from '@/components/Formation/Formation';
import { ELEMENT_ID } from './LineupEditorView';

const playersSections = [
  {
    id: ELEMENT_ID.BENCH_PLAYERS,
    title: 'Bench',
    lineupPosition: LINEUP_NAMED_POSITION.BENCH,
    playersType: LivePlayersType.Bench,
  },
  {
    id: ELEMENT_ID.OFF_PLAYERS,
    title: 'Players off',
    lineupPosition: LINEUP_NAMED_POSITION.OFF,
    playersType: LivePlayersType.Off,
  },
  {
    id: ELEMENT_ID.RETIRED_PLAYERS,
    title: 'Retired Players',
    lineupPosition: LINEUP_NAMED_POSITION.RETIRED,
    playersType: LivePlayersType.Retired,
  },
];

export const LiveLineupEditorView = () => {
  return (
    <Stack flex='1' gap={1} padding={1}>
      <Stack
        direction='row'
        gap={1}
        flex='1 1 0'
        overflow='hidden'
        justifyContent='center'
      >
        <Stack
          id={ELEMENT_ID.PITCH}
          maxWidth='590px'
          width='33vw'
          margin='auto'
          position='relative'
        >
          <Formation pitchOrientation={PitchOrientation.Vertical} />
        </Stack>

        {playersSections.map(({ id, playersType, title }) => (
          <Paper
            id={id}
            key={id}
            component={Stack}
            gap={1}
            elevation={2}
            p={2}
            overflow='hidden'
            flex={1}
          >
            <Typography fontSize='1.2em' textAlign='center' variant='overline'>
              {title}
            </Typography>
            <Divider flexItem />

            <Stack flex='1' overflow='auto'>
              <LiveLineupPlayersList playersType={playersType} />
            </Stack>
          </Paper>
        ))}
      </Stack>
    </Stack>
  );
};
