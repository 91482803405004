import { FC } from 'react';
import { Chip, ChipProps } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import { useCurrentView } from '@/views/utils/useCurrentView';

type ViewLabelProps = ChipProps;

export const ViewLabel: FC<ViewLabelProps> = (props) => {
  const currentView = useCurrentView();

  return (
    <>
      {currentView && (
        <Chip
          variant='outlined'
          size='small'
          color='info'
          icon={<Visibility />}
          label={currentView}
          {...props}
        />
      )}
    </>
  );
};
