import { FC, useCallback } from 'react';
import { Button, type ButtonProps } from '@mui/material';
import SaveAlt from '@mui/icons-material/SaveAlt';
import type { LineupNamedPosition } from '@/stores/LineupStore/constants';
import { useStartingLineupsContext } from './StartingLineupContext';

type DropZoneProps = ButtonProps & {
  lineupPosition: LineupNamedPosition;
};

export const DropZone: FC<DropZoneProps> = (allProps) => {
  const { lineupPosition, onClick, sx, ...props } = allProps;
  const startingLineupStore = useStartingLineupsContext();
  const selectedEntry = startingLineupStore.useStore(
    (state) => state.selectedEntry,
  );
  const _onClick = useCallback<NonNullable<DropZoneProps['onClick']>>(
    (...args) => {
      startingLineupStore.selectEntry(lineupPosition, null);
      onClick && onClick(...args);
    },
    [lineupPosition, onClick, startingLineupStore],
  );

  const isDisabled =
    selectedEntry === null ||
    selectedEntry[0] === lineupPosition ||
    selectedEntry[1] === null;

  return (
    <Button
      {...props}
      disabled={props.disabled || isDisabled}
      color='secondary'
      variant='contained'
      fullWidth
      aria-label={`Put ${lineupPosition}`}
      sx={{
        height: '3em',
        ...sx,
      }}
      onClick={_onClick}
    >
      <SaveAlt fontSize='large' />
    </Button>
  );
};
