import { Divider, Stack } from '@mui/material';
import { useEffect } from 'react';
import { ActionView } from '@/components/ActionView/ActionView';
import { QAHistoryTable } from '@/components/QAHistoryTable/QAHistoryTable';
import { HotkeyManager } from '@/components/HotkeyManager/HotkeyManager';
import { FiltersDrawer } from '@/components/ActionFilters/FiltersDrawer';
import {
  applyFilters,
  setIsMappingActionTimestamps,
  useFiltersStore,
} from '@/stores/FiltersStore/FiltersStore';
import { FiltersDrawerVariant } from '@/components/ActionFilters/constants';
import { QALineups } from '@/components/QALineups/QALineups';
import { Timeline } from '@/components/TimeLine/TimeLine';
import { useFixtureStore } from '@/stores/FixtureStore';
import { SelectedFilterChips } from '@/components/ActionFilters/SelectedFilterChips';
import { OpenFiltersButton } from '@/components/ActionFilters/OpenFiltersButton';
import { AddAction } from '@/components/AddAction/AddAction';
import { ActionDialog } from '@/components/Dialogs/ActionDialog';

export const QAView = () => {
  const tableActions = useFixtureStore((state) => state.tableActions);
  const filterValues = useFiltersStore((state) => state.filterValues);
  const filteredActions = useFiltersStore((state) => state.tableActions);

  useEffect(() => {
    setIsMappingActionTimestamps(true);
    return () => {
      setIsMappingActionTimestamps(false);
    };
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filterValues, tableActions]);

  return (
    <HotkeyManager hotkeysVariant='qa'>
      <Stack height='100%' overflow='hidden' p={1}>
        {/* Main content */}
        <Stack flex='1 0 0' overflow='hidden' gap={0.5}>
          {/* First row */}
          <Stack direction='row' flex='26 0 0' overflow='hidden' gap={1}>
            {/* Left side  */}
            <Stack sx={{ flex: '60 0 0', overflow: 'hidden' }}>
              <ActionView />
            </Stack>

            {/* Right side */}
            <Stack sx={{ flex: '40 0 0', overflow: 'hidden' }}></Stack>
          </Stack>

          <Divider flexItem />

          {/* Second row */}
          <Stack direction='row' flex='20 0 0' overflow='hidden' gap={1}>
            {/* Left side */}
            <Stack sx={{ flex: '60 0 0', overflowY: 'auto' }}>
              <Stack direction='row' alignItems='flex-start' gap={1} margin={2}>
                <SelectedFilterChips />
                <Stack
                  flex='0 0 auto'
                  direction='row'
                  marginLeft='auto'
                  gap={1}
                >
                  <OpenFiltersButton />
                  <AddAction />
                </Stack>
              </Stack>

              <QAHistoryTable actions={filteredActions} />
            </Stack>
            {/* Right side */}
            <Stack sx={{ flex: '40 0 0', overflow: 'hidden' }}>
              <QALineups />
            </Stack>
          </Stack>

          <Divider flexItem />

          {/* Third row */}
          <Stack flex='1 1 0'>
            <Timeline />
          </Stack>
        </Stack>
      </Stack>
      <FiltersDrawer variant={FiltersDrawerVariant.QA} />
      <ActionDialog />
    </HotkeyManager>
  );
};
