import { type FC } from 'react';
import { Stack, IconButton } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useFiltersStore } from '@/stores/FiltersStore/FiltersStore';
import { pauseClock, startClock, useClockStore } from '@/stores/ClockStore';
import { type TableAction } from '@/stores/FixtureStore';
import { getNextAction, getPeriodFromTime, getPreviousAction } from './utils';
import type { TimelinePeriod } from './TimeLine';
import { TIMELINE_SKIP_SECONDS } from './constants';

interface TimeControlProps {
  onChange: (value: number, action?: TableAction) => void;
  value: number;
  maxValue: number;
  timeLinePeriods: TimelinePeriod[];
}

export const TimeControl: FC<TimeControlProps> = ({
  onChange,
  value,
  maxValue,
  timeLinePeriods,
}) => {
  const isClockRunning = useClockStore((state) => state.isRunning);
  const filterStoreActions = useFiltersStore((state) => state.tableActions);
  const period =
    getPeriodFromTime(timeLinePeriods, value) || timeLinePeriods[0];
  const previousAction = getPreviousAction(value, filterStoreActions, period);
  const nextAction = getNextAction(value, filterStoreActions, period);

  const moveBack = () => {
    const time =
      value - TIMELINE_SKIP_SECONDS > 0 ? value - TIMELINE_SKIP_SECONDS : 0;
    onChange(time);
  };

  const moveForward = () => {
    if (value + TIMELINE_SKIP_SECONDS >= maxValue) {
      pauseClock();
      onChange(maxValue);
      return;
    }

    onChange(value + TIMELINE_SKIP_SECONDS);
  };

  const moveToAction = (action: TableAction | null) => {
    if (!action) return;

    onChange(action.clockTimeTicks, action);
  };

  return (
    <Stack direction='row' justifyContent='center'>
      <IconButton disabled={value === 0} onClick={moveBack}>
        <KeyboardDoubleArrowLeftIcon />
      </IconButton>
      <IconButton
        disabled={!previousAction}
        onClick={() => moveToAction(previousAction)}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton disabled={!isClockRunning} onClick={pauseClock}>
        <PauseIcon />
      </IconButton>
      <IconButton disabled={isClockRunning} onClick={startClock}>
        <PlayArrowIcon />
      </IconButton>
      <IconButton
        disabled={!nextAction}
        onClick={() => moveToAction(nextAction)}
      >
        <ChevronRightIcon />
      </IconButton>
      <IconButton disabled={value >= maxValue} onClick={moveForward}>
        <KeyboardDoubleArrowRightIcon />
      </IconButton>
    </Stack>
  );
};
