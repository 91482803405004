import { FC } from 'react';
import { MenuItem, ListItemText, ListItemIcon } from '@mui/material';
import History from '@mui/icons-material/History';
import { setContextAction } from '@/stores/ActionStore/ActionStore';
import {
  actionHasBackup,
  resyncBackupAction,
} from '@/stores/UnsyncedActionsStore';
import type { PropsWithAction } from '../ActionContextMenuContent';

export const RESEND_LABEL = 'Resend';

export const Resend: FC<PropsWithAction> = ({ action }) => {
  const hasBackupAction = actionHasBackup(action);
  if (!hasBackupAction) return;

  return (
    <MenuItem
      onClick={() => {
        resyncBackupAction(action);
        setContextAction(null);
      }}
      aria-label={RESEND_LABEL}
    >
      <ListItemIcon>
        <History />
      </ListItemIcon>
      <ListItemText disableTypography>{RESEND_LABEL}</ListItemText>
    </MenuItem>
  );
};
