import { FC, MutableRefObject } from 'react';
import { Stack, Typography } from '@mui/material';
import { ViewportList, ViewportListRef } from 'react-viewport-list';
import { useFixtureStore } from '@/stores/FixtureStore';
import { ACTION_TABLE_VARIANT } from '@/components/ActionContextMenu/ActionContextMenu';
import { NO_ACTIONS_ADDED_LABEL } from '@/constants';
import { ActionsTableRow } from './ActionsTableRow';
import { useActionsTable } from './useActionsTable';

interface ActionsTableBodyProps {
  viewportRef: MutableRefObject<HTMLDivElement | null>;
  listRef: MutableRefObject<ViewportListRef | null>;
}

export const ActionsTableBody: FC<ActionsTableBodyProps> = ({
  viewportRef,
  listRef,
}) => {
  const items = useFixtureStore((state) => state.tableActions);

  const { onContextMenuClick, onTableDoubleClick, contextMenu } =
    useActionsTable(ACTION_TABLE_VARIANT.PITCH_VIEW);

  if (!items.length) {
    return (
      <Typography variant='body2' sx={{ textAlign: 'center', padding: '8px' }}>
        {NO_ACTIONS_ADDED_LABEL}
      </Typography>
    );
  }

  return (
    <Stack
      onContextMenu={onContextMenuClick}
      onDoubleClick={onTableDoubleClick}
    >
      <ViewportList viewportRef={viewportRef} ref={listRef} items={items}>
        {(action) => <ActionsTableRow key={action.actionId} action={action} />}
      </ViewportList>
      {contextMenu}
    </Stack>
  );
};
