import { Button } from '@mui/material';
import { useState } from 'react';
import { AddActionDialog } from '@/components/AddAction/AddActionDialog';
import { ADD_ACTION_LABEL } from '@/components/AddAction/constants';

export const AddAction = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Button
        id='add-action'
        variant='outlined'
        onClick={() => setIsDialogOpen((isOpen) => !isOpen)}
      >
        {ADD_ACTION_LABEL}
      </Button>
      <AddActionDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
};
