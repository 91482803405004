import { ApiError } from './ApiError';
import {
  API_METHODS,
  DEFAULT_HEADERS,
  DEFAULT_REQUEST_OPTIONS,
} from './constants/requests';

type PostDataOptions<Data> = {
  url: string;
  data: Data;
  token?: string;
  options?: Partial<RequestInit>;
};

export async function postData<Data = unknown, ResponseBody = unknown>(
  params: PostDataOptions<Data>,
): Promise<ResponseBody | undefined> {
  const { data, token } = params;
  if (!token) {
    throw new Error('Missing auth token');
  }
  const headers = new Headers(DEFAULT_HEADERS);
  headers.set('Authorization', 'Bearer ' + token);

  const { options } = params;
  if (options && options.headers) {
    Object.values(options.headers).forEach(([name, value]) => {
      headers.set(name, value);
    });
  }
  const finalOptions: RequestInit = {
    ...DEFAULT_REQUEST_OPTIONS,
    ...options,
    method: API_METHODS.POST,
    headers,
    body: JSON.stringify(data),
  };

  const response = await fetch(params.url, finalOptions);
  if (!response.ok) {
    const error = await ApiError.parseFromResponse(response);
    throw error;
  }

  // TODO: /v1/PassAction is not returning Content-Length header
  // const contentLength = Number(response.headers.get('Content-Length'));
  // if (!contentLength) {
  //   return;
  // }

  const contentType = response.headers.get('content-type');
  const isJson = contentType && contentType === 'application/json';
  const responseData: ResponseBody = isJson
    ? await response.json()
    : await response.text();
  return responseData;
}
