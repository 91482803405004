import { Outlet, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ROUTE_PARAM } from './router/constants';
import { setDataCollectionId } from './stores/FixtureStore';
import { setupMockData } from './service/helpers/isUsingMockData';

setupMockData();

export function App() {
  const dataCollectionId = useParams()[ROUTE_PARAM.COLLECTION_ID];

  useEffect(() => {
    setDataCollectionId(dataCollectionId);
  }, [dataCollectionId]);

  return <Outlet />;
}
