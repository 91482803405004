import { ActionTypeOption } from '@/types/action/actionType';
import { QA_ACTIONS_BLACKLIST } from '@/components/AddAction/constants';
import { ACTION_TEAM_NONE } from '@/stores/ActionStore/constants';

export function isTeamActionType(actionType: ActionTypeOption) {
  return !ACTION_TEAM_NONE.includes(actionType.value);
}

export function isQaActionType(actionType: ActionTypeOption) {
  return !QA_ACTIONS_BLACKLIST.includes(actionType.value);
}
