import { FC, useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  FormControl,
  FormGroup,
  FormLabel,
  TextField,
} from '@mui/material';
import { PeriodDto } from '@contract';
import { useFixtureStore } from '@/stores/FixtureStore';
import { MinMaxInput } from '@/components/Form/MinMaxInput';
import {
  MAX_MINUTES_VALUE,
  MAX_SECONDS_VALUE,
  MIN_MINUTES_VALUE,
  MIN_SECONDS_VALUE,
} from '../Clock/constants';
import { MINUTES_LABEL, PERIOD_LABEL, SECONDS_LABEL } from './constants';

export interface TimeSelectProps {
  controlLabel: string;
  clockTime?: string | null;
  period: PeriodDto | null;
  onClockTimeChange: (time: string) => void;
  onPeriodChange: (period: PeriodDto) => void;
  disabled?: boolean;
}

export const PeriodTimeSelect: FC<TimeSelectProps> = ({
  clockTime,
  controlLabel,
  onClockTimeChange,
  onPeriodChange,
  period,
  disabled,
}) => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [periodOptions, setPeriodOptions] = useState<PeriodDto[]>([]);
  const periodDetails = useFixtureStore((state) => state.periodsConfig);

  useEffect(() => {
    const [minutes, seconds] = (clockTime || '00:00').split(':').map(Number);

    setSeconds(seconds);
    setMinutes(minutes);
  }, [clockTime]);

  useEffect(() => {
    const periods = periodDetails?.map((period) => ({
      sequence: period.seq,
      label: period.label,
    }));

    if (!periods) return;

    setPeriodOptions(periods);
  }, [periodDetails]);

  const changeTime = (fieldValue: string) => {
    onClockTimeChange(fieldValue);
  };

  const handlePeriodChange = (
    value: PeriodDto | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (!['clear', 'selectOption'].includes(reason)) return;

    const selectedPeriod = periodDetails?.find(
      (periodDetail) => periodDetail.seq === value?.sequence,
    );

    if (selectedPeriod) {
      onPeriodChange({
        sequence: selectedPeriod.seq,
        label: selectedPeriod.label,
      });
    }
  };

  return (
    <FormControl>
      <FormLabel sx={{ mb: 1 }}>{controlLabel}</FormLabel>
      <FormGroup row sx={{ flexWrap: 'nowrap' }}>
        <Autocomplete
          options={periodOptions}
          value={period}
          sx={{ mr: 1, minWidth: '200px' }}
          renderInput={(params) => (
            <TextField {...params} label={PERIOD_LABEL} />
          )}
          isOptionEqualToValue={(option, value) =>
            value && option.sequence === value.sequence
          }
          onChange={(_, period, reason) => handlePeriodChange(period, reason)}
          disabled={disabled}
        />
        <MinMaxInput
          sx={{ mr: 1, minWidth: '100px' }}
          label={MINUTES_LABEL}
          min={MIN_MINUTES_VALUE}
          max={MAX_MINUTES_VALUE}
          value={minutes}
          onNumberChange={(minutes) => changeTime(`${minutes}:${seconds}`)}
          disabled={disabled}
        />
        <MinMaxInput
          label={SECONDS_LABEL}
          sx={{ minWidth: '100px' }}
          min={MIN_SECONDS_VALUE}
          max={MAX_SECONDS_VALUE}
          value={seconds}
          onNumberChange={(seconds) => changeTime(`${minutes}:${seconds}`)}
          disabled={disabled}
        />
      </FormGroup>
    </FormControl>
  );
};
