import { Autocomplete, TextField } from '@mui/material';
import { FILTER_NAME } from '@/stores/FiltersStore/constants';
import { Extras } from '@contract';
import type { FilterComponent } from '../constants';

const EXTRAS_OPTIONS: Extras[] = Object.keys(Extras)
  .filter((key) => isNaN(parseInt(key)))
  .sort()
  .map((key) => Extras[key as keyof typeof Extras]);

export const ExtrasFilter: FilterComponent<'extras'> = ({
  value,
  onChange,
}) => {
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      onChange={(_, extras) => {
        onChange(extras);
      }}
      options={EXTRAS_OPTIONS}
      autoHighlight
      getOptionLabel={(option) => Extras[option]}
      renderInput={(params) => (
        <TextField
          sx={{ textTransform: 'capitalize' }}
          {...params}
          label={FILTER_NAME.EXTRAS}
        />
      )}
      value={value}
    />
  );
};
