import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  DEFAULT_FILTER_VALUE,
  FILTER_NAME,
  FilterValue,
} from '@/stores/FiltersStore/constants';
import { isDebugEnabled, isTraceStoreEnabled } from '@/utils/debug';
import { sortByActionTimes } from '@/utils/sortByActionTimes';
import { makeFilters } from '@/utils/filters';
import { TableAction, useFixtureStore } from '../FixtureStore';
import { mapToTableAction } from '../tableActionMapper';
import { makeActionTimestampMap } from '../utils';

type PeriodActionTimestampInfo = {
  timestamps: Array<TableAction['clockTimeTicks']>;
  timestampAction: Record<
    TableAction['clockTimeTicks'],
    TableAction['actionId']
  >;
};
export type ActionTimestampMap = Record<
  TableAction['period']['sequence'],
  PeriodActionTimestampInfo
>;

type FiltersStore = {
  tableActions: TableAction[];

  /**
   * A helper map built from table actions.
   * It stores action ids per period and action clock time.
   * Used to quickly find (by object key) closest action to given
   * clock time ticks. For example in QA view, where clock time
   * can change quickly.
   */
  actionTimestampMap: ActionTimestampMap;

  /**
   * Enables/disables creating action timestamp map
   */
  isMappingActionTimestamps: boolean;

  filterValues: FilterValue;
  isDrawerOpen: boolean;
};

const DEFAULT_STATE: FiltersStore = {
  tableActions: [],
  filterValues: DEFAULT_FILTER_VALUE,
  isDrawerOpen: false,
  isMappingActionTimestamps: false,
  actionTimestampMap: {},
};

export const useFiltersStore = create<FiltersStore>()(
  devtools(() => ({ ...DEFAULT_STATE }), {
    enabled: isDebugEnabled(),
    trace: isTraceStoreEnabled,
    name: 'FiltersStore',
  }),
);

export function reset() {
  useFiltersStore.setState(DEFAULT_STATE, false, 'reset');
}

export function useHasFilters() {
  const filters = useFiltersStore((state) => state.filterValues);
  return (
    filters[FILTER_NAME.PLAYERS].option !==
      DEFAULT_FILTER_VALUE[FILTER_NAME.PLAYERS].option ||
    filters[FILTER_NAME.EVENTS].length ||
    filters[FILTER_NAME.EXTRAS].length ||
    filters[FILTER_NAME.PERIOD].length ||
    filters[FILTER_NAME.OUTCOME] !==
      DEFAULT_FILTER_VALUE[FILTER_NAME.OUTCOME] ||
    filters[FILTER_NAME.FLAGS] !== DEFAULT_FILTER_VALUE[FILTER_NAME.FLAGS] ||
    filters[FILTER_NAME.WARNINGS] !==
      DEFAULT_FILTER_VALUE[FILTER_NAME.WARNINGS] ||
    filters[FILTER_NAME.STATUS] !== DEFAULT_FILTER_VALUE[FILTER_NAME.STATUS] ||
    filters[FILTER_NAME.TEAM] !== DEFAULT_FILTER_VALUE[FILTER_NAME.TEAM]
  );
}

export const applyFilters = () => {
  const filterValues = useFiltersStore.getState().filterValues;

  const actions = useFixtureStore.getState().actions;
  const actionsAsArray = [...actions.values()].map((value) => value[0]);

  const filters = makeFilters(filterValues);
  const filteredActions = filters.reduce((acc, filterFn) => {
    return acc.filter(filterFn);
  }, actionsAsArray);

  const tableActions = filteredActions
    .filter(({ isRemoved }) => !isRemoved)
    .map(mapToTableAction)
    .sort(sortByActionTimes);

  useFiltersStore.setState({ tableActions }, false, 'applyFilters');
};

export function setFilterValues(filterValues: FilterValue) {
  return useFiltersStore.setState({ filterValues }, false, 'setFilterValues');
}

export function toggleDrawer() {
  return useFiltersStore.setState(
    (state) => ({
      isDrawerOpen: !state.isDrawerOpen,
    }),
    false,
    'toggleDrawer',
  );
}

export function openDrawer() {
  useFiltersStore.setState({ isDrawerOpen: true }, false, 'openDrawer');
}
export function closeDrawer() {
  useFiltersStore.setState({ isDrawerOpen: false }, false, 'closeDrawer');
}

export function setIsMappingActionTimestamps(
  isMappingActionTimestamps: FiltersStore['isMappingActionTimestamps'],
) {
  useFiltersStore.setState(
    (state) => {
      if (state.isMappingActionTimestamps === isMappingActionTimestamps)
        return state;

      const actionTimestampMap = isMappingActionTimestamps
        ? makeActionTimestampMap(state.tableActions)
        : DEFAULT_STATE.actionTimestampMap;

      return {
        isMappingActionTimestamps,
        actionTimestampMap,
      };
    },
    false,
    'setIsMappingActionTimestamps',
  );
}

export const autoMapActionsTimestamps = useFiltersStore.subscribe(
  (state, prevState) => {
    if (!state.isMappingActionTimestamps) return;
    if (state.tableActions === prevState.tableActions) {
      return;
    }
    useFiltersStore.setState(
      {
        actionTimestampMap: makeActionTimestampMap(state.tableActions),
      },
      false,
      'actionTimestampsAutoUpdate',
    );
  },
);
