import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { FIXTURES_COLLECTIONS_COLUMNS } from './constants';

export const EmptyTableBody: FC<PropsWithChildren> = ({ children }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={FIXTURES_COLLECTIONS_COLUMNS.length}>
          <Typography
            variant='body2'
            sx={{ textAlign: 'center', padding: '8px' }}
          >
            {children}
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};
