import { FC } from 'react';
import { type ClearanceAction } from '@/types/action/action';
import { ToggleHeaded } from './MenuItems/ToggleHeaded';

interface ClearanceContextMenuProps {
  action: ClearanceAction;
}

export const ClearanceContextMenu: FC<ClearanceContextMenuProps> = ({
  action,
}) => {
  return (
    <>
      <ToggleHeaded action={action} />
    </>
  );
};
