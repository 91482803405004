import { v1 as uuid } from 'uuid';
import {
  Action,
  CrossAction,
  GoalkeeperKickFromHandsAction,
  GoalkeeperThrowAction,
  LaunchAction,
  PassAction,
} from '@/types/action/action';
import { ACTION_TYPE_ID, METADATA_KEY } from '@contract';
import type { Coords } from '@/types';
import { getActionMetadata } from '@/utils/actions';
import type { ActionBaseSpecific } from '@/types/action/base';
import { ACTION_STATE } from '../constants';
import { getCurrentClock } from '../ClockStore';
import {
  extractCurrentPeriodDto,
  findPotentialParentActionId,
  useFixtureStore,
} from '../FixtureStore';
import { useUserStore } from '../UserStore';
import { useLineupStore } from '../LineupStore/LineupStore';
import { useActionStore } from './ActionStore';
import {
  ACTION_OUTCOME_DEFAULT_SUCCESS,
  MODE,
  TargetActionMode,
} from './constants';

function getDefaultIsSuccessful(actionTypeId: Action['actionTypeId']) {
  if (ACTION_OUTCOME_DEFAULT_SUCCESS.includes(actionTypeId)) {
    return true;
  }
  return null;
}

export function createActionBase<T extends Action>(
  actionTypeId: T['actionTypeId'],
  actionTypeMetadata: T['actionTypeMetadata'],
): ActionBaseSpecific<T> | null {
  const { currentPeriodConfig, dataCollectionId, fixtureId } =
    useFixtureStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !fixtureId || !userId) {
    return null;
  }

  const { teamId, teamName } = useLineupStore.getState();
  const { player } = useActionStore.getState();
  const team = teamId && teamName ? { id: teamId, name: teamName } : null;

  const now = Date.now();
  const actionBase: Omit<Action, 'metadata'> = {
    actionId: uuid(),
    actionTypeId,
    actionTypeMetadata,
    createdAt: now,
    updatedAt: now,
    fixtureId,
    dataCollectionId,
    isRemoved: false,
    messageId: null,
    team,
    period: extractCurrentPeriodDto(currentPeriodConfig),
    teamPossessionPhaseId: null,
    userId,
    warnings: null,
    player,
    isSuccessful: getDefaultIsSuccessful(actionTypeId),
    state: ACTION_STATE.NONE,
    ...getCurrentClock(),
    parentActionId: null,
  };
  actionBase.parentActionId = findPotentialParentActionId(actionBase);
  return actionBase;
}

export function createAction<T extends Action>(
  actionTypeId: T['actionTypeId'],
  actionTypeMetadata: T['actionTypeMetadata'],
  metadata?: ActionMeta<T>,
) {
  const actionBase = createActionBase(actionTypeId, actionTypeMetadata);
  if (!actionBase) return null;

  const actionMetadata = {
    [actionTypeMetadata]: metadata || {},
  } as { [key in T['actionTypeMetadata']]: ActionMeta<T> } as T['metadata'];

  const action = {
    ...actionBase,
    metadata: actionMetadata,
  };
  return action as T;
}

export function createPendingPass(data: {
  coords: Coords;
  mode?: TargetActionMode;
}):
  | PassAction
  | CrossAction
  | LaunchAction
  | GoalkeeperThrowAction
  | GoalkeeperKickFromHandsAction
  | null {
  const actionMetadata: ActionMeta<
    | PassAction
    | CrossAction
    | LaunchAction
    | GoalkeeperThrowAction
    | GoalkeeperKickFromHandsAction
  > = {
    position: { x: data.coords.x, y: data.coords.y },
    targetPosition: { x: data.coords.x, y: data.coords.y },
    direction: null,
    distance: null,
    isAssist: false,
    actionContext: null,
    bodyPart: null,
  };

  if (data.mode === MODE.CROSS) {
    return createAction<CrossAction>(
      ACTION_TYPE_ID.Cross,
      METADATA_KEY.cross,
      actionMetadata,
    );
  }
  if (data.mode === MODE.LAUNCH) {
    return createAction<LaunchAction>(
      ACTION_TYPE_ID.Launch,
      METADATA_KEY.launch,
      actionMetadata,
    );
  }
  if (data.mode === MODE.GOALKEEPER_THROW) {
    return createAction<GoalkeeperThrowAction>(
      ACTION_TYPE_ID.GoalkeeperThrow,
      METADATA_KEY.goalkeeperThrow,
      actionMetadata,
    );
  }
  if (data.mode === MODE.GOALKEEPER_KICK_FROM_HANDS) {
    return createAction<GoalkeeperKickFromHandsAction>(
      ACTION_TYPE_ID.GoalkeeperKickFromHands,
      METADATA_KEY.goalkeeperKickFromHands,
      actionMetadata,
    );
  }
  return createAction<PassAction>(
    ACTION_TYPE_ID.Pass,
    METADATA_KEY.pass,
    actionMetadata,
  );
}

export function modifyActionMetadata<
  T extends Action,
  Metadata extends ActionMeta<T>,
>(action: T, metadata: Partial<Metadata>) {
  const key = action.actionTypeMetadata;
  if (action.actionTypeMetadata !== key) return action;
  if (!(key in action.metadata)) return action;

  const currentMetadata = getActionMetadata(action);
  const newAction: T = {
    ...action,
    metadata: {
      [key]: {
        ...currentMetadata,
        ...metadata,
      },
    },
  };
  return newAction;
}
