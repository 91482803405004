import { Chip, Zoom } from '@mui/material';
import { useStartingLineupsContext } from './StartingLineupContext';

const TEST_ID = 'formation-chip';

export const Formation = () => {
  const startingLineupStore = useStartingLineupsContext();
  const formation = startingLineupStore.useStore((state) => state.formation);

  return (
    <Zoom in={!!formation}>
      <Chip data-testid={TEST_ID} label={formation} />
    </Zoom>
  );
};
