import { FC } from 'react';
import Person from '@mui/icons-material/Person';
import { getActionWarnings } from '@/components/ActionWarnings/helpers';
import { PlayersList } from '@/components/PlayersList/PlayersList';
import { postAction } from '@/service/helpers/postAction';
import { setContextAction } from '@/stores/ActionStore/ActionStore';
import { PLAYER_LABEL } from '@/constants';
import type { PropsWithAction } from '../ActionContextMenuContent';
import { NestedMenuItem } from '../NestedMenuItem';

export const ChangePlayer: FC<PropsWithAction> = ({ action }) => {
  if (!action.player) return;

  const handlePlayerSelection = (player: (typeof action)['player']) => {
    const editedAction = {
      ...action,
      player,
    };

    editedAction.warnings = getActionWarnings(editedAction);

    postAction(editedAction);
    setContextAction(null);
  };

  return (
    <NestedMenuItem menuItemLabel={PLAYER_LABEL} icon={<Person />}>
      <PlayersList action={action} onClick={handlePlayerSelection} />
    </NestedMenuItem>
  );
};
