import { FC, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { PeriodDto } from '@contract';
import { PassAction } from '@/types/action/action';
import { PeriodTimeSelect } from '@/components/Form/PeriodTimeSelect';
import { postAction } from '@/service/helpers/postAction';
import { timeStringToSeconds } from '@/utils/timeStringToSeconds';
import {
  COMMIT_BUTTON_LABEL,
  RESET_BUTTON_LABEL,
  TIME_LABEL,
} from '@/constants';
import { setContextAction } from '@/stores/ActionStore/ActionStore';
import type { PropsWithAction } from '../ActionContextMenuContent';

export const EditActionTimePanel: FC<PropsWithAction> = ({ action }) => {
  const [clockTime, setClockTime] = useState(action.clockTime);
  const [period, setPeriod] = useState(action.period);
  const hasChanged =
    clockTime !== action.clockTime ||
    period?.sequence !== action.period?.sequence;

  const onReset = () => {
    setClockTime(action.clockTime);
    setPeriod(action.period);
  };

  const onPeriodChange = (period: PeriodDto) => {
    setPeriod(period);
  };

  const onClockTimeChange = (clockTime: string) => {
    setClockTime(clockTime);
  };

  const onSubmit = () => {
    postAction({
      ...(action as PassAction),
      period,
      clockTime,
      clockTimeTicks: timeStringToSeconds(clockTime),
    });
    setContextAction(null);
  };

  return (
    <Stack gap={2} sx={{ p: 2 }}>
      <PeriodTimeSelect
        onClockTimeChange={onClockTimeChange}
        period={period}
        onPeriodChange={onPeriodChange}
        controlLabel={TIME_LABEL}
        clockTime={clockTime}
      />
      <Stack justifyContent={'flex-end'} direction='row'>
        <Button onClick={onReset} variant='text' disabled={!hasChanged}>
          {RESET_BUTTON_LABEL}
        </Button>
        <Button
          onClick={onSubmit}
          variant='outlined'
          disabled={!hasChanged}
          sx={{ flex: '1' }}
        >
          {COMMIT_BUTTON_LABEL}
        </Button>
      </Stack>
    </Stack>
  );
};
