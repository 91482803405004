const API_BASE_URL =
  import.meta.env.VITE_API_URL + '/' + import.meta.env.VITE_API_VERSION;

export const SERVICE_ENDPOINT = {
  ClockAction: {
    periodStart: `${API_BASE_URL}/ClockAction/period/start`,
    periodEnd: `${API_BASE_URL}/ClockAction/period/end`,
    fixtureStart: `${API_BASE_URL}/ClockAction/fixture/start`,
    fixtureEnd: `${API_BASE_URL}/ClockAction/fixture/end`,
  },
  PassAction: {
    post: `${API_BASE_URL}/PassAction`,
  },
  ShotAction: {
    post: `${API_BASE_URL}/ShotAction`,
  },
  GoalAction: {
    post: `${API_BASE_URL}/GoalAction`,
  },
  Lineups: {
    get: `${API_BASE_URL}/Lineups`,
  },
  DataCollections: {
    get: `${API_BASE_URL}/DataCollections`,
  },
  DataCollection: {
    get: (id: string) => `${API_BASE_URL}/DataCollections/${id}`,
  },
  Error: {
    post: `${API_BASE_URL}/ErrorAction`,
  },
  CardAction: {
    Yellow: {
      post: `${API_BASE_URL}/YellowCardAction`,
    },
    Red: {
      post: `${API_BASE_URL}/RedCardAction`,
    },
  },
  PlayerLeftAction: {
    post: `${API_BASE_URL}/PlayerLeftAction`,
  },
  PlayerReturnedAction: {
    post: `${API_BASE_URL}/PlayerReturnedAction`,
  },
  ClearanceAction: { post: `${API_BASE_URL}/ClearanceAction` },
  GoalkeeperAction: {
    Catch: {
      post: `${API_BASE_URL}/GoalkeeperAction/catch`,
    },
    Collection: {
      post: `${API_BASE_URL}/GoalkeeperAction/collection`,
    },
    Drop: {
      post: `${API_BASE_URL}/GoalkeeperAction/drop`,
    },
    Punch: {
      post: `${API_BASE_URL}/GoalkeeperAction/punch`,
    },
    Save: {
      post: `${API_BASE_URL}/GoalkeeperAction/save`,
    },
    Throw: {
      post: `${API_BASE_URL}/GoalkeeperAction/throw`,
    },
    KickFromHands: {
      post: `${API_BASE_URL}/GoalkeeperAction/kickFromHands`,
    },
  },
  ThrowInAction: {
    Awarded: {
      post: `${API_BASE_URL}/ThrowInAction/awarded`,
    },
    Taken: {
      post: `${API_BASE_URL}/ThrowInAction/taken`,
    },
  },
  CornerAction: {
    Awarded: {
      post: `${API_BASE_URL}/CornerAction/awarded`,
    },
    Taken: {
      post: `${API_BASE_URL}/CornerAction/taken`,
    },
  },
  GoalKickAction: {
    Awarded: {
      post: `${API_BASE_URL}/GoalKickAction/awarded`,
    },
    Taken: {
      post: `${API_BASE_URL}/GoalKickAction/taken`,
    },
  },
  FreeKickAction: {
    Awarded: {
      post: `${API_BASE_URL}/FreeKickAction/awarded`,
    },
    Taken: {
      post: `${API_BASE_URL}/FreeKickAction/taken`,
    },
  },
  VARAction: {
    Underway: { post: `${API_BASE_URL}/VARAction/underway` },
    Result: { post: `${API_BASE_URL}/VARAction/result` },
  },
  SubstitutionAction: {
    post: `${API_BASE_URL}/SubstitutionAction`,
  },
  InterceptionAction: { post: `${API_BASE_URL}/InterceptionAction` },
  OffsideAction: { post: `${API_BASE_URL}/OffsideAction` },
  AerialAction: { post: `${API_BASE_URL}/AerialAction` },
  DispossessedAction: { post: `${API_BASE_URL}/DispossessedAction` },
  BallTouchAction: { post: `${API_BASE_URL}/BallTouchAction` },
  BallRecoveryAction: { post: `${API_BASE_URL}/BallRecoveryAction` },
  GoalkeeperPenaltyFacedAction: {
    post: `${API_BASE_URL}/GoalkeeperPenaltyFacedAction`,
  },
  BlockAction: { post: `${API_BASE_URL}/BlockAction` },
  OffsideForAction: { post: `${API_BASE_URL}/OffsideForAction` },
  CrossAction: { post: `${API_BASE_URL}/CrossAction` },
  LaunchAction: { post: `${API_BASE_URL}/LaunchAction` },
  PlayAction: {
    Resumed: {
      post: `${API_BASE_URL}/PlayAction/resumed`,
    },
    Stopped: {
      post: `${API_BASE_URL}/PlayAction/stopped`,
    },
  },
  TackleAction: {
    post: `${API_BASE_URL}/TackleAction`,
  },
  BadTouchAction: {
    post: `${API_BASE_URL}/BadTouchAction`,
  },
  AdditionalTimeAction: {
    post: `${API_BASE_URL}/AdditionalTimeAction`,
  },
  PlayerBeatenAction: {
    post: `${API_BASE_URL}/PlayerBeatenAction`,
  },
  TakeOnAction: {
    post: `${API_BASE_URL}/TakeOnAction`,
  },
  RefereeDropBall: {
    post: `${API_BASE_URL}/RefereeDropBallAction`,
  },
  LineupsAction: {
    post: `${API_BASE_URL}/LineupsAction`,
  },
  FormationAction: {
    post: `${API_BASE_URL}/FormationAction`,
  },
  PostMatchCheckCompleteAction: {
    post: `${API_BASE_URL}/PostMatchCheckCompleteAction`,
  },
} as const;
