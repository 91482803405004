export const YES_LABEL = 'Yes';
export const NO_LABEL = 'No';
export const CANCEL_BUTTON_LABEL = 'Cancel';
export const COMMIT_BUTTON_LABEL = 'Commit';
export const RESET_BUTTON_LABEL = 'Reset';
export const DELETE_LABEL = 'Delete';
export const HOME_BUTTON_LABEL = 'Home';
export const SIGN_OUT_BUTTON_LABEL = 'Sign out';
export const SAVE_BUTTON_LABEL = 'Save';
export const CREATE_BUTTON_LABEL = 'Create';

export const NO_ACTIONS_ADDED_LABEL = 'No actions have been added yet';

export const ATTEMPT_TYPE_LABEL = 'Attempt type';
export const ATTEMPT_BY_LABEL = 'Attempt by';
export const ASSIST_LABEL = 'Assist';
export const TIME_LABEL = 'Time';
export const SELECT_TIME_LABEL = 'Select time';
export const PLAYER_LABEL = 'Player';
export const OUTCOME_LABEL = 'Outcome';
export const FROM_PLAYER_LABEL = 'From player';
export const PATTERN_OF_PLAY_LABEL = 'Pattern of play';
export const BODY_PARTS_LABEL = 'Body parts';
export const OFF_TARGET_DESTINATION_LABEL = 'Off target destination';
export const EXTRAS_LABEL = 'Extras';
export const GOAL_TYPE_LABEL = 'Type';
export const FLAG_LABEL = 'Flag';
export const CARD_LABEL = 'Card';
export const REASON_LABEL = 'Please select a reason';
export const PLAYER_CARDED_LABEL = 'Player carded';
export const OPPOSITE_TEAM_LABEL = 'Opposite team';
export const HEADED_LABEL = 'Headed';
export const STANCE_LABEL = 'Stance';
export const TOGGLE_ACTION_CONTEXT_LABEL = 'Toggle action context';
export const ACTION_CONTEXT_LABEL = 'Action Context';
export const PENALTY_LABEL = 'Penalty';
export const CORNER_TAKEN_LABEL = 'Corner Taken';
export const FREE_KICK_TAKEN_LABEL = 'Free Kick Taken';
export const GOAL_KICK_TAKEN_LABEL = 'Goal Kick Taken';
export const PENALTY_TAKEN_LABEL = 'Penalty Taken';
export const KICK_OFF_START_PERIOD_LABEL = 'Kick-Off - Start Period';
export const KICK_OFF_AFTER_GOAL_LABEL = 'Kick-Off - After Goal';
export const NONE_LABEL = 'None';
