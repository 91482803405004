import { Extras, GoalType, PatternOfPlay } from '@contract';

const EXTRAS = {
  [Extras.Woodwork]: 'Woodwork',
  [Extras.Deflection]: 'Deflection',
  [Extras.BigChance]: 'Big chance',
};

type ExtrasOptions = Extras.Woodwork | Extras.Deflection | Extras.BigChance;

export const getGoalExtras = (patternOfPlay: PatternOfPlay) =>
  Object.keys(EXTRAS).map((k) => {
    const key = Number(k) as ExtrasOptions;
    return {
      value: key,
      label: EXTRAS[key],
      isDisabled:
        key === Extras.BigChance && patternOfPlay === PatternOfPlay.Penalty,
    };
  });

export const GOAL_TYPE = {
  [GoalType.Goal]: 'Goal',
  [GoalType.OwnGoal]: 'Own Goal',
};
