import { FC, useRef } from 'react';
import { ViewportListRef } from 'react-viewport-list';
import { Stack, TableContainer } from '@mui/material';
import { TableAction } from '@/stores/FixtureStore';
import { EventsTableHeader } from './EventsTableHeader';
import { EventsTableBody } from './EventsTableBody';

interface EventsTableProps {
  actions: TableAction[];
}

export const EventsTable: FC<EventsTableProps> = ({ actions }) => {
  const viewportRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<ViewportListRef | null>(null);

  return (
    <TableContainer id='event-history-table' ref={viewportRef}>
      <Stack sx={{ minWidth: 900 }}>
        <EventsTableHeader />
        <EventsTableBody
          actions={actions}
          listRef={listRef}
          viewportRef={viewportRef}
        />
      </Stack>
    </TableContainer>
  );
};
