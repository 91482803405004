import { Autocomplete, TextField } from '@mui/material';
import { useMemo } from 'react';
import {
  FILTER_NAME,
  type PlayerType,
  CommonOptions,
  type FilterPlayer,
} from '@/stores/FiltersStore/constants';
import { makeTablePlayerName } from '@/stores/utils';
import { camelCaseToSpace } from '@/utils/string';
import { RadioButtons } from '@/components/Form/RadioButtons';
import { useFixtureStore } from '@/stores/FixtureStore';
import { useGameStateAt } from '@/stores/hooks';
import { composeLineup } from '@/stores/LineupStore/utils';
import { LINEUP_NAMED_POSITION } from '@/stores/LineupStore/constants';
import {
  FiltersDrawerVariant,
  PLAYER_SWITCH_NAME,
  type FilterComponent,
} from '../constants';

export const PlayersFilter: FilterComponent<'players'> = ({
  value,
  onChange,
  variant,
}) => {
  const { playersSelected, option } = value;
  const showPlayerAutocomplete = useMemo(
    () =>
      variant === FiltersDrawerVariant.COLLECTOR ||
      (variant === FiltersDrawerVariant.QA &&
        option === CommonOptions.Assigned),
    [variant, option],
  );
  const gameState = useGameStateAt(0, 0);
  const homeTeam = useFixtureStore((state) => state.homeTeam);
  const awayTeam = useFixtureStore((state) => state.awayTeam);

  const showPlayerSwitch = useMemo(
    () => variant === FiltersDrawerVariant.QA,
    [variant],
  );

  const playerOptions: FilterPlayer[] = useMemo(() => {
    const { lineups } = gameState;
    const playerOptions: FilterPlayer[] = [];
    if (homeTeam && lineups[homeTeam.id]) {
      const homePlayers = composeLineup(lineups[homeTeam.id])
        .filter((pp) => pp.position !== LINEUP_NAMED_POSITION.OFF)
        .map((pp) => ({ ...pp.player, teamName: homeTeam.name }));
      playerOptions.push(...homePlayers);
    }
    if (awayTeam && lineups[awayTeam.id]) {
      const awayPlayers = composeLineup(lineups[awayTeam.id])
        .filter((pp) => pp.position !== LINEUP_NAMED_POSITION.OFF)
        .map((pp) => ({ ...pp.player, teamName: awayTeam.name }));
      playerOptions.push(...awayPlayers);
    }
    return playerOptions;
  }, [gameState, homeTeam, awayTeam]);

  const setValue = (partialValue: Partial<PlayerType>) => {
    const updatedValue = { ...value, ...partialValue };

    if (variant === FiltersDrawerVariant.COLLECTOR) {
      updatedValue.option = CommonOptions.Assigned;
    }

    onChange(updatedValue);
  };

  return (
    <>
      {showPlayerSwitch && (
        <RadioButtons
          fields={CommonOptions}
          value={option}
          name={PLAYER_SWITCH_NAME}
          onChange={(option) => setValue({ option })}
          controlLabel={camelCaseToSpace(PLAYER_SWITCH_NAME)}
        />
      )}
      {showPlayerAutocomplete && (
        <Autocomplete
          multiple
          disableCloseOnSelect
          onChange={(_, playersSelected) => {
            setValue({ playersSelected });
          }}
          groupBy={(option) => option.teamName}
          options={playerOptions}
          autoHighlight
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => makeTablePlayerName(option)}
          renderInput={(params) => (
            <TextField
              sx={{ textTransform: 'capitalize' }}
              {...params}
              label={FILTER_NAME.PLAYERS}
            />
          )}
          value={playersSelected}
        />
      )}
    </>
  );
};
