import { useReducer } from 'react';

type SimpleReducerUpdate<T> = (state: T, update: Partial<T>) => T;

export function useSimpleReducer<StateType>(initialState: StateType) {
  return useReducer<SimpleReducerUpdate<StateType>>(
    (state, update) => ({
      ...state,
      ...update,
    }),
    initialState,
  );
}
