import { setMode } from '@/stores/ActionStore/ActionStore';
import { commitAction } from '@/stores/ActionStore/commitActions';
import { MODE } from '@/stores/ActionStore/constants';
import { ACTION_TYPE_ID } from '@contract';

const passButtons = {
  title: 'Pass Actions',
  buttons: [
    { onClick: () => setMode(MODE.LAUNCH), label: 'Launch' },
    { onClick: () => setMode(MODE.CROSS), label: 'Cross' },
  ],
};

const outOfPlayButtons = {
  title: 'Out of Play Actions',
  buttons: [
    {
      onClick: () => commitAction(ACTION_TYPE_ID.CornerAwarded),
      label: 'Corner awarded',
    },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.ThrowInAwarded),
      label: 'Throw in awarded',
    },
    { onClick: () => setMode(MODE.THROW_IN), label: 'Throw in taken' },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.GoalKickAwarded),
      label: 'Goal kick awarded',
    },
  ],
};

const otherButtons = {
  title: 'Other Actions',
  buttons: [
    {
      onClick: () => commitAction(ACTION_TYPE_ID.BallTouch),
      label: 'Ball touch',
    },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.Interception),
      label: 'Interception',
    },
    { onClick: () => commitAction(ACTION_TYPE_ID.Aerial), label: 'Aerial' },
    { onClick: () => commitAction(ACTION_TYPE_ID.Tackle), label: 'Tackle' },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.BallRecovery),
      label: 'Ball recovery',
    },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.Dispossessed),
      label: 'Dispossessed',
    },
    { onClick: () => commitAction(ACTION_TYPE_ID.TakeOn), label: 'Take on' },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.Clearance),
      label: 'Clearance',
    },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.BadTouch),
      label: 'Bad touch',
    },
    { onClick: () => setMode(MODE.ERROR), label: 'Error' },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.PlayerLeft),
      label: 'Player left',
    },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.PlayerReturned),
      label: 'Player returned',
    },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.PlayerBeaten),
      label: 'Player beaten',
    },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.RefereeDropBall),
      label: 'Referee drop ball',
    },
  ],
};

const goalkeeperButtons = {
  title: 'GK Actions',
  buttons: [
    { onClick: () => setMode(MODE.GOALKEEPER_SAVE), label: 'Goalkeeper save' },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.GoalkeeperCatch),
      label: 'Goalkeeper catch',
    },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.GoalkeeperCollection),
      label: 'Goalkeeper collection',
    },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.GoalkeeperDrop),
      label: 'Goalkeeper drop',
    },
    {
      onClick: () => setMode(MODE.GOALKEEPER_THROW),
      label: 'Goalkeeper throw',
    },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.GoalkeeperPunch),
      label: 'Goalkeeper punch',
    },
    {
      onClick: () => setMode(MODE.GOALKEEPER_PENALTY_FACED),
      label: 'Goalkeeper penalty faced',
    },
    {
      onClick: () => setMode(MODE.GOALKEEPER_KICK_FROM_HANDS),
      label: 'GK kick - hands',
    },
  ],
};

const keyButtons = {
  title: 'Key Actions',
  buttons: [
    { onClick: () => setMode(MODE.YELLOW_CARD), label: 'Yellow Card' },
    { onClick: () => setMode(MODE.RED_CARD), label: 'Red Card' },
    { onClick: () => setMode(MODE.SUBSTITUTION), label: 'Substitution' },
    { onClick: () => setMode(MODE.FREE_KICK), label: 'Free kick awarded' },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.Offside),
      label: 'Offside Against',
    },
    {
      onClick: () => commitAction(ACTION_TYPE_ID.OffsideFor),
      label: 'Offside for',
    },
  ],
};

const timeButtons = {
  title: 'Time Actions',
  buttons: [
    {
      onClick: () => commitAction(ACTION_TYPE_ID.PlayResumed),
      label: 'Play resumed',
    },
    { onClick: () => setMode(MODE.PLAY_STOPPED), label: 'Play stopped' },
    { onClick: () => setMode(MODE.VAR_UNDERWAY), label: 'VAR underway' },
    { onClick: () => setMode(MODE.VAR_RESULT), label: 'VAR result' },
    { onClick: () => setMode(MODE.ADDITIONAL_TIME), label: 'Additional time' },
  ],
};

export const leftColumnButtons = [passButtons, otherButtons, keyButtons];

export const rightColumnButtons = [
  outOfPlayButtons,
  goalkeeperButtons,
  timeButtons,
];
