import { FC } from 'react';
import { Stack } from '@mui/material';
import { TableAction } from '@/stores/FixtureStore';
import { COLUMNS, TABLE_CLASS_NAMES } from './constants';
import { EventsTableCell } from './EventsTableCell';

interface EventsTableRowProps {
  action: TableAction;
}

export const EventsTableRow: FC<EventsTableRowProps> = ({ action }) => {
  return (
    <Stack
      className={`${TABLE_CLASS_NAMES.ROW}`}
      direction='row'
      data-action-state={action.state}
      data-action-id={action.actionId}
      sx={{
        backgroundColor: '#292929',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: (theme) => theme.palette.action.hover,
        },
      }}
    >
      {COLUMNS.map((column) => (
        <EventsTableCell key={column.id} action={action} column={column} />
      ))}
    </Stack>
  );
};
