import { Box, type SxProps } from '@mui/material';
import type { FC, PropsWithChildren, SVGProps } from 'react';
import PitchSVG from '@/assets/football-pitch-vertical.svg?react';
import { PitchOrientation } from '../Formation/constants';

type ResponsivePitchProps = PropsWithChildren & {
  variant?: 'goals' | 'noGoals';
  orientation?: PitchOrientation;
  PitchSvg?: FC<SVGProps<SVGSVGElement>>;
  sx?: SxProps;
};

const orientationAspectRatio = {
  goals: {
    [PitchOrientation.Horizontal]: '1130 / 760',
    [PitchOrientation.Vertical]: '760 / 1130',
  },
  noGoals: {
    [PitchOrientation.Horizontal]: '1056 / 686',
    [PitchOrientation.Vertical]: '686 / 1056',
  },
} as const;

export const ResponsivePitch: FC<ResponsivePitchProps> = ({
  variant = 'noGoals',
  orientation = PitchOrientation.Vertical,
  PitchSvg = PitchSVG,
  children,
  sx,
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        maxWidth: '100%',
        maxHeight: '100%',
        aspectRatio: orientationAspectRatio[variant][orientation],
        margin: 'auto 0 auto auto',
        inset: 0,
        ...sx,
      }}
    >
      <PitchSvg
        style={{
          width: '100%',
          height: '100%',
        }}
      />
      {children}
    </Box>
  );
};
