declare global {
  interface Window {
    _ed: {
      setDebug?: (enable: boolean) => void;
      $video?: HTMLVideoElement | null;
      toggleMockData?: () => void;
    };
  }
}

export const SS_KEY = {
  DEBUG: 'debug',
  TRACE_STORE: 'traceStore',
  USE_MOCK_DATA: 'isUsingMockData',
  MOCK_DATA_COLLECTION_CONFIG: 'mockDataCollection',
  MOCK_FIXTURES_COLLECTION: 'mockFixturesCollection',
  MOCK_LINEUPS: 'mockLineups',
  MOCK_COLLECTION: 'mockCollection',
};

export const setupWindow = () => {
  if (!window._ed) {
    window._ed = {};
  }
};

export function registerWindowUtil<Key extends keyof Window['_ed']>(
  key: Key,
  val: Window['_ed'][Key],
) {
  setupWindow();
  window._ed[key] = val;
}
