import { Autocomplete, TextField } from '@mui/material';
import { useMemo } from 'react';
import { FILTER_NAME } from '@/stores/FiltersStore/constants';
import { type PeriodDto } from '@contract';
import { useFixtureStore } from '@/stores/FixtureStore';
import type { FilterComponent } from '../constants';

export const PeriodFilter: FilterComponent<'period'> = ({
  value,
  onChange,
}) => {
  const periodDetails = useFixtureStore((state) => state.periodsConfig);

  const periodOptions = useMemo(() => {
    if (!periodDetails) return [];

    return periodDetails.map((period) => ({
      sequence: period.seq,
      label: period.label,
    })) as PeriodDto[];
  }, [periodDetails]);

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      onChange={(_, period) => onChange(period)}
      options={periodOptions}
      isOptionEqualToValue={(option, value) =>
        value && option.sequence === value.sequence
      }
      autoHighlight
      renderInput={(params) => (
        <TextField
          sx={{ textTransform: 'capitalize' }}
          {...params}
          label={FILTER_NAME.PERIOD}
        />
      )}
      value={value}
    />
  );
};
