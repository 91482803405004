import { ButtonProps } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { FC, MouseEventHandler, useState } from 'react';
import Logout from '@mui/icons-material/Logout';
import { SIGN_OUT_BUTTON_LABEL } from '@/constants';
import { LoadingButton } from '../LoadingButton/LoadingButton';

export const SignOutButton: FC<ButtonProps> = ({ onClick, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { oktaAuth } = useOktaAuth();
  const _onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onClick && typeof onClick === 'function') {
      onClick(e);
    }
    setIsLoading(true);
    oktaAuth.signOut().finally(() => setIsLoading(false));
  };

  return (
    <LoadingButton
      {...props}
      isLoading={isLoading}
      onClick={_onClick}
      startIcon={props.startIcon || <Logout />}
    >
      {SIGN_OUT_BUTTON_LABEL}
    </LoadingButton>
  );
};
