import { HubConnectionState } from '@microsoft/signalr';
import {
  ActionAddedMsg,
  ActionsReceivedMsg,
  ActionsSubscribedMsg,
  SCORING_WORKER_ACTION,
  SCORING_WORKER_HOST_ACTION,
  ScoringWorkerMsgEvent,
  WebSocketConnectionMsg,
  WorkerReadyMsg,
  type ActionsSubscribeErrorMsg,
  type StateReceivedMsg,
  type StateSubscribedMsg,
  type StateSubscribeErrorMsg,
} from '@/workers/scoring/types';
import {
  storeAction,
  setActions,
  setTableActions,
  useFixtureStore,
  setTeamPossessionPhase,
  getInitialPossessionPhaseId,
  checkUnresolvedAwardedActions,
  setCollectionState,
} from '@/stores/FixtureStore';
import { sortByCreatedAt } from '@/utils/sortByActionTimes';
import { konsole } from '@/utils/debug';
import { SocketMessageType } from '@contract';
import { createTableActionsFromActions } from '../tableActionMapper';
import {
  getBackedUpActions,
  cleanSyncedActions,
} from '../UnsyncedActionsStore';
import { useLineupStore } from '../LineupStore/LineupStore';
import {
  setActionsError,
  setActionsReceived,
  setActionsSubscribed,
  setIsWorkerReady,
  setStateError,
  setStateReceived,
  setStateSubscribed,
  setWSConnection,
  useSocketStore,
} from './SocketStore';

function onWorkerReady(message: WorkerReadyMsg) {
  setIsWorkerReady(message.payload.isWorkerReady);
}

function onWsConnection(message: WebSocketConnectionMsg) {
  const { dataCollectionId, fixtureId } = useFixtureStore.getState();

  const { teamId } = useLineupStore.getState();
  const worker = useSocketStore.getState().worker;

  setWSConnection(message.payload.wsConnection);

  if (message.payload.wsConnection !== HubConnectionState.Connected) return;
  /**
   * Socket subscriptions requires collection info response.
   * Sometimes Socket connection happens before that response.
   * In this case we need to make subscriptions later - on response received.
   * When fixture id is known - it means response have been received.
   * In this case we should make Socket subscriptions.
   * WS connection can happen on app start as well as when connection
   * is lost and then restored.
   */
  if (!dataCollectionId || !fixtureId) return;

  worker.postMessage({
    action: SCORING_WORKER_HOST_ACTION.ACTIONS_SUBSCRIBE,
    payload: { dataCollectionId, teamId },
  });
  worker.postMessage({
    action: SCORING_WORKER_HOST_ACTION.STATE_SUBSCRIBE,
    payload: { dataCollectionId },
  });
}

function onActionsSubscribed({ payload }: ActionsSubscribedMsg) {
  setActionsSubscribed(payload.actionsSubscribed);

  if (!payload.actionsSubscribed) {
    onActionsReceived({
      action: SocketMessageType.actionsHistoryReceived,
      payload: { actions: new Map() },
    });
  } else {
    setActionsError(false);
  }
}

export function onActionsReceived({ payload }: ActionsReceivedMsg) {
  setActionsReceived(payload.actions !== null);
  const actions = new Map(payload.actions);
  cleanSyncedActions(actions);

  const backupActions = getBackedUpActions();
  for (const backup of backupActions) {
    const matchedAction = actions.get(backup.actionId);
    if (!matchedAction) {
      actions.set(backup.actionId, [backup]);
      continue;
    }
    actions.set(
      backup.actionId,
      [backup, ...matchedAction].sort(sortByCreatedAt),
    );
  }

  setTeamPossessionPhase(getInitialPossessionPhaseId(actions));
  setActions(actions);
  setTableActions(createTableActionsFromActions(actions));
  checkUnresolvedAwardedActions();
}

function onActionAdded({ payload }: ActionAddedMsg) {
  storeAction(payload.action);
  return false;
}

function onStateSubscribed({ payload }: StateSubscribedMsg) {
  setStateSubscribed(payload.stateSubscribed);
  if (!payload.stateSubscribed) {
    setStateReceived(false);
    return setCollectionState(undefined);
  }
  setStateError(false);
}
function onStateReceived(payload: StateReceivedMsg['payload']) {
  setStateReceived(true);
  setCollectionState(payload);
}

function onActionsError({ payload }: ActionsSubscribeErrorMsg) {
  setActionsError(payload.actionsError);
}
function onStateError({ payload }: StateSubscribeErrorMsg) {
  setStateError(payload.stateError);
}

export function onWorkerMessage({ data }: ScoringWorkerMsgEvent) {
  konsole.log(`workerMsg->${data.action}`, data.payload);
  switch (data.action) {
    case SCORING_WORKER_ACTION.ACTIONS_SUBSCRIBE_ERROR:
      return onActionsError(data);
    case SCORING_WORKER_ACTION.STATE_SUBSCRIBE_ERROR:
      return onStateError(data);
    case SCORING_WORKER_ACTION.ACTION_ADDED:
      return onActionAdded(data);
    case SCORING_WORKER_ACTION.ACTIONS_SUBSCRIBED:
      return onActionsSubscribed(data);
    case SCORING_WORKER_ACTION.STATE_SUBSCRIBED:
      return onStateSubscribed(data);
    case SCORING_WORKER_ACTION.WS_CONNECTION:
      return onWsConnection(data);
    case SCORING_WORKER_ACTION.ACTIONS_RECEIVED:
      return onActionsReceived(data);
    case SCORING_WORKER_ACTION.WORKER_READY:
      return onWorkerReady(data);
    case SCORING_WORKER_ACTION.STATE_RECEIVED:
      return onStateReceived(data.payload);
    default:
      break;
  }
}
