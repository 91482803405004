import { FC } from 'react';
import { FilterValue } from '../../../stores/FiltersStore/constants';
import {
  FILTERS_COMPONENTS,
  QA_FILTERS,
  FilterComponent,
  FiltersDrawerVariant,
} from '../constants';

interface QAFormProps {
  filterValue: FilterValue;
  onChange: (value: Partial<FilterValue>) => void;
}

export const QAForm: FC<QAFormProps> = ({ filterValue, onChange }) => {
  return QA_FILTERS.map((filterName) => {
    const FilterComponent = FILTERS_COMPONENTS[filterName] as FilterComponent<
      typeof filterName
    >;

    return (
      <FilterComponent
        key={filterName}
        value={filterValue[filterName]}
        onChange={(value) => {
          onChange({ [filterName]: value });
        }}
        variant={FiltersDrawerVariant.QA}
      />
    );
  });
};
