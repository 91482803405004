import { ChangeEvent, FC } from 'react';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { PlayStoppedAction } from '@/types/action/action';
import { OPPOSITE_TEAM_LABEL, PLAYER_LABEL, REASON_LABEL } from '@/constants';
import {
  setAction,
  setActionPartialMetadata,
} from '@/stores/ActionStore/ActionStore';
import { Autocomplete } from '@/components/Form/Autocomplete';
import { PlayStoppedReason } from '@contract';
import { getActionMetadata } from '@/utils/actions';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { PlayerAutocomplete } from '@/components/Form/PlayerAutocomplete';
import { Player } from '@/service/lineups';
import { ParentAction } from '@/components/ParentAction/ParentAction';
import { Controls } from '../Common/Controls';
import { PLAY_STOPPED_REASON_OPTIONS } from './constants';

interface PlayStoppedDialogContentProps {
  action: PlayStoppedAction;
}

export const PlayStoppedDialogContent: FC<PlayStoppedDialogContentProps> = ({
  action,
}) => {
  const metadata = getActionMetadata(action);

  const onPlayerChange = (player: Player | null) => {
    setAction({
      ...action,
      player,
    });
  };

  const onReasonChange = (reason: PlayStoppedReason) => {
    if (metadata.reason === PlayStoppedReason.Injury) {
      setAction({ ...action, player: null });
    }

    setActionPartialMetadata<PlayStoppedAction>({
      reason,
      isFromOppositeTeam: false,
    });
  };

  const onOppositeTeamChange = (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (metadata.reason === PlayStoppedReason.Injury) {
      setAction({ ...action, player: null });
    }

    setActionPartialMetadata<PlayStoppedAction>({
      isFromOppositeTeam: checked,
    });
  };

  return (
    <Stack gap={2}>
      <Autocomplete
        options={PLAY_STOPPED_REASON_OPTIONS}
        value={metadata.reason}
        onChange={onReasonChange}
        label={REASON_LABEL}
      />
      <PlayerAutocomplete
        label={PLAYER_LABEL}
        player={action.player}
        periodNumber={action.period.sequence}
        clockTimeTicks={action.clockTimeTicks}
        teamId={action.team?.id}
        disabled={
          metadata.reason !== PlayStoppedReason.Injury ||
          !!metadata.isFromOppositeTeam
        }
        onChange={onPlayerChange}
      />
      <PeriodClockField action={action} />
      <ParentAction action={action} />
      <FormControlLabel
        control={
          <Checkbox
            onChange={onOppositeTeamChange}
            disabled={metadata.reason !== PlayStoppedReason.Injury}
            checked={!!metadata.isFromOppositeTeam}
          />
        }
        label={OPPOSITE_TEAM_LABEL}
      />
      <Controls action={action} />
    </Stack>
  );
};
