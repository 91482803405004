/* eslint-disable no-restricted-imports */
export * from '@img-arena/sps-msed-contract';
import { MetadataDto } from '@img-arena/sps-msed-contract';

export { FixtureActionType as ACTION_TYPE_ID } from '@img-arena/sps-msed-contract';

export const METADATA_KEY: { [K in keyof MetadataDto]: K } = {
  additionalTime: 'additionalTime',
  aerial: 'aerial',
  ballRecovery: 'ballRecovery',
  ballTouch: 'ballTouch',
  error: 'error',
  yellowCard: 'yellowCard',
  redCard: 'redCard',
  clearance: 'clearance',
  clock: 'clock',
  cornerAwarded: 'cornerAwarded',
  cross: 'cross',
  dispossessed: 'dispossessed',
  freeKickAwarded: 'freeKickAwarded',
  goal: 'goal',
  goalkeeperCatch: 'goalkeeperCatch',
  goalkeeperCollection: 'goalkeeperCollection',
  goalkeeperDrop: 'goalkeeperDrop',
  goalkeeperPenaltyFaced: 'goalkeeperPenaltyFaced',
  goalkeeperPunch: 'goalkeeperPunch',
  goalkeeperSave: 'goalkeeperSave',
  goalKickAwarded: 'goalKickAwarded',
  interception: 'interception',
  launch: 'launch',
  lineups: 'lineups',
  offside: 'offside',
  offsideFor: 'offsideFor',
  pass: 'pass',
  playerLeft: 'playerLeft',
  playerReturned: 'playerReturned',
  playResumed: 'playResumed',
  playStopped: 'playStopped',
  postMatchCheckComplete: 'postMatchCheckComplete',
  shot: 'shot',
  substitution: 'substitution',
  tackle: 'tackle',
  throwInAwarded: 'throwInAwarded',
  throwInTaken: 'throwInTaken',
  varResult: 'varResult',
  varUnderway: 'varUnderway',
  badTouch: 'badTouch',
  goalkeeperThrow: 'goalkeeperThrow',
  endMatch: 'endMatch',
  endPeriod: 'endPeriod',
  startMatch: 'startMatch',
  startPeriod: 'startPeriod',
  playerBeaten: 'playerBeaten',
  takeOn: 'takeOn',
  goalkeeperKickFromHands: 'goalkeeperKickFromHands',
  refereeDropBall: 'refereeDropBall',
  formation: 'formation',
};

export type MetadataKey = Pretty<keyof typeof METADATA_KEY>;
