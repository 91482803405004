/* eslint-disable no-restricted-imports */
import {
  FixtureActionType as ACTION_TYPE_ID,
  DataCollectionPhase,
  type PeriodDetailsDto,
  type GameStateDto,
  type PeriodStateDto,
} from '@img-arena/sps-msed-contract';

export const FIXTURE_PHASE_LABEL: Record<DataCollectionPhase, string> = {
  [DataCollectionPhase.FirstHalf]: 'FIRST HALF',
  [DataCollectionPhase.FirstHalfEt]: 'FIRST HALF ET',
  [DataCollectionPhase.FullTime]: 'FULL TIME',
  [DataCollectionPhase.HalfTime]: 'HALF TIME',
  [DataCollectionPhase.KickOff]: 'KICK OFF',
  [DataCollectionPhase.PenaltySht]: 'PENALTY SHT',
  [DataCollectionPhase.PreGame]: 'PRE GAME',
  [DataCollectionPhase.SecondHalf]: 'SECOND HALF',
  [DataCollectionPhase.SecondHalfEt]: 'SECOND HALF ET',
  [DataCollectionPhase.Unknown]: 'UNKNOWN',
  [DataCollectionPhase.PostGame]: 'KICK OFF',
};

export const PLAYER_INPUT_AUTO_COMMIT_TIME = 300;

export const ACTION_STATE = {
  NONE: 'none',
  ERROR: 'error',
  SYNC: 'sync',
  SUCCESS: 'success',
} as const;
export type ActionState = ConstType<typeof ACTION_STATE>;

export const AWARDED_ACTIONS = [
  ACTION_TYPE_ID.CornerAwarded,
  ACTION_TYPE_ID.ThrowInAwarded,
  ACTION_TYPE_ID.FreeKickAwarded,
  ACTION_TYPE_ID.GoalKickAwarded,
];

export const TAKEN_ACTIONS = [
  ACTION_TYPE_ID.Pass,
  ACTION_TYPE_ID.EndPeriod,
  ACTION_TYPE_ID.Cross,
  ACTION_TYPE_ID.Launch,
  ACTION_TYPE_ID.Shot,
  ACTION_TYPE_ID.Goal,
];

export const DEFAULT_GAME_STATE: GameStateDto = {
  lineups: {},
  phase: DataCollectionPhase.PreGame,
  score: {},
  cards: {},
  substitutions: {},
} as const;

export const PRE_GAME_PERIOD: PeriodStateDto = {
  sequence: 0,
  startTime: 0,
  endTime: 0,
  isHomeTeamLeft: true,
  gameStates: {
    0: DEFAULT_GAME_STATE,
  },
} as const;

export const PRE_GAME_PERIOD_CONFIG: PeriodDetailsDto = {
  seq: 0,
  label: FIXTURE_PHASE_LABEL[DataCollectionPhase.PreGame],
  initialClock: 0,
  disableEndingPeriodLimit: 0,
} as const;
