import { ACTION_TYPE_ID } from '@contract';

export const PARENT_ACTION_LABEL = 'Parent Action';
export const NO_PARENT_ACTION_LABEL = 'No parent Action';

export const PARENT_ACTION_NONE: ACTION_TYPE_ID[] = [
  ACTION_TYPE_ID.YellowCard,
  ACTION_TYPE_ID.RedCard,
  ACTION_TYPE_ID.Substitution,
  ACTION_TYPE_ID.AdditionalTime,
];

export const PARENT_ACTION_CONDITIONAL: Partial<
  Record<ACTION_TYPE_ID, ACTION_TYPE_ID>
> = {
  [ACTION_TYPE_ID.PlayerLeft]: ACTION_TYPE_ID.PlayResumed,
  [ACTION_TYPE_ID.VARUnderway]: ACTION_TYPE_ID.VARResult,
};
