import { ACTION_TYPE_ID } from '@contract';

export const ADD_ACTION_DIALOG_TITLE = 'Add action';
export const ADD_ACTION_LABEL = 'Add action';
export const ACTION_TYPE_LABEL = 'Action type';
export const TEAM_LABEL = 'Team';

export const QA_ACTIONS_BLACKLIST: ACTION_TYPE_ID[] = [
  ACTION_TYPE_ID.Lineups,

  ACTION_TYPE_ID.PostMatchCheckComplete,
];
