import { ApiError } from './ApiError';
import { API_METHODS } from './constants/requests';

interface FetchDataOptions {
  token: string;
  url: string;
}

export const fetchData = async ({ token, url }: FetchDataOptions) => {
  if (!token) {
    throw new Error('Missing auth token');
  }
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: API_METHODS.GET,
  });

  if (!res.ok) {
    const error = await ApiError.parseFromResponse(res);
    throw error;
  }

  const contentType = res.headers.get('content-type');

  if (!contentType || !contentType.includes('application/json')) {
    return res.text();
  }

  return res.json();
};
