export const DATE_FORMAT = {
  YEAR: 'YYYY',
  MONTH: 'MM',
  DAY: 'DD',
  HOUR: 'HH',
  MINUTE: 'mm',
  SECOND: 'ss',
};

const DATE_FORMAT_FULL = `${DATE_FORMAT.DAY}/${DATE_FORMAT.MONTH}/${DATE_FORMAT.YEAR} ${DATE_FORMAT.HOUR}:${DATE_FORMAT.MINUTE}:${DATE_FORMAT.SECOND}`;

export const addLeadingZero = (date: string | number) => {
  const stringDate = date.toString();

  return stringDate.length === 1 ? `0${stringDate}` : stringDate;
};

export const formatDate = (
  dateString: Date | string | number,
  format?: string,
) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) return;

  const day = addLeadingZero(date.getUTCDate());
  const month = addLeadingZero(date.getUTCMonth() + 1);
  const year = String(date.getUTCFullYear());
  const hours = addLeadingZero(date.getUTCHours());
  const minutes = addLeadingZero(date.getUTCMinutes());
  const seconds = addLeadingZero(date.getUTCSeconds());

  return (format ? format : DATE_FORMAT_FULL)
    .replace(DATE_FORMAT.YEAR, year)
    .replace(DATE_FORMAT.MONTH, month)
    .replace(DATE_FORMAT.DAY, day)
    .replace(DATE_FORMAT.HOUR, hours)
    .replace(DATE_FORMAT.MINUTE, minutes)
    .replace(DATE_FORMAT.SECOND, seconds);
};
