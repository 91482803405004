import { FC } from 'react';
import { FilterValue } from '../../../stores/FiltersStore/constants';
import {
  COLLECTOR_FILTERS,
  FILTERS_COMPONENTS,
  FilterComponent,
  FiltersDrawerVariant,
} from '../constants';

interface CollectorFormProps {
  filterValue: FilterValue;
  onChange: (value: Partial<FilterValue>) => void;
}

export const CollectorForm: FC<CollectorFormProps> = ({
  filterValue,
  onChange,
}) => {
  return COLLECTOR_FILTERS.map((filterName) => {
    const FilterComponent = FILTERS_COMPONENTS[filterName] as FilterComponent<
      typeof filterName
    >;

    return (
      <FilterComponent
        key={filterName}
        value={filterValue[filterName]}
        onChange={(value) => {
          onChange({ [filterName]: value });
        }}
        variant={FiltersDrawerVariant.COLLECTOR}
      />
    );
  });
};
