import { FC } from 'react';
import { Button, Stack } from '@mui/material';
import VerticalPitchSVG from '@/assets/football-pitch-vertical.svg?react';
import { PITCH_LINEUP_ID, PitchOrientation } from '../constants';
import { PitchGrid } from './PitchGrid';

interface DisplayVerticalFormationProps {
  onEdit: VoidFunction;
}

export const DisplayVerticalFormation: FC<DisplayVerticalFormationProps> = ({
  onEdit,
}) => {
  return (
    <Stack flex={1} p={1} gap={1}>
      <Stack
        position='relative'
        id={PITCH_LINEUP_ID}
        direction='column'
        marginTop='auto'
      >
        <VerticalPitchSVG />
        <PitchGrid pitchOrientation={PitchOrientation.Vertical} />
      </Stack>
      <Button variant='outlined' onClick={onEdit}>
        Edit formation
      </Button>
    </Stack>
  );
};
