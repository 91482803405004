import { Stack, colors } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import WarningIcon from '@mui/icons-material/Warning';
import FlagIcon from '@mui/icons-material/Flag';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import StarIcon from '@mui/icons-material/Star';
import EventIcon from '@mui/icons-material/Event';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import GroupsIcon from '@mui/icons-material/Groups';
import StorageIcon from '@mui/icons-material/Storage';
import { Extras } from '@contract';
import { makeTablePlayerName } from '@/stores/utils';
import {
  setFilterValues,
  useFiltersStore,
} from '@/stores/FiltersStore/FiltersStore';
import { CommonOptions, FILTER_NAME } from '@/stores/FiltersStore/constants';
import { ACTION_STATE } from '@/stores/constants';
import { ACTION_TYPE } from '@/types/action/actionType';
import { TeamName } from '../TeamName/TeamName';
import { FilterChip } from './FilterChip';
import { FLAGS_OPTIONS, STATUS_OPTIONS, WARNINGS_OPTIONS } from './common';

const LABELS = {
  PLAYERS: {
    [CommonOptions.None]: 'Actions without players',
  },
  OUTCOME: 'Outcome',
} as const;

const ICONS_COLORS = {
  events: colors.orange[500],
  extras: colors.blue[500],
  outcome: {
    successful: 'green',
    failed: 'red',
  },
  flags: colors.red[900],
  warnings: colors.orange[500],
  status: {
    [ACTION_STATE.ERROR]: 'red',
    [ACTION_STATE.SYNC]: 'orange',
    [ACTION_STATE.SUCCESS]: 'green',
    [ACTION_STATE.NONE]: '',
  },
};

export const SelectedFilterChips = () => {
  const selectedValues = useFiltersStore((state) => state.filterValues);
  const {
    players,
    events,
    extras,
    outcome,
    flags,
    warnings,
    period: periods,
    status,
    team,
  } = selectedValues;

  const { option, playersSelected } = players;

  return (
    <Stack id='filters-view' gap={0.5} direction='row' flexWrap='wrap'>
      {option === CommonOptions.None && (
        <FilterChip
          onDelete={() => {
            setFilterValues({
              ...selectedValues,
              [FILTER_NAME.PLAYERS]: {
                ...players,
                option: CommonOptions.All,
              },
            });
          }}
          icon={<PersonIcon />}
          label={LABELS.PLAYERS[option]}
        />
      )}
      {option === CommonOptions.Assigned &&
        playersSelected.map((player) => (
          <FilterChip
            onDelete={() => {
              setFilterValues({
                ...selectedValues,
                [FILTER_NAME.PLAYERS]: {
                  ...players,
                  playersSelected: playersSelected.filter(
                    (p) => p.id !== player.id,
                  ),
                },
              });
            }}
            key={player.id}
            icon={<PersonIcon />}
            label={makeTablePlayerName(player)}
          />
        ))}

      {team !== null && (
        <FilterChip
          icon={<GroupsIcon />}
          onDelete={() => {
            setFilterValues({
              ...selectedValues,
              [FILTER_NAME.TEAM]: null,
            });
          }}
          label={<TeamName id={team} fallbackValue={CommonOptions.None} />}
        />
      )}

      {events.map((event) => (
        <FilterChip
          tooltipMessage='Event'
          icon={<EventIcon sx={{ '&&': { color: ICONS_COLORS.events } }} />}
          onDelete={() => {
            setFilterValues({
              ...selectedValues,
              [FILTER_NAME.EVENTS]: events.filter((e) => e !== event),
            });
          }}
          key={event}
          label={ACTION_TYPE[event]}
        />
      ))}

      {extras.map((extra) => (
        <FilterChip
          tooltipMessage='Extra'
          icon={<StarIcon sx={{ '&&': { color: ICONS_COLORS.extras } }} />}
          onDelete={() => {
            setFilterValues({
              ...selectedValues,
              [FILTER_NAME.EXTRAS]: extras.filter((e) => e !== extra),
            });
          }}
          key={extra}
          label={Extras[extra]}
        />
      ))}

      {periods.map((period) => (
        <FilterChip
          tooltipMessage={'Period'}
          icon={<ViewTimelineIcon />}
          key={period.label}
          label={period.label}
          onDelete={() =>
            setFilterValues({
              ...selectedValues,
              [FILTER_NAME.PERIOD]: periods.filter(
                (p) => p.sequence !== period.sequence,
              ),
            })
          }
        />
      ))}

      {outcome !== null && (
        <FilterChip
          icon={
            outcome ? (
              <CheckCircleOutlineIcon
                sx={{ '&&': { color: ICONS_COLORS.outcome.successful } }}
              />
            ) : (
              <HighlightOffIcon
                sx={{ '&&': { color: ICONS_COLORS.outcome.failed } }}
              />
            )
          }
          onDelete={() => {
            setFilterValues({
              ...selectedValues,
              [FILTER_NAME.OUTCOME]: null,
            });
          }}
          label={LABELS.OUTCOME}
        />
      )}

      {flags !== null && (
        <FilterChip
          icon={<FlagIcon sx={{ '&&': { color: ICONS_COLORS.flags } }} />}
          onDelete={() => {
            setFilterValues({
              ...selectedValues,
              [FILTER_NAME.FLAGS]: null,
            });
          }}
          label={FLAGS_OPTIONS[flags ? 1 : 0]}
        />
      )}

      {warnings !== null && (
        <FilterChip
          icon={<WarningIcon sx={{ '&&': { color: ICONS_COLORS.warnings } }} />}
          onDelete={() => {
            setFilterValues({
              ...selectedValues,
              [FILTER_NAME.WARNINGS]: null,
            });
          }}
          label={WARNINGS_OPTIONS[warnings ? 1 : 0]}
        />
      )}

      {status !== null && (
        <FilterChip
          icon={
            <StorageIcon
              sx={{ '&&': { color: ICONS_COLORS.status[status] } }}
            />
          }
          onDelete={() => {
            setFilterValues({
              ...selectedValues,
              [FILTER_NAME.STATUS]: null,
            });
          }}
          label={STATUS_OPTIONS[status]}
        />
      )}
    </Stack>
  );
};
