import { Stack } from '@mui/material';
import { useActionStore } from '@/stores/ActionStore/ActionStore';
import { ActionDialogContent } from '../Dialogs/ActionDialogContent';

export const ActionEdit = () => {
  const actionViewAction = useActionStore((state) => state.actionViewAction);
  const action = useActionStore((state) => state.action);

  if (!actionViewAction || !action) return;

  if (actionViewAction.actionId !== action.actionId) return;

  return (
    <Stack
      flex='0 1 auto'
      alignItems={'flex-start'}
      id='action-edit'
      key={action.actionId}
      data-testid='action-edit'
      overflow='auto'
      maxWidth='100%'
    >
      <ActionDialogContent action={action} />
    </Stack>
  );
};
