import type { FC } from 'react';
import { Button, Stack } from '@mui/material';
import { NO_LABEL, YES_LABEL } from '@/constants';
import { Dialog } from '../../Dialog/Dialog';

interface ConfirmationDialogProps {
  isOpen: boolean;
  title: string;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  isOpen,
  title,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog isOpen={isOpen} title={title}>
      <Stack width='100%' flexDirection='row' justifyContent='end' gap={1}>
        <Button variant='outlined' color='inherit' onClick={onCancel}>
          {NO_LABEL}
        </Button>
        <Button variant='contained' color='primary' onClick={onConfirm}>
          {YES_LABEL}
        </Button>
      </Stack>
    </Dialog>
  );
};
