import { RadioButtons } from '@/components/Form/RadioButtons';
import { FILTER_NAME } from '@/stores/FiltersStore/constants';
import type { FilterComponent } from '../constants';

export const FLAGS_OPTIONS = {
  0: 'Unflagged events',
  1: 'Flagged events',
} as const;

export const FlagsFilter: FilterComponent<'flags'> = ({ value, onChange }) => {
  return (
    <RadioButtons
      controlLabel={FILTER_NAME.FLAGS}
      name={FILTER_NAME.FLAGS}
      value={value === null ? null : Number(value)}
      onChange={(value) => {
        onChange(!!value);
      }}
      onClear={() => onChange(null)}
      fields={FLAGS_OPTIONS}
    />
  );
};
