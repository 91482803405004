import { FC, useRef } from 'react';
import { type PlayerPositionDto } from '@contract';
import type { FormationAction } from '@/types/action/action';
import { calculateFormation } from '@/stores/LineupStore/utils';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';

import { EditHorizontalFormation } from './EditHorizontalFormation';
import { EditVerticalFormation } from './EditVerticalFormation';

interface EditFormationProps {
  action: FormationAction;
  onSave: VoidFunction;
  onCancel: VoidFunction;
  isHorizontal: boolean;
}

export const EditFormation: FC<EditFormationProps> = ({
  action,
  onSave,
  onCancel,
  isHorizontal,
}) => {
  const { pitchPlayers } = action.metadata.formation;
  const initialPitchPlayers = useRef(pitchPlayers);
  const formation = calculateFormation(pitchPlayers);
  const pitchPlayersMap = pitchPlayers.reduce((acc, player) => {
    acc.set(player.position, player.player);
    return acc;
  }, new Map());

  const onChange = (changedPitchPlayers: PlayerPositionDto[]) => {
    setActionPartialMetadata<FormationAction>({
      pitchPlayers: changedPitchPlayers,
    });
  };

  const hasChanged = initialPitchPlayers.current.some((playerPosition) => {
    const pitchPlayer = pitchPlayersMap.get(playerPosition.position);

    return !pitchPlayer || pitchPlayer.id !== playerPosition.player.id;
  });

  const Component = isHorizontal
    ? EditHorizontalFormation
    : EditVerticalFormation;

  return (
    <Component
      action={action}
      onSave={onSave}
      onCancel={onCancel}
      onChange={onChange}
      formation={formation}
      hasChanged={hasChanged}
    />
  );
};
