import type { RouteObject } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { App } from '@/App';
import { PitchView } from '@/views/PitchView';
import { QAView } from '@/views/QAView';
import { FixtureCollectionView } from '@/views/FixtureCollectionView';
import { NotFound } from '@/views/NotFound';
import { SignInView } from '@/views/SignInView';
import { Protected } from '@/components/Protected/Protected';
import { AuthProvider } from '@/contexts/auth/AuthProvider';
import { FixtureView } from '@/views/FixtureView';
import { EventHistoryView } from '@/views/EventHistoryView';
import { LineupEditorView } from '@/views/LineupEditorView';
import { LiveLineupEditorView } from '@/views/LiveLineupEditorView';
import { FIXTURE_VIEW_ROUTES } from './fixtureViewRoutes';
import { ROUTE_ID, ROUTE_URL, ROUTES_FIXTURE_VIEW } from './constants';

export const routes: RouteObject[] = [
  {
    path: ROUTE_URL.SIGN_IN,
    id: ROUTE_ID.SIGN_IN,
    element: (
      <AuthProvider>
        <SignInView />
      </AuthProvider>
    ),
  },
  {
    path: ROUTE_URL.OKTA_LOGIN_CALLBACK,
    element: (
      <AuthProvider>
        <LoginCallback />
      </AuthProvider>
    ),
  },

  {
    path: ROUTE_URL.HOME,
    id: ROUTE_ID.HOME,
    element: (
      <AuthProvider>
        <Protected>
          <App />
        </Protected>
      </AuthProvider>
    ),
    children: [
      {
        path: '',
        id: ROUTE_ID.FIXTURE_COLLECTION,
        element: <FixtureCollectionView />,
      },
      {
        path: ROUTE_URL.VIEW.HOME_COLLECTOR.DEFAULT,
        id: ROUTE_ID.HOME_COLLECTOR,
        element: <FixtureView view={FIXTURE_VIEW_ROUTES.HOME_COLLECTOR} />,
        children: [
          {
            path: ROUTES_FIXTURE_VIEW.STARTING_LINEUP.PATH,
            id: ROUTE_ID.HOME_COLLECTOR_LINEUP_INIT,
            element: <LineupEditorView />,
          },
          {
            path: ROUTES_FIXTURE_VIEW.PITCH.PATH,
            id: ROUTE_ID.HOME_COLLECTOR_PITCH,
            element: <PitchView />,
          },
          {
            path: ROUTES_FIXTURE_VIEW.HISTORY.PATH,
            id: ROUTE_ID.HOME_COLLECTOR_HISTORY,
            element: <EventHistoryView />,
          },
          {
            path: ROUTES_FIXTURE_VIEW.LIVE_LINEUP.PATH,
            id: ROUTE_ID.HOME_COLLECTOR_LIVE_LINEUP,
            element: <LiveLineupEditorView />,
          },
        ],
      },
      {
        path: ROUTE_URL.VIEW.AWAY_COLLECTOR.DEFAULT,
        id: ROUTE_ID.AWAY_COLLECTOR,
        element: <FixtureView view={FIXTURE_VIEW_ROUTES.AWAY_COLLECTOR} />,
        children: [
          {
            path: ROUTES_FIXTURE_VIEW.STARTING_LINEUP.PATH,
            id: ROUTE_ID.AWAY_COLLECTOR_LINEUP_INIT,
            element: <LineupEditorView />,
          },
          {
            path: ROUTES_FIXTURE_VIEW.PITCH.PATH,
            id: ROUTE_ID.AWAY_COLLECTOR_PITCH,
            element: <PitchView />,
          },
          {
            path: ROUTES_FIXTURE_VIEW.HISTORY.PATH,
            id: ROUTE_ID.AWAY_COLLECTOR_HISTORY,
            element: <EventHistoryView />,
          },
          {
            path: ROUTES_FIXTURE_VIEW.LIVE_LINEUP.PATH,
            id: ROUTE_ID.AWAY_COLLECTOR_LIVE_LINEUP,
            element: <LiveLineupEditorView />,
          },
        ],
      },
      {
        path: ROUTE_URL.VIEW.QA.DEFAULT,
        id: ROUTE_ID.QA,
        element: <FixtureView view={FIXTURE_VIEW_ROUTES.QA} />,
        children: [
          {
            path: ROUTES_FIXTURE_VIEW.STARTING_LINEUP.PATH,
            id: ROUTE_ID.QA_LINEUP_INIT,
            element: <LineupEditorView />,
          },
          {
            path: ROUTES_FIXTURE_VIEW.PITCH.PATH,
            id: ROUTE_ID.QA_PITCH,
            element: <QAView />,
          },
          {
            path: ROUTES_FIXTURE_VIEW.HISTORY.PATH,
            id: ROUTE_ID.QA_HISTORY,
            element: <EventHistoryView />,
          },
        ],
      },
      { path: '*', element: <NotFound /> },
    ],
  },
  { path: '*', element: <NotFound /> },
];
