import { useMemo } from 'react';
import { RadioButtons } from '@/components/Form/RadioButtons';
import { FILTER_NAME, CommonOptions } from '@/stores/FiltersStore/constants';
import { useFixtureStore } from '@/stores/FixtureStore';
import { NONE_LABEL } from '@/constants';
import type { FilterComponent } from '../constants';

export const TeamFilter: FilterComponent<'team'> = ({ value, onChange }) => {
  const homeTeam = useFixtureStore((state) => state.homeTeam);
  const awayTeam = useFixtureStore((state) => state.awayTeam);

  const teamOptions = useMemo(() => {
    const output: Record<string, string> = {};
    if (homeTeam) {
      output[homeTeam.id] = homeTeam.name;
    }
    if (awayTeam) {
      output[awayTeam.id] = awayTeam.name;
    }
    output[CommonOptions.None] = NONE_LABEL;
    return output;
  }, [homeTeam, awayTeam]);

  return (
    <RadioButtons
      controlLabel={FILTER_NAME.TEAM}
      name={FILTER_NAME.TEAM}
      value={value}
      onChange={onChange}
      onClear={() => onChange(null)}
      fields={teamOptions}
    />
  );
};
