import type { FC } from 'react';
import {
  FILTER_NAME,
  type FilterName,
  type FilterValue,
} from '@/stores/FiltersStore/constants';
import {
  EventsFilter,
  ExtrasFilter,
  FlagsFilter,
  OutcomeFilter,
  PeriodFilter,
  PlayersFilter,
  StatusFilter,
  TeamFilter,
  WarningsFilter,
} from './common';

export enum FiltersDrawerVariant {
  QA = 'qa',
  COLLECTOR = 'collector',
}

export const FILTERS_LABEL = 'Filters';
export const PLAYER_SWITCH_NAME = 'playersOptions';

type CommonFilterProps<T extends FilterName> = {
  value: FilterValue[T];
  onChange: (value: FilterValue[T]) => void;
  variant?: FiltersDrawerVariant;
};

export type FilterComponent<T extends FilterName> = FC<CommonFilterProps<T>>;

type FiltersComponents = {
  [key in FilterName]: FilterComponent<key>;
};

export const FILTERS_COMPONENTS: FiltersComponents = {
  [FILTER_NAME.PLAYERS]: PlayersFilter,
  [FILTER_NAME.TEAM]: TeamFilter,
  [FILTER_NAME.EXTRAS]: ExtrasFilter,
  [FILTER_NAME.EVENTS]: EventsFilter,
  [FILTER_NAME.PERIOD]: PeriodFilter,
  [FILTER_NAME.OUTCOME]: OutcomeFilter,
  [FILTER_NAME.FLAGS]: FlagsFilter,
  [FILTER_NAME.WARNINGS]: WarningsFilter,
  [FILTER_NAME.STATUS]: StatusFilter,
};

export const COLLECTOR_FILTERS: FilterName[] = [
  FILTER_NAME.PLAYERS,
  FILTER_NAME.PERIOD,
  FILTER_NAME.EVENTS,
  FILTER_NAME.EXTRAS,
  FILTER_NAME.OUTCOME,
  FILTER_NAME.FLAGS,
  FILTER_NAME.WARNINGS,
];

export const QA_FILTERS: FilterName[] = [
  FILTER_NAME.PLAYERS,
  FILTER_NAME.PERIOD,
  FILTER_NAME.EVENTS,
  FILTER_NAME.EXTRAS,
  FILTER_NAME.TEAM,
  FILTER_NAME.OUTCOME,
  FILTER_NAME.FLAGS,
  FILTER_NAME.WARNINGS,
  FILTER_NAME.STATUS,
];
