import { FC } from 'react';
import { Stack } from '@mui/material';
import { ErrorReason } from '@contract';
import { ErrorAction } from '@/types/action/action';
import { ActionPlayerField } from '@/components/Dialogs/Common/ActionPlayerField';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { FlagField } from '@/components/Dialogs/Common/FlagField';
import { RadioButtons } from '@/components/Form/RadioButtons';
import { getActionMetadata } from '@/utils/actions';
import { REASON_LABEL } from '@/constants';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { ERROR_REASONS } from '@/components/Dialogs/ErrorDialogContent/constants';
import { Controls } from '../Common/Controls';

interface ErrorDialogProps {
  action: ErrorAction;
}

const onErrorReasonChange = (reason: ErrorReason) => {
  setActionPartialMetadata({ reason });
};

export const ErrorDialogContent: FC<ErrorDialogProps> = ({ action }) => {
  const metadata = getActionMetadata(action);

  return (
    <Stack gap={2}>
      <RadioButtons
        name='errorReason'
        onChange={onErrorReasonChange}
        value={metadata.reason}
        controlLabel={REASON_LABEL}
        fields={ERROR_REASONS}
      />
      <ActionPlayerField action={action} />
      <PeriodClockField action={action} />
      <FlagField action={action} />
      <Controls action={action} />
    </Stack>
  );
};
