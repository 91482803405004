import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { UserRole } from '@contract';
import { Label } from '@/components/Label/Label';
import { DATE_FORMAT, formatDate } from '@/utils/formatDate';
import { isUsingMockData } from '@/service/helpers/isUsingMockData';
import { SS_KEY } from '@/utils/window';
import { ROLE_VIEW } from '@/router/constants';
import {
  FixtureCollectionDataStatusColor,
  FixtureCollectionDataStatusLabel,
  FixtureCollectionStatusColor,
  FixtureCollectionStatusLabel,
  NO_FIXTURES_LABEL,
  TEAM_SIDE,
  UserRoleLabel,
} from './constants';
import { AssignedChip } from './AssignedChip';
import { EmptyTableBody } from './EmptyTableBody';
import type { SingleRoleDataCollection } from './FixturesCollectionsTable';
import { getCollectorTeamName } from './utils';

export interface FixturesCollectionsTableBodyProps {
  collections: SingleRoleDataCollection[];
  isLoading: boolean;
}

export const FixturesCollectionsTableBody: FC<
  FixturesCollectionsTableBodyProps
> = ({ collections, isLoading }) => {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <EmptyTableBody>
        <CircularProgress size='2rem' />
      </EmptyTableBody>
    );
  }

  if (!collections.length) {
    return <EmptyTableBody>{NO_FIXTURES_LABEL}</EmptyTableBody>;
  }

  const onRowClick = (collection: SingleRoleDataCollection) => {
    isUsingMockData() &&
      sessionStorage.setItem(
        SS_KEY.MOCK_COLLECTION,
        JSON.stringify(collection),
      );

    const routeView = ROLE_VIEW[collection.role];

    navigate(`${collection.id}/${routeView}`);
  };

  return (
    <TableBody>
      {collections.map((collection) => (
        <TableRow
          key={`${collection.id}_${collection.role}`}
          onClick={() => onRowClick(collection)}
          sx={{
            cursor: 'pointer',
          }}
        >
          <TableCell>
            {formatDate(
              collection.fixture.startDateUtc,
              `${DATE_FORMAT.DAY}/${DATE_FORMAT.MONTH}/${DATE_FORMAT.YEAR}`,
            )}
          </TableCell>
          <TableCell>
            {formatDate(
              collection.fixture.startDateUtc,
              `${DATE_FORMAT.HOUR}:${DATE_FORMAT.MINUTE}`,
            )}
          </TableCell>
          <TableCell>
            {getCollectorTeamName(collection, TEAM_SIDE.HOME)}
            {collection.role === UserRole.HomeScorer && <AssignedChip />}
          </TableCell>
          <TableCell>
            {getCollectorTeamName(collection, TEAM_SIDE.AWAY)}
            {collection.role === UserRole.AwayScorer && <AssignedChip />}
          </TableCell>
          <TableCell width={200}>{UserRoleLabel[collection.role]}</TableCell>
          <TableCell>{collection.fixture.sportName}</TableCell>
          <TableCell>{collection.fixture.competitionName}</TableCell>
          <TableCell>
            <Label
              backgroundColor={
                FixtureCollectionDataStatusColor[collection.status]
              }
            >
              {FixtureCollectionDataStatusLabel[collection.status]}
            </Label>
          </TableCell>
          <TableCell>
            <Label
              backgroundColor={
                FixtureCollectionStatusColor[collection.fixture.status]
              }
            >
              {FixtureCollectionStatusLabel[collection.fixture.status]}
            </Label>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
