import { FC } from 'react';
import { PropsWithAction } from '@/components/ActionContextMenu/ActionContextMenuContent';
import { Resend } from '@/components/ActionContextMenu/MenuItems/Resend';
import { ChangePlayer } from '@/components/ActionContextMenu/MenuItems/ChangePlayer';
import { ChangeOutcome } from '@/components/ActionContextMenu/MenuItems/ChangeOutcome';
import { ToggleFlag } from '@/components/ActionContextMenu/MenuItems/ToggleFlag';
import { EditTime } from '@/components/ActionContextMenu/MenuItems/EditTime';
import { DisplayDetails } from '@/components/ActionContextMenu/MenuItems/DisplayDetails';
import { Delete } from '@/components/ActionContextMenu/MenuItems/Delete';

export const EventsHistoryContextMenuItems: FC<PropsWithAction> = ({
  action,
}) => {
  return (
    <>
      <Resend action={action} />
      <ChangePlayer action={action} />
      <ChangeOutcome action={action} />
      <ToggleFlag action={action} />
      <EditTime action={action} />
      <DisplayDetails action={action} />
      <Delete action={action} />
    </>
  );
};
