import { Autocomplete, TextField } from '@mui/material';
import { FILTER_NAME } from '@/stores/FiltersStore/constants';
import { ACTION_TYPE_ID } from '@contract';
import { ACTION_TYPE } from '@/types/action/actionType';
import type { FilterComponent } from '../constants';

const EVENT_OPTIONS: ACTION_TYPE_ID[] = Object.entries(ACTION_TYPE)
  .sort(([, a], [, b]) => {
    return a.localeCompare(b);
  })
  .map(([key]) => Number(key));

export const EventsFilter: FilterComponent<'events'> = ({
  value,
  onChange,
}) => {
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      onChange={(_, events) => {
        onChange(events);
      }}
      options={EVENT_OPTIONS}
      autoHighlight
      getOptionKey={(option) => ACTION_TYPE_ID[option]}
      getOptionLabel={(option) => ACTION_TYPE[option]}
      renderInput={(params) => (
        <TextField
          sx={{ textTransform: 'capitalize' }}
          {...params}
          label={FILTER_NAME.EVENTS}
        />
      )}
      value={value}
    />
  );
};
