import { Coords } from '@/types';

export enum TeamColor {
  Home = 'red',
  Away = 'cyan',
  None = 'yellow',
}

export const KICK_OFF_COORDS: Coords = { x: 50, y: 50 };
export const POSITION_ATTRIBUTE = 'data-position-id';
export const COORDS_DOT_CONTAINER_TEST_ID = 'pitch-dot-container';
export const COORDS_TARGET_DOT_CONTAINER_TEST_ID = 'pitch-target-dot-container';
