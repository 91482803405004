import { FC } from 'react';
import { Stack } from '@mui/material';
import { AdditionalTimeAction } from '@/types/action/action';
import { getActionMetadata } from '@/utils/actions';
import { AdditionalTimeDto } from '@contract';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { MinMaxInput } from '@/components/Form/MinMaxInput';
import { SELECT_TIME_LABEL } from '@/constants';
import {
  MAX_EXTRA_TIME_VALUE,
  MIN_EXTRA_TIME_VALUE,
} from '@/components/Dialogs/AdditionalTimeDialogContent/constants';
import { Controls } from '../Common/Controls';

interface AdditionalTimeDialogProps {
  action: AdditionalTimeAction;
}

const onExtraTimeChange = (extraTime: AdditionalTimeDto['extraTime']) => {
  setActionPartialMetadata<AdditionalTimeAction>({ extraTime });
};

export const AdditionalTimeDialogContent: FC<AdditionalTimeDialogProps> = ({
  action,
}) => {
  const metadata = getActionMetadata(action);

  return (
    <Stack width={400} maxWidth='100%' mt={1} gap={1}>
      <MinMaxInput
        label={SELECT_TIME_LABEL}
        value={metadata.extraTime}
        onNumberChange={onExtraTimeChange}
        min={MIN_EXTRA_TIME_VALUE}
        max={MAX_EXTRA_TIME_VALUE}
      />
      <Controls action={action} />
    </Stack>
  );
};
