import { Button, Stack } from '@mui/material';
import { FC, useRef } from 'react';
import { CANCEL_BUTTON_LABEL, COMMIT_BUTTON_LABEL } from '@/constants';
import { Action } from '@/types/action/action';
import { isActionFieldsFulfilled } from '@/utils/isActionFieldsFulfilled';
import { hasActionChanged } from '@/utils/actions';
import { isNewAction } from '@/stores/utils';
import { onDialogClose, submitActionDialog } from '../utils';

interface Controls {
  onClose?: VoidFunction;
  onSubmit?: VoidFunction;
  action: Action;
  shouldCloseAfterSubmit?: boolean;
}

export const Controls: FC<Controls> = ({
  onClose = onDialogClose,
  onSubmit,
  action,
  shouldCloseAfterSubmit = true,
}) => {
  const initialAction = useRef(action);
  const isCommitDisabled =
    !isActionFieldsFulfilled(action) ||
    (!isNewAction(action) && !hasActionChanged(initialAction.current, action));

  const onSubmitClick = () => {
    onSubmit ? onSubmit() : submitActionDialog(action);

    if (shouldCloseAfterSubmit) {
      onClose();
    }
  };

  return (
    <Stack
      flexDirection='row'
      justifyContent='end'
      gap={1}
      sx={{ marginTop: 'auto' }}
    >
      <Button variant='outlined' color='inherit' onClick={onClose}>
        {CANCEL_BUTTON_LABEL}
      </Button>
      <Button
        disabled={isCommitDisabled}
        variant='contained'
        color='primary'
        onClick={onSubmitClick}
      >
        {COMMIT_BUTTON_LABEL}
      </Button>
    </Stack>
  );
};
