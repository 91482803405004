import type { Action } from '@/types/action/action';
import {
  ACTION_TYPE_ID,
  AttemptType,
  EndPossessionEvents,
  InPossessionEvents,
  type PeriodStateDto,
} from '@contract';
import { DIRECTION_OF_PLAY } from '@/components/Periods/constants';
import { sortNumbersAscending } from '@/utils/array';
import { getActionBackup } from './UnsyncedActionsStore';
import {
  useFixtureStore,
  type FixtureStore,
  type TableAction,
} from './FixtureStore';
import type { ActionTimestampMap } from './FiltersStore/FiltersStore';

export function makeTablePlayerName(
  player: { shirtNumber: string; lastName: string } | null,
) {
  return player ? `${player.shirtNumber}. ${player.lastName}` : '';
}

export function isExactAction(
  a: Action | TableAction,
  b: Action | TableAction,
) {
  return (
    a.actionId === b.actionId &&
    a.createdAt === b.createdAt &&
    a.updatedAt === b.updatedAt
  );
}

export function findFirstActionOfType(
  actions: Action[],
  actionTypeId: ACTION_TYPE_ID | ACTION_TYPE_ID[],
) {
  if (Array.isArray(actionTypeId)) {
    return actions.findIndex((action) =>
      actionTypeId.includes(action.actionTypeId),
    );
  }

  return actions.findIndex((action) => action.actionTypeId === actionTypeId);
}

export function checkUnresolvedAction(
  actions: Action[],
  awardedTypeId: ACTION_TYPE_ID,
  resolvingTypeIds: ACTION_TYPE_ID[],
) {
  const actionIndex = findFirstActionOfType(actions, awardedTypeId);
  const resolvingActionIndex = findFirstActionOfType(actions, resolvingTypeIds);

  return (actionIndex >= 0 && resolvingActionIndex === -1) ||
    (actionIndex >= 0 && actionIndex < resolvingActionIndex)
    ? actions[actionIndex]
    : null;
}

export function isInPossessionAction(action: Action): boolean {
  if (!InPossessionEvents[action.actionTypeId]) return false;

  return (
    (action.actionTypeId === ACTION_TYPE_ID.Pass && action.isSuccessful) ||
    action.actionTypeId === ACTION_TYPE_ID.BallRecovery ||
    action.actionTypeId === ACTION_TYPE_ID.BadTouch ||
    (action.actionTypeId === ACTION_TYPE_ID.Clearance && action.isSuccessful) ||
    (action.actionTypeId === ACTION_TYPE_ID.Cross && action.isSuccessful) ||
    (action.actionTypeId === ACTION_TYPE_ID.Tackle && action.isSuccessful) ||
    action.actionTypeId === ACTION_TYPE_ID.GoalkeeperCollection ||
    action.actionTypeId === ACTION_TYPE_ID.GoalkeeperCatch
  );
}

export function isEndPossessionAction(action: Action): boolean {
  if (!EndPossessionEvents[action.actionTypeId]) return false;

  return (
    action.actionTypeId === ACTION_TYPE_ID.ThrowInAwarded ||
    action.actionTypeId === ACTION_TYPE_ID.CornerAwarded ||
    action.actionTypeId === ACTION_TYPE_ID.GoalKickAwarded ||
    action.actionTypeId === ACTION_TYPE_ID.FreeKickAwarded ||
    (action.actionTypeId === ACTION_TYPE_ID.BallTouch &&
      !action.isSuccessful) ||
    (action.actionTypeId === ACTION_TYPE_ID.Shot &&
      action.metadata.shot.attemptType === AttemptType.OnTarget) ||
    (action.actionTypeId === ACTION_TYPE_ID.Shot &&
      action.metadata.shot.attemptType === AttemptType.OffTarget) ||
    (action.actionTypeId === ACTION_TYPE_ID.Shot &&
      action.metadata.shot.attemptType === AttemptType.Blocked) ||
    action.actionTypeId === ACTION_TYPE_ID.Goal ||
    (action.actionTypeId === ACTION_TYPE_ID.Tackle && !action.isSuccessful) ||
    action.actionTypeId === ACTION_TYPE_ID.Interception ||
    action.actionTypeId === ACTION_TYPE_ID.Offside
  );
}

export function deriveDirectionOfPlay(
  isHomeTeamLeft: boolean,
  isHomeTeamAssigned: boolean | null,
) {
  if (isHomeTeamAssigned === null) return DIRECTION_OF_PLAY.LEFT_TO_RIGHT;

  if (isHomeTeamAssigned) {
    return isHomeTeamLeft
      ? DIRECTION_OF_PLAY.LEFT_TO_RIGHT
      : DIRECTION_OF_PLAY.RIGHT_TO_LEFT;
  }

  return isHomeTeamLeft
    ? DIRECTION_OF_PLAY.RIGHT_TO_LEFT
    : DIRECTION_OF_PLAY.LEFT_TO_RIGHT;
}

export function isNewAction(action: Action): boolean {
  if (action.messageId) return false;
  const backup = getActionBackup(action.actionId);
  if (backup) return false;

  const existingAction = useFixtureStore
    .getState()
    .actions.get(action.actionId);

  if (existingAction) return false;
  return true;
}

export function makePeriodStatesTimestamps(period: PeriodStateDto) {
  return Object.keys(period.gameStates).map(Number).sort(sortNumbersAscending);
}
/**
 * Returns closest number, less or equal to given number.
 * Given array needs to be sorted ascending for this function to work properly.
 * @example
 * findClosest(10, [5,8,11,14]) // 8
 * findClosest(11, [5,8,11,14]) // 11
 * findClosest(15, [5,8,11,14]) // 14
 * findClosest(-1, [5,8,11,14]) // 5
 * findClosest(0, [5,8,11,14]) // 5
 */
export function findClosest(number: number, array: number[]) {
  const biggestNumber = array.at(-1);
  if (biggestNumber !== undefined && number >= biggestNumber) {
    return biggestNumber;
  }
  const lowestNumber = array.at(0);
  if (lowestNumber !== undefined && number <= lowestNumber) {
    return lowestNumber;
  }
  return array.findLast((n) => n <= number);
}

export function makeActionTimestampMap(
  tableActions: TableAction[],
): ActionTimestampMap {
  const reversedTableActions = tableActions.toReversed();
  const tempResult: Record<
    number,
    { timestamps: Set<number>; timestampAction: Record<number, string> }
  > = {};

  const periods = new Set<number>();

  for (const action of reversedTableActions) {
    const period = action.period.sequence;
    periods.add(period);
    const time = action.clockTimeTicks;
    if (!tempResult[period]) {
      tempResult[period] = {
        timestamps: new Set<number>(),
        timestampAction: {},
      };
    }
    tempResult[period].timestamps.add(time);
    tempResult[period].timestampAction[time] = action.actionId;
  }

  const result: ActionTimestampMap = {};
  for (const period of periods) {
    const timestamps = [...tempResult[period].timestamps.keys()].sort(
      sortNumbersAscending,
    );
    result[period] = {
      timestamps,
      timestampAction: tempResult[period].timestampAction,
    };
  }
  return result;
}

export function selectTeam({
  isHomeTeamCollector,
  awayTeam,
  homeTeam,
}: Pick<FixtureStore, 'homeTeam' | 'awayTeam' | 'isHomeTeamCollector'>) {
  if (isHomeTeamCollector === null) return undefined;
  return isHomeTeamCollector ? homeTeam : awayTeam;
}

export function selectActionVersion(
  backup: Action | null | undefined,
  actionHistory: Action[] | null | undefined,
) {
  if (!actionHistory && !backup) {
    return null;
  }
  if (!actionHistory && backup) {
    return backup;
  }
  if (actionHistory && !backup) {
    return actionHistory[0];
  }
  if (!actionHistory || !backup) {
    return null;
  }

  if (isExactAction(actionHistory[0], backup)) {
    return actionHistory[0];
  }
  if (backup.updatedAt > actionHistory[0].updatedAt) {
    return backup;
  } else {
    return actionHistory[0];
  }
}

export function getLatestActionVersion(actionId: string) {
  const backup = getActionBackup(actionId);
  const actionHistory = useFixtureStore.getState().actions.get(actionId);
  return selectActionVersion(backup, actionHistory);
}
