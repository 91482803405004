import { useActionStore } from '@/stores/ActionStore/ActionStore';
import { ActionEdit } from '@/components/ActionEdit/ActionEdit';
import { PitchPreview } from '@/components/Pitch/PitchPreview';

export const ActionView = () => {
  const action = useActionStore((state) => state.action);
  const actionViewAction = useActionStore((state) => state.actionViewAction);
  const isActionSelected = !!(actionViewAction && action);

  if (isActionSelected) {
    return <ActionEdit />;
  }

  return <PitchPreview />;
};
