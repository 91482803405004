import { ACTION_TYPE_ID, Extras, type PeriodDto } from '@contract';
import type { OutcomeType } from '@/components/ActionOutcome/constants';
import type { Player } from '@/service/lineups';
import type { STATUS_OPTIONS } from '@/components/ActionFilters/common';

export const FILTER_NAME = {
  PLAYERS: 'players',
  EVENTS: 'events',
  EXTRAS: 'extras',
  OUTCOME: 'outcome',
  FLAGS: 'flags',
  WARNINGS: 'warnings',
  TEAM: 'team',
  PERIOD: 'period',
  STATUS: 'status',
} as const;

const ALL_LABEL = 'All';
const NONE_LABEL = 'None';
const ASSIGNED_LABEL = 'Assigned';

export type FilterName = ConstType<typeof FILTER_NAME>;
export type FilterPlayer = Player & { teamName: string };

export enum CommonOptions {
  All = ALL_LABEL,
  None = NONE_LABEL,
  Assigned = ASSIGNED_LABEL,
}

export type TeamFilter = string | CommonOptions.None;

export type PlayerType = {
  option: CommonOptions;
  playersSelected: FilterPlayer[];
};

export type FilterValue = {
  [FILTER_NAME.PLAYERS]: PlayerType;
  [FILTER_NAME.EVENTS]: ACTION_TYPE_ID[];
  [FILTER_NAME.EXTRAS]: Extras[];
  [FILTER_NAME.PERIOD]: PeriodDto[];
  [FILTER_NAME.OUTCOME]: OutcomeType | null;
  [FILTER_NAME.FLAGS]: boolean | null;
  [FILTER_NAME.WARNINGS]: boolean | null;
  [FILTER_NAME.STATUS]: keyof typeof STATUS_OPTIONS | null;
  [FILTER_NAME.TEAM]: TeamFilter | null;
};

export const DEFAULT_FILTER_VALUE: FilterValue = {
  [FILTER_NAME.PLAYERS]: {
    option: CommonOptions.All,
    playersSelected: [],
  },
  [FILTER_NAME.EVENTS]: [],
  [FILTER_NAME.EXTRAS]: [],
  [FILTER_NAME.PERIOD]: [],
  [FILTER_NAME.OUTCOME]: null,
  [FILTER_NAME.FLAGS]: null,
  [FILTER_NAME.WARNINGS]: null,
  [FILTER_NAME.STATUS]: null,
  [FILTER_NAME.TEAM]: null,
};
