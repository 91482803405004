export function timeStringToSeconds(timeString: string): number {
  const [m, s] = timeString.split(':');
  const minutes = Number(m) * 60;
  if (Number.isNaN(minutes)) {
    throw new Error('Unable to parse time string');
  }
  const seconds = Number(s);
  if (Number(isNaN(seconds))) {
    throw new Error('Unable to parse time string');
  }
  return minutes + seconds;
}
