import { FC, PropsWithChildren, useEffect } from 'react';
import hotkeysJs from 'hotkeys-js';
import { useActionStore } from '@/stores/ActionStore/ActionStore';
import { SPORT, Sport } from '@/service/constants';
import {
  HotkeysVariant,
  HOTKEYS_VARIANT,
  getHotkeysConfig,
  registerHotkeys,
  unregisterHotkeys,
} from './hotkeys';

type HotkeyManagerProps = PropsWithChildren & {
  hotkeysVariant?: HotkeysVariant;
  sport?: Sport;
};
export const HotkeyManager: FC<HotkeyManagerProps> = ({
  children,
  hotkeysVariant = HOTKEYS_VARIANT.COLLECTOR,
  sport = SPORT.SOCCER,
}) => {
  const mode = useActionStore((state) => state.mode);
  const hotkeysConfig = getHotkeysConfig(sport, hotkeysVariant);
  useEffect(() => {
    registerHotkeys(hotkeysConfig);
    return () => {
      unregisterHotkeys(hotkeysConfig);
    };
  }, [hotkeysConfig]);

  useEffect(() => {
    hotkeysJs.setScope(mode);
  }, [mode]);

  return children;
};
