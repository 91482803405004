import { type FC } from 'react';
import { Stack } from '@mui/material';
import type { Action } from '@/types/action/action';
import { ParentAction } from '@/components/ParentAction/ParentAction';
import { DirectionOfPlayRadioGroup } from '@/components/Form/DirectionOfPlayRadioGroup';
import { SimplePitch } from '../Common/SimplePitch';
import { PeriodClockField } from '../Common/PeriodClockField';
import { ActionPlayerField } from '../Common/ActionPlayerField';
import { FlagField } from '../Common/FlagField';
import { OutcomeField } from '../Common/OutcomeField';
import { IsAssistActionField } from '../Common/IsAssistActionField';
import { IsHeadedField } from '../Common/IsHeadedField';
import { Controls } from '../Common/Controls';

type GenericEditDialogContentProps = {
  action: Action;
};

export const GenericEditDialogContent: FC<GenericEditDialogContentProps> = ({
  action,
}) => {
  const pitch = <SimplePitch action={action} />;

  return (
    <Stack direction='row' width={pitch ? '100%' : undefined} gap={2} flex={1}>
      {!!pitch && (
        <Stack direction='row' flex={6}>
          {pitch}
        </Stack>
      )}

      <Stack flex={pitch ? 3 : undefined} gap={2}>
        <PeriodClockField action={action} />
        <ParentAction action={action} />
        <ActionPlayerField action={action} />
        <OutcomeField action={action} />
        <Stack direction='row' flexWrap='wrap'>
          <FlagField action={action} />
          <IsAssistActionField action={action} />
          <IsHeadedField action={action} />
        </Stack>
        <DirectionOfPlayRadioGroup action={action} />
        <Controls action={action} />
      </Stack>
    </Stack>
  );
};
