import { FC } from 'react';
import { Chip, ChipProps } from '@mui/material';
import SportsSoccer from '@mui/icons-material/SportsSoccer';
import { useFixtureStore } from '@/stores/FixtureStore';

type FixtureLabelProps = ChipProps;

export const CollectionIdLabel: FC<FixtureLabelProps> = (props) => {
  const dataCollectionId = useFixtureStore((state) => state.dataCollectionId);
  const fixtureId = useFixtureStore((state) => state.fixtureId);
  if (!dataCollectionId) return;
  return (
    <Chip
      variant='outlined'
      size='small'
      color={fixtureId ? 'info' : 'error'}
      icon={<SportsSoccer />}
      label={dataCollectionId}
      {...props}
    />
  );
};
