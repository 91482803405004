import { currentEnvironment } from '@/utils/environment';
import { registerWindowUtil, SS_KEY } from '@/utils/window';
import { ENVIRONMENT, type Environment } from '@root/env/environment';

const DEFAULT_MOCK_DATA: Record<Environment, boolean> = {
  [ENVIRONMENT.LOCAL]: true,
  [ENVIRONMENT.DEV]: true,
  [ENVIRONMENT.TEST]: false,
  [ENVIRONMENT.STAGING]: false,
  [ENVIRONMENT.PROD]: false,
};
const MOCK_DATA_POSSIBLE: Record<Environment, boolean> = {
  [ENVIRONMENT.LOCAL]: true,
  [ENVIRONMENT.DEV]: true,
  [ENVIRONMENT.TEST]: true,
  [ENVIRONMENT.STAGING]: true,
  [ENVIRONMENT.PROD]: false,
};

export function isUsingMockData() {
  return sessionStorage.getItem(SS_KEY.USE_MOCK_DATA) === 'true';
}

function toggleMockData() {
  sessionStorage.setItem(
    SS_KEY.USE_MOCK_DATA,
    String(!(sessionStorage.getItem(SS_KEY.USE_MOCK_DATA) === 'true')),
  );
}

export function setupMockData() {
  if (currentEnvironment === ENVIRONMENT.PROD) {
    return;
  }
  const hasItem = window.sessionStorage.getItem(SS_KEY.USE_MOCK_DATA);
  if (!hasItem) {
    const defaultValue = DEFAULT_MOCK_DATA[currentEnvironment].toString();
    window.sessionStorage.setItem(SS_KEY.USE_MOCK_DATA, defaultValue);
  }

  const canToggle = MOCK_DATA_POSSIBLE[currentEnvironment];
  if (canToggle) {
    registerWindowUtil('toggleMockData', toggleMockData);
  }
}
