import { useEffect, useMemo, useState } from 'react';
import { useFixtureStore } from '@/stores/FixtureStore';
import { useClockStore } from '@/stores/ClockStore';
import { useLatestTableAction } from '@/stores/hooks';
import type { TimelinePeriod } from './TimeLine';

export function useTimelinePeriods(): TimelinePeriod[] {
  const collectionState = useFixtureStore((state) => state.collectionState);
  if (!collectionState) return [];

  return collectionState.periods.reduce((acc: TimelinePeriod[], curr) => {
    const previousPeriod = acc[curr.sequence - 1];
    const offset = previousPeriod
      ? (previousPeriod.endTime || 0) - curr.startTime + 1
      : 0;

    if (!previousPeriod) {
      acc.push({
        sequence: curr.sequence,
        offset: 0,
        startTime: curr.startTime,
        endTime: curr.endTime,
      });
      return acc;
    }
    acc.push({
      sequence: curr.sequence,
      offset,
      endTime: curr.endTime ? curr.endTime + offset : null,
      startTime: previousPeriod.endTime ? previousPeriod.endTime + 1 : 0,
    });
    return acc;
  }, []);
}

export function useTimelineHighestClock() {
  const clockTimeTicks = useClockStore((state) => state.clockTimeTicks);
  const [highestClock, setHighestClock] = useState(clockTimeTicks);

  useEffect(() => {
    setHighestClock((currentHighest) => {
      if (clockTimeTicks > currentHighest) return clockTimeTicks;
      return currentHighest;
    });
  }, [clockTimeTicks]);

  return highestClock;
}

export function useTimelineMaxTime(lastTimelinePeriod?: TimelinePeriod) {
  const latestAction = useLatestTableAction();
  const highestClock = useTimelineHighestClock();
  const offset = lastTimelinePeriod ? lastTimelinePeriod.offset : 0;

  if (!latestAction) {
    return highestClock + offset;
  }

  return Math.max(highestClock, latestAction.clockTimeTicks) + offset;
}

export function useTimelineValue(timelinePeriods: TimelinePeriod[]) {
  const clockTimeTicks = useClockStore((state) => state.clockTimeTicks);
  const currentPeriod = useFixtureStore((state) => state.currentPeriod);
  const currentPeriodSequence = currentPeriod.sequence;

  const timeLinePeriod = useMemo(
    () =>
      timelinePeriods.find(
        (period) => period.sequence === currentPeriodSequence,
      ),
    [timelinePeriods, currentPeriodSequence],
  );

  const timeValue =
    clockTimeTicks + (timeLinePeriod ? timeLinePeriod.offset : 0);

  return timeValue;
}
