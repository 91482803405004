import { CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { useFixtureStore } from '@/stores/FixtureStore';
import { mapPlayerPositionToPlayer } from '@/stores/LineupStore/utils';
import { LineupPlayers } from './LineupPlayer';
import { LineupType } from './constants';

export const QALineups = () => {
  const gameState = useFixtureStore((state) => state.gameState);
  const homeTeam = useFixtureStore((state) => state.homeTeam);
  const awayTeam = useFixtureStore((state) => state.awayTeam);

  if (!homeTeam || !awayTeam) {
    return (
      <Stack flex={1} alignItems='center' justifyContent='center'>
        <CircularProgress size='2em' />
      </Stack>
    );
  }
  const homePitchPlayers = (
    gameState.lineups[homeTeam.id]?.pitchPlayers || []
  ).map(mapPlayerPositionToPlayer);
  const awayPitchPlayers = (
    gameState.lineups[awayTeam.id]?.pitchPlayers || []
  ).map(mapPlayerPositionToPlayer);

  const homeOffPlayers = gameState.lineups[homeTeam.id]?.offPlayers || [];
  const awayOffPlayers = gameState.lineups[awayTeam.id]?.offPlayers || [];
  const homeBenchPlayers = gameState.lineups[homeTeam.id]?.benchPlayers || [];
  const awayBenchPlayers = gameState.lineups[awayTeam.id]?.benchPlayers || [];
  const homeRetiredPlayers =
    gameState.lineups[homeTeam.id]?.retiredPlayers || [];
  const awayRetiredPlayers =
    gameState.lineups[awayTeam.id]?.retiredPlayers || [];

  const homeOutPlayers = [
    ...homeOffPlayers,
    ...homeBenchPlayers,
    ...homeRetiredPlayers,
  ];

  const awayOutPlayers = [
    ...awayOffPlayers,
    ...awayBenchPlayers,
    ...awayRetiredPlayers,
  ];

  return (
    <Stack overflow='auto' paddingInline={2}>
      <Stack direction='row' justifyContent='space-between' gap={2}>
        <Stack flex={1}>
          <Typography variant='h6'>{homeTeam.name}</Typography>
          <LineupPlayers
            players={homePitchPlayers}
            type={LineupType.Home}
            paddingBlock={2}
          />
        </Stack>

        <Divider flexItem orientation='vertical' />

        <Stack flex={1}>
          <Typography variant='h6' textAlign={'right'}>
            {awayTeam.name}
          </Typography>
          <LineupPlayers
            players={awayPitchPlayers}
            type={LineupType.Away}
            paddingBlock={2}
          />
        </Stack>
      </Stack>

      <Divider flexItem />

      <Stack direction='row' gap={2}>
        <Stack flex={1}>
          <LineupPlayers
            players={homeOutPlayers}
            type={LineupType.Home}
            paddingBlock={({ spacing }) => spacing(2, 0)}
          />
        </Stack>

        <Divider flexItem orientation='vertical' />

        <Stack flex={1}>
          <LineupPlayers
            players={awayOutPlayers}
            type={LineupType.Away}
            paddingBlock={({ spacing }) => spacing(2, 0)}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
