import { Checkbox, FormControlLabel } from '@mui/material';
import { FC } from 'react';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { getActionMetadata } from '@/utils/actions';
import { HEADED_LABEL } from '@/constants';
import type { Action } from '@/types/action/action';
import { ACTION_TYPE_ID, BodyPart } from '@contract';

type IsHeadedFieldProps = {
  action: Action;
};

export const IsHeadedField: FC<IsHeadedFieldProps> = ({ action }) => {
  const actionType = action.actionTypeId;
  if (
    actionType !== ACTION_TYPE_ID.Pass &&
    actionType !== ACTION_TYPE_ID.Clearance &&
    actionType !== ACTION_TYPE_ID.Interception
  ) {
    return;
  }
  const isHeaded = getActionMetadata(action).bodyPart === BodyPart.Head;
  const onChange = () => {
    if (!action) return;
    setActionPartialMetadata<typeof action>({
      bodyPart: isHeaded ? null : BodyPart.Head,
    });
  };

  return (
    <FormControlLabel
      control={<Checkbox onChange={onChange} checked={isHeaded} />}
      label={HEADED_LABEL}
    />
  );
};
