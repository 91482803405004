import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { type ButtonProps } from '@mui/material';
import { PITCH_PLAYERS_MAX_LEN } from '@/stores/LineupStore/constants';
import { useFixtureStore } from '@/stores/FixtureStore';
import { postAction } from '@/service/helpers/postAction';
import { createAction } from '@/stores/ActionStore/utils';
import type { LineupsAction } from '@/types/action/action';
import { ACTION_TYPE_ID, METADATA_KEY } from '@contract';
import { useGetLineups } from '@/service/lineups';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { useStartingLineupsContext } from './StartingLineupContext';

export const SubmitButton: FC<ButtonProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useGetLineups();
  const startingLineupsStore = useStartingLineupsContext();

  const pitchPlayersLength = startingLineupsStore.useStore(
    (state) => state.pitchPlayersLength,
  );
  const isGKSelected = startingLineupsStore.useStore(
    (state) => state.isGKSelected,
  );
  const teamId = startingLineupsStore.useStore((state) => state.teamId);

  const dataCollectionId = useFixtureStore((state) => state.dataCollectionId);
  const isDisabled =
    pitchPlayersLength !== PITCH_PLAYERS_MAX_LEN || !isGKSelected;

  const navigate = useNavigate();

  const submit = async () => {
    if (!dataCollectionId || !teamId) return;

    const { addedPlayers, benchPlayers, pitchPlayers, squadPlayers } =
      startingLineupsStore.useStore.getState();

    const action = createAction<LineupsAction>(
      ACTION_TYPE_ID.Lineups,
      METADATA_KEY.lineups,
      {
        addedPlayers,
        benchPlayers,
        pitchPlayers,
        squadPlayers,
      },
    );
    if (!action) return;

    setIsLoading(true);
    try {
      await postAction(action);
      const data = await mutate();

      if (data && data.pitchPlayers.length === PITCH_PLAYERS_MAX_LEN) {
        navigate('..', { relative: 'route' });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingButton
      {...props}
      onClick={submit}
      disabled={props.disabled || isDisabled}
      variant='contained'
      isLoading={isLoading}
    >
      Submit
    </LoadingButton>
  );
};
