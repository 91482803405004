import { FC } from 'react';
import { ShotAction } from '@/types/action/action';
import { UpdateAssistPlayer } from './MenuItems/UpdateAssistPlayer';

interface ShotContextMenuProps {
  action: ShotAction;
}

export const ShotContextMenu: FC<ShotContextMenuProps> = ({ action }) => {
  return (
    <>
      <UpdateAssistPlayer action={action} />
    </>
  );
};
