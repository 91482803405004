import { FC } from 'react';
import { StartPeriodAction } from '@/types/action/action';
import { UpdateDirectionOfPlay } from '@/components/ActionContextMenu/MenuItems/UpdateDirectionOfPlay';

interface StartPeriodContextMenuProps {
  action: StartPeriodAction;
}
export const StartPeriodContextMenu: FC<StartPeriodContextMenuProps> = ({
  action,
}) => {
  return (
    <>
      <UpdateDirectionOfPlay action={action} />
    </>
  );
};
