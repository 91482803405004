import { Chip, Typography } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { PITCH_PLAYERS_MAX_LEN } from '@/stores/LineupStore/constants';
import { useStartingLineupsContext } from './StartingLineupContext';

export const SquadLength = () => {
  const startingLineupsStore = useStartingLineupsContext();
  const pitchPlayersLength = startingLineupsStore.useStore(
    (state) => state.pitchPlayersLength,
  );
  const isGKSelected = startingLineupsStore.useStore(
    (state) => state.isGKSelected,
  );

  const requiredPlayers = PITCH_PLAYERS_MAX_LEN - pitchPlayersLength;

  const isGKRequired = !requiredPlayers && !isGKSelected;
  const isSuccess = !requiredPlayers && isGKSelected;

  if (requiredPlayers) {
    return <Typography>{requiredPlayers}</Typography>;
  }
  if (isGKRequired) {
    return <Chip icon={<ErrorOutline />} label={'GK Required'} color='error' />;
  }
  if (isSuccess) {
    return <CheckCircle color='success' />;
  }
};
