import { useRef } from 'react';
import { ViewportListRef } from 'react-viewport-list';
import { Stack, TableContainer } from '@mui/material';
import { ActionsTableHeader } from './ActionsTableHeader';
import { ActionsTableBody } from './ActionsTableBody';

export const ActionsTable = () => {
  const viewportRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<ViewportListRef | null>(null);

  return (
    <TableContainer id='actions-table' ref={viewportRef}>
      <Stack sx={{ minWidth: 900 }}>
        <ActionsTableHeader />
        <ActionsTableBody listRef={listRef} viewportRef={viewportRef} />
      </Stack>
    </TableContainer>
  );
};
