import { Tab, Tabs as MuiTabs, colors } from '@mui/material';
import { Link as RouterLink, useMatches } from 'react-router-dom';
import { FC } from 'react';
import { ROUTES, FIXTURE_VIEW_ROUTES } from '@/router/fixtureViewRoutes';
import { ROUTES_FIXTURE_VIEW } from '@/router/constants';
import { useGetLineups } from '@/service/lineups';

const FIXTURE_VIEW_TABS_ID = 'fixture-view-tabs';

export interface FixtureViewTabsProps {
  view: FIXTURE_VIEW_ROUTES;
}

const useCurrentViewRoutes = (view: FIXTURE_VIEW_ROUTES): string => {
  const matches = useMatches();
  const viewRoutes = ROUTES[view];

  const DEFAULT_ROUTE = ROUTES[FIXTURE_VIEW_ROUTES.HOME_COLLECTOR][0].ID;

  if (!matches) return DEFAULT_ROUTE;

  const match = matches.findLast((match) => {
    return viewRoutes.some((route) => match.id === route.ID);
  });

  if (!match) return DEFAULT_ROUTE;

  return match.id;
};

export const FixtureViewTabs: FC<FixtureViewTabsProps> = ({ view }) => {
  const activeRouteId = useCurrentViewRoutes(view);
  const { data } = useGetLineups();

  return (
    <MuiTabs
      value={activeRouteId}
      TabIndicatorProps={{ sx: { height: 0 } }}
      sx={{ mr: 'auto' }}
      id={FIXTURE_VIEW_TABS_ID}
    >
      {ROUTES[view].map((route) => {
        const disabled =
          (route.NAME === ROUTES_FIXTURE_VIEW.PITCH.NAME ||
            route.NAME === ROUTES_FIXTURE_VIEW.LIVE_LINEUP.NAME) &&
          data &&
          !data.pitchPlayers.length;

        return (
          <Tab
            sx={{
              backgroundColor: (theme) =>
                route.ID === activeRouteId
                  ? theme.palette.background.default
                  : colors.grey[900],
              color: colors.common.white,
              height: '50px',
            }}
            component={RouterLink}
            key={route.PATH}
            label={route.NAME}
            to={route.PATH}
            value={route.ID}
            disabled={disabled}
          />
        );
      })}
    </MuiTabs>
  );
};
