import {
  ListItemText,
  MenuItem,
  Popper,
  Paper,
  ListItemIcon,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { FC, PropsWithChildren, useMemo, useRef } from 'react';
import { toKebabCase } from '@/utils/string';
import { setContextNestedMenu, useUIStore } from '@/stores/UIStore';

interface NestedMenuItemProps {
  menuItemLabel: string;
  icon?: JSX.Element;
}

export const NestedMenuItem: FC<PropsWithChildren<NestedMenuItemProps>> = ({
  children,
  menuItemLabel,
  icon,
}) => {
  const menuItem = useRef<HTMLLIElement>(null);
  const itemId = useMemo(() => toKebabCase(menuItemLabel), [menuItemLabel]);
  const contextNestedMenu = useUIStore((state) => state.contextNestedMenu);

  return (
    <>
      <MenuItem
        ref={menuItem}
        aria-owns={itemId}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingRight: 1,
        }}
        onClick={() =>
          setContextNestedMenu(contextNestedMenu === itemId ? null : itemId)
        }
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText disableTypography>{menuItemLabel}</ListItemText>
        <ListItemIcon sx={{ justifyContent: 'end' }}>
          <ArrowRightIcon />
        </ListItemIcon>
      </MenuItem>
      <Popper
        id={itemId}
        open={contextNestedMenu === itemId}
        anchorEl={menuItem.current}
        placement='right-start'
        sx={{
          zIndex: 1300,
        }}
      >
        <Paper
          elevation={10}
          sx={{
            width: 'fit-content',
            maxWidth: '100%',
          }}
        >
          {children}
        </Paper>
      </Popper>
    </>
  );
};
