import type { MouseEvent } from 'react';
import type { Coords } from '@/types';
import { clamp } from './clamp';
import { mirrorValueInRange } from './mirrorValueInRange';

type PxToPercentProps = {
  clientWidth: number;
  clientHeight: number;
  offsetX: number;
  offsetY: number;
};

export class CoordsCalc {
  static HALF_OF_THE_PITCH = 50;

  static calcPxToActionCoords({
    clientWidth,
    clientHeight,
    offsetX,
    offsetY,
  }: PxToPercentProps): Coords {
    const xPx = clamp(offsetX, 0, clientWidth);
    const yPx = clamp(offsetY, 0, clientHeight);
    const xDraw = clamp((xPx / clientWidth) * 100, 0, 100);
    const yDraw = clamp((yPx / clientHeight) * 100, 0, 100);
    const x = xDraw;
    const y = mirrorValueInRange(yDraw);

    return {
      x: Number(x.toFixed(2)),
      y: Number(y.toFixed(2)),
    };
  }

  static calcClickToCoords(e: MouseEvent, outerClientRect: DOMRect): Coords {
    const outerCoords: Coords = {
      x: (e.nativeEvent.offsetX * 100) / outerClientRect.width,
      y: (e.nativeEvent.offsetY * 100) / outerClientRect.height,
    };
    return outerCoords;
  }

  static calcClickToActionCoords(
    e: MouseEvent,
    innerClientRect: DOMRect,
    outerClientRect: DOMRect,
  ) {
    const cornerPadding = (outerClientRect.width - innerClientRect.width) / 2;
    const outPadding = (outerClientRect.height - innerClientRect.height) / 2;

    return this.calcPxToActionCoords({
      clientWidth: innerClientRect.width,
      clientHeight: innerClientRect.height,
      offsetX: e.nativeEvent.offsetX - cornerPadding,
      offsetY: e.nativeEvent.offsetY - outPadding,
    });
  }

  /**
   * Calculates the dot position based on bounding rects.
   * This is necessary when pitch has outer area (eg. PassDialog).
   * @param innerCoords - actual action coords
   * @param innerClientRect - bounding rect of inner pitch area
   * @param outerClientRect - bounding client rect of outer pitch area
   * @returns {Coords}
   */
  static scaleUp(
    innerCoords: Coords,
    innerClientRect: DOMRect,
    outerClientRect: DOMRect,
  ): Coords {
    const onePercentWidth = outerClientRect.width / 100;
    const paddingHorizontal =
      (outerClientRect.width - innerClientRect.width) / onePercentWidth;
    const onePercentHeight = outerClientRect.height / 100;
    const paddingVertical =
      (outerClientRect.height - innerClientRect.height) / onePercentHeight;
    const HORIZONTAL_RATIO = (100 - paddingHorizontal) / 100;
    const VERTICAL_RATIO = (100 - paddingVertical) / 100;

    const x =
      (innerCoords.x - this.HALF_OF_THE_PITCH) * HORIZONTAL_RATIO +
      this.HALF_OF_THE_PITCH;
    const y =
      (100 - innerCoords.y - this.HALF_OF_THE_PITCH) * VERTICAL_RATIO +
      this.HALF_OF_THE_PITCH;

    return { x, y };
  }

  static assumeGoalKickCoords(coords: Coords): Coords {
    const goalKickCoords = {
      ...coords,
      y: 50,
    };

    if (coords.x >= 50) {
      goalKickCoords.x = 95;
    } else {
      goalKickCoords.x = 5;
    }

    return goalKickCoords;
  }

  static assumeCornerCoords(coords: Coords): Coords {
    const cornerCoords = {
      ...coords,
    };

    if (coords.y >= 50) {
      cornerCoords.y = 100;
    } else {
      cornerCoords.y = 0;
    }
    if (coords.x >= 50) {
      cornerCoords.x = 100;
    } else {
      cornerCoords.x = 0;
    }

    return cornerCoords;
  }
}
