import {
  useCallback,
  useEffect,
  useMemo,
  type ElementType,
  type FC,
  type SyntheticEvent,
} from 'react';
import { Slider, SliderMarkLabel } from '@mui/material';
import { useFiltersStore } from '@/stores/FiltersStore/FiltersStore';
import { setClockTicks, useClockStore } from '@/stores/ClockStore';
import { setCurrentPeriodConfig, useFixtureStore } from '@/stores/FixtureStore';
import type { TimelinePeriod } from './TimeLine';
import { getPeriodFromTime } from './utils';

interface TimeSliderProps {
  timeLinePeriods: TimelinePeriod[];
  onChange: (value: number) => void;
  value: number;
  maxValue: number;
}

const ClickableSliderLabel: ElementType = (props) => {
  const { onLabelClick, ownerState, ...restProps } = props;
  const markValue = ownerState.marks[props?.['data-index']].value;

  const noop = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  const onClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (markValue !== null) {
        onLabelClick(markValue);
      }
    },
    [onLabelClick, markValue],
  );

  return (
    <SliderMarkLabel
      onClick={onClick}
      onMouseDown={noop}
      onTouchStart={noop}
      ownerState={ownerState}
      {...restProps}
    />
  );
};

export const TimeSlider: FC<TimeSliderProps> = ({
  timeLinePeriods,
  onChange,
  value,
  maxValue,
}) => {
  const currentPeriod = useFixtureStore((state) => state.currentPeriod);
  const periodsConfig = useFixtureStore((state) => state.periodsConfig);
  const filterTableActions = useFiltersStore((state) => state.tableActions);
  const isClockRunning = useClockStore((state) => state.isRunning);

  const timeLinePeriod = useMemo(
    () => getPeriodFromTime(timeLinePeriods, value),
    [timeLinePeriods, value],
  );
  const timeLineMarks = useMemo(
    () =>
      timeLinePeriods.map((period) => ({
        value: period.endTime === null ? maxValue : period.endTime,
        label: periodsConfig.find((p) => p.seq === period.sequence)?.label,
      })),
    [maxValue, periodsConfig, timeLinePeriods],
  );

  useEffect(() => {
    if (
      !timeLinePeriod ||
      !isClockRunning ||
      timeLinePeriod.sequence === currentPeriod.sequence
    )
      return;

    const periodConfig = periodsConfig.find(
      (periodConfig) => periodConfig.seq === timeLinePeriod?.sequence,
    );

    if (!periodConfig) return;

    setClockTicks(value - timeLinePeriod.offset);
    setCurrentPeriodConfig(periodConfig);
  }, [
    currentPeriod.sequence,
    isClockRunning,
    periodsConfig,
    timeLinePeriod,
    value,
  ]);

  return (
    <Slider
      disabled={!filterTableActions.length}
      min={0}
      max={maxValue}
      step={1}
      valueLabelDisplay='off'
      marks={timeLineMarks}
      value={value || 0}
      onChange={(_: Event, value: number | number[]) =>
        onChange(value as number)
      }
      slots={{
        markLabel: ClickableSliderLabel,
      }}
      slotProps={{
        // @ts-expect-error custom prop passed
        markLabel: { onLabelClick: onChange },
      }}
    />
  );
};
