import { FC } from 'react';
import { ButtonGroup, Button } from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import Tune from '@mui/icons-material/Tune';
import {
  openDrawer,
  setFilterValues,
  useHasFilters,
} from '@/stores/FiltersStore/FiltersStore';
import { DEFAULT_FILTER_VALUE } from '@/stores/FiltersStore/constants';

export const OpenFiltersButton: FC = () => {
  const hasFilters = useHasFilters();

  return (
    <ButtonGroup variant={hasFilters ? 'contained' : 'outlined'}>
      <Button onClick={openDrawer} startIcon={<Tune />}>
        Filters
      </Button>
      {hasFilters && (
        <Button
          size='small'
          onClick={() => setFilterValues(DEFAULT_FILTER_VALUE)}
        >
          <Clear fontSize='small' />
        </Button>
      )}
    </ButtonGroup>
  );
};
