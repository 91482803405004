import { FC } from 'react';
import { Chip, ChipProps } from '@mui/material';
import CloudSync from '@mui/icons-material/CloudSync';
import { useSocketStore } from '@/stores/SocketStore/SocketStore';

type StateSubscriptionLabelProps = ChipProps;

export const StateSubscriptionLabel: FC<StateSubscriptionLabelProps> = (
  props,
) => {
  const stateSubscribed = useSocketStore((state) => state.stateSubscribed);
  return (
    <Chip
      variant='outlined'
      size='small'
      color='info'
      icon={<CloudSync />}
      label={stateSubscribed ? 'State subscribed' : 'State not subscribed'}
      {...props}
    />
  );
};
