import { FC } from 'react';
import { Chip, Stack } from '@mui/material';
import type { FormationAction } from '@/types/action/action';
import type { PlayerPositionDto } from '@contract';
import PitchHorizontalSVG from '@/assets/football-pitch.svg?react';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { EditPitchGrid } from '@/components/Formation/EditFormation/EditPitchGrid';
import { PITCH_FORMATION_ID } from '@/components/Formation/constants';
import { calculateFormation } from '@/stores/LineupStore/utils';
import { FormationContainer } from '@/components/Formation/FormationContainer';
import { PeriodClockField } from '../Common/PeriodClockField';
import { FlagField } from '../Common/FlagField';
import { Controls } from '../Common/Controls';

interface FormationDialogContentProps {
  action: FormationAction;
}

export const FormationDialogContent: FC<FormationDialogContentProps> = ({
  action,
}) => {
  const formation = calculateFormation(action.metadata.formation.pitchPlayers);
  const onChange = (pitchPlayers: PlayerPositionDto[]) => {
    setActionPartialMetadata<FormationAction>({ pitchPlayers });
  };

  return (
    <Stack direction='row' gap={2} width='100%'>
      <Stack flex={6} direction='row'>
        <Stack
          sx={{
            position: 'relative',
            flex: 1,
            width: 'clamp(500px, 100%, 1000px)',
          }}
        >
          <PitchHorizontalSVG />
          <EditPitchGrid
            setPitchPlayers={onChange}
            pitchPlayers={action.metadata.formation.pitchPlayers}
          />
          <FormationContainer>
            <Chip id={PITCH_FORMATION_ID} label={formation} />
          </FormationContainer>
        </Stack>
      </Stack>

      <Stack flex={3} gap={2}>
        <PeriodClockField action={action} />
        <Stack direction='row' flexWrap='wrap'>
          <FlagField action={action} />
        </Stack>
        <Controls action={action} />
      </Stack>
    </Stack>
  );
};
