import { FC, useEffect, useRef, type MouseEvent } from 'react';
import { Box, css, styled } from '@mui/material';
import { Zone } from './Zone';
import { GOAL_COLORS, GOAL_GRID_ZONES, ZONE_TYPES } from './constants';
import { getGoalGridAreas } from './utils';

const { gridFarAreas, gridGoalAreas, gridOffAreas, gridWoodAreas } =
  getGoalGridAreas();

const GridBox = styled(Box)(
  () => css`
    display: grid;
    cursor: pointer;

    &.disabled {
      background: ${GOAL_COLORS.DISABLED};
      cursor: default;
    }
  `,
);

const FarContainer = styled(GridBox)(
  () => css`
    grid-template-areas: ${gridFarAreas};
    background: ${GOAL_COLORS.FAR};
  `,
);

const OffContainer = styled(GridBox)(
  () => css`
    grid-area: main;
    grid-template-areas: ${gridOffAreas};
    background: ${GOAL_COLORS.OFF};
  `,
);

const WoodContainer = styled(GridBox)(
  () => css`
    grid-area: main;
    grid-template-areas: ${gridWoodAreas};
    background: ${GOAL_COLORS.WOOD};

    &.disabled {
      background: ${GOAL_COLORS.WOOD};
    }
  `,
);

const GoalContainer = styled(GridBox)(
  () => css`
    grid-area: main;
    grid-template-areas: ${gridGoalAreas};
    background: ${GOAL_COLORS.GOAL};
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;

    & :nth-of-type(6n + 2),
    & :nth-of-type(6n + 4) {
      border-right: 1px solid black;
    }
    & :nth-of-type(12n + 1),
    & :nth-of-type(12n + 2),
    & :nth-of-type(12n + 3),
    & :nth-of-type(12n + 4),
    & :nth-of-type(12n + 5),
    & :nth-of-type(12n + 6) {
      border-top: 1px solid black;
    }
  `,
);

const zones = GOAL_GRID_ZONES.split('\n')
  .join(' ')
  .replaceAll(`'`, '')
  .split(' ')
  .filter((e) => e);

const offZones = zones.filter((e) => e.includes(ZONE_TYPES.OFF));
const woodZones = zones.filter((e) => e.includes(ZONE_TYPES.WOOD));
const goalZones = zones.filter((e) => e.includes(ZONE_TYPES.GOAL));

const mapElemToZone = (zone: string, index: number) => (
  <Zone key={`${zone}_${index}`} zone={zone} />
);

const offElements = offZones.map(mapElemToZone);
const woodElements = woodZones.map(mapElemToZone);
const goalElements = goalZones.map(mapElemToZone);

interface GoalProps {
  zoneNumber: string | null;
  onTargetDisabled?: boolean;
  offTargetDisabled?: boolean;
  goalColor?: string;
  setPartialMetadata: (metadata: { zoneNumber: number }) => void;
}

export const Goal: FC<GoalProps> = ({
  zoneNumber,
  onTargetDisabled = false,
  offTargetDisabled = false,
  goalColor = GOAL_COLORS.GOAL,
  setPartialMetadata,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const setZoneNumber = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const { zoneId, zoneType } = target.dataset;

    if (
      !zoneId ||
      !zoneType ||
      (offTargetDisabled &&
        [ZONE_TYPES.FAR, ZONE_TYPES.OFF, ZONE_TYPES.WOOD].includes(zoneType)) ||
      (onTargetDisabled && zoneType === ZONE_TYPES.GOAL)
    )
      return;

    setPartialMetadata({ zoneNumber: Number(zoneId) });
  };

  useEffect(() => {
    if (!zoneNumber || !containerRef.current) return;

    const oldActiveElements = containerRef.current.querySelectorAll('.active');
    oldActiveElements.forEach((element) => {
      element.classList.remove('active');
    });

    const activeElement = containerRef.current.querySelector(
      `[data-zone-id='${zoneNumber}']`,
    );

    if (activeElement) {
      activeElement.classList.add('active');
    }
  }, [zoneNumber]);

  return (
    <Box
      sx={{
        width: '100%',
        aspectRatio: '5/2',
        display: 'flex',
      }}
    >
      <FarContainer
        id='off-target-area'
        ref={containerRef}
        className={offTargetDisabled ? 'disabled' : ''}
        onClick={setZoneNumber}
        sx={{ flex: 1 }}
      >
        <Zone zone='FAR_142' />
        <Zone zone='FAR_141' />
        <OffContainer className={offTargetDisabled ? 'disabled' : ''}>
          {offElements}
          <WoodContainer className={offTargetDisabled ? 'disabled' : ''}>
            {woodElements}
            <GoalContainer
              id='on-target-area'
              className={onTargetDisabled ? 'disabled' : ''}
              sx={{ background: goalColor }}
            >
              {goalElements}
            </GoalContainer>
          </WoodContainer>
        </OffContainer>
        <Zone zone='FAR_143' />
      </FarContainer>
    </Box>
  );
};
