import { GoalkeeperPenaltyFacedOutcome, METADATA_KEY } from '@contract';
import { postAction } from '@/service/helpers/postAction';
import type { Action } from '@/types/action/action';
import {
  commitFreeKickAwarded,
  commitGoal,
  commitGoalkeeperPenaltyFaced,
  commitRedCard,
  commitShot,
  commitSubstitution,
  commitYellowCard,
} from '@/stores/ActionStore/commitActions';
import { getActionMetadata } from '@/utils/actions';
import {
  resetToPitchMode,
  setActionViewAction,
  setDialogAction,
  setMode,
} from '@/stores/ActionStore/ActionStore';
import { MODE } from '@/stores/ActionStore/constants';
import { isNewAction } from '@/stores/utils';
import { getActionWarnings } from '../ActionWarnings/helpers';

export function submitActionDialog(action: Action) {
  const am = action.actionTypeMetadata;

  if (am === METADATA_KEY.shot) {
    return commitShot();
  }
  if (am === METADATA_KEY.goal) {
    return commitGoal();
  }
  if (am === METADATA_KEY.yellowCard) {
    return commitYellowCard();
  }
  if (am === METADATA_KEY.redCard) {
    return commitRedCard();
  }
  if (am === METADATA_KEY.substitution) {
    return commitSubstitution();
  }
  if (am === METADATA_KEY.freeKickAwarded) {
    return commitFreeKickAwarded();
  }
  if (am === METADATA_KEY.goalkeeperPenaltyFaced) {
    const metadata = getActionMetadata(action);

    if (
      isNewAction(action) &&
      metadata.outcome === GoalkeeperPenaltyFacedOutcome.Saved
    ) {
      commitGoalkeeperPenaltyFaced();
      return setMode(MODE.GOALKEEPER_SAVE);
    }

    commitGoalkeeperPenaltyFaced();
    onDialogClose();
    return;
  }

  postAction({ ...action, warnings: getActionWarnings(action) });
}

export const onDialogClose = () => {
  setDialogAction(null);
  setActionViewAction(null);
  resetToPitchMode();
};
