import type { MouseEvent } from 'react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  ACTION_TYPE_ID,
  ActionContext,
  AttemptType,
  BodyPart,
  CardReason,
  ErrorReason,
  FreeKickAwardedReason,
  GoalkeeperPenaltyFacedOutcome,
  GoalkeeperSaveAttemptType,
  GoalType,
  METADATA_KEY,
  PatternOfPlay,
  Position,
  RedCardType,
  Stance,
} from '@contract';
import { isDebugEnabled, isTraceStoreEnabled } from '@/utils/debug';
import { CoordsCalc } from '@/utils/coordsCalc';
import { getActionMetadata, isAssistActionType } from '@/utils/actions';
import type { Coords } from '@/types';
import { Player } from '@/service/lineups';
import { postAction } from '@/service/helpers/postAction';
import { DIRECTION_OF_PLAY } from '@/components/Periods/constants';
import { ACTION_WARNING } from '@/components/ActionWarnings/constants';
import {
  Action,
  AdditionalTimeAction,
  ErrorAction,
  FreeKickAwardedAction,
  GoalAction,
  GoalkeeperPenaltyFacedAction,
  GoalkeeperSaveAction,
  PlayStoppedAction,
  RedCardAction,
  ShotAction,
  SubstitutionAction,
  VARResultAction,
  VARUnderwayAction,
  YellowCardAction,
} from '@/types/action/action';
import { UNKNOWN_PLAYER } from '@/stores/LineupStore/constants';
import { removeTableAction, useFixtureStore } from '../FixtureStore';
import { getLatestActionVersion } from '../utils';
import { useLineupStore } from '../LineupStore/LineupStore';
import {
  setContextNestedMenu,
  setPitchBlockedActionType,
  useUIStore,
} from '../UIStore';
import { getRecentAddedAction } from '../UnsyncedActionsStore';
import { useUserStore } from '../UserStore';
import { PLAYER_INPUT_AUTO_COMMIT_TIME } from '../constants';
import {
  AssistAction,
  MODE,
  Mode,
  PlayerQuickInputMap,
  TargetActionMode,
} from './constants';
import { buildPlayerFromMap } from './playerInput';
import { createAction, createPendingPass, modifyActionMetadata } from './utils';

export type ActionStore = {
  mode: Mode;
  pitchInnerSize: DOMRect | null;
  pitchOuterSize: DOMRect | null;
  coords: Coords | null;
  targetCoords: Coords | null;
  dotCoords: Coords | null;
  dotTargetCoords: Coords | null;
  playerNumber: string | null;
  player: Player | null;
  action: Action | null;
  actionViewAction: Action | null;
  dialogAction: Action | null;
  recentAwardedAction: Action | null;
  contextAction: Action | null;
  playerInputMap: PlayerQuickInputMap | null;
};

export const ACTIONS_DEFAULT_STATE = {
  action: null,
  actionViewAction: null,
  dialogAction: null,
  contextAction: null,
};

export const DEFAULT_STATE: ActionStore = {
  ...ACTIONS_DEFAULT_STATE,
  mode: MODE.PITCH,
  pitchInnerSize: null,
  pitchOuterSize: null,
  coords: null,
  targetCoords: null,
  dotCoords: null,
  dotTargetCoords: null,
  playerNumber: null,
  player: null,
  recentAwardedAction: null,
  playerInputMap: null,
};

export const useActionStore = create<ActionStore>()(
  devtools(
    () => ({
      ...DEFAULT_STATE,
    }),
    {
      enabled: isDebugEnabled(),
      trace: isTraceStoreEnabled,
      name: 'ActionStore',
    },
  ),
);

export function restoreDefault() {
  useActionStore.setState(DEFAULT_STATE, false, 'restoreDefault');
}

export function reset() {
  const { pitchBlockedActionType } = useUIStore.getState();

  if (pitchBlockedActionType) {
    setPitchBlockedActionType(null);
    return;
  }

  return useActionStore.setState(
    (state) => {
      return {
        ...DEFAULT_STATE,
        playerInputMap: state.playerInputMap,
        pitchInnerSize: state.pitchInnerSize,
        pitchOuterSize: state.pitchOuterSize,
      };
    },
    false,
    'reset',
  );
}

export function setRecentlyAwardedAction(recentAwardedAction: Action | null) {
  setPitchBlockedActionType(
    recentAwardedAction ? recentAwardedAction.actionTypeId : null,
  );

  return useActionStore.setState({ recentAwardedAction });
}

export function setPlayerInputMap(playerInputMap: PlayerQuickInputMap | null) {
  return useActionStore.setState({ playerInputMap });
}

export function getPlayerInputMap() {
  return useActionStore.getState().playerInputMap;
}

export function setPitchInnerSize(
  pitchInnerSize: ActionStore['pitchInnerSize'],
) {
  useActionStore.setState({ pitchInnerSize }, false, 'setPitchInnerSize');
  const state = useActionStore.getState();
  if (!state.pitchOuterSize) return;
  if (state.coords && !state.dotCoords) {
    setCoords(state.coords);
  }
  if (state.targetCoords && !state.dotTargetCoords) {
    setTargetCoords(state.targetCoords);
  }
}

export function setPitchOuterSize(
  pitchOuterSize: ActionStore['pitchOuterSize'],
) {
  useActionStore.setState({ pitchOuterSize }, false, 'setPitchOuterSize');
  const state = useActionStore.getState();
  if (!state.pitchInnerSize) return;
  if (state.coords && !state.dotCoords) {
    setCoords(state.coords);
  }
  if (state.targetCoords && !state.dotTargetCoords) {
    setTargetCoords(state.targetCoords);
  }
}

export function setCoords(coords: ActionStore['coords']) {
  return useActionStore.setState(
    (state) => {
      if (coords === null) {
        return {
          coords: null,
          dotCoords: null,
        };
      }
      if (!state.pitchInnerSize || !state.pitchOuterSize) {
        return { coords, dotCoords: null };
      }
      const { pitchInnerSize, pitchOuterSize } = state;
      const dotCoords = CoordsCalc.scaleUp(
        coords,
        pitchInnerSize,
        pitchOuterSize,
      );
      return {
        coords,
        dotCoords,
      };
    },
    false,
    'setCoords',
  );
}

export function setTargetCoords(targetCoords: ActionStore['targetCoords']) {
  return useActionStore.setState(
    (state) => {
      if (targetCoords === null) {
        return {
          targetCoords: null,
          dotTargetCoords: null,
        };
      }
      if (!state.pitchInnerSize || !state.pitchOuterSize) {
        return { targetCoords, dotTargetCoords: null };
      }
      const { pitchInnerSize, pitchOuterSize } = state;
      const dotTargetCoords = CoordsCalc.scaleUp(
        targetCoords,
        pitchInnerSize,
        pitchOuterSize,
      );
      return {
        targetCoords,
        dotTargetCoords,
      };
    },
    false,
    'setTargetCoords',
  );
}

export function setDotCoords(event: MouseEvent | null) {
  return useActionStore.setState(
    (state) => {
      if (event === null) {
        return { coords: null, dotCoords: null };
      }
      if (!state.pitchInnerSize || !state.pitchOuterSize) {
        return state;
      }
      const { pitchInnerSize, pitchOuterSize } = state;
      const dotCoords = CoordsCalc.calcClickToCoords(event, pitchOuterSize);
      const coords = CoordsCalc.calcClickToActionCoords(
        event,
        pitchInnerSize,
        pitchOuterSize,
      );
      return {
        coords,
        dotCoords,
      };
    },
    false,
    'setDotCoords',
  );
}

export function setDotTargetCoords(event: MouseEvent | null) {
  return useActionStore.setState(
    (state) => {
      if (event === null) {
        return { targetCoords: null, dotTargetCoords: null };
      }
      if (!state.pitchInnerSize || !state.pitchOuterSize) {
        return state;
      }

      const { pitchInnerSize, pitchOuterSize } = state;
      const dotTargetCoords = CoordsCalc.calcClickToCoords(
        event,
        pitchOuterSize,
      );
      const targetCoords = CoordsCalc.calcClickToActionCoords(
        event,
        pitchInnerSize,
        pitchOuterSize,
      );
      return {
        targetCoords,
        dotTargetCoords,
      };
    },
    false,
    'setDotTargetCoords',
  );
}

export function cancelPitch() {
  return useActionStore.setState(
    (state) => {
      if (state.playerNumber) {
        return {
          playerNumber: null,
          player: null,
        };
      }
      return { ...DEFAULT_STATE, action: state.action };
    },
    false,
    'cancelPitch',
  );
}

export function cancelTargetActionMode() {
  return useActionStore.setState(
    (state) => {
      if (
        (state.action && state.action.actionTypeId === ACTION_TYPE_ID.Pass) ||
        (state.action && state.action.actionTypeId === ACTION_TYPE_ID.Cross) ||
        (state.action && state.action.actionTypeId === ACTION_TYPE_ID.Launch) ||
        (state.action &&
          state.action.actionTypeId === ACTION_TYPE_ID.GoalkeeperThrow) ||
        (state.action &&
          state.action.actionTypeId === ACTION_TYPE_ID.GoalkeeperKickFromHands)
      ) {
        removeTableAction(state.action.actionId);
      }
      return {
        mode: MODE.PITCH,
        targetCoords: null,
        dotTargetCoords: null,
        action: null,
      };
    },
    false,
    'cancelPass',
  );
}

export function updatePreviousActionIsAssist(action: ShotAction) {
  const previousAction = action.parentActionId
    ? getLatestActionVersion(action.parentActionId)
    : null;
  if (!previousAction || !isAssistActionType(previousAction)) {
    return Promise.resolve();
  }
  const shotAssist = action.metadata.shot.assistPlayer;
  if (
    shotAssist &&
    previousAction.metadata[previousAction.actionTypeMetadata]?.isAssist
  ) {
    return Promise.resolve();
  }
  if (
    !shotAssist &&
    !previousAction.metadata[previousAction.actionTypeMetadata]?.isAssist
  ) {
    return Promise.resolve();
  }

  const previousAssistUpdate: AssistAction = modifyActionMetadata(
    previousAction,
    {
      isAssist: !!shotAssist,
    },
  );
  previousAssistUpdate.messageId = null;
  return postAction(previousAssistUpdate);
}

function _setPassMode(mode: TargetActionMode) {
  const { dataCollectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState(
    (state) => {
      if (!state.playerNumber || !state.coords || !state.player) {
        return state;
      }

      const newPassAction = createPendingPass({
        mode,
        coords: state.coords,
      });
      if (!newPassAction) return state;

      const newPassActionMetadata = getActionMetadata(newPassAction);
      const { recentAwardedAction } = state;

      if (
        recentAwardedAction &&
        (newPassAction.actionTypeId === ACTION_TYPE_ID.Pass ||
          newPassAction.actionTypeId === ACTION_TYPE_ID.Cross ||
          newPassAction.actionTypeId === ACTION_TYPE_ID.Launch)
      ) {
        if (recentAwardedAction.actionTypeId === ACTION_TYPE_ID.CornerAwarded) {
          newPassActionMetadata.actionContext = ActionContext.CornerTaken;
        }
        if (
          recentAwardedAction.actionTypeId === ACTION_TYPE_ID.GoalKickAwarded
        ) {
          newPassActionMetadata.actionContext = ActionContext.GoalKickTaken;
        }
        if (
          recentAwardedAction.actionTypeId === ACTION_TYPE_ID.FreeKickAwarded
        ) {
          const recentAwardedActionMetadata =
            getActionMetadata(recentAwardedAction);

          newPassActionMetadata.actionContext =
            recentAwardedActionMetadata.isPenalty
              ? ActionContext.PenaltyTaken
              : ActionContext.FreeKickTaken;
        }
      }

      const previousAction = getRecentAddedAction();
      if (
        previousAction &&
        previousAction.actionTypeId === ACTION_TYPE_ID.StartPeriod
      ) {
        newPassActionMetadata.actionContext = ActionContext.KickOff;
      }

      return {
        mode: MODE.PASS,
        action: newPassAction,
      };
    },
    false,
    'setMode',
  );
}

function _setPlayStoppedMode() {
  const { dataCollectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    const action = createAction<PlayStoppedAction>(
      ACTION_TYPE_ID.PlayStopped,
      METADATA_KEY.playStopped,
      {
        reason: null,
        isFromOppositeTeam: null,
      },
    );

    if (!action) return state;

    return { mode: MODE.PLAY_STOPPED, action, dialogAction: action };
  });
}

function _setShotMode() {
  const { dataCollectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    if (!state.playerNumber || !state.coords || !state.player) {
      return state;
    }

    const action = createAction<ShotAction>(
      ACTION_TYPE_ID.Shot,
      METADATA_KEY.shot,
      {
        assistPlayer: null,
        zoneNumber: null,
        blockersPositions: null,
        bodyPart: null,
        extras: null,
        patternOfPlay: null,
        attemptType: AttemptType.TemptAttempt,
        position: state.coords,
        isAssist: false,
        shotOffTargetType: null,
      },
    );

    if (!action) return state;

    return {
      mode: MODE.SHOT,
      action,
      dialogAction: action,
    };
  });
}

function _setGoalMode() {
  const { dataCollectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();
  const { directionOfPlay } = useFixtureStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    if (!state.playerNumber || !state.coords || !state.player) {
      return state;
    }

    const action = createAction<GoalAction>(
      ACTION_TYPE_ID.Goal,
      METADATA_KEY.goal,
      {
        assistPlayer: null,
        bodyPart: BodyPart.RightFoot,
        extras: null,
        patternOfPlay: PatternOfPlay.RegularPlay,
        position: state.coords,
        zoneNumber: 91,
        blockersPositions: [
          directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
            ? { x: 15, y: 50 }
            : { x: 85, y: 50 },
        ],
        goalType: GoalType.Goal,
      },
    );

    if (!action) return state;

    return { mode: MODE.GOAL, action, dialogAction: action };
  });
}

function _setSubstitutionMode() {
  const { dataCollectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    const action = createAction<SubstitutionAction>(
      ACTION_TYPE_ID.Substitution,
      METADATA_KEY.substitution,
      {
        targetPlayer: null,
      },
    );

    if (!action) return state;

    return { mode: MODE.SUBSTITUTION, action, dialogAction: action };
  });
}

function _setThrowInMode() {
  const { dataCollectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState(
    (state) => {
      if (!state.playerNumber || !state.coords || !state.player) {
        return state;
      }

      return { mode: MODE.THROW_IN };
    },
    false,
    'setThrowInMode',
  );
}

function _setYellowCardMode() {
  const { dataCollectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    if (!state.playerNumber || !state.coords || !state.player) {
      return state;
    }

    const action = createAction<YellowCardAction>(
      ACTION_TYPE_ID.YellowCard,
      METADATA_KEY.yellowCard,
      {
        reason: CardReason.Foul,
      },
    );

    if (!action) return state;

    return { mode: MODE.YELLOW_CARD, action, dialogAction: action };
  });
}

function _setRedCardMode() {
  const { dataCollectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    if (!state.playerNumber || !state.coords || !state.player) {
      return state;
    }

    const action = createAction<RedCardAction>(
      ACTION_TYPE_ID.RedCard,
      METADATA_KEY.redCard,
      {
        outcome: RedCardType.SecondYellow,
        reason: CardReason.Foul,
      },
    );

    if (!action) return state;

    return { mode: MODE.RED_CARD, action, dialogAction: action };
  });
}

function _setVARUnderwayMode() {
  const { dataCollectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    const action = createAction<VARUnderwayAction>(
      ACTION_TYPE_ID.VARUnderway,
      METADATA_KEY.varUnderway,
      {
        reason: null,
      },
    );

    if (!action) return state;

    return { mode: MODE.VAR_UNDERWAY, action, dialogAction: action };
  });
}

function _setVARResultMode() {
  const { dataCollectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    const action = createAction<VARResultAction>(
      ACTION_TYPE_ID.VARResult,
      METADATA_KEY.varResult,
      {
        outcome: null,
      },
    );

    if (!action) return state;

    return { mode: MODE.VAR_RESULT, action, dialogAction: action };
  });
}

function _setFreeKickMode() {
  const { teamId } = useLineupStore.getState();
  const { dataCollectionId } = useFixtureStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    if (!state.coords || !state.player) return state;

    const action = createAction<FreeKickAwardedAction>(
      ACTION_TYPE_ID.FreeKickAwarded,
      METADATA_KEY.freeKickAwarded,
      {
        isPenalty: false,
        reason: FreeKickAwardedReason.Foul,
        position: state.coords,
      },
    );

    if (!action) return state;

    return { mode: MODE.FREE_KICK, action, dialogAction: action };
  });
}

function _setGoalkeeperPenaltyFacedMode() {
  const { teamId, lineup } = useLineupStore.getState();
  const { dataCollectionId } = useFixtureStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    if (!state.coords) return state;

    const goalkeeperPlayer = lineup.find(
      ({ position }) => position === Position.Gk,
    )?.player;

    const defaultGoalkeeperPenaltyFacedAction =
      createAction<GoalkeeperPenaltyFacedAction>(
        ACTION_TYPE_ID.GoalkeeperPenaltyFaced,
        METADATA_KEY.goalkeeperPenaltyFaced,
        {
          position: state.coords,
          stance: Stance.DivedLeft,
          outcome: GoalkeeperPenaltyFacedOutcome.Scored,
        },
      );

    if (!defaultGoalkeeperPenaltyFacedAction) return state;

    const action: GoalkeeperPenaltyFacedAction = {
      ...defaultGoalkeeperPenaltyFacedAction,
      player: goalkeeperPlayer || UNKNOWN_PLAYER,
    };

    return {
      mode: MODE.GOALKEEPER_PENALTY_FACED,
      action,
      dialogAction: action,
    };
  });
}

function _setGoalkeeperSaveMode() {
  const { teamId, lineup } = useLineupStore.getState();
  const { dataCollectionId } = useFixtureStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    if (!state.coords) return state;

    const goalkeeperPlayer = lineup.find(
      ({ position }) => position === Position.Gk,
    )?.player;

    const defaultGoalkeeperSaveAction = createAction<GoalkeeperSaveAction>(
      ACTION_TYPE_ID.GoalkeeperSave,
      METADATA_KEY.goalkeeperSave,
      {
        attemptType: GoalkeeperSaveAttemptType.TemptSave,
        bodyPart: null,
        type: null,
        extras: null,
        position: state.coords,
      },
    );

    if (!defaultGoalkeeperSaveAction) return state;

    const action: GoalkeeperSaveAction = {
      ...defaultGoalkeeperSaveAction,
      player: goalkeeperPlayer || UNKNOWN_PLAYER,
      warnings: [ACTION_WARNING.FLAG_TO_QA],
    };

    return { mode: MODE.GOALKEEPER_SAVE, action, dialogAction: action };
  });
}

function _setAdditionalTimeMode() {
  const { teamId } = useLineupStore.getState();
  const { dataCollectionId } = useFixtureStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    const action = createAction<AdditionalTimeAction>(
      ACTION_TYPE_ID.AdditionalTime,
      METADATA_KEY.additionalTime,
      { extraTime: 0 },
    );

    if (!action) return state;

    return { mode: MODE.ADDITIONAL_TIME, action, dialogAction: action };
  });
}

function _setErrorMode() {
  const { teamId } = useLineupStore.getState();
  const { dataCollectionId } = useFixtureStore.getState();
  const { userId } = useUserStore.getState();

  if (!dataCollectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    if (!state.player || !state.playerNumber || !state.coords) return state;

    const action = createAction<ErrorAction>(
      ACTION_TYPE_ID.Error,
      METADATA_KEY.error,
      { reason: ErrorReason.LeadingToShot },
    );

    if (!action) return state;

    return { mode: MODE.ERROR, action, dialogAction: action };
  });
}

export function setMode(mode: ActionStore['mode']) {
  switch (mode) {
    case MODE.PASS:
    case MODE.CROSS:
    case MODE.LAUNCH:
    case MODE.GOALKEEPER_THROW:
    case MODE.GOALKEEPER_KICK_FROM_HANDS:
      return _setPassMode(mode);
    case MODE.SHOT:
      return _setShotMode();
    case MODE.GOAL:
      return _setGoalMode();
    case MODE.THROW_IN:
      return _setThrowInMode();
    case MODE.YELLOW_CARD:
      return _setYellowCardMode();
    case MODE.RED_CARD:
      return _setRedCardMode();
    case MODE.VAR_UNDERWAY:
      return _setVARUnderwayMode();
    case MODE.VAR_RESULT:
      return _setVARResultMode();
    case MODE.SUBSTITUTION:
      return _setSubstitutionMode();
    case MODE.FREE_KICK:
      return _setFreeKickMode();
    case MODE.PLAY_STOPPED:
      return _setPlayStoppedMode();
    case MODE.GOALKEEPER_PENALTY_FACED:
      return _setGoalkeeperPenaltyFacedMode();
    case MODE.GOALKEEPER_SAVE:
      return _setGoalkeeperSaveMode();
    case MODE.ADDITIONAL_TIME:
      return _setAdditionalTimeMode();
    case MODE.ERROR:
      return _setErrorMode();
    default:
      return useActionStore.setState({ mode, action: null }, false, 'setMode');
  }
}

export function setPlayerNumber(playerNumber: ActionStore['playerNumber']) {
  useActionStore.setState(
    (state) => {
      if (!state.coords) return { playerNumber: null };
      return { playerNumber };
    },
    false,
    'setPlayerNumber',
  );
}

export function setAction(action: Action | null) {
  return useActionStore.setState({ action });
}

export function setActionViewAction(action: Action | null) {
  return useActionStore.setState({ actionViewAction: action, action });
}

export function setDialogAction(dialogAction: Action | null) {
  return useActionStore.setState({
    dialogAction,
    action: dialogAction,
  });
}

export function setContextAction(contextAction: Action | null) {
  setContextNestedMenu(null);
  return useActionStore.setState({ contextAction });
}

export function setActionPartialMetadata<
  T extends Action,
  Metadata = ActionMeta<T>,
>(metadata: Partial<Metadata>) {
  return useActionStore.setState((state) => {
    const { action } = state;
    if (!action) return state;
    return {
      action: modifyActionMetadata(action, metadata),
    };
  });
}

export function setPlayer(player: ActionStore['player']) {
  return useActionStore.setState(
    (state) => {
      if (!state.coords) return { player: null };
      if (!player) return { player };
      return { player, playerNumber: player.shirtNumber };
    },
    false,
    'setPlayer',
  );
}

function _commitPlayerQuickInput(shirtNumber: string, player: Player | null) {
  setPlayerNumber(shirtNumber);
  setPlayer(player);
}

export const buildPlayerNumber = buildPlayerFromMap(
  getPlayerInputMap,
  _commitPlayerQuickInput,
  PLAYER_INPUT_AUTO_COMMIT_TIME,
);

export function resetToPitchMode() {
  return useActionStore.setState(
    {
      targetCoords: null,
      dotTargetCoords: null,
      mode: MODE.PITCH,
      action: null,
    },
    false,
    'resetToPitchMode',
  );
}
