import { useEffect, useState, type RefObject } from 'react';
import { CoordinatesDto } from '@contract';

const OUTER_CONTAINER_ID = '#pitch';
const INNER_CONTAINER_ID = '#pitch-in';

export const calculateCoordsClockwise = (coords: CoordinatesDto) => {
  return {
    x: coords.y,
    y: (10000 - coords.x * 100) / 100,
  };
};

export const calculateCoordsCounterClockwise = (coords: CoordinatesDto) => {
  return {
    x: (10000 - coords.y * 100) / 100,
    y: coords.x,
  };
};

export const usePitchElements = (
  $pitchContainer: RefObject<HTMLDivElement>,
) => {
  const [innerClientRect, setInnerClientRect] = useState<DOMRect | null>(null);
  const [outerClientRect, setOuterClientRect] = useState<DOMRect | null>(null);

  useEffect(() => {
    const element = $pitchContainer?.current;

    if (!element) return;
    const $field = element.querySelector(INNER_CONTAINER_ID)
      ? element.querySelector(INNER_CONTAINER_ID)
      : element;
    const $pitch = element.querySelector(OUTER_CONTAINER_ID)
      ? element.querySelector(OUTER_CONTAINER_ID)
      : element;

    const observer = new ResizeObserver(() => {
      setInnerClientRect($field ? $field.getBoundingClientRect() : null);
      setOuterClientRect($pitch ? $pitch.getBoundingClientRect() : null);
    });

    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [$pitchContainer]);

  return { innerClientRect, outerClientRect };
};
