export const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

export const DEFAULT_REQUEST_OPTIONS: RequestInit = {
  mode: 'cors',
};

export const API_METHODS = {
  GET: 'GET',
  POST: 'POST',
};
