import {
  Autocomplete,
  AutocompleteChangeReason,
  TextField,
} from '@mui/material';
import { FC } from 'react';
import { ACTION_TYPE_LABEL } from '@/components/AddAction/constants';
import { ActionTypeOption } from '@/types/action/actionType';

interface ActionAutocompleteProps {
  onChange: (actionType: ActionTypeOption | null) => void;
  options: ActionTypeOption[];
}

export const ActionTypeAutocomplete: FC<ActionAutocompleteProps> = ({
  onChange,
  options,
}) => {
  const onFieldChange = (
    actionType: ActionTypeOption | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (!['clear', 'selectOption'].includes(reason)) return;

    onChange(actionType);
  };

  return (
    <Autocomplete
      sx={{ pt: 1 }}
      autoHighlight
      defaultValue={null}
      options={options}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField {...params} label={ACTION_TYPE_LABEL} />
      )}
      onChange={(e, actionType, reason) => onFieldChange(actionType, reason)}
    />
  );
};
