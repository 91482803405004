import { FC } from 'react';
import { Stack } from '@mui/material';
import { GoalkeeperPenaltyFacedAction } from '@/types/action/action';
import { RadioButtons } from '@/components/Form/RadioButtons';
import { OUTCOME_LABEL, STANCE_LABEL } from '@/constants';
import { GoalkeeperPenaltyFacedOutcome, Stance } from '@contract';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { getActionMetadata } from '@/utils/actions';
import {
  GOALKEEPER_PENALTY_FACED_OUTCOME,
  GOALKEEPER_PENALTY_FACED_STANCE,
} from '@/components/Dialogs/GoalkeeperPenaltyFacedDialogContent/constants';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { ActionPlayerField } from '@/components/Dialogs/Common/ActionPlayerField';
import { FlagField } from '@/components/Dialogs/Common/FlagField';
import { ParentAction } from '@/components/ParentAction/ParentAction';
import { Controls } from '../Common/Controls';

interface GoalkeeperPenaltyFacedDialogContentProps {
  action: GoalkeeperPenaltyFacedAction;
}

const onOutcomeChange = (outcome: GoalkeeperPenaltyFacedOutcome) => {
  setActionPartialMetadata<GoalkeeperPenaltyFacedAction>({ outcome });
};

const onStanceChange = (stance: Stance) => {
  setActionPartialMetadata<GoalkeeperPenaltyFacedAction>({ stance });
};

export const GoalkeeperPenaltyFacedDialogContent: FC<
  GoalkeeperPenaltyFacedDialogContentProps
> = ({ action }) => {
  const metadata = getActionMetadata(action);

  return (
    <Stack gap={2}>
      <RadioButtons
        name={OUTCOME_LABEL}
        onChange={onOutcomeChange}
        value={metadata.outcome}
        controlLabel={OUTCOME_LABEL}
        fields={GOALKEEPER_PENALTY_FACED_OUTCOME}
      />
      <RadioButtons
        name={STANCE_LABEL}
        onChange={onStanceChange}
        value={metadata.stance}
        controlLabel={STANCE_LABEL}
        fields={GOALKEEPER_PENALTY_FACED_STANCE}
      />
      <ActionPlayerField action={action} />
      <PeriodClockField action={action} />
      <ParentAction action={action} />
      <FlagField action={action} />
      <Controls action={action} shouldCloseAfterSubmit={false} />
    </Stack>
  );
};
