import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { isDebugEnabled, isTraceStoreEnabled } from '@/utils/debug';
import {
  ACTION_STATE,
  ActionState,
  AWARDED_ACTIONS,
  DEFAULT_GAME_STATE,
  PRE_GAME_PERIOD,
  PRE_GAME_PERIOD_CONFIG,
  TAKEN_ACTIONS,
} from '@/stores/constants';
import { Action } from '@/types/action/action';
import { sortByActionTimes, sortByUpdatedAt } from '@/utils/sortByActionTimes';
import {
  ACTION_TYPE_ID,
  ActionContext,
  UserRole,
  type DataCollectionDto,
  type FixtureTeamDto,
  type PeriodDto,
  type DataCollectionStateDto,
  type PeriodStateDto,
  type GameStateDto,
  type PeriodDetailsDto,
} from '@contract';
import { TableExtrasCellValue } from '@/components/TableExtrasCell/TableExtrasCell';
import type { Player } from '@/service/lineups';
import { getActionMetadata } from '@/utils/actions';
import { CoordsCalc } from '@/utils/coordsCalc';
import { DIRECTION_OF_PLAY } from '@/components/Periods/constants';
import { TEAM_SIDE } from '@/components/FixturesCollectionsTable/constants';
import {
  PARENT_ACTION_CONDITIONAL,
  PARENT_ACTION_NONE,
} from '@/components/ParentAction/constants';
import { IS_HOME_TEAM_VIEW } from '@/router/fixtureViewRoutes';
import { getRoleView } from '@/router/utils';
import {
  deriveDirectionOfPlay,
  findClosest,
  isEndPossessionAction,
  isExactAction,
  isInPossessionAction,
  makePeriodStatesTimestamps,
  selectTeam,
} from './utils';
import {
  subscribeActions,
  subscribeState,
  unsubscribeActions,
  unsubscribeState,
} from './SocketStore/SocketStore';
import { mapToTableAction } from './tableActionMapper';
import {
  setCoords,
  setRecentlyAwardedAction,
  restoreDefault as actionStoreReset,
} from './ActionStore/ActionStore';
import {
  backupAction,
  getBackedUpActionsMap,
  removeBackupAction,
  subscribeUnsyncedActions,
} from './UnsyncedActionsStore';
import {
  useLineupStore,
  reset as lineupStoreReset,
  setLineup,
  resetPlayers as resetLineupPlayers,
} from './LineupStore/LineupStore';
import { reset as filtersStoreReset } from './FiltersStore/FiltersStore';
import { reset as UIStoreReset } from './UIStore';
import {
  getCurrentClock,
  setClockTicks,
  reset as clockStoreReset,
} from './ClockStore';
import { composeLineup } from './LineupStore/utils';

export type TableAction = {
  time: string;
  clockTimeTicks: number;
  type: string;
  typeId: ACTION_TYPE_ID;
  extras?: TableExtrasCellValue;
  player?: Player;
  createdAt: number;
  updatedAt: number;
  isSuccessful: boolean | null;
  actionId: string;
  state: ActionState;
  isRemoved: boolean;
  messageId?: string;
  period: Action['period'];
  warnings: Action['warnings'];
  team?: string;
};

type PossessionPhase = {
  id: number;
  shouldBump: boolean;
};

export type FixtureStore = {
  fixtureId?: string;
  dataCollectionId?: string;
  roles?: UserRole[];
  homeTeam?: FixtureTeamDto;
  awayTeam?: FixtureTeamDto;
  isHomeTeamCollector: boolean | null;
  directionOfPlay: DIRECTION_OF_PLAY;
  /**
   * Stores original actions received from Socket.
   * This map is an aggregate of all action entries by `key=actionId`.
   * The value is an array of Action, which NEEDS TO BE ALWAYS SORTED by `updatedAt` property.
   * First element in the array is the latest action (highest `updatedAt`).
   */
  actions: Map<string, Action[]>;
  /**
   * Stores mapped actions for table presentation.
   * Simplified, flat representation of an action.
   * This collection is originally created from `actions` map,
   * grabbing latest entry for each `actionId`.
   * Afterwards, this collection is maintained during app lifetime and
   * NEEDS TO BE ALWAYS SORTED by `clockTimeTicks` and `createdAt` property.
   */
  tableActions: TableAction[];

  teamPossessionPhase: PossessionPhase;
  /**
   * Available periods for the game. Comes from REST API (SDM).
   */
  periodsConfig: PeriodDetailsDto[];
  /**
   * Currently selected period configuration.
   * It's used for changing periods during the game.
   * Changing currentPeriodConfig will find corresponding
   * period state, get its game states and find closest game state
   * based on current clock time.
   */
  currentPeriodConfig: PeriodDetailsDto;

  /**
   * Contains all period states with particular
   * game state snapshots per clock time when they happened.
   * This value should be only set when socket pushes state
   */
  collectionState?: DataCollectionStateDto;
  /**
   * The state of current period.
   * This value is computed, does not have a setter.
   * It is controlled by `collectionState` change subscription.
   * Whenever collection changes the `currentPeriod` value
   * will correspond to `currentPeriodConfig`.
   */
  currentPeriod: PeriodStateDto;
  /**
   * Current game state.
   * This is a computed value, does not have a setter.
   * It is controlled by `currentPeriod` and `clockTime` values.
   * Whenever `currentPeriod` changes a `gameState` at current `clockTime`
   * is assigned here.
   */
  gameState: GameStateDto;

  /**
   * This is a computed value, does not have a setter.
   * Helper array. Keys of period game states.
   * It is used to find correct clock time within
   * game states object.
   */
  gameStateTimestamps: number[];
};

const DEFAULT_STATE: FixtureStore = {
  fixtureId: undefined,
  dataCollectionId: undefined,
  roles: undefined,
  homeTeam: undefined,
  awayTeam: undefined,
  isHomeTeamCollector: null,
  directionOfPlay: DIRECTION_OF_PLAY.LEFT_TO_RIGHT,

  actions: new Map(),
  tableActions: [],

  teamPossessionPhase: {
    id: 0,
    shouldBump: false,
  },
  periodsConfig: [PRE_GAME_PERIOD_CONFIG],
  currentPeriodConfig: PRE_GAME_PERIOD_CONFIG,
  collectionState: undefined,
  currentPeriod: PRE_GAME_PERIOD,
  gameState: DEFAULT_GAME_STATE,
  gameStateTimestamps: [PRE_GAME_PERIOD.startTime],
};

export const useFixtureStore = create<FixtureStore>()(
  devtools(() => ({ ...DEFAULT_STATE }), {
    enabled: isDebugEnabled(),
    trace: isTraceStoreEnabled,
    name: 'FixtureStore',
  }),
);

export function reset() {
  return useFixtureStore.setState({ ...DEFAULT_STATE });
}

export function setDataCollectionId(dataCollectionId?: string) {
  if (!dataCollectionId) {
    return setCurrentCollection(null);
  }
  useFixtureStore.setState({ dataCollectionId }, false, 'setDataCollectionId');
}

export function setCurrentCollection(collection: DataCollectionDto | null) {
  const prevState = useFixtureStore.getState();
  const oldTeamId = useLineupStore.getState().teamId;
  const roleView = getRoleView();
  const oldCollectionId = prevState.dataCollectionId;

  if (!collection || !collection.id) {
    if (oldCollectionId) {
      unsubscribeActions({
        dataCollectionId: oldCollectionId,
        teamId: oldTeamId,
      });
      unsubscribeState({ dataCollectionId: oldCollectionId });
    }

    reset();
    clockStoreReset();
    lineupStoreReset();
    actionStoreReset();
    filtersStoreReset();
    UIStoreReset();
    subscribeUnsyncedActions(undefined);
    return;
  }

  const isHomeTeamCollector = roleView ? IS_HOME_TEAM_VIEW[roleView] : null;

  const homeTeam = collection.fixture.teams.find(
    (team) => team.side === TEAM_SIDE.HOME,
  );
  const awayTeam = collection.fixture.teams.find(
    (team) => team.side === TEAM_SIDE.AWAY,
  );
  const team = selectTeam({ isHomeTeamCollector, homeTeam, awayTeam });

  useFixtureStore.setState({
    fixtureId: collection.fixture.id,
    dataCollectionId: collection.id,
    roles: collection.roles,
    isHomeTeamCollector,
    homeTeam,
    awayTeam,
    periodsConfig: [PRE_GAME_PERIOD_CONFIG, ...collection.periodDetails],
  });
  if (team) {
    useLineupStore.setState({ teamId: team.id, teamName: team.name });
  }
  const didCollectionIdChange = prevState.dataCollectionId !== collection.id;
  const didTeamIdChange = team?.id !== oldTeamId;
  const didFixtureIdChange = collection.fixture.id !== prevState.fixtureId;

  if (didCollectionIdChange || didTeamIdChange || didFixtureIdChange) {
    subscribeActions({
      dataCollectionId: collection.id,
      teamId: team?.id,
      oldDataCollectionId: prevState.dataCollectionId,
      oldTeamId,
    });

    subscribeState({
      dataCollectionId: collection.id,
      oldDataCollectionId: prevState.dataCollectionId,
    });
    subscribeUnsyncedActions(collection.id);
  }
}

export function setDirectionOfPlay(
  directionOfPlay: FixtureStore['directionOfPlay'],
) {
  useFixtureStore.setState({ directionOfPlay }, false, 'setDirectionOfPlay');
}

export const directionOfPlayController = useFixtureStore.subscribe(
  (state, prevState) => {
    if (state.isHomeTeamCollector === null) return;
    if (
      state.isHomeTeamCollector === prevState.isHomeTeamCollector &&
      state.currentPeriod.isHomeTeamLeft ===
        prevState.currentPeriod.isHomeTeamLeft
    ) {
      return;
    }

    if (
      state.currentPeriod.sequence === 0 &&
      state.directionOfPlay !== DIRECTION_OF_PLAY.LEFT_TO_RIGHT
    ) {
      return setDirectionOfPlay(DIRECTION_OF_PLAY.LEFT_TO_RIGHT);
    }

    const newDirectionOfPlay = deriveDirectionOfPlay(
      state.currentPeriod.isHomeTeamLeft,
      state.isHomeTeamCollector,
    );
    setDirectionOfPlay(newDirectionOfPlay);
  },
);

export const onTableActionsReceived = useFixtureStore.subscribe(
  (state, prevState) => {
    if (
      state.tableActions.length === 0 ||
      prevState.tableActions.length !== 0 ||
      !state.collectionState
    ) {
      return;
    }
    const latestAction = state.tableActions[0];
    if (!latestAction) return;
    const currentPeriod = state.collectionState.periods.find(
      (p) => p.sequence === latestAction.period.sequence,
    );
    if (!currentPeriod) {
      return;
    }
    const currentPeriodConfig = state.periodsConfig.find(
      (cfg) => cfg.seq === latestAction.period.sequence,
    );
    if (!currentPeriodConfig) return;

    setClockTicks(latestAction.clockTimeTicks);
    useFixtureStore.setState(
      { currentPeriod, currentPeriodConfig },
      false,
      'latestTableActionSync',
    );
  },
);

export function setCollectionState(
  collectionState: FixtureStore['collectionState'],
) {
  return useFixtureStore.setState((state) => {
    if (state.collectionState === collectionState) return state;
    if (!collectionState) {
      return {
        collectionState: DEFAULT_STATE.collectionState,
        currentPeriod: DEFAULT_STATE.currentPeriod,
        currentPeriodConfig: DEFAULT_STATE.currentPeriodConfig,
        gameState: DEFAULT_STATE.gameState,
        gameStateTimestamps: DEFAULT_STATE.gameStateTimestamps,
      };
    }
    // When entered collection, rewind to latest known action
    if (!state.collectionState) {
      const latestAction = state.tableActions[0];

      const currentPeriodSeq = latestAction
        ? latestAction.period.sequence
        : PRE_GAME_PERIOD.sequence;

      const currentPeriod = collectionState.periods.find(
        (p) => p.sequence === currentPeriodSeq,
      ) ||
        collectionState.periods[0] || { ...PRE_GAME_PERIOD };

      const currentPeriodConfig = state.periodsConfig.find(
        (cfg) => cfg.seq === currentPeriodSeq,
      ) || { ...PRE_GAME_PERIOD_CONFIG };

      const currentTime = latestAction ? latestAction.clockTimeTicks : 0;
      setClockTicks(currentTime);

      return {
        collectionState,
        currentPeriod,
        currentPeriodConfig,
      };
    }
    const currentPeriod = collectionState.periods.find(
      (periodState) => periodState.sequence === state.currentPeriodConfig.seq,
    );
    if (!currentPeriod) return { collectionState };
    return {
      collectionState,
      currentPeriod,
    };
  });
}

export function switchGameState(clockTimeTicks: number) {
  useFixtureStore.setState(
    (state) => {
      const timestamp =
        findClosest(clockTimeTicks, state.gameStateTimestamps) ??
        state.gameStateTimestamps.at(-1);
      const gameState =
        timestamp !== undefined
          ? state.currentPeriod.gameStates[timestamp]
          : state.gameState;
      return { gameState };
    },
    false,
    'switchGameState',
  );
}

export function getGameStateAt(periodNumber: number, clockTimeTicks: number) {
  const collectionState = useFixtureStore.getState().collectionState;

  const currentGameState = useFixtureStore.getState().gameState;
  if (!collectionState) return currentGameState;
  const period = collectionState.periods.find(
    (period) => period.sequence === periodNumber,
  );
  if (!period) return currentGameState;
  const timestamps = makePeriodStatesTimestamps(period);
  const timestamp = findClosest(clockTimeTicks, timestamps);
  if (timestamp === undefined) return currentGameState;
  return period.gameStates[timestamp];
}

/**
 * A hook which returns array of substitutions for given player in current gameState.
 * Each substitution contains information of it's time and period.
 */

export function useGetPlayerSubstitutions(playerId: Player['id']) {
  const gameState = useFixtureStore((state) => state.gameState);

  return gameState.substitutions[playerId];
}

export const onCurrentPeriodChange = useFixtureStore.subscribe(
  (state, prevState) => {
    if (state.currentPeriod === prevState.currentPeriod) return;
    const currentPeriod = state.currentPeriod;
    const gameStateTimestamps = makePeriodStatesTimestamps(currentPeriod);
    useFixtureStore.setState(
      { gameStateTimestamps },
      false,
      'onCurrentPeriodChange',
    );
    const { clockTimeTicks } = getCurrentClock();
    switchGameState(clockTimeTicks);
  },
);

export const onGameStateChange = useFixtureStore.subscribe(
  (state, prevState) => {
    if (state.gameState === prevState.gameState) return;
    const teamId = useLineupStore.getState().teamId;
    if (!teamId || !state.gameState) {
      resetLineupPlayers();
      return;
    }

    const teamLineups = state.gameState.lineups[teamId];
    if (!teamLineups) {
      resetLineupPlayers();
      return;
    }
    setLineup(composeLineup(teamLineups));
  },
);

export function extractCurrentPeriodDto(
  currentPeriod: FixtureStore['currentPeriodConfig'],
): PeriodDto {
  return {
    sequence: currentPeriod.seq,
    label: currentPeriod.label,
  };
}

export function setCurrentPeriodConfig(
  currentPeriodConfig: FixtureStore['currentPeriodConfig'],
) {
  return useFixtureStore.setState(
    (state) => {
      const periodNumber = currentPeriodConfig.seq;
      if (!state.collectionState) return { currentPeriodConfig };
      const currentPeriod = state.collectionState.periods.find(
        (period) => period.sequence === periodNumber,
      );
      if (!currentPeriod) return { currentPeriodConfig };
      return { currentPeriodConfig, currentPeriod };
    },
    false,
    'setCurrentPeriodConfig',
  );
}

export function setTeamPossessionPhase(teamPossessionPhase: {
  id: number;
  shouldBump: boolean;
}) {
  return useFixtureStore.setState({ teamPossessionPhase });
}

export function checkUnresolvedAwardedActions() {
  const { tableActions, actions } = useFixtureStore.getState();
  const actionContextValues = Object.values(ActionContext).filter(
    (val) => typeof val === 'number',
  );

  const backupActions = getBackedUpActionsMap();
  let awardedAction;

  for (const tableAction of tableActions) {
    let action = backupActions[tableAction.actionId];

    if (!action) {
      const tableActionsByActionId = actions.get(tableAction.actionId);
      if (!tableActionsByActionId) continue;

      action = tableActionsByActionId[0];
    }

    if (TAKEN_ACTIONS.includes(action.actionTypeId)) {
      const actionMetadata = getActionMetadata(action);

      if (!('actionContext' in actionMetadata)) continue;

      if (
        !!actionMetadata.actionContext &&
        actionContextValues.includes(actionMetadata.actionContext)
      ) {
        setRecentlyAwardedAction(null);
        return;
      }
    }

    if (AWARDED_ACTIONS.includes(action.actionTypeId)) {
      awardedAction = action;
      break;
    }
  }

  if (!awardedAction) return;

  const awardedActionMetadata = getActionMetadata(awardedAction);

  if (!('position' in awardedActionMetadata)) return;

  switch (awardedAction.actionTypeId) {
    case ACTION_TYPE_ID.FreeKickAwarded:
      setCoords(awardedActionMetadata.position);
      break;
    case ACTION_TYPE_ID.ThrowInAwarded:
      setCoords(awardedActionMetadata.position);
      break;
    case ACTION_TYPE_ID.CornerAwarded:
      setCoords(CoordsCalc.assumeCornerCoords(awardedActionMetadata.position));
      break;
    case ACTION_TYPE_ID.GoalKickAwarded:
      setCoords(
        CoordsCalc.assumeGoalKickCoords(awardedActionMetadata.position),
      );
      break;
    default:
      break;
  }

  setRecentlyAwardedAction(awardedAction);
}

/**
 * Should be only called when received actions
 */
export function setActions(actions: Map<string, Action[]>) {
  return useFixtureStore.setState(
    (state) => {
      const currentActions = state.actions;
      if (!currentActions) {
        return { actions };
      }
      return { actions: new Map([...actions, ...currentActions]) };
    },
    false,
    'setActions',
  );
}
/**
 * Should be only called when received actions
 */
export function setTableActions(tableActions: TableAction[]) {
  return useFixtureStore.setState(
    (state) => {
      const notPostedActions = state.tableActions.filter(
        ({ state }) => state === ACTION_STATE.NONE,
      );
      const newTableActions = [...notPostedActions, ...tableActions];
      return { tableActions: newTableActions };
    },
    false,
    'setTableActions',
  );
}

/**
 * Stores the action.
 * This function is called before and after sync process.
 * New action is stored and backed up.
 * Confirmed action removes a backup and changes the state to success.
 */
export function storeAction(action: Action) {
  useFixtureStore.setState(
    (state) => {
      if (action.state === ACTION_STATE.NONE) {
        return { actions: state.actions };
      }

      if (action.state === ACTION_STATE.SUCCESS) {
        removeBackupAction(action);
      } else {
        backupAction(action);
      }

      const newActions = new Map(state.actions);
      const actionHistory = newActions.get(action.actionId) || [];
      const confirmedActionIdx = actionHistory.findIndex((a) =>
        isExactAction(a, action),
      );

      const needsNewEntry =
        !!actionHistory[0] && actionHistory[0].state !== ACTION_STATE.ERROR;

      if (confirmedActionIdx >= 0) {
        actionHistory[confirmedActionIdx] = action;
      } else if (needsNewEntry) {
        actionHistory.unshift(action);
      } else {
        actionHistory[0] = action;
      }

      const newHistory = actionHistory.sort(sortByUpdatedAt);
      newActions.set(action.actionId, newHistory);

      if (action.updatedAt >= newHistory[0].updatedAt) {
        updateTableAction(action);
      }
      return {
        actions: newActions,
      };
    },
    false,
    'storeAction',
  );
}

/**
 * Use this to manipulate table actions.
 * This is called whenever a new action is added (received from socket).
 * This can be called to control action state and display the updates.
 * Using this causes optimistic updates to actions table,
 * unfortunately, for now, we do not revert the state in case of failure.
 */
export function updateTableAction(
  action: Action,
  actionUpdate?: Partial<TableAction>,
) {
  if (action.isRemoved && action.state === ACTION_STATE.SUCCESS) {
    return removeTableAction(action.actionId);
  }

  return useFixtureStore.setState(
    (state) => {
      const newActions = [...state.tableActions];
      const updated = { ...mapToTableAction(action), ...actionUpdate };

      const originalIndex = state.tableActions.findIndex(
        ({ actionId }) => actionId === action.actionId,
      );
      if (originalIndex < 0) {
        const originalFirstItem = state.tableActions[0];
        newActions.unshift(updated);

        if (
          originalFirstItem &&
          (originalFirstItem.period?.sequence !== updated.period?.sequence ||
            originalFirstItem.clockTimeTicks > updated.clockTimeTicks)
        ) {
          newActions.sort(sortByActionTimes);
        }
      } else {
        newActions[originalIndex] = updated;
        if (
          updated.period?.sequence !==
            state.tableActions[originalIndex].period?.sequence ||
          updated.clockTimeTicks !==
            state.tableActions[originalIndex].clockTimeTicks
        ) {
          newActions.sort(sortByActionTimes);
        }
      }

      return {
        tableActions: newActions,
      };
    },
    false,
    'updateTableAction',
  );
}

/**
 * Removes the action from the table.
 * Use this to remove actions that are not synced.
 * For example, canceling a new pass which was not yet posted.
 *
 * Removing from actions table is not the same as
 * marking the action as `isRemoved` for the system.
 */
export function removeTableAction(actionId: string) {
  return useFixtureStore.setState(
    (state) => {
      const index = state.tableActions.findIndex(
        (tableAction) => tableAction.actionId === actionId,
      );
      if (index < 0) {
        return state;
      }
      const newTableActions = [...state.tableActions];
      newTableActions.splice(index, 1);
      return { tableActions: newTableActions };
    },
    false,
    'removeTableAction',
  );
}

type ParentActionCandidate = Pick<
  Action,
  'actionId' | 'period' | 'clockTimeTicks' | 'createdAt' | 'actionTypeId'
> & { typeId: ACTION_TYPE_ID };

export function canBeParentAction(
  action: Omit<ParentActionCandidate, 'actionTypeId'>,
  childAction: Omit<ParentActionCandidate, 'typeId'>,
) {
  if (action.actionId === childAction.actionId) return false;
  if (action.period?.sequence !== childAction.period?.sequence) return false;

  if (PARENT_ACTION_NONE.includes(action.typeId)) return false;
  if (action.typeId in PARENT_ACTION_CONDITIONAL) {
    return (
      PARENT_ACTION_CONDITIONAL[action.typeId] === childAction.actionTypeId
    );
  }

  if (action.clockTimeTicks === childAction.clockTimeTicks) {
    return action.createdAt < childAction.createdAt;
  }
  return action.clockTimeTicks < childAction.clockTimeTicks;
}

export function findPotentialParentActionId(
  action: Omit<ParentActionCandidate, 'typeId'>,
) {
  const { tableActions } = useFixtureStore.getState();
  const previousAction = tableActions.find((tableAction) =>
    canBeParentAction(tableAction, action),
  );

  if (!previousAction) return null;
  if (previousAction.isSuccessful === false) return null;
  return previousAction.actionId;
}

export function getInitialPossessionPhaseId(actionsMap: Map<string, Action[]>) {
  const actions = [...actionsMap.values()]
    .map(([latestAction]) => latestAction)
    .sort(sortByActionTimes);

  if (actions.length === 0) {
    return DEFAULT_STATE.teamPossessionPhase;
  }

  const lastInPossessionActionIdx = actions.findIndex(isInPossessionAction);
  const lastEndPossessionActionIdx = actions.findIndex(isEndPossessionAction);

  if (lastEndPossessionActionIdx === -1 && lastInPossessionActionIdx === -1) {
    return DEFAULT_STATE.teamPossessionPhase;
  }

  if (
    lastInPossessionActionIdx > lastEndPossessionActionIdx &&
    lastEndPossessionActionIdx !== -1
  ) {
    return {
      id: actions[lastEndPossessionActionIdx].teamPossessionPhaseId || 0,
      shouldBump: true,
    };
  }

  return {
    id: actions[lastInPossessionActionIdx]?.teamPossessionPhaseId || 0,
    shouldBump: false,
  };
}

export function getTeamPossessionPhaseId(action: Action): number | null {
  const { id, shouldBump } = useFixtureStore.getState().teamPossessionPhase;

  if (isInPossessionAction(action) && shouldBump) {
    const bumpedId = id + 1;
    useFixtureStore.setState({
      teamPossessionPhase: { id: bumpedId, shouldBump: false },
    });

    return bumpedId;
  }

  if (isEndPossessionAction(action)) {
    useFixtureStore.setState({ teamPossessionPhase: { id, shouldBump: true } });
  }

  return id;
}
