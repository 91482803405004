import { Chip, ChipProps } from '@mui/material';
import { FC } from 'react';
import { useActionStore } from '@/stores/ActionStore/ActionStore';

export const SelectedPlayersLabels: FC<ChipProps> = (props) => {
  const player = useActionStore((state) => state.player);
  if (!player) return;
  return (
    <Chip
      variant='filled'
      label={`Selected player: ${player.shirtNumber}, ${player.fullName || player.lastName}`}
      {...props}
    />
  );
};
