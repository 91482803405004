import { PlayStoppedReason } from '@contract';

export const PLAY_STOPPED_REASON: Record<PlayStoppedReason, string> = {
  [PlayStoppedReason.Injury]: 'Injury',
  [PlayStoppedReason.CrowdTrouble]: 'Crowd Trouble',
  [PlayStoppedReason.PitchIntruder]: 'Pitch Intruder',
  [PlayStoppedReason.FloodlightIssue]: 'Floodlight Issue',
  [PlayStoppedReason.DrinksBreak]: 'Drinks break',
  [PlayStoppedReason.TechnicalIssueReferee]: 'Technical Issue (Referee)',
  [PlayStoppedReason.Other]: 'Other',
};

export const PLAY_STOPPED_REASON_OPTIONS = Object.keys(PLAY_STOPPED_REASON).map(
  (reason) => {
    const key = Number(reason) as PlayStoppedReason;
    return {
      value: key,
      label: PLAY_STOPPED_REASON[key],
    };
  },
);
