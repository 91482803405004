import { Stack } from '@mui/material';
import { FC } from 'react';
import type { Player } from '@/service/lineups';
import type { Position } from '@contract';
import { POSITION_NAME } from '@/stores/LineupStore/constants';
import { useActionStore } from '@/stores/ActionStore/ActionStore';
import { PlayerCurrentCard } from '@/components/PlayerCard/PlayerCard';
import { LineupPlayerLabel, LineupPlayerShirt } from '../Player';
import { getTransition } from '../utils';

type PitchItemProps = {
  position: Position;
  player: Player;
};

export const PitchItem: FC<PitchItemProps> = ({ position, player }) => {
  const positionName = POSITION_NAME[position];
  const playerNumber = useActionStore((state) => state.playerNumber);
  const selectedPlayer = useActionStore((state) => state.player);

  const getClassName = () => {
    if (
      selectedPlayer &&
      player &&
      player.shirtNumber === selectedPlayer.shirtNumber
    ) {
      return 'active';
    }

    if (
      !selectedPlayer &&
      player &&
      playerNumber &&
      player.shirtNumber.startsWith(String(playerNumber))
    ) {
      return 'active-partial';
    }
  };

  return (
    <Stack
      style={{ gridArea: positionName }}
      sx={{
        color: 'white',
        position: 'relative',
        justifySelf: 'stretch',
        alignSelf: 'center',
        alignItems: 'center',
      }}
      data-position-id={position}
      className={getClassName()}
    >
      <LineupPlayerShirt
        sx={{
          width: '2.5em',
          fontSize: '1.1em',
          fontWeight: '500',
          transition: getTransition,
        }}
        cardComponent={<PlayerCurrentCard player={player} />}
      >
        {player.shirtNumber}
      </LineupPlayerShirt>
      <LineupPlayerLabel sx={{ transition: getTransition }}>
        {player.fullName}
      </LineupPlayerLabel>
    </Stack>
  );
};
