import {
  FormControl,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';
import { FC } from 'react';
import { OUTCOME_LABEL } from '@/constants';
import type { Action } from '@/types/action/action';
import {
  OUTCOME_TYPE_VALUE_MAP,
  OUTCOME_VALUE,
  type OutcomeOption,
  type OutcomeType,
} from './constants';
import { OutcomeIcon } from './OutcomeIcon';

type EventType = Parameters<NonNullable<ToggleButtonGroupProps['onChange']>>[0];

type OutcomeToggleButtonGroup = ToggleButtonGroupProps & {
  value: Action['isSuccessful'];
  onChange: (e: EventType, value: Action['isSuccessful']) => void;
  options: OutcomeOption[];
};

export const OutcomeToggleButtonGroup: FC<OutcomeToggleButtonGroup> = ({
  value,
  onChange,
  options,
  ...props
}) => {
  const type = OUTCOME_TYPE_VALUE_MAP.get(value);

  const _onChange: ToggleButtonGroupProps['onChange'] = (
    e,
    typeVal: OutcomeType,
  ) => {
    if (!typeVal) return;
    const value = OUTCOME_VALUE[typeVal];
    onChange(e, value);
  };

  return (
    <FormControl>
      <FormLabel id='action-outcome'>{OUTCOME_LABEL}</FormLabel>
      <ToggleButtonGroup
        aria-labelledby='action-outcome'
        {...props}
        exclusive
        value={type}
        onChange={_onChange}
      >
        {options.map(({ type, label, value }) => (
          <ToggleButton sx={{ gap: 1 }} key={type} value={type}>
            <OutcomeIcon outcome={value} />
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </FormControl>
  );
};
