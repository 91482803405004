import { FC, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  TextField,
  Typography,
} from '@mui/material';
import { Player } from '@/service/lineups';
import { makeTablePlayerName } from '@/stores/utils';
import {
  getPlayersList,
  type PlayersListVariant,
} from '@/stores/LineupStore/utils';
import {
  UNKNOWN_PLAYER_LABEL,
  UNKNOWN_PLAYER_ID,
  UNKNOWN_TEAM_LABEL,
} from '@/stores/LineupStore/constants';
import { useTeamLineupsAt } from '@/stores/hooks';

export const MISSING_OPTIONS_LABEL = 'Could not find players';

export interface PlayerAutocompleteProps {
  label: string;
  player: Player | null;
  periodNumber: number;
  clockTimeTicks: number;
  teamId?: string;
  onChange: (player: Player | null) => void;
  disableClearable?: boolean;
  disabled?: boolean;
  playersListVariant?: PlayersListVariant;
}

const getPlayerOptionLabel = (option: Player) => {
  if (option.id === UNKNOWN_PLAYER_ID) {
    return UNKNOWN_PLAYER_LABEL;
  }
  return makeTablePlayerName(option);
};

export const PlayerAutocomplete: FC<PlayerAutocompleteProps> = ({
  player,
  periodNumber,
  clockTimeTicks,
  teamId,
  label,
  onChange,
  disableClearable = true,
  disabled = false,
  playersListVariant,
}) => {
  const initialPlayer = useRef(player);
  const _teamId = teamId ?? '';
  const lineups = useTeamLineupsAt(periodNumber, clockTimeTicks, _teamId);
  const [options, setOptions] = useState<Player[]>(
    getPlayersList(lineups, playersListVariant),
  );

  useEffect(() => {
    setOptions(getPlayersList(lineups, playersListVariant));
  }, [playersListVariant, lineups]);

  const onFieldChange = (
    player: Player | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (!['clear', 'selectOption'].includes(reason)) return;

    onChange(player);
  };

  if (!teamId) {
    return <Typography color={'error'}>{UNKNOWN_TEAM_LABEL}</Typography>;
  }
  if (!options.length) {
    return <Typography color={'error'}>{MISSING_OPTIONS_LABEL}</Typography>;
  }

  return (
    <Autocomplete
      sx={{ pt: 1 }}
      disableClearable={disableClearable}
      autoHighlight
      defaultValue={initialPlayer.current}
      options={options}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => value && option.id === value.id}
      getOptionLabel={getPlayerOptionLabel}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(e, player, reason) => onFieldChange(player, reason)}
    />
  );
};
