import { FC } from 'react';
import { MenuList } from '@mui/material';
import type { Action } from '@/types/action/action';
import { ACTION_TYPE_ID } from '@contract';
import {
  ACTION_TABLE_VARIANT,
  ActionTableVariant,
} from '@/components/ActionContextMenu/ActionContextMenu';
import { QAHistoryContextMenuItems } from '@/components/ActionContextMenu/QAHistoryContextMenuItems';
import { EventsHistoryContextMenuItems } from './EventsHistoryContextMenuItems';
import { PitchMenuContextItems } from './PitchContextMenuItems';
import { InterceptionContextMenu } from './InterceptionContextMenu';
import { ClearanceContextMenu } from './ClearanceContextMenu';
import { ToggleAssist } from './MenuItems/ToggleAssist';
import { ShotContextMenu } from './ShotContextMenu';
import { PassContextMenu } from './PassContextMenu';
import { StartPeriodContextMenu } from './StartPeriodContextMenu';

export type PropsWithAction = {
  action: Action;
  tableName?: ActionTableVariant;
};

const ContextMenuItems: FC<PropsWithAction> = ({ action, tableName }) => {
  switch (tableName) {
    case ACTION_TABLE_VARIANT.PITCH_VIEW:
      return <PitchMenuContextItems action={action} />;
    case ACTION_TABLE_VARIANT.HISTORY_VIEW:
      return <EventsHistoryContextMenuItems action={action} />;
    case ACTION_TABLE_VARIANT.QA_VIEW:
      return <QAHistoryContextMenuItems action={action} />;
    default:
      return;
  }
};

const ActionSpecificMenuItems: FC<PropsWithAction> = ({ action }) => {
  switch (action.actionTypeId) {
    case ACTION_TYPE_ID.Pass:
    case ACTION_TYPE_ID.Cross:
    case ACTION_TYPE_ID.Launch:
    case ACTION_TYPE_ID.GoalkeeperThrow:
      return <PassContextMenu action={action} />;
    case ACTION_TYPE_ID.Shot:
      return <ShotContextMenu action={action} />;
    case ACTION_TYPE_ID.Tackle:
    case ACTION_TYPE_ID.BadTouch:
      return <ToggleAssist action={action} />;
    case ACTION_TYPE_ID.Clearance:
      return <ClearanceContextMenu action={action} />;
    case ACTION_TYPE_ID.Interception:
      return <InterceptionContextMenu action={action} />;
    case ACTION_TYPE_ID.StartPeriod:
      return <StartPeriodContextMenu action={action} />;
    default:
      return;
  }
};

export const ActionContextMenuContent: FC<PropsWithAction> = (props) => {
  return (
    <MenuList variant='menu'>
      <ActionSpecificMenuItems {...props} />
      <ContextMenuItems {...props} />
    </MenuList>
  );
};
