import { FC } from 'react';
import { type InterceptionAction } from '@/types/action/action';
import { ToggleHeaded } from './MenuItems/ToggleHeaded';

interface InterceptionContextMenuProps {
  action: InterceptionAction;
}

export const InterceptionContextMenu: FC<InterceptionContextMenuProps> = ({
  action,
}) => {
  return (
    <>
      <ToggleHeaded action={action} />
    </>
  );
};
