import { useEffect, useState } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteChangeReason,
  SxProps,
  TextField,
} from '@mui/material';

type Option<T> = {
  value: T;
  label: string;
};

interface AutocompleteProps<T> {
  options: Option<T>[];
  label: string;
  value: T | null;
  onChange: (val: T) => void;
  disableClearable?: boolean;
  disabled?: boolean;
  isOptionEqualToValue?: (option: Option<T>, value: Option<T>) => boolean;
  sx?: SxProps;
}

export function Autocomplete<T>({
  options,
  value,
  onChange,
  label,
  disableClearable = true,
  disabled = false,
  isOptionEqualToValue,
  sx,
}: AutocompleteProps<T>) {
  const [selected, setSelected] = useState<Option<T>>();

  useEffect(() => {
    const option = options.find((option) => option.value === value);
    setSelected(option);
  }, [value, options]);

  const onFieldChange = (
    data: Option<T> | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (!['clear', 'selectOption'].includes(reason)) return;

    if (!data) return null;

    onChange(data.value);
  };

  return (
    <MuiAutocomplete
      sx={{ ...sx, pt: 1 }}
      isOptionEqualToValue={isOptionEqualToValue}
      disableClearable={disableClearable}
      autoHighlight
      value={selected || null}
      options={options}
      disabled={disabled}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(_, data, reason) => onFieldChange(data, reason)}
    />
  );
}
