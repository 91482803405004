import { FC, useCallback, useMemo, useRef, useState } from 'react';
import {
  Checkbox,
  type CheckboxProps,
  Chip,
  FormControlLabel,
  IconButton,
  Popover,
  Stack,
} from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import type { Action } from '@/types/action/action';
import {
  canBeParentAction,
  findPotentialParentActionId,
  type TableAction,
  useFixtureStore,
} from '@/stores/FixtureStore';
import { getLatestActionVersion } from '@/stores/utils';
import { ActionChip } from '@/components/ActionChip/ActionChip';
import { ActionPicker } from '@/components/ActionPicker/ActionPicker';
import { setAction } from '@/stores/ActionStore/ActionStore';
import { NO_PARENT_ACTION_LABEL, PARENT_ACTION_LABEL } from './constants';

type ParentActionProps = {
  action: Action;
  onChange?: (parentActionId: string | null) => void;
};

export const ParentAction: FC<ParentActionProps> = ({ action, onChange }) => {
  const $popperAnchor = useRef(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [parentAction, _setParentAction] = useState(() => {
    if (!action.parentActionId) {
      const potentialParentId = findPotentialParentActionId(action);
      if (!potentialParentId) return null;
      return getLatestActionVersion(potentialParentId);
    }
    return getLatestActionVersion(action.parentActionId);
  });
  const [hasParentActionId, setHasParentActionId] = useState(
    !!action.parentActionId && !!parentAction,
  );

  const tableActions = useFixtureStore((state) => state.tableActions);

  const childAction = useMemo(
    () => ({
      actionId: action.actionId,
      period: action.period,
      clockTimeTicks: action.clockTimeTicks,
      createdAt: action.createdAt,
      actionTypeId: action.actionTypeId,
    }),
    [
      action.actionId,
      action.period,
      action.clockTimeTicks,
      action.createdAt,
      action.actionTypeId,
    ],
  );

  const pickerActions: TableAction[] = useMemo(
    () =>
      tableActions.filter((tableAction) =>
        canBeParentAction(tableAction, childAction),
      ),
    [childAction, tableActions],
  );

  const _onChange = useCallback(
    (parentActionId: string | null) => {
      const parentAction = parentActionId
        ? getLatestActionVersion(parentActionId)
        : null;
      _setParentAction(parentAction);
      setAction({
        ...action,
        parentActionId,
      });
      if (onChange) {
        onChange(parentActionId);
      }
      setIsPickerOpen(false);
    },
    [action, onChange],
  );

  const onCheckboxChange: CheckboxProps['onChange'] = (_, checked) => {
    const parentActionId = checked ? parentAction?.actionId || null : null;
    setAction({
      ...action,
      parentActionId,
    });
    if (onChange) {
      onChange(parentActionId);
    }
    setHasParentActionId(checked);
  };

  return (
    <Stack maxWidth='100%'>
      <Stack direction='row' alignItems='center'>
        <FormControlLabel
          control={
            <Checkbox checked={hasParentActionId} onChange={onCheckboxChange} />
          }
          label={PARENT_ACTION_LABEL}
        />
        <IconButton
          disabled={!hasParentActionId}
          ref={$popperAnchor}
          onClick={() => setIsPickerOpen((isOpen) => !isOpen)}
        >
          <Edit />
        </IconButton>
      </Stack>

      <Stack direction='row' flex={'1 1 0'}>
        {parentAction ? (
          <ActionChip action={parentAction} />
        ) : (
          <Chip label={NO_PARENT_ACTION_LABEL} />
        )}
      </Stack>

      <Popover
        open={isPickerOpen}
        onClose={() => setIsPickerOpen(false)}
        anchorEl={$popperAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ActionPicker
          tableActions={pickerActions}
          onChange={_onChange}
          selected={parentAction?.actionId}
        />
      </Popover>
    </Stack>
  );
};
