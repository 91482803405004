import {
  isUnknownPlayerAction,
  isUnknownPlayerWarning,
  isPassSamePlayerAction,
  isPassSamePlayerWarning,
} from '@/utils/actions';
import { ACTION_TYPE_ID, ActionWarning } from '@contract';
import { Action } from '@/types/action/action';
import { WarningType, ACTION_WARNING } from './constants';

const AT = ACTION_TYPE_ID;
const { PASS_SAME_PLAYER, UNKNOWN_PLAYER } = ACTION_WARNING;

const WARNINGS = {
  [WarningType.UnknownPlayer]: {
    condition: isUnknownPlayerAction,
    isWarning: isUnknownPlayerWarning,
    warning: UNKNOWN_PLAYER,
  },
  [WarningType.PassSamePlayer]: {
    condition: isPassSamePlayerAction,
    isWarning: isPassSamePlayerWarning,
    warning: PASS_SAME_PLAYER,
  },
};

type ActionWarningsType = Exclude<WarningType, WarningType.Flag>;

const DEFAULT_WARNINGS: ActionWarningsType[] = [WarningType.UnknownPlayer];

const ACTION_SPECIFIC_WARNINGS: Partial<{
  [key in ACTION_TYPE_ID]: ActionWarningsType[];
}> = Object.freeze({
  [AT.Pass]: [WarningType.PassSamePlayer],
  [AT.Cross]: [WarningType.PassSamePlayer],
  [AT.Launch]: [WarningType.PassSamePlayer],
  [AT.GoalkeeperThrow]: [WarningType.PassSamePlayer],
  [AT.GoalkeeperKickFromHands]: [WarningType.PassSamePlayer],
});

function toggleActionWarnings(
  action: Action,
  actionWarnings: ActionWarningsType[],
) {
  let warnings = action.warnings ? [...action.warnings] : [];

  actionWarnings.forEach((type) => {
    const { condition, isWarning, warning } = WARNINGS[type];

    if (condition(action) && !warnings.some((w) => warning.type === w.type)) {
      warnings.push(warning);
    } else if (!condition(action)) {
      warnings = warnings.filter((w) => !isWarning(w));
    }
  });

  return warnings.length === 0 ? null : warnings;
}

export function getActionWarnings(action: Action) {
  const actionWarnings = [...DEFAULT_WARNINGS];
  const actionSpecificWarnings = ACTION_SPECIFIC_WARNINGS[action.actionTypeId];

  if (actionSpecificWarnings) {
    actionWarnings.push(...actionSpecificWarnings);
  }

  return toggleActionWarnings(action, actionWarnings);
}

export function toggleWarning(
  originalWarnings: Action['warnings'],
  warning: ActionWarning,
) {
  let warnings = originalWarnings ? [...originalWarnings] : null;

  if (warnings === null) {
    warnings = [warning];
  } else if (warnings.some((w) => w.type === warning.type)) {
    warnings = warnings.filter((w) => w.type !== warning.type);
  } else {
    warnings.push(warning);
  }

  return warnings;
}
