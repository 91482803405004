import { Stack } from '@mui/material';
import { FC, useRef } from 'react';
import { ClickableDots, type DotProps } from '@/components/Pitch/ClickableDots';
import { getActionMetadata } from '@/utils/actions';
import PitchHorizontalSVG from '@/assets/football-pitch-with-goals.svg?react';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import type { Action } from '@/types/action/action';
import { PITCH_POSITION } from '../constants';

type SimplePitchProps = {
  action: Action;
};

export const SimplePitch: FC<SimplePitchProps> = ({ action }) => {
  const metadata = getActionMetadata(action);
  const $pitchContainer = useRef<HTMLDivElement>(null);
  const hasPosition = 'position' in metadata && !!metadata.position;
  if (!hasPosition) return;

  const setCoords = (dot: DotProps) => {
    if (!dot.positionId || dot.positionId !== PITCH_POSITION.ACTION_POSITION) {
      return;
    }

    setActionPartialMetadata({ position: dot.coords });
  };

  return (
    <Stack
      ref={$pitchContainer}
      sx={{
        position: 'relative',
        flex: 1,
        width: 'clamp(500px, 100%, 1000px)',
      }}
    >
      <PitchHorizontalSVG />
      <ClickableDots
        dots={[
          {
            positionId: PITCH_POSITION.ACTION_POSITION,
            coords: metadata.position,
          },
        ]}
        setCoords={setCoords}
        pitchContainerRef={$pitchContainer}
      />
    </Stack>
  );
};
