import { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useOktaAuth } from '@okta/okta-react';
import { AuthApiError } from '@okta/okta-auth-js';
import { z } from 'zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@/components/LoadingButton/LoadingButton';
import {
  FORM_TITLE,
  GENERIC_ERROR,
  PASSWORD_INPUT_LABEL,
  SIGN_IN_BUTTON_LABEL,
  TRANSACTION_SUCCESS,
  EMAIL_INPUT_LABEL,
  SIGN_IN_SUBTITLE,
  SIGN_IN_TITLE,
  SIGN_IN_WRONG_CREDENTIALS,
} from './constants';

const usernameSchema = z
  .string({ required_error: 'E-mail is required.' })
  .email('Email format is incorrect.');
const passwordSchema = z.string().min(1, 'Password is required.');

const signInSchema = z.object({
  username: usernameSchema,
  password: passwordSchema,
});

type SignInSchema = z.infer<typeof signInSchema>;

export const SignInForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInSchema>({
    resolver: zodResolver(signInSchema),
    mode: 'onTouched',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const { oktaAuth } = useOktaAuth();

  const onSubmit: SubmitHandler<SignInSchema> = async (data) => {
    setFormError(null);
    setIsLoading(true);

    try {
      const transaction = await oktaAuth.signInWithCredentials(data);

      if (transaction.status !== TRANSACTION_SUCCESS) {
        throw 'We cannot handle the ' + transaction.status + ' status';
      }

      oktaAuth.signInWithRedirect({ sessionToken: transaction.sessionToken });
    } catch (error) {
      if (error instanceof AuthApiError) {
        setFormError(SIGN_IN_WRONG_CREDENTIALS);
        return;
      }

      setFormError(GENERIC_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form role='form' onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ mb: (theme) => theme.spacing(8) }}>
        <Typography variant='h4' sx={{ mb: (theme) => theme.spacing(1) }}>
          {SIGN_IN_TITLE}
        </Typography>
        <Typography variant='h6'>{SIGN_IN_SUBTITLE}</Typography>
      </Box>
      <Stack sx={{ mx: 'auto' }} gap={2}>
        <Typography variant='h5'>{FORM_TITLE}</Typography>
        <TextField
          {...register('username')}
          error={!!errors.username || !!formError}
          label={EMAIL_INPUT_LABEL}
          variant='outlined'
          helperText={errors.username?.message}
        />
        <TextField
          {...register('password')}
          error={!!errors.password || !!formError}
          label={PASSWORD_INPUT_LABEL}
          type='password'
          variant='outlined'
          helperText={errors.password?.message}
        />
        {formError && <Typography color='error'>{formError}</Typography>}
        <LoadingButton
          sx={{
            width: 'fit-content',
            padding: (theme) => theme.spacing(1, 3),
          }}
          variant='contained'
          type='submit'
          isLoading={isLoading}
          aria-label={SIGN_IN_BUTTON_LABEL}
        >
          {SIGN_IN_BUTTON_LABEL}
        </LoadingButton>
      </Stack>
    </form>
  );
};
