import { Box, css, styled } from '@mui/material';

export const PlayersGrid = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    inset: 0;
    display: grid;
    padding: ${theme.spacing(1)};
    gap: ${theme.spacing(0.5)};
  `,
);
