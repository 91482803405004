import { Paper, Stack } from '@mui/material';
import { PitchModeLabel } from '../Labels/PitchModeLabel';
import { VersionLabel } from '../Labels/VersionLabel';
import { EnvironmentLabel } from '../Labels/EnvironmentLabel';
import { CollectionIdLabel } from '../Labels/CollectionIdLabel';
import { ViewLabel } from '../Labels/ViewLabel';
import { ActionsSubscriptionLabel } from '../Labels/ActionsSubscriptionLabel';
import { StateSubscriptionLabel } from '../Labels/StateSubscriptionLabel';

const LABEL_ID = {
  MODE: 'mode-label',
  COLLECTION_ID: 'collection-id-label',
  VIEW: 'current-view-label',
  ENV: 'env-label',
  VERSION: 'version-label',
  ACTIONS: 'actions-subscription',
  STATE: 'state-subscription',
};
export const BottomBar = () => {
  return (
    <Paper variant='outlined' square>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        gap={0.5}
        fontSize={'0.7rem'}
      >
        <PitchModeLabel id={LABEL_ID.MODE} />
        <StateSubscriptionLabel id={LABEL_ID.STATE} />
        <ActionsSubscriptionLabel id={LABEL_ID.ACTIONS} />
        <CollectionIdLabel id={LABEL_ID.COLLECTION_ID} />
        <ViewLabel id={LABEL_ID.VIEW} />
        <EnvironmentLabel id={LABEL_ID.ENV} />
        <VersionLabel id={LABEL_ID.VERSION} />
      </Stack>
    </Paper>
  );
};
