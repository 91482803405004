import { TableAction } from '@/stores/FixtureStore';
import { ACTION_TYPE_ID } from '@contract';

type ColumnId = keyof TableAction;
export type EventsTableColumn = {
  id: ColumnId | 'flag';
  name: JSX.Element | string;
  width: number;
};

export const HIGHLIGHT_ACTION_TYPE_ID: ACTION_TYPE_ID[] = [ACTION_TYPE_ID.Goal];

export const COLUMNS: EventsTableColumn[] = [
  { id: 'period', name: 'Period', width: 5 },
  { id: 'time', name: 'Time', width: 5 },
  { id: 'type', name: 'Event', width: 8 },
  { id: 'extras', name: 'Extras', width: 28 },
  { id: 'player', name: 'Player', width: 14 },
  { id: 'isSuccessful', name: 'Outcome', width: 5 },
  { id: 'createdAt', name: 'Timestamp', width: 10 },
  { id: 'actionId', name: 'ID', width: 7 },
  { id: 'warnings', name: 'Warning', width: 7 },
  { id: 'flag', name: 'Flag', width: 7 },
  { id: 'state', name: 'Status', width: 4 },
];

(function () {
  const totalWidth = COLUMNS.reduce((acc, col) => {
    return acc + col.width;
  }, 0);
  if (totalWidth !== 100) {
    console.error(
      `ActionsTable columns width is ${totalWidth}% - should be a 100%`,
    );
  }
})();

export const TABLE_CLASS_NAMES = {
  ROW: 'action-row',
};
