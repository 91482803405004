import useSWR from 'swr';
import { DataCollectionDto } from '@contract';
import { useFixtureStore, setCurrentCollection } from '@/stores/FixtureStore';
import { makeFakeFixturesCollectionResponse } from '@root/db/fakeCollectionInfo';
import { SS_KEY } from '@/utils/window';
import { SERVICE_ENDPOINT } from './constants/endpoint';
import { fetchData } from './fetchData';
import { useAuthToken } from './hooks/authToken';
import { isUsingMockData } from './helpers/isUsingMockData';

export type FixturesCollectionResponse = {
  previousCollections: DataCollectionDto[];
  upcomingCollections: DataCollectionDto[];
};

function getFixturesCollectionCallback(url: string, token: string) {
  if (isUsingMockData()) {
    return Promise.resolve(makeFakeFixturesCollectionResponse());
  } else {
    return fetchData({ url, token });
  }
}

function getCollectionCallback(
  url: string,
  token: string,
  id: string | undefined,
): Promise<DataCollectionDto> {
  if (isUsingMockData()) {
    return new Promise((resolve, reject) => {
      const collectionItem = window.sessionStorage.getItem(
        SS_KEY.MOCK_COLLECTION,
      );
      if (!collectionItem) {
        return reject('Mock collection does not exist in session storage');
      }
      const collection = JSON.parse(collectionItem) as DataCollectionDto;

      if (collection.id !== id) {
        return reject(
          'Mock collection in session storage does not match the id',
        );
      }
      return resolve(collection);
    });
  } else {
    return fetchData({ url, token });
  }
}

export function useGetFixturesCollection() {
  const token = useAuthToken();

  return useSWR<FixturesCollectionResponse>(
    SERVICE_ENDPOINT.DataCollections.get,
    (url: string) => getFixturesCollectionCallback(url, token),
  );
}

export function useGetCollection() {
  const token = useAuthToken();
  const id = useFixtureStore((state) => state.dataCollectionId);
  const url = id ? `${SERVICE_ENDPOINT.DataCollection.get(id)}` : null;

  return useSWR<DataCollectionDto>(
    url,
    (url: string) =>
      getCollectionCallback(url, token, id).then(
        (response: DataCollectionDto) => {
          setCurrentCollection(response);
          return response;
        },
      ),
    { revalidateOnMount: true, dedupingInterval: 20 },
  );
}
