import CloudSync from '@mui/icons-material/CloudSync';
import { Chip, type ChipProps } from '@mui/material';
import { FC } from 'react';
import { useSocketStore } from '@/stores/SocketStore/SocketStore';
import { useFixtureStore } from '@/stores/FixtureStore';

type ActionsSubscriptionLabelProps = ChipProps;

export const ActionsSubscriptionLabel: FC<ActionsSubscriptionLabelProps> = (
  props,
) => {
  const actionsSubscribed = useSocketStore((state) => state.actionsSubscribed);
  const homeTeam = useFixtureStore((state) => state.homeTeam);
  const awayTeam = useFixtureStore((state) => state.awayTeam);

  const getLabel = () => {
    if (!actionsSubscribed) {
      return 'Actions not subscribed';
    }
    const label = 'Actions subscribed';
    if (typeof actionsSubscribed === 'boolean') {
      return label;
    }
    let teamName: string | undefined;
    if (actionsSubscribed === homeTeam?.id) {
      teamName = homeTeam.name;
    } else if (actionsSubscribed === awayTeam?.id) {
      teamName = awayTeam.name;
    } else {
      teamName = actionsSubscribed;
    }
    return `${label}: ${teamName}`;
  };
  return (
    <Chip
      variant='outlined'
      size='small'
      color='info'
      icon={<CloudSync />}
      label={getLabel()}
      {...props}
    />
  );
};
