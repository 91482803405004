import hotkeys, { KeyHandler } from 'hotkeys-js';
import {
  CSSProperties,
  DetailedHTMLProps,
  FC,
  VideoHTMLAttributes,
  useEffect,
  useRef,
} from 'react';
import { useEffectOnce, useSessionStorage } from 'usehooks-ts';
import { styled } from '@mui/material';
import { useUIStore } from '@/stores/UIStore';
import { registerWindowUtil } from '@/utils/window';
import { isDevMode } from '@/utils/environment';

type VideoProps = DetailedHTMLProps<
  VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;

let logged = false;
if (isDevMode && !logged) {
  logged = true;
  console.log('`window._ed.$video` - reference to video element');
  console.log('[Shift+V] - toggle video interactivity');
}

const SS_KEY = {
  VIDEO_INTERACTIVE: 'video-interactive',
} as const;

const OverlayVideo = styled('video')`
  position: absolute;
  width: 66%;
  inset: 0;
  margin: auto;
  background: black;
  pointer-events: none;
  opacity: 0.85;
  z-index: 0;
`;

export const VIDEO_TEST_ID = 'stream-video';

export const StreamVideo: FC<VideoProps> = (props) => {
  const $video = useRef<HTMLVideoElement | null>(null);
  const $streamContainer = useUIStore((state) => state.$streamContainer);

  useEffect(() => {
    if ($video && $video.current) {
      registerWindowUtil('$video', $video.current);
    }

    return () => {
      registerWindowUtil('$video', null);
    };
  }, [$video]);

  const [interactive, setInteractive] = useSessionStorage(
    SS_KEY.VIDEO_INTERACTIVE,
    false,
  );

  useEffectOnce(() => {
    const enableInteractive: KeyHandler = function () {
      setInteractive((interactive) => !interactive);
    };
    hotkeys('shift+v', { scope: 'all' }, enableInteractive);
    return () => {
      hotkeys.unbind('shift+v', 'all', enableInteractive);
    };
  });

  /**
   * This styles should always be called as last.
   * If the stream has no parent it should be invisible
   * and unclickable. The reverse-portal keeps it mounted
   * to preserve its state while reparenting in the DOM,
   * so we need to make it seem unexistant when no parent
   * is defined for the portal placeholder div.
   */
  function getNoParentStyle(): CSSProperties {
    if ($streamContainer) return {};
    return {
      opacity: 0,
      width: 0,
      height: 0,
      pointerEvents: 'none',
    };
  }

  function getInteractiveStyle(): CSSProperties {
    if (!interactive) return {};
    return {
      pointerEvents: 'all',
      opacity: 1,
    };
  }

  return (
    <OverlayVideo
      autoPlay
      muted
      controls={interactive}
      {...props}
      ref={$video}
      // onLoadedData={({ target }) => {
      // (target as HTMLVideoElement).currentTime = 180;
      // }}
      style={{ ...getInteractiveStyle(), ...getNoParentStyle() }}
      data-testid={VIDEO_TEST_ID}
    />
  );
};
