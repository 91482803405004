import { MouseEvent, useState } from 'react';
import { getLatestActionVersion } from '@/stores/utils';
import {
  setContextAction,
  setDialogAction,
  setMode,
  useActionStore,
} from '@/stores/ActionStore/ActionStore';
import { ACTION_TYPE_MODE, MODE } from '@/stores/ActionStore/constants';
import {
  ActionContextMenu,
  ActionTableVariant,
} from '../ActionContextMenu/ActionContextMenu';
import { findRowInPath, getRowActionId } from './utils';

export const useActionsTable = (tableName: ActionTableVariant) => {
  const [contextMenuPosition, setContextMenuPosition] = useState({
    top: 0,
    left: 0,
  });
  const [contextMenuVisible, setContextMenuVisible] = useState(false);

  const getRowId = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const path = e.nativeEvent.composedPath() as HTMLElement[];

    return getRowActionId(findRowInPath(path));
  };

  const onContextMenuClick = (e: MouseEvent<HTMLDivElement>) => {
    const mode = useActionStore.getState().mode;
    if (mode !== MODE.PITCH) {
      setContextMenuVisible(false);
      return;
    }

    const clickedRowActionId = getRowId(e);
    if (!clickedRowActionId) {
      setContextMenuVisible(false);
      return;
    }

    const action = getLatestActionVersion(clickedRowActionId);
    if (!action) {
      setContextMenuVisible(false);
      return;
    }
    setContextMenuVisible(true);
    setContextMenuPosition({ top: e.pageY, left: e.pageX + 5 });
    setContextAction(action);
  };

  const contextMenu = contextMenuVisible ? (
    <ActionContextMenu position={contextMenuPosition} tableName={tableName} />
  ) : undefined;

  const onTableDoubleClick = (e: MouseEvent<HTMLDivElement>) => {
    const clickedRowActionId = getRowId(e);

    if (!clickedRowActionId) return;

    const action = getLatestActionVersion(clickedRowActionId);

    if (!action) return;

    const mode = ACTION_TYPE_MODE[action.actionTypeId] || MODE.PITCH;

    setMode(mode);
    setDialogAction(getLatestActionVersion(clickedRowActionId));
  };

  return {
    contextMenu,
    getRowId,
    onContextMenuClick,
    onTableDoubleClick,
  };
};
