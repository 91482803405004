import { FC, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { IconsContainer } from '../IconsContainer';
import { PITCH_LINEUP_ID } from '../constants';
import { PitchSVG as HorizontalPitchSVG } from '../PitchSVG';
import { PitchGrid } from './PitchGrid';

interface DisplayHorizontalFormationProps {
  onEdit: VoidFunction;
}

export const DisplayHorizontalFormation: FC<
  DisplayHorizontalFormationProps
> = ({ onEdit }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <Stack flex={1} p={1}>
      <Stack
        position='relative'
        id={PITCH_LINEUP_ID}
        direction='column'
        marginTop='auto'
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <HorizontalPitchSVG />
        <PitchGrid />
        {isMouseOver && (
          <IconsContainer>
            <IconButton color='primary' onClick={onEdit}>
              <Edit />
            </IconButton>
          </IconsContainer>
        )}
      </Stack>
    </Stack>
  );
};
