import { Chip, colors } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

export const AssignedChip = () => {
  return (
    <Chip
      sx={{
        background: colors.grey[800],
        height: '2em',
        fontSize: '0.8em',
        display: 'flex',
        width: 'fit-content',
      }}
      icon={
        <CircleIcon
          sx={{
            fill: colors.purple[600],
            height: '0.7em',
          }}
        />
      }
      label='Assigned to me'
    />
  );
};
