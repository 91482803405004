import { FC } from 'react';
import { Stack } from '@mui/material';
import { TableAction } from '@/stores/FixtureStore';
import { formatDate } from '@/utils/formatDate';
import { getNoFlagWarnings } from '@/utils/actions';
import {
  getStateCell,
  getFlagCell,
  getPlayerCell,
  getWarningCell,
  getPeriodCell,
} from '@/components/ActionsTable/TableCells';
import { TableExtrasCell } from '@/components/TableExtrasCell/TableExtrasCell';
import { OutcomeIcon } from '@/components/ActionOutcome/OutcomeIcon';
import { EventsTableColumn, HIGHLIGHT_ACTION_TYPE_ID } from './constants';

type EventsTableCellProps = {
  column: EventsTableColumn;
  action: TableAction;
};

function getCellValue(columnId: EventsTableColumn['id'], action: TableAction) {
  switch (columnId) {
    case 'state':
      return getStateCell(action);
    case 'warnings': {
      const noFlagWarnings = getNoFlagWarnings(action);
      return getWarningCell(noFlagWarnings);
    }
    case 'flag':
      return getFlagCell(action);
    case 'isSuccessful':
      return <OutcomeIcon outcome={action.isSuccessful} />;
    case 'player':
      return getPlayerCell(action[columnId]);
    case 'createdAt':
      return formatDate(new Date(action[columnId]));
    case 'extras':
      return <TableExtrasCell extras={action[columnId]} />;
    case 'period':
      return getPeriodCell(action);
    default:
      return action[columnId];
  }
}

export const EventsTableCell: FC<EventsTableCellProps> = ({
  column,
  action,
}) => {
  const isHighlighted =
    column.id === 'type' && HIGHLIGHT_ACTION_TYPE_ID.includes(action.typeId);

  return (
    <Stack
      key={column.id}
      width={`${column.width}%`}
      justifyContent='flex-start'
      flexWrap={'wrap'}
      lineHeight={1.43}
      direction='row'
      fontSize='0.875rem'
      textAlign='left'
      alignItems='center'
      px={1}
      sx={{
        backgroundColor: isHighlighted ? '#363e2c' : '',
      }}
    >
      {getCellValue(column.id, action)}
    </Stack>
  );
};
