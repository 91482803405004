export function sortExceptFirst<T>(
  arr: T[],
  compareFn: (a: T, b: T) => number,
) {
  const [defaultValue, ...remainingValues] = arr;
  return [defaultValue, ...remainingValues.sort(compareFn)];
}

export function sortNumbersAscending(a: number, b: number) {
  return a - b;
}
