import { type FC } from 'react';
import type { Action } from '@/types/action/action';
import { METADATA_KEY } from '@contract';
import { PassDialogContent } from './PassDialogContent/PassDialogContent';
import { ShotDialogContent } from './ShotDialogContent/ShotDialogContent';
import { GoalDialogContent } from './GoalDialogContent/GoalDialogContent';
import { YellowCardDialogContent } from './YellowCardDialogContent/YellowCardDialogContent';
import { RedCardDialogContent } from './RedCardDialogContent/RedCardDialogContent';
import { VARUnderwayDialogContent } from './VARUnderwayDialogContent/VARUnderwayDialogContent';
import { VARResultDialogContent } from './VARResultDialogContent/VARResultDialogContent';
import { SubstitutionDialogContent } from './SubstitutionDialogContent/SubstitutionDialogContent';
import { FreeKickDialogContent } from './FreeKickDialogContent/FreeKickDialogContent';
import { PlayStoppedDialogContent } from './PlayStoppedDialogContent/PlayStoppedDialogContent';
import { GoalkeeperPenaltyFacedDialogContent } from './GoalkeeperPenaltyFacedDialogContent/GoalkeeperPenaltyFacedDialogContent';
import { GoalkeeperSaveDialogContent } from './GoalkeeperSaveDialogContent/GoalkeeperSaveDialogContent';
import { AdditionalTimeDialogContent } from './AdditionalTimeDialogContent/AdditionalTimeDialogContent';
import { ErrorDialogContent } from './ErrorDialogContent/ErrorDialogContent';
import { GenericEditDialogContent } from './GenericEditDialogContent/GenericEditDialogContent';
import { FormationDialogContent } from './FormationDialogContent/FormationDialogContent';

interface ActionDialogContentProps {
  action: Action;
}

export const ActionDialogContent: FC<ActionDialogContentProps> = ({
  action,
}) => {
  const am = action.actionTypeMetadata;

  if (
    am === METADATA_KEY.pass ||
    am === METADATA_KEY.launch ||
    am === METADATA_KEY.cross ||
    am === METADATA_KEY.goalkeeperThrow ||
    am === METADATA_KEY.throwInTaken ||
    am === METADATA_KEY.goalkeeperKickFromHands
  ) {
    return <PassDialogContent action={action} />;
  }
  if (am === METADATA_KEY.shot) {
    return <ShotDialogContent action={action} />;
  }
  if (am === METADATA_KEY.goal) {
    return <GoalDialogContent action={action} />;
  }
  if (am === METADATA_KEY.yellowCard) {
    return <YellowCardDialogContent action={action} />;
  }
  if (am === METADATA_KEY.redCard) {
    return <RedCardDialogContent action={action} />;
  }
  if (am === METADATA_KEY.varUnderway) {
    return <VARUnderwayDialogContent action={action} />;
  }
  if (am === METADATA_KEY.varResult) {
    return <VARResultDialogContent action={action} />;
  }
  if (am === METADATA_KEY.substitution) {
    return <SubstitutionDialogContent action={action} />;
  }
  if (am === METADATA_KEY.freeKickAwarded) {
    return <FreeKickDialogContent action={action} />;
  }
  if (am === METADATA_KEY.playStopped) {
    return <PlayStoppedDialogContent action={action} />;
  }
  if (am === METADATA_KEY.goalkeeperPenaltyFaced) {
    return <GoalkeeperPenaltyFacedDialogContent action={action} />;
  }
  if (am === METADATA_KEY.goalkeeperSave) {
    return <GoalkeeperSaveDialogContent action={action} />;
  }
  if (am === METADATA_KEY.additionalTime) {
    return <AdditionalTimeDialogContent action={action} />;
  }
  if (am === METADATA_KEY.error) {
    return <ErrorDialogContent action={action} />;
  }
  if (am === METADATA_KEY.formation) {
    return <FormationDialogContent action={action} />;
  }
  if (am) {
    return <GenericEditDialogContent action={action} />;
  }
  return;
};
