import { useState, type FC } from 'react';
import { getDefaultPitchPlayersMap } from '@/stores/LineupStore/utils';
import type { Player } from '@/service/lineups';
import type { PlayerPositionDto, Position } from '@contract';
import { useFixtureStore } from '@/stores/FixtureStore';
import { bottomUpGrid } from '@/components/LineupEditor/PitchGrid';
import { gridAreas, PITCH_GRID_ID, PitchOrientation } from '../constants';
import { PlayersGrid } from '../PlayersGrid';
import { EditPitchItem } from './EditPitchItem';

interface PitchGridProps {
  setPitchPlayers: (playerPosition: PlayerPositionDto[]) => void;
  pitchPlayers: PlayerPositionDto[];
  pitchOrientation?: PitchOrientation;
}

export const EditPitchGrid: FC<PitchGridProps> = ({
  pitchPlayers,
  setPitchPlayers,
  pitchOrientation = PitchOrientation.Horizontal,
}) => {
  const directionOfPlay = useFixtureStore((state) => state.directionOfPlay);
  const [editSelectedEntry, setEditSelectedEntry] = useState<{
    player: Player | null;
    position: Position;
  } | null>(null);
  const emptyPitchPlayersMap = getDefaultPitchPlayersMap();
  const pitchPlayersMap = pitchPlayers.reduce((acc, player) => {
    acc.set(player.position, player.player);
    return acc;
  }, new Map());
  const combinedPlayersMap = new Map([
    ...emptyPitchPlayersMap,
    ...pitchPlayersMap,
  ]);
  const pitchEntries = [...combinedPlayersMap.entries()];
  const selectedEntry = editSelectedEntry?.position || null;
  const isPitchHorizontal = pitchOrientation === PitchOrientation.Horizontal;

  const selectEntry = (player: Player | null, position: Position) => {
    if (!editSelectedEntry) {
      setEditSelectedEntry({ player, position });
      return;
    }

    if (editSelectedEntry.player?.id === player?.id) {
      setEditSelectedEntry(null);
      return;
    }

    if (!editSelectedEntry.position && !position) {
      return;
    }

    const newPitchPlayers = pitchPlayers.map((pitchPlayer) => {
      if (pitchPlayer.position === position) {
        return {
          ...pitchPlayer,
          position: editSelectedEntry.position,
        };
      }
      if (pitchPlayer.position === editSelectedEntry.position) {
        return {
          ...pitchPlayer,
          position,
        };
      }
      return pitchPlayer;
    });

    setPitchPlayers(newPitchPlayers);
    setEditSelectedEntry(null);
  };

  return (
    <PlayersGrid
      id={PITCH_GRID_ID}
      sx={{
        gridTemplateAreas: isPitchHorizontal
          ? gridAreas[directionOfPlay]
          : bottomUpGrid,
        gridTemplateColumns: `repeat(${isPitchHorizontal ? 7 : 5}, 1fr)`,
        gridTemplateRows: `repeat(${isPitchHorizontal ? 5 : 7}, 1fr)`,
      }}
    >
      {pitchEntries.map(([position, player]) => (
        <EditPitchItem
          key={position}
          position={position}
          player={player}
          selectEntry={selectEntry}
          selectedEntry={selectedEntry}
        />
      ))}
    </PlayersGrid>
  );
};
