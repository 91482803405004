import { FC, PropsWithChildren, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { ROUTE_URL } from '@/router/constants';
import { setAccessToken, setUserId } from '@/stores/UserStore';

export const Protected: FC<PropsWithChildren> = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );
      oktaAuth.setOriginalUri(originalUri);
      navigate(ROUTE_URL.SIGN_IN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  useEffect(() => {
    if (!authState || !authState.accessToken || !authState.idToken) {
      return;
    }
    setAccessToken(authState.accessToken.accessToken);
    setUserId(authState.idToken.claims.sub);
  }, [authState]);

  if (!authState || !authState?.isAuthenticated) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return children;
};
