import { CSSProperties, FC } from 'react';
import { css, styled } from '@mui/material';
import type { Coords } from '@/types';

type CoordsLineProps = {
  coordsStart: Coords;
  coordsEnd: Coords;
  asArrow?: boolean;
  color?: string;
};

const Line = styled('svg')(
  () => css`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
    line {
      stroke: var(--targetsColor);
      stroke-width: 2px;
    }
    marker {
      fill: var(--targetsColor);
    }
  `,
);
export const CoordsLine: FC<CoordsLineProps> = ({
  coordsStart,
  coordsEnd,
  asArrow,
  color,
}) => {
  return (
    <Line style={{ '--targetsColor': color } as CSSProperties}>
      <marker
        id='arrowHead'
        viewBox='0 0 10 10'
        refX='12'
        refY='3'
        markerWidth='10'
        markerHeight='10'
        orient='auto'
      >
        <path d='M0,0 L0,6 L9,3 z' />
      </marker>
      <line
        markerEnd={asArrow ? 'url(#arrowHead)' : ''}
        x1={coordsStart.x + '%'}
        y1={coordsStart.y + '%'}
        x2={coordsEnd.x + '%'}
        y2={coordsEnd.y + '%'}
      />
    </Line>
  );
};
