import { FC, useEffect } from 'react';
import { ShotAction } from '@/types/action/action';
import { AttemptType, ShotOffTargetType } from '@contract';
import { OFF_TARGET_DESTINATION_LABEL } from '@/constants';
import { OFF_TARGET_DESTINATION } from '@/components/Dialogs/ShotDialogContent/constants';
import { RadioButtons } from '@/components/Form/RadioButtons';
import { getActionMetadata } from '@/utils/actions';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';

interface OffTargetDestinationGroupProps {
  action: ShotAction;
}

const onOffTargetDestinationChange = (shotOffTargetType: ShotOffTargetType) => {
  setActionPartialMetadata<ShotAction>({ shotOffTargetType });
};

export const OffTargetDestinationGroup: FC<OffTargetDestinationGroupProps> = ({
  action,
}) => {
  const metadata = getActionMetadata(action);
  const isDisabled = metadata.attemptType !== AttemptType.OffTarget;

  useEffect(() => {
    if (metadata.attemptType === AttemptType.OffTarget) {
      setActionPartialMetadata<ShotAction>({
        shotOffTargetType: ShotOffTargetType.PastGoalLine,
      });
    } else {
      setActionPartialMetadata<ShotAction>({
        shotOffTargetType: null,
      });
    }
  }, [metadata.attemptType]);

  return (
    <RadioButtons
      name='offTargetDestination'
      onChange={onOffTargetDestinationChange}
      value={metadata.shotOffTargetType}
      controlLabel={OFF_TARGET_DESTINATION_LABEL}
      fields={OFF_TARGET_DESTINATION}
      disabled={isDisabled}
    />
  );
};
