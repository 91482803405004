import { Stack, Theme } from '@mui/material';
import { FC, useCallback } from 'react';
import type { Player } from '@/service/lineups';
import type { Position } from '@contract';
import { POSITION_NAME } from '@/stores/LineupStore/constants';
import { LineupPlayerLabel, LineupPlayerShirt } from '../Player';

type PitchItemProps = {
  position: Position;
  player: Player | null;
  selectEntry: (player: Player | null, position: Position) => void;
  selectedEntry: Position | null;
};

function getTransition(theme: Theme) {
  return theme.transitions.create(['transform', 'background-color'], {
    duration: theme.transitions.duration.shortest,
    easing: theme.transitions.easing.easeOut,
  });
}
export const EditPitchItem: FC<PitchItemProps> = ({
  position,
  player,
  selectEntry,
  selectedEntry,
}) => {
  const isSelected = selectedEntry && selectedEntry === position;
  const positionName = POSITION_NAME[position];

  const onClick = useCallback(() => {
    selectEntry(player, position);
  }, [selectEntry, player, position]);

  const getClassName = () => {
    if (isSelected) {
      return 'active';
    }
    if (player) {
      return 'active-partial';
    }
  };

  return (
    <Stack
      style={{ gridArea: positionName }}
      sx={{
        color: 'white',
        position: 'relative',
        justifySelf: 'stretch',
        alignSelf: 'center',
        alignItems: 'center',
      }}
      data-position-id={position}
      className={getClassName()}
    >
      <LineupPlayerShirt
        sx={{
          width: '2.5em',
          fontSize: '1.1em',
          fontWeight: '500',
          cursor: 'pointer',
          transition: getTransition,
        }}
        onClick={onClick}
      >
        {player ? player.shirtNumber : positionName}
      </LineupPlayerShirt>
      {player && (
        <LineupPlayerLabel sx={{ transition: getTransition }}>
          {player.fullName}
        </LineupPlayerLabel>
      )}
      {player && (
        <LineupPlayerLabel
          sx={{
            bottom: '100%',
            top: 'unset',
            marginTop: 'unset',
            transition: getTransition,
          }}
        >
          {positionName}
        </LineupPlayerLabel>
      )}
    </Stack>
  );
};
