import React, { FC, useMemo, useState } from 'react';
import EastIcon from '@mui/icons-material/East';
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { StartPeriodAction } from '@/types/action/action';
import { NestedMenuItem } from '@/components/ActionContextMenu/NestedMenuItem';
import {
  DIRECTION_OF_PLAY,
  DIRECTION_OF_PLAY_LABEL,
  directionOfPlayOptions,
} from '@/components/Periods/constants';
import { getActionMetadata } from '@/utils/actions';
import { toggleDirectionOfPlay } from '@/components/ActionContextMenu/actions';
import { ConfirmationDialog } from '@/components/Dialogs/Confirmation/ConfirmationDialog';
import { DIRECTION_OF_PLAY_CONFIRMATION } from '@/components/ActionContextMenu/MenuItems/constants';
import { deriveDirectionOfPlay } from '@/stores/utils';
import { setDirectionOfPlay, useFixtureStore } from '@/stores/FixtureStore';
import { setContextAction } from '@/stores/ActionStore/ActionStore';

interface ToggleDirectionOfPlayProps {
  action: StartPeriodAction;
}

export const UpdateDirectionOfPlay: FC<ToggleDirectionOfPlayProps> = ({
  action,
}) => {
  const metadata = getActionMetadata(action);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const initialDirectionOfPlay = useMemo(() => {
    return metadata.isHomeTeamLeft
      ? DIRECTION_OF_PLAY.LEFT_TO_RIGHT
      : DIRECTION_OF_PLAY.RIGHT_TO_LEFT;
  }, [metadata.isHomeTeamLeft]);
  const isHomeTeamAssigned = useFixtureStore(
    (state) => state.isHomeTeamCollector,
  );

  const onSubmit = () => {
    toggleDirectionOfPlay(action);
    setDirectionOfPlay(
      deriveDirectionOfPlay(!metadata.isHomeTeamLeft, isHomeTeamAssigned),
    );
    setIsConfirmationDialogOpen(false);
    setContextAction(null);
  };

  return (
    <NestedMenuItem
      menuItemLabel={DIRECTION_OF_PLAY_LABEL}
      icon={<SportsSoccerIcon />}
    >
      <MenuList variant='menu' sx={{ py: 0 }}>
        {directionOfPlayOptions.map(({ label, value }) => (
          <MenuItem
            key={value}
            onClick={() => setIsConfirmationDialogOpen(true)}
            disabled={initialDirectionOfPlay === value}
          >
            <ListItemIcon>
              {value === DIRECTION_OF_PLAY.LEFT_TO_RIGHT ? (
                <EastIcon />
              ) : (
                <WestIcon />
              )}
            </ListItemIcon>
            <ListItemText disableTypography>{label}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        title={DIRECTION_OF_PLAY_CONFIRMATION}
        onCancel={() => setIsConfirmationDialogOpen(false)}
        onConfirm={onSubmit}
      />
    </NestedMenuItem>
  );
};
