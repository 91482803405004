import {
  ACTION_TYPE_ID,
  ActionContext,
  AttemptType,
  BodyPart,
  Extras,
  PatternOfPlay,
} from '@contract';
import { Action } from '@/types/action/action';
import { UNKNOWN_PLAYER } from '@/stores/LineupStore/constants';

export const ACTION_TYPE_DEFAULT_PLAYER: Record<
  ACTION_TYPE_ID,
  Action['player']
> = {
  [ACTION_TYPE_ID.Lineups]: null,
  [ACTION_TYPE_ID.StartMatch]: null,
  [ACTION_TYPE_ID.EndMatch]: null,
  [ACTION_TYPE_ID.StartPeriod]: null,
  [ACTION_TYPE_ID.EndPeriod]: null,
  [ACTION_TYPE_ID.RefereeDropBall]: null,
  [ACTION_TYPE_ID.AdditionalTime]: null,
  [ACTION_TYPE_ID.PostMatchCheckComplete]: null,
  [ACTION_TYPE_ID.PlayResumed]: null,
  [ACTION_TYPE_ID.Formation]: null,
  [ACTION_TYPE_ID.Substitution]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.FreeKickAwarded]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.VARUnderway]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.VARResult]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.PlayStopped]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.PlayerLeft]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.PlayerReturned]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.Goal]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.RedCard]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.YellowCard]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.GoalKickAwarded]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.Offside]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.Shot]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.ThrowInTaken]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.ThrowInAwarded]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.CornerAwarded]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.Pass]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.Clearance]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.Cross]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.Tackle]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.OffsideFor]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.Interception]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.Error]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.Aerial]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.Dispossessed]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.BallRecovery]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.Launch]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.BadTouch]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.TakeOn]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.PlayerBeaten]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.GoalkeeperKickFromHands]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.BallTouch]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.GoalkeeperCollection]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.GoalkeeperPunch]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.GoalkeeperDrop]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.GoalkeeperSave]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.GoalkeeperCatch]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.GoalkeeperPenaltyFaced]: UNKNOWN_PLAYER,
  [ACTION_TYPE_ID.GoalkeeperThrow]: UNKNOWN_PLAYER,
};

export enum PITCH_POSITION {
  SHOT = 'shot',
  GOALKEEPER = 'goalkeeper',
  DEFENDER = 'defender',
  PASS_FROM = 'passForm',
  PASS_TO = 'passTo',
  ACTION_POSITION = 'actionPosition',
  TARGET_POSITION = 'targetPosition',
}

export const ATTEMPT_TYPE: Record<AttemptType, string> = {
  [AttemptType.TemptAttempt]: 'Temp. attempt',
  [AttemptType.OffTarget]: 'Off target',
  [AttemptType.OnTarget]: 'On target',
  [AttemptType.Blocked]: 'Blocked',
};

export const PATTERN_OF_PLAY: Record<PatternOfPlay, string> = {
  [PatternOfPlay.RegularPlay]: 'Regular play',
  [PatternOfPlay.Corner]: 'Corner',
  [PatternOfPlay.FreeKick]: 'Free kick',
  [PatternOfPlay.ThrowIn]: 'Throw in',
  [PatternOfPlay.Penalty]: 'Penalty',
};

export type ShotGoalBodyPart = Exclude<
  BodyPart,
  BodyPart.Body | BodyPart.Feet | BodyPart.Hands
>;

export const BODY_PART: Record<ShotGoalBodyPart, string> = {
  [BodyPart.LeftFoot]: 'Left foot',
  [BodyPart.RightFoot]: 'Right foot',
  [BodyPart.Head]: 'Head',
  [BodyPart.Other]: 'Other',
};

type ExtrasOptions = Exclude<Extras, Extras.Blocked>;

export const EXTRAS: Record<ExtrasOptions, string> = {
  [Extras.Woodwork]: 'Woodwork',
  [Extras.Deflection]: 'Deflection',
  [Extras.BigChance]: 'Big chance',
};

export const ACTION_CONTEXT: Record<ActionContext, string> = {
  [ActionContext.CornerTaken]: 'Corner taken',
  [ActionContext.FreeKickTaken]: 'Free kick taken',
  [ActionContext.GoalKickTaken]: 'Goal kick taken',
  [ActionContext.KickOff]: 'Kick Off, Start Period',
  [ActionContext.KickOffAfterGoal]: 'Kick Off, After Goal',
  [ActionContext.PenaltyTaken]: 'Penalty taken',
};

export const getAttemptExtrasOptions = (patternOfPlay: PatternOfPlay | null) =>
  Object.keys(EXTRAS).map((k) => {
    const key = Number(k) as ExtrasOptions;
    return {
      value: key,
      label: EXTRAS[key],
      isDisabled:
        key === Extras.BigChance && patternOfPlay === PatternOfPlay.Penalty,
    };
  });

export const PITCH_PLAYERS_VALUES = {
  SHOT: { COLOR: 'red', LABEL: 'Position of shot' },
  GOALKEEPER: { COLOR: 'yellow', LABEL: 'Goalkeeper position' },
  DEFENDER: { COLOR: 'purple', LABEL: 'Defender position' },
};

const RTL_POSITION = {
  Y: 36.5,
  X: 8,
};

const LTR_POSITION = {
  Y: 36.5,
  X: 92,
};

export const DEFENDERS_VALUES = {
  MAX_COUNT: 10,
  MIN_COUNT: 0,
  GAP: 3,
  RTL_POSITION,
  LTR_POSITION,
};
