export const TRANSACTION_SUCCESS = 'SUCCESS';
export const GENERIC_ERROR = 'Something went wrong';
export const SIGN_IN_WRONG_CREDENTIALS =
  'Email address or password are incorrect. If you have login problems, please contact technical support.';

export const SIGN_IN_TITLE = 'Welcome to Event Data';
export const SIGN_IN_SUBTITLE = 'Discover the world of fascinating events';
export const FORM_TITLE = 'Sign in';
export const EMAIL_INPUT_LABEL = 'E-mail address';
export const PASSWORD_INPUT_LABEL = 'Password';
export const SIGN_IN_BUTTON_LABEL = 'Sign in';
