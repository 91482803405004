export const OUTCOME_TYPE = {
  SUCCESSFUL: 'successful',
  UNSUCCESSFUL: 'unsuccessful',
  NONE: 'none',
} as const;
export type OutcomeType = ConstType<typeof OUTCOME_TYPE>;

export const OUTCOME_VALUE = {
  [OUTCOME_TYPE.NONE]: null,
  [OUTCOME_TYPE.UNSUCCESSFUL]: false,
  [OUTCOME_TYPE.SUCCESSFUL]: true,
} as const;
type OutcomeValue = ConstType<typeof OUTCOME_VALUE>;

export const OUTCOME_TYPE_VALUE_MAP = new Map<OutcomeValue, OutcomeType>([
  [OUTCOME_VALUE[OUTCOME_TYPE.NONE], OUTCOME_TYPE.NONE],
  [OUTCOME_VALUE[OUTCOME_TYPE.UNSUCCESSFUL], OUTCOME_TYPE.UNSUCCESSFUL],
  [OUTCOME_VALUE[OUTCOME_TYPE.SUCCESSFUL], OUTCOME_TYPE.SUCCESSFUL],
]);

export const OUTCOME_OPTION_LABEL = {
  [OUTCOME_TYPE.SUCCESSFUL]: 'Successful',
  [OUTCOME_TYPE.UNSUCCESSFUL]: 'Unsuccessful',
  [OUTCOME_TYPE.NONE]: 'None',
};

export type OutcomeOption = {
  type: OutcomeType;
  value: OutcomeValue;
  label: string;
};

const outcomeOption = (type: OutcomeType): OutcomeOption => ({
  type,
  value: OUTCOME_VALUE[type],
  label: OUTCOME_OPTION_LABEL[type],
});

export const REQUIRED_OUTCOME_OPTIONS = [
  outcomeOption(OUTCOME_TYPE.SUCCESSFUL),
  outcomeOption(OUTCOME_TYPE.UNSUCCESSFUL),
];

export const DEFAULT_OUTCOME_OPTIONS = [
  ...REQUIRED_OUTCOME_OPTIONS,
  outcomeOption(OUTCOME_TYPE.NONE),
];
