import { Chip, ChipProps } from '@mui/material';
import { FC } from 'react';

type SingleExtra = string | (ChipProps & { key: string });
export type TableExtrasCellValue = SingleExtra[] | string | undefined;
type TableExtrasCellProps = {
  extras: TableExtrasCellValue;
};
const EXTRAS_CHIP_TEST_ID = 'extras-chip';
const ExtrasChip: FC<ChipProps> = (props) => {
  return (
    <Chip
      data-testid={EXTRAS_CHIP_TEST_ID}
      size='small'
      variant='outlined'
      {...props}
    />
  );
};

function mapExtra(extra: SingleExtra) {
  if (typeof extra === 'string') {
    return <ExtrasChip size='small' key={extra} label={extra} />;
  }
  return <ExtrasChip size='small' {...extra} />;
}

export const TableExtrasCell: FC<TableExtrasCellProps> = ({ extras }) => {
  if (!extras || !extras.length) return;
  if (typeof extras === 'string') {
    return <ExtrasChip label={extras} />;
  }
  return extras.map(mapExtra);
};
