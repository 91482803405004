import type { FC } from 'react';
import { MenuItem, ListItemText, ListItemIcon, Chip } from '@mui/material';
import SpatialAudioOff from '@mui/icons-material/SpatialAudioOff';
import { setContextAction } from '@/stores/ActionStore/ActionStore';
import { getActionMetadata } from '@/utils/actions';
import { BodyPart } from '@contract';
import { toggleHeaded } from '../actions';

const TOGGLE_HEADED_LABEL = 'Toggle headed';

type ToggleHeadedProps = {
  action: Parameters<typeof toggleHeaded>[0];
};

export const ToggleHeaded: FC<ToggleHeadedProps> = ({ action }) => {
  const isHeaded = getActionMetadata(action).bodyPart === BodyPart.Head;
  return (
    <MenuItem
      aria-label={TOGGLE_HEADED_LABEL}
      onClick={() => {
        toggleHeaded(action);
        setContextAction(null);
      }}
    >
      <ListItemIcon>{<SpatialAudioOff />}</ListItemIcon>
      <ListItemText disableTypography>{TOGGLE_HEADED_LABEL}</ListItemText>
      <Chip
        size='small'
        sx={{ marginLeft: 1 }}
        label={isHeaded ? 'OFF' : 'ON'}
      />
    </MenuItem>
  );
};
