import {
  Action,
  AdditionalTimeAction,
  BadTouchAction,
  type ClearanceAction,
  CrossAction,
  FreeKickAwardedAction,
  GoalAction,
  GoalkeeperPenaltyFacedAction,
  GoalkeeperSaveAction,
  GoalkeeperThrowAction,
  type InterceptionAction,
  LaunchAction,
  PassAction,
  PlayStoppedAction,
  type RedCardAction,
  ShotAction,
  type StartPeriodAction,
  TackleAction,
  VARResultAction,
  VARUnderwayAction,
  YellowCardAction,
} from '@/types/action/action';
import { ACTION_TYPE } from '@/types/action/actionType';
import {
  ACTION_TYPE_ID,
  BodyPart,
  CardReason,
  Extras,
  GoalType,
  PatternOfPlay,
  RedCardType,
} from '@contract';
import { PlayerName } from '@/components/PlayerName/PlayerName';
import { ACTION_CONTEXT, ATTEMPT_TYPE } from '@/components/Dialogs/constants';
import { GOAL_TYPE } from '@/components/Dialogs/GoalDialogContent/constants';
import { sortByActionTimes } from '@/utils/sortByActionTimes';
import { getActionMetadata } from '@/utils/actions';
import { camelCaseToSpace } from '@/utils/string';
import { VAR_UNDERWAY_REASON } from '@/components/Dialogs/VARUnderwayDialogContent/constants';
import { VAR_RESULT } from '@/components/Dialogs/VARResultDialogContent/constants';
import {
  FREE_KICK_TYPE,
  FreeKickType,
} from '@/components/Dialogs/FreeKickDialogContent/constants';
import { PLAY_STOPPED_REASON } from '@/components/Dialogs/PlayStoppedDialogContent/constants';
import {
  ASSIST_LABEL,
  HEADED_LABEL,
  OPPOSITE_TEAM_LABEL,
  PENALTY_LABEL,
} from '@/constants';
import {
  GOALKEEPER_SAVE_BODY_PART,
  GOALKEEPER_SAVE_EXTRAS,
  GOALKEEPER_SAVE_STANCE,
  GoalkeeperSaveBodyPart,
} from '@/components/Dialogs/GoalkeeperSaveDialogContent/constants';
import { GOALKEEPER_PENALTY_FACED_STANCE } from '@/components/Dialogs/GoalkeeperPenaltyFacedDialogContent/constants';
import { OFF_TARGET_DESTINATION } from '@/components/Dialogs/ShotDialogContent/constants';
import { type FixtureStore, type TableAction } from './FixtureStore';
import { ACTION_STATE } from './constants';

export function createTableActionsFromActions(
  actions: FixtureStore['actions'],
): FixtureStore['tableActions'] {
  return [...actions.values()]
    .map(([latestAction]) => latestAction)
    .filter(({ isRemoved }) => !isRemoved)
    .sort(sortByActionTimes)
    .map(mapToTableAction);
}

function mapActionBase(action: Action): TableAction {
  const {
    messageId,
    actionId,
    actionTypeId,
    clockTime,
    clockTimeTicks,
    createdAt,
    isRemoved,
    warnings,
    state,
    player,
    updatedAt,
    isSuccessful,
    period,
    team,
  } = action;
  return {
    messageId: messageId ?? undefined,
    typeId: actionTypeId,
    type: ACTION_TYPE[actionTypeId],
    time: clockTime,
    team: team ? team.name : undefined,
    clockTimeTicks: clockTimeTicks,
    createdAt,
    updatedAt,
    period,
    player: player ?? undefined,
    extras: undefined,
    actionId,
    isRemoved,
    warnings,
    state: state ?? ACTION_STATE.NONE,
    isSuccessful: isSuccessful,
  };
}

function mapPass(
  action: PassAction | LaunchAction | CrossAction | GoalkeeperThrowAction,
): TableAction {
  const metadata = getActionMetadata(action);

  const { isAssist, bodyPart } = metadata;
  const extras = [];
  if (metadata.actionContext !== null) {
    extras.push(ACTION_CONTEXT[metadata.actionContext]);
  }
  if (bodyPart === BodyPart.Head) {
    extras.push(HEADED_LABEL);
  }
  if (isAssist) {
    extras.push(ASSIST_LABEL);
  }

  return {
    ...mapActionBase(action),
    extras: extras,
  };
}

function mapShot(shot: ShotAction): TableAction {
  const {
    attemptType,
    assistPlayer,
    extras: metaExtras,
    shotOffTargetType,
  } = shot.metadata.shot;
  const extras: TableAction['extras'] = [ATTEMPT_TYPE[attemptType]];

  if (assistPlayer) {
    extras.push({
      key: assistPlayer.id,
      label: (
        <>
          {`${ASSIST_LABEL}: `}
          <PlayerName player={assistPlayer} />
        </>
      ),
    });
  }
  if (metaExtras) {
    metaExtras.forEach(
      (extra) => {
        extras.push(Extras[extra]);
      },
      [extras],
    );
  }

  if (shotOffTargetType !== null) {
    extras.push(OFF_TARGET_DESTINATION[shotOffTargetType]);
  }

  return {
    ...mapActionBase(shot),
    extras: extras,
  };
}

function mapGoal(goal: GoalAction): TableAction {
  const {
    assistPlayer,
    extras: metaExtras,
    goalType,
    patternOfPlay,
  } = goal.metadata.goal;
  const extras: TableAction['extras'] = [];

  if (assistPlayer) {
    extras.push({
      key: assistPlayer.id,
      label: (
        <>
          {`${ASSIST_LABEL}: `}
          <PlayerName player={assistPlayer} />
        </>
      ),
    });
  }
  if (metaExtras) {
    metaExtras.forEach(
      (extra) => {
        extras.push(Extras[extra]);
      },
      [extras],
    );
  }

  if (goalType !== null && goalType !== GoalType.Goal) {
    extras.push(GOAL_TYPE[goalType]);
  }

  if (patternOfPlay === PatternOfPlay.Penalty) {
    extras.push(PENALTY_LABEL);
  }

  return {
    ...mapActionBase(goal),
    extras,
  };
}

function mapCardAction(cardAction: YellowCardAction | RedCardAction) {
  const tableAction = mapActionBase(cardAction);
  const metadata = getActionMetadata(cardAction);
  tableAction.extras = [];

  if (
    metadata &&
    'outcome' in metadata &&
    metadata.outcome !== null &&
    metadata.outcome !== undefined
  ) {
    tableAction.extras.push(camelCaseToSpace(RedCardType[metadata.outcome]));
  }

  if (metadata && metadata.reason !== null && metadata.reason !== undefined) {
    tableAction.extras.push(camelCaseToSpace(CardReason[metadata.reason]));
  }

  return tableAction;
}

function mapVARUnderway(varAction: VARUnderwayAction): TableAction {
  const tableAction: TableAction = mapActionBase(varAction);
  const reason = varAction.metadata.varUnderway.reason;
  if (reason !== null) {
    tableAction.extras = VAR_UNDERWAY_REASON[reason];
  }
  return tableAction;
}

function mapVARResult(varAction: VARResultAction): TableAction {
  const tableAction: TableAction = mapActionBase(varAction);
  const outcome = varAction.metadata?.varResult?.outcome;
  if (outcome !== null) {
    tableAction.extras = VAR_RESULT[outcome];
  }
  return tableAction;
}

function mapFreeKick(freeKickAction: FreeKickAwardedAction): TableAction {
  const tableAction: TableAction = mapActionBase(freeKickAction);
  const isPenalty = freeKickAction.metadata.freeKickAwarded.isPenalty;
  if (isPenalty) {
    tableAction.extras = FREE_KICK_TYPE[FreeKickType.Penalty];
  }
  return tableAction;
}

function mapActionIsAssistOnly(
  action: TackleAction | BadTouchAction,
): TableAction {
  const tableAction: TableAction = mapActionBase(action);
  const metadata = getActionMetadata(action);

  const { isAssist } = metadata;

  if (isAssist) {
    tableAction.extras = [ASSIST_LABEL];
  }

  return tableAction;
}

function mapPlayStopped(playStoppedAction: PlayStoppedAction) {
  const tableAction: TableAction = mapActionBase(playStoppedAction);
  const { reason, isFromOppositeTeam } = getActionMetadata(playStoppedAction);

  tableAction.extras = [];

  if (reason !== null) {
    tableAction.extras.push(PLAY_STOPPED_REASON[reason]);
  }
  if (isFromOppositeTeam) {
    tableAction.extras.push(OPPOSITE_TEAM_LABEL);
  }

  return tableAction;
}
function mapClearance(action: ClearanceAction) {
  const tableAction = mapActionBase(action);
  const isHeaded = getActionMetadata(action).bodyPart === BodyPart.Head;
  if (isHeaded) {
    tableAction.extras = [HEADED_LABEL];
  }
  return tableAction;
}

function mapInterception(action: InterceptionAction) {
  const tableAction = mapActionBase(action);
  const isHeaded = getActionMetadata(action).bodyPart === BodyPart.Head;
  if (isHeaded) {
    tableAction.extras = [HEADED_LABEL];
  }
  return tableAction;
}

function mapGoalkeeperSave(goalkeeperSaveAction: GoalkeeperSaveAction) {
  const tableAction: TableAction = mapActionBase(goalkeeperSaveAction);
  const {
    type,
    extras: metaExtras,
    bodyPart,
  } = getActionMetadata(goalkeeperSaveAction);

  const extras: TableAction['extras'] = [];

  if (bodyPart !== null) {
    extras.push(GOALKEEPER_SAVE_BODY_PART[bodyPart as GoalkeeperSaveBodyPart]);
  }

  if (type !== null) {
    extras.push(GOALKEEPER_SAVE_STANCE[type]);
  }

  if (metaExtras !== null) {
    metaExtras.forEach((extra) => {
      extras.push(GOALKEEPER_SAVE_EXTRAS[extra]);
    });
  }

  return {
    ...tableAction,
    extras,
  };
}

function mapGoalkeeperPenaltyFaced(
  goalkeeperPenaltyFacedAction: GoalkeeperPenaltyFacedAction,
) {
  const tableAction: TableAction = mapActionBase(goalkeeperPenaltyFacedAction);
  const { stance } = getActionMetadata(goalkeeperPenaltyFacedAction);

  tableAction.extras = [];

  if (stance) {
    tableAction.extras.push(GOALKEEPER_PENALTY_FACED_STANCE[stance]);
  }

  return tableAction;
}

function mapStartPeriod(action: StartPeriodAction) {
  const tableAction: TableAction = mapActionBase(action);
  const { isHomeTeamLeft } = getActionMetadata(action);

  tableAction.extras = [isHomeTeamLeft ? 'Home:Away' : 'Away:Home'];

  return tableAction;
}

function mapAdditionalTime(
  additionalTimeAction: AdditionalTimeAction,
): TableAction {
  const tableAction: TableAction = mapActionBase(additionalTimeAction);
  const { extraTime } = getActionMetadata(additionalTimeAction);

  tableAction.extras = [];

  if (extraTime) {
    tableAction.extras.push(`Extra Time: ${extraTime}`);
  }

  return tableAction;
}

export function mapToTableAction(action: Action): TableAction {
  switch (action.actionTypeId) {
    case ACTION_TYPE_ID.Pass:
    case ACTION_TYPE_ID.Cross:
    case ACTION_TYPE_ID.Launch:
    case ACTION_TYPE_ID.GoalkeeperThrow:
      return mapPass(action);
    case ACTION_TYPE_ID.Shot:
      return mapShot(action);
    case ACTION_TYPE_ID.Goal:
      return mapGoal(action);
    case ACTION_TYPE_ID.YellowCard:
    case ACTION_TYPE_ID.RedCard:
      return mapCardAction(action);
    case ACTION_TYPE_ID.VARUnderway:
      return mapVARUnderway(action);
    case ACTION_TYPE_ID.VARResult:
      return mapVARResult(action);
    case ACTION_TYPE_ID.FreeKickAwarded:
      return mapFreeKick(action);
    case ACTION_TYPE_ID.Tackle:
    case ACTION_TYPE_ID.BadTouch:
      return mapActionIsAssistOnly(action);
    case ACTION_TYPE_ID.Clearance:
      return mapClearance(action);
    case ACTION_TYPE_ID.Interception:
      return mapInterception(action);
    case ACTION_TYPE_ID.PlayStopped:
      return mapPlayStopped(action);
    case ACTION_TYPE_ID.GoalkeeperSave:
      return mapGoalkeeperSave(action);
    case ACTION_TYPE_ID.GoalkeeperPenaltyFaced:
      return mapGoalkeeperPenaltyFaced(action);
    case ACTION_TYPE_ID.StartPeriod:
      return mapStartPeriod(action);
    case ACTION_TYPE_ID.AdditionalTime:
      return mapAdditionalTime(action);
    default:
      return mapActionBase(action);
  }
}
