export const camelCaseToSpace = (str: string) => {
  return str.replace(/([A-Z])/g, ' $1').trim();
};

export function toKebabCase(str: string) {
  const camelToSpace = camelCaseToSpace(str);
  const lower = camelToSpace.toLowerCase();
  const split = lower.split(' ');
  return split.join('-');
}
