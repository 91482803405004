import {
  OUTCOME_OPTION_LABEL,
  OUTCOME_TYPE,
} from '@/components/ActionOutcome/constants';
import { RadioButtons } from '@/components/Form/RadioButtons';
import { FILTER_NAME } from '@/stores/FiltersStore/constants';
import { NONE_LABEL } from '@/constants';
import type { FilterComponent } from '../constants';

const OUTCOME_OPTIONS = {
  [OUTCOME_TYPE.NONE]: NONE_LABEL,
  [OUTCOME_TYPE.SUCCESSFUL]: OUTCOME_OPTION_LABEL[OUTCOME_TYPE.SUCCESSFUL],
  [OUTCOME_TYPE.UNSUCCESSFUL]: OUTCOME_OPTION_LABEL[OUTCOME_TYPE.UNSUCCESSFUL],
};

export const OutcomeFilter: FilterComponent<'outcome'> = ({
  value,
  onChange,
}) => {
  return (
    <RadioButtons
      controlLabel={FILTER_NAME.OUTCOME}
      name={FILTER_NAME.OUTCOME}
      value={value}
      onChange={onChange}
      onClear={() => onChange(null)}
      fields={OUTCOME_OPTIONS}
    />
  );
};
