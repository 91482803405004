import { Stack } from '@mui/material';
import { FC } from 'react';
import { Autocomplete } from '@/components/Form/Autocomplete';
import { VARReason } from '@contract';
import { VARUnderwayAction } from '@/types/action/action';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { REASON_LABEL } from '@/constants';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { ParentAction } from '@/components/ParentAction/ParentAction';
import { Controls } from '../Common/Controls';
import { VAR_UNDERWAY_REASON_OPTIONS } from './constants';

interface VARUnderwayDialogContentProps {
  action: VARUnderwayAction;
}

const onReasonChange = (reason: VARReason) => {
  setActionPartialMetadata<VARUnderwayAction>({ reason });
};

export const VARUnderwayDialogContent: FC<VARUnderwayDialogContentProps> = ({
  action,
}) => {
  return (
    <Stack gap={2}>
      <Stack gap={6} direction='row'>
        <Stack gap={2}>
          <Autocomplete
            options={VAR_UNDERWAY_REASON_OPTIONS}
            value={action.metadata.varUnderway.reason || null}
            onChange={onReasonChange}
            label={REASON_LABEL}
          />
          <PeriodClockField action={action} />
          <ParentAction action={action} />
          <Controls action={action} />
        </Stack>
      </Stack>
    </Stack>
  );
};
