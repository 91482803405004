import { Tooltip } from '@mui/material';
import { FC } from 'react';
import Warning from '@mui/icons-material/Warning';
import { FixtureAction } from '@contract';
import { ActionWarningTooltip } from './ActionWarningTooltip';

export const WARNINGS_TEST_ID = 'warnings';
interface ActionWarningProps {
  warnings: NonNullable<FixtureAction['warnings']>;
}

export const ActionWarning: FC<ActionWarningProps> = ({ warnings }) => {
  return (
    <Tooltip
      title={<ActionWarningTooltip warnings={warnings} />}
      placement='top'
      arrow
    >
      <Warning data-testid={WARNINGS_TEST_ID} color='warning' />
    </Tooltip>
  );
};
