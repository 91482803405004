import type { FC } from 'react';
import { MenuItem, ListItemText, MenuList, Chip } from '@mui/material';
import AirlineStops from '@mui/icons-material/AirlineStops';
import { setContextAction } from '@/stores/ActionStore/ActionStore';
import { getActionMetadata } from '@/utils/actions';
import { ActionContext } from '@contract';
import { TOGGLE_ACTION_CONTEXT_LABEL } from '@/constants';
import { ACTION_CONTEXT } from '@/components/Dialogs/constants';
import { toggleActionContext } from '../actions';
import { NestedMenuItem } from '../NestedMenuItem';
import { toggleTakenContext } from './constants';

type ToggleTakenProps = {
  action: Parameters<typeof toggleActionContext>[0];
};

export const ToggleActionContext: FC<ToggleTakenProps> = ({ action }) => {
  const { actionContext } = getActionMetadata(action);

  const toggleTaken = (clickedActionContext: ActionContext) => {
    toggleActionContext(action, clickedActionContext);
    setContextAction(null);
  };

  return (
    <NestedMenuItem
      menuItemLabel={TOGGLE_ACTION_CONTEXT_LABEL}
      icon={<AirlineStops />}
    >
      <MenuList variant='menu' sx={{ py: 0 }}>
        {toggleTakenContext[action.actionTypeId].map((takenType) => (
          <MenuItem
            key={ACTION_CONTEXT[takenType]}
            onClick={() => toggleTaken(takenType)}
          >
            <ListItemText>{ACTION_CONTEXT[takenType]}</ListItemText>
            <Chip
              size='small'
              sx={{ marginLeft: 1 }}
              label={actionContext === takenType ? 'OFF' : 'ON'}
            />
          </MenuItem>
        ))}
      </MenuList>
    </NestedMenuItem>
  );
};
