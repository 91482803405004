import { FC } from 'react';
import GpsFixed from '@mui/icons-material/GpsFixed';
import { postAction } from '@/service/helpers/postAction';
import { setContextAction } from '@/stores/ActionStore/ActionStore';
import { OUTCOME_LABEL } from '@/constants';
import { useIsActionOutcomeEditable } from '@/utils/actions';
import type { PropsWithAction } from '../ActionContextMenuContent';
import { NestedMenuItem } from '../NestedMenuItem';
import { OutcomeList } from '../../ActionOutcome/OutcomeList';

export const ChangeOutcome: FC<PropsWithAction> = ({ action }) => {
  const isEditable = useIsActionOutcomeEditable(action.actionTypeId);
  if (!isEditable) return;
  const handleOutcomeSelection = (outcome: boolean | null) => {
    const editedAction = {
      ...action,
      isSuccessful: outcome,
    };

    postAction(editedAction);
    setContextAction(null);
  };

  return (
    <NestedMenuItem menuItemLabel={OUTCOME_LABEL} icon={<GpsFixed />}>
      <OutcomeList action={action} onClick={handleOutcomeSelection} />
    </NestedMenuItem>
  );
};
