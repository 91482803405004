import { Link as RouterLink, Outlet } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import {
  createHtmlPortalNode,
  InPortal,
  OutPortal,
} from 'react-reverse-portal';
import { BottomBar } from '@/components/BottomBar/BottomBar';
import {
  FixtureViewTabs,
  FixtureViewTabsProps,
} from '@/components/FixtureViewTabs/FixtureViewTabs';
import { NavBar } from '@/components/NavBar/NavBar';
import { useSocketStore } from '@/stores/SocketStore/SocketStore';
import { ROUTE_URL } from '@/router/constants';
import { LoadingOverlay } from '@/components/LoadingOverlay/LoadingOverlay';
import { HOME_BUTTON_LABEL } from '@/constants';
import { StreamVideo } from '@/components/StreamVideo/StreamVideo';
import { setStreamPortalNode, useUIStore } from '@/stores/UIStore';
import { useGetCollection } from '@/service/dataCollection';
import { useRetrySubsribeActions, useRetrySubsribeState } from '@/stores/hooks';
import { Error } from '@/components/Error/Error';
import { UserRole, type DataCollectionDto } from '@contract';
import { FIXTURE_VIEW_ROUTES } from '@/router/fixtureViewRoutes';
import { RoleError } from './RoleError';

const FixtureViewContent = ({
  collection,
}: {
  collection: DataCollectionDto;
}) => {
  const isSubscribedToActions = useSocketStore(
    (state) => state.actionsSubscribed,
  );
  const isSubscribedToState = useSocketStore((state) => state.stateSubscribed);
  const isStateReceived = useSocketStore((state) => state.stateReceived);
  const isActionsReceived = useSocketStore((state) => state.actionsReceived);
  const actionsError = useSocketStore((state) => state.actionsError);
  const stateError = useSocketStore((state) => state.stateError);
  const retrySubscribeActions = useRetrySubsribeActions();
  const retrySubscribeState = useRetrySubsribeState();
  const hasError = !!actionsError || !!stateError;

  const isLoading =
    !collection ||
    !isSubscribedToActions ||
    !isSubscribedToState ||
    !isStateReceived ||
    !isActionsReceived;

  if (hasError) {
    return (
      <Stack alignItems={'center'} justifyContent={'center'} flex='1'>
        {actionsError ? (
          <Error
            message='Could not subscribe to collection actions'
            onRetry={retrySubscribeActions}
          />
        ) : (
          <Error
            message='Could not subscribe to collection state'
            onRetry={retrySubscribeState}
          />
        )}
      </Stack>
    );
  }

  if (isLoading) {
    return (
      <LoadingOverlay>
        {!isSubscribedToActions && (
          <Typography variant='body2'>Subscribing to actions</Typography>
        )}
        {isSubscribedToActions && !isActionsReceived && (
          <Typography variant='body2'>Waiting for actions history</Typography>
        )}
        {!isSubscribedToState && (
          <Typography variant='body2'>
            Subscribing to collection state
          </Typography>
        )}
        {isSubscribedToState && !isStateReceived && (
          <Typography variant='body2'>Waiting for collection state</Typography>
        )}
      </LoadingOverlay>
    );
  }

  return <Outlet />;
};

export const FixtureView: FC<FixtureViewTabsProps> = ({ view }) => {
  const {
    data: collection,
    error: collectionError,
    isLoading: isCollectionLoading,
    mutate: collectionRetry,
  } = useGetCollection();
  const userRoles = collection && collection.roles;
  const $streamContainer = useUIStore((state) => state.$streamContainer);
  const $streamPortalNode = useMemo(
    () =>
      createHtmlPortalNode({
        attributes: { id: 'stream-container' },
      }),
    [],
  );

  useEffect(() => {
    setStreamPortalNode($streamPortalNode);
    return () => {
      setStreamPortalNode(null);
    };
  }, [$streamPortalNode]);

  if (!collection) {
    if (isCollectionLoading) {
      return (
        <LoadingOverlay>
          <Typography variant='body2'>Awaiting collection info</Typography>
        </LoadingOverlay>
      );
    }
    return (
      <Stack alignItems={'center'} justifyContent={'center'} flex='1'>
        {collectionError ? (
          <Error
            message='Could not fetch collection'
            onRetry={collectionRetry}
          />
        ) : (
          <Error message='Something went wrong' onRetry={collectionRetry} />
        )}
      </Stack>
    );
  }

  if (!userRoles || !userRoles.length) {
    return <RoleError error='You are not assigned to that collection' />;
  }

  if (
    (view === FIXTURE_VIEW_ROUTES.QA &&
      !userRoles.some((role) =>
        [UserRole.Qa, UserRole.Supervisor].includes(role),
      )) ||
    (view === FIXTURE_VIEW_ROUTES.HOME_COLLECTOR &&
      !userRoles.includes(UserRole.HomeScorer)) ||
    (view === FIXTURE_VIEW_ROUTES.AWAY_COLLECTOR &&
      !userRoles.includes(UserRole.AwayScorer))
  ) {
    return (
      <RoleError error='You are not assigned to that role in collection' />
    );
  }

  return (
    <Stack height='100vh' justifyContent='space-between'>
      <NavBar>
        <FixtureViewTabs view={view} />
        <Button
          component={RouterLink}
          to={ROUTE_URL.HOME}
          startIcon={<HomeIcon />}
        >
          {HOME_BUTTON_LABEL}
        </Button>
      </NavBar>

      <InPortal node={$streamPortalNode}>
        {
          <StreamVideo
            key={'video-content'}
            // src='https://imgarena-fe-event-data-poc.s3.amazonaws.com/Colorado_Rapids_v_FC_Dallas.mp4'
          />
        }
      </InPortal>

      {$streamContainer === null && <OutPortal node={$streamPortalNode} />}

      <Stack
        position='relative'
        flex='1'
        maxHeight={'100%'}
        overflow={'hidden'}
      >
        <FixtureViewContent collection={collection} />
      </Stack>

      <BottomBar />
    </Stack>
  );
};
