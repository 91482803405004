import { Stack, Drawer } from '@mui/material';
import { FC } from 'react';
import { useFiltersStore } from '@/stores/FiltersStore/FiltersStore';

import { FiltersDrawerVariant } from './constants';
import { FiltersDrawerContent } from './FiltersDrawerContent';

interface FiltersDrawerProps {
  variant: FiltersDrawerVariant;
}

export const FiltersDrawer: FC<FiltersDrawerProps> = ({ variant }) => {
  const drawerOpen = useFiltersStore((state) => state.isDrawerOpen);

  return (
    <Drawer id='filters-drawer' elevation={2} anchor='right' open={drawerOpen}>
      <Stack
        justifyContent='space-between'
        sx={{ p: 2, width: '500px', height: '100%' }}
      >
        <FiltersDrawerContent variant={variant} />
      </Stack>
    </Drawer>
  );
};
