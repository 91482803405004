import { FC, useState } from 'react';
import JSONPretty from 'react-json-pretty';
import {
  MenuItem,
  ListItemText,
  Stack,
  Typography,
  ListItemIcon,
  Tooltip,
  IconButton,
  Divider,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import DataObject from '@mui/icons-material/DataObject';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Close from '@mui/icons-material/Close';
import { Dialog } from '@/components/Dialog/Dialog';
import type { PropsWithAction } from '../ActionContextMenuContent';

export const SHOW_JSON_LIST_ITEM = 'Show JSON';
export const SHOW_JSON_DIALOG_TITLE = 'Action JSON';

export const DisplayDetails: FC<PropsWithAction> = ({ action }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <MenuItem
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        <ListItemIcon>
          <DataObject />
        </ListItemIcon>
        <ListItemText disableTypography>{SHOW_JSON_LIST_ITEM}</ListItemText>
      </MenuItem>

      <Dialog
        isOpen={isDialogOpen}
        title={
          <Stack
            justifyContent='space-between'
            flexDirection='row'
            alignItems='center'
          >
            <Typography variant='h6'>{SHOW_JSON_DIALOG_TITLE}</Typography>
            <IconButton onClick={() => setIsDialogOpen(false)}>
              <Close />
            </IconButton>
          </Stack>
        }
      >
        <Stack
          sx={{
            background: grey[900],
            width: '100%',
            borderRadius: '0.5rem',
          }}
        >
          <Stack alignItems='end'>
            <Tooltip title='Copy to clipboard' arrow>
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(JSON.stringify(action, null, 2))
                }
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <JSONPretty
            data={action}
            theme={{
              main: `line-height:1.3; color:${grey[400]};`,
              error: `line-height:1.3; color:#E53935; background:white;`,
              key: `color:#BA68C8;`,
              string: `color:#E53935;`,
              value: `color:#42A5F5;`,
              boolean: `color:${grey[400]};`,
            }}
            style={{ paddingInline: '1rem', overflow: 'auto' }}
          />
        </Stack>
      </Dialog>
    </>
  );
};
